import { stroot } from '@birdi/js-sdk';
import { user } from '@birdi/js-sdk';

export const FETCH = 'account/stats/FETCH';
export const FETCHING = 'account/stats/FETCHING';

export const get = () => (dispatch) => {
  dispatch({ type: FETCHING });
  return user
    .stats(stroot('ooc9ph'))
    .then((response) => dispatch({ type: FETCH, payload: response.body }))
    .catch((err) => console.log(err)); // TODO: Log application area
};

export const initialState = {
  loaded: false,
  dataUsage: 0,
  planAllowance: 0,
  missionCount: 0,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCHING:
      return Object.assign({}, initialState, { loaded: false });
    case FETCH:
      return Object.assign({}, state, payload, { loaded: true });
    default:
      return state;
  }
};
