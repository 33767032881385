/* global window */
import React from 'react';
import { auth, stroot } from '@birdi/js-sdk';
import { css } from '@emotion/react';
import SignOutSVG from '@birdi/icons/log-out.svg';

export const SignOutButton = () => (
  <button
    type="button"
    css={css`
      text-align: left;
      width: 100%;
      padding: 0.5rem;
      display: block;
    `}
    onClick={() => {
      auth.clearCookie(stroot('gsdzx3'));
    }}
  >
    <SignOutSVG
      css={css`
        font-size: 1.25em;
        margin-right: 1rem;
        margin-left: 0.25em;
        vertical-align: middle;
      `}
    />
    <span
      css={(theme) => css`
        vertical-align: middle;
        color: ${theme.midnight};
        font-weight: bold;
      `}
    >
      Log Out
    </span>
  </button>
);
