import { withRouter } from 'react-router-dom';
import { css } from '@emotion/react';
import { SideBar, SidebarLink, SideMenuHeader } from './side-bar-utils';
import { useMetaSelector } from '../../meta/reducer';
import BottomSection from './bottom-section';

const SideNavigationPilot = withRouter(({ history }) => {
  const meta = useMetaSelector();
  const jobNotif = meta.jobNotifications;
  return (
    <div
      css={css`
        position: relative;
      `}
    >
      <SideBar>
        <SideMenuHeader label="Pilot Settings" id="pilot-settings" />
        <SidebarLink to="/user/pilot" match={history.location.pathname}>
          <span>Pilot Profile</span>
        </SidebarLink>
        <SidebarLink to="/user/credentials" match={history.location.pathname}>
          <span>Credentials</span>
        </SidebarLink>
        <SidebarLink to="/equipment" match={history.location.pathname}>
          <span>Equipment</span>
        </SidebarLink>
        <div
          css={css`
            height: 7em;
          `}
        />
      </SideBar>
      <BottomSection />
    </div>
  );
});

export default SideNavigationPilot;
