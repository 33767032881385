import React, { useEffect, useState } from 'react';
import { css } from '@emotion/react';
import {
  pageTitleHolder,
  pageTitle,
  pageGraySection,
} from '@birdi/theme/blocks';
import { BirdiTheme } from '@birdi/theme';
import { OrganisationCampaignSelect } from './CampaignSelect';
import { InspectionTable } from './InspectionTable';

const MAX_WIDTH = '414px';

export const Inspections = () => {
  // TODO: Contain this logic inside button component
  const [campaignId, setCampaignId] = useState<string>(null);
  return (
    <div>
      <div css={pageTitleHolder}>
        <h2 css={pageTitle}>Inspection Summary</h2>
      </div>
      <div css={pageGraySection}>
        <p>View, download and access your inspection progress.</p>
      </div>
      <div
        css={css`
          padding: 1em;
        `}
      >
        <OrganisationCampaignSelect
          onSelect={(option) => {
            setCampaignId(option.value);
          }}
          onClear={() => {
            setCampaignId(null);
          }}
        />
      </div>
      <div
        css={css`
          box-sizing: border-box;
          padding: 0.5em 1rem;
          width: 100%;
          @media (max-width: ${MAX_WIDTH}) {
            padding: 1rem 1.2rem;
          }
        `}
      >
        <InspectionTable campaignId={campaignId} />
      </div>
    </div>
  );
};
