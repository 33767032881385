/* globals window */

import React from 'react';
import { connect } from 'react-redux';
import { css } from '@emotion/react';
import { Link } from 'react-router-dom';
import ExternalLinkSVG from '@birdi/icons/external-link.svg';
import { CSSTransition } from 'react-transition-group';
import { ContextMenuHOC } from '@birdi/context-menu';
import { hasPermission } from '@birdi/utils/src/helpers';
import { overlayMenuContainerCSS } from '@birdi/theme/blocks';
import { auth, stroot } from '@birdi/js-sdk';
import SignOutSVG from '@birdi/icons/log-out.svg';
import ContactUsSVG from '@birdi/icons/menu-contactus2.svg';
import SuggestSVG from '@birdi/icons/menu-suggest2.svg';
import { BirdiTheme } from '@birdi/theme';
import { TopMenuButton } from '../side-menu/side-bar-utils';
import history from '../../state/history';
import { FeatureFlagNames } from '@birdi/js-sdk/src/public/organisation-features';

const avatarButtonCSS = (url: string) => (theme: BirdiTheme) => css`
  background-image: url('${url}');
  background-position: center;
  background-size: cover;
  background-color: ${theme.flora};
  color: white;
  width: 3em;
  height: 3em;
  border-radius: 3em;
`;

const linkStyle = (theme: BirdiTheme) => css`
  // vertical-align: center;
  color: ${theme.midnight};
  padding: 0.5em;
  width: 95%;
  border-radius: 3px;
  &:hover {
    background: ${theme.lightOcean};
  }
  .icon-external {
    font-size: 0.75em;
    color: black;
    margin-left: 0.5em;
  }
  .icon-comment {
    font-size: 1.5em;
    margin-right: 0.5em;
    vertical-align: middle;
  }
`;

const breakLineStyle = (theme: BirdiTheme) => css`
  height: 2px;
  background: ${theme.mono20};
  width: 95%;
  margin: 0.25em 0;
`;

const AvatarButtonInternal = ContextMenuHOC(
  ({
    toggleRef,
    toggle,
    open,
    wrapperRef, // Context menu props
    meta, // contextual props
  }) => {
    const costCalculator = meta.orgFeatureFlags.includes(
      FeatureFlagNames.GigapixelCalculator,
    );
    const jobNotif = meta.jobNotifications;
    const manageUsers =
      meta.organisation && hasPermission(meta.permissions, 'org:manage-users');
    const manageOrg =
      meta.permissions.includes('org:manage-org') ||
      meta.opsActions.includes('access_ops');
    const customBranding = meta.orgFeatureFlags.includes(
      FeatureFlagNames.CustomBranding,
    );

    const isAdmin = meta.permissions.includes('org:manage-users'); // (meta.scopeRole === 'Admin' || meta.scopeRole === 'Creator' || meta.scopeRole === 'Operations');

    return (
      <div css={css(overlayMenuContainerCSS)}>
        <button
          type="button"
          css={avatarButtonCSS(meta.profilePic)}
          ref={toggleRef}
          onClick={toggle}
        >
          {!meta.profilePic && meta.fname && meta.fname[0]}
        </button>
        <CSSTransition
          in={open}
          timeout={150}
          classNames="drop-menu"
          unmountOnExit
        >
          <div
            css={(theme: BirdiTheme) => css`
            position: absolute;
            display: flex;
            flex-direction: column;
            top: 3.5rem;
            right: 0;
            min-width: 32em;
            color: ${theme.midnight};
            background: white;
            box-shadow: 0 0 10px ${theme.mono40};
            border-radius: 5px;
            overflow: hidden;
            .divider {
              border-top: 2px solid ${theme.mono20};
            }
            .item {
              flex: 1;
              white-space: nowrap;
              text-align: left;
              border-radius: 3px;
              &:hover {
                background: ${theme.lightOcean};
              }
              &:disabled {
                background: ${theme.mono0};
                cursor: default;
              }
            }
          }`}
            ref={wrapperRef}
          >
            <div
              css={(theme: BirdiTheme) => css`
                display: flex;
                flex-direction: row;
                padding: 1em;
                .header-item {
                  font-weight: bold;
                  padding: 0.5rem;
                }
              `}
            >
              <div
                css={css`
                  display: flex;
                  flex: 1;
                  flex-direction: column;
                  justify-content: flex-start;
                  align-items: flex-start;
                `}
              >
                <span className="header-item">Account</span>
                <div css={breakLineStyle} />
                <TopMenuButton
                  link="/admin-general"
                  label="Administration"
                  closeMenu={toggle}
                  disabled={!isAdmin}
                />
                <TopMenuButton
                  link="/admin-plan"
                  label="Billing & Subscription"
                  closeMenu={toggle}
                  disabled={!isAdmin}
                />
                <TopMenuButton
                  link="/admin-customization"
                  label="Customization"
                  closeMenu={toggle}
                  disabled={!isAdmin || !customBranding}
                />
                <TopMenuButton
                  link={`/profile-general/${meta.scopeId}`}
                  label="My Profile"
                  closeMenu={toggle}
                />
                <TopMenuButton
                  link="/admin-users"
                  label="User Management"
                  closeMenu={toggle}
                />
                {/* <TopMenuButton link="/workspace-general" label="Workspaces" closeMenu={toggle} /> */}
                <TopMenuButton
                  link="/assessment-types"
                  label="Inspection Tools"
                  closeMenu={toggle}
                />
                <TopMenuButton
                  link="/user/pilot"
                  label="Pilot Settings"
                  closeMenu={toggle}
                />
              </div>
              <div
                css={css`
                  display: flex;
                  flex: 1;
                  flex-direction: column;
                `}
              >
                <span className="header-item">Help</span>
                <div css={breakLineStyle} />
                {costCalculator && (
                  <TopMenuButton
                    link="/cost-calculator"
                    label="Processing Cost Calculator"
                    closeMenu={toggle}
                  />
                )}
                <Link
                  to={{ pathname: 'https://help.birdi.io/' }}
                  target="_blank"
                  css={linkStyle}
                >
                  <span>Knowledge Base</span>
                  <ExternalLinkSVG className="icon-external" />
                </Link>
                <Link
                  to={{ pathname: 'https://www.birdi.io/partner-program' }}
                  target="_blank"
                  css={linkStyle}
                >
                  <span>Partner Program</span>
                  <ExternalLinkSVG className="icon-external" />
                </Link>
                <Link
                  to={{ pathname: 'https://www.birdi.io/terms-and-conditions' }}
                  target="_blank"
                  css={linkStyle}
                >
                  <span>Terms & Conditions</span>
                  <ExternalLinkSVG className="icon-external" />
                </Link>
                <div css={breakLineStyle} />
                <button
                  type="button"
                  onClick={() => {
                    auth.clearCookie(stroot('bsdzx3'));
                  }}
                  css={(theme: BirdiTheme) => css`
                    display: flex;
                    justify-content: flex-start;
                    padding: 0.5em;
                    border-radius: 3px;
                    &:hover {
                      background: ${theme.lightOcean};
                    }
                  `}
                >
                  <SignOutSVG
                    css={css`
                      stroke-width: 2px;
                      vertical-align: middle;
                      margin-right: 0.5em;
                    `}
                  />
                  <span
                    css={(theme: BirdiTheme) => css`
                      vertical-align: middle;
                      color: ${theme.midnight};
                      font-weight: bold;
                    `}
                  >
                    Log Out
                  </span>
                </button>
              </div>
            </div>
            {/* <hr className="divider" /> */}
            <div
              css={(theme: BirdiTheme) => css`
                display: flex;
                flex-direction: row;
                background: ${theme.mono20};
                padding: 0.75em;
              `}
            >
              <Link
                to={{
                  pathname:
                    'https://forms.monday.com/forms/0d33a85fe9a0f250f31fe772487c2978?r=use1',
                }}
                target="_blank"
                css={linkStyle}
              >
                <SuggestSVG className="icon-comment" />
                <span>Suggest a feature</span>
              </Link>
              <a href="mailto:support@birdi.io" css={linkStyle}>
                <ContactUsSVG className="icon-comment" />
                <span>Contact us</span>
              </a>
            </div>
          </div>
        </CSSTransition>
      </div>
    );
  },
);

export const AvatarButton = connect((state) => ({
  meta: state.meta,
}))(AvatarButtonInternal);
