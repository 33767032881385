/* globals confirm */

import React from 'react';
import { css } from '@emotion/react';
import ExternalLinkSVG from '@birdi/icons/external-link.svg';
import { ContextMenu, contextStyles } from '@birdi/context-menu/ContextMenu';
import TrashSVG from '@birdi/icons/trash.svg';
import EditSVG from '@birdi/icons/edit.svg';
import CheckCircleSVG from '@birdi/icons/check-circle.svg';
import XCircleSVG from '@birdi/icons/x-circle.svg';
import { format, parseISO, isAfter } from 'date-fns';
import { useDispatch } from 'react-redux';

const CredentialCard = ({
  credential,
  deleteCredential,
  editCredential,
  isAdmin = false,
  verifyCredential,
  removeVerification,
}) => {
  const dispatch = useDispatch();
  const hasExpired = isAfter(new Date(), parseISO(credential.expiryDate));
  return (
    <li
      className="certification"
      css={(theme) => css`
        position: relative;
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        margin-right: 1em;
        margin-bottom: 1em;
        padding: 1.5em;
        border-radius: 2px;
        width: 19.5rem;
        min-height: 10em;
        max-width: 22em;
        background: ${theme.mono0};
        border: solid 1px ${theme.mono30};
        h3 {
          font-size: 1.25em;
          margin: 0;
          margin-bottom: 1em;
        }
      `}
    >
      <div
        css={css`
          position: absolute;
          top: 1rem;
          right: 1rem;
        `}
      >
        <ContextMenu>
          {(props) => (
            <div
              css={(theme) => css`
                ${contextStyles(theme)};
                min-width: ${isAdmin ? '13em' : '6em'};
              `}
            >
              <button type="button" onClick={() => editCredential(credential)}>
                <span>
                  <EditSVG />
                </span>
                <span>Edit</span>
              </button>
              <button
                type="button"
                onClick={() => {
                  props.hide();
                  confirm(
                    'Are you sure you want to remove this certificate?',
                  ) && deleteCredential(dispatch, credential.id);
                }}
              >
                <span>
                  <TrashSVG />
                </span>
                <span>Delete</span>
              </button>
              {isAdmin && credential.verified === 'PENDING' && (
                <button
                  type="button"
                  onClick={() => {
                    props.hide();
                    verifyCredential(credential.id);
                  }}
                >
                  <span>
                    <CheckCircleSVG />
                  </span>
                  <span>Mark as verified</span>
                </button>
              )}
              {isAdmin && credential.verified === 'VERIFIED' && (
                <button
                  type="button"
                  onClick={() => {
                    props.hide();
                    removeVerification(credential.id);
                  }}
                >
                  <span>
                    <XCircleSVG />
                  </span>
                  <span>Clear verification</span>
                </button>
              )}
            </div>
          )}
        </ContextMenu>
      </div>
      <h3
        css={css`
          max-width: 85%;
        `}
      >
        {credential.acronym || credential.credential || credential.customType}
      </h3>
      {credential.acronym && (
        <p
          css={css`
            margin: 0;
          `}
        >
          {credential.credential}
        </p>
      )}
      <p
        css={css`
          margin: 0;
        `}
      >
        {credential.number}
      </p>
      {credential.upload && (
        <a href={credential.upload} target="_blank" rel="noopener noreferrer">
          <span>Attachment</span>
          <ExternalLinkSVG
            css={css`
              padding-left: 0.25rem;
              transform: translate(0, 2px);
            `}
          />
        </a>
      )}
      {credential.expiryDate && (
        <p
          css={css`
            width: fit-content;
            padding: 0 2px;
            border-radius: 2px;
            margin-top: auto;
            margin-bottom: 0;
            background: ${hasExpired
              ? 'rgb(255, 200, 200)'
              : 'rgb(200, 255, 212);'};
            color: black;
          `}
        >
          {`${hasExpired ? 'Expired' : 'Expires'} ${format(new Date(credential.expiryDate), 'dd MMM yyyy')}`}
        </p>
      )}
      {isAdmin && credential.verified === 'PENDING' && (
        <p
          css={(theme) => css`
            margin: 2em 0 0 0;
            color: ${theme.mono50};
          `}
        >
          Pending verification
        </p>
      )}
      {isAdmin && credential.verified === 'VERIFIED' && (
        <footer
          css={css`
            margin: 2em 0 0 0;
          `}
        >
          <span
            css={css`
              margin-right: 0.5em;
            `}
          >
            <CheckCircleSVG />
          </span>
          Verified by&nbsp;
          <a
            css={css`
              color: inherit;
              text-decoration: underline;
            `}
            href={`/users/${credential.verifiedBy}`}
          >
            {credential.verifiedByName}
          </a>
          {` on ${format(parseISO(credential.verifiedAt), 'yyyy-MM-dd')}`}
        </footer>
      )}
    </li>
  );
};

export default CredentialCard;
