import { stroot, sites as sitesAPI } from '@birdi/js-sdk';
import { sites as adminSitesAPI } from '@birdi/js-sdk/src/admin';
import { css } from '@emotion/react';
import React, { useEffect, useState } from 'react';
import { reactSelect } from '@birdi/theme/form';
import AsyncCreatableSelect from 'react-select/async-creatable';
import {
  components,
  SingleValueProps,
  OptionProps,
  SingleValue,
} from 'react-select';

const RADIUS = 50; // radius in km to search for the site from mission location

export interface Site {
  id: string;
  name: string;
  organisation: {
    id: string;
    name: string;
  };
}

interface SiteOption {
  label: string;
  value: string;
  location?: any;
  data: Site;
}

interface SiteSelectProps {
  title: string;
  theme?: any;
  onChange: (option: SiteOption | null) => void;
  defaultValue?: SiteOption;
  defaultValueAsync?: string; // Resolves value by id
  coordinates?: undefined | Array<number>; // | null
  organisationId?: string | null;
  isCreatable: boolean;
  isClearable: boolean;
  placeholder: string;
  selectedOption: SiteOption | null;
  global: boolean;
  inputId: string;
  controlStyles?: any;
  containerStyles?: any;
}

const SiteOption = (props: OptionProps<SiteOption>) => {
  const { isSelected, data } = props;
  return (
    <components.Option {...props}>
      <div>
        <div>{data.label}</div>
        {data.location && (
          <div
            css={css`
              ${(!isSelected && 'color: grey') || ''}
            `}
          >
            {data.location.name}
          </div>
        )}
        {data?.data?.organisation && (
          <div
            css={css`
              ${(!isSelected && 'color: grey') || ''}
            `}
          >
            {data.data.organisation.name}
          </div>
        )}
      </div>
    </components.Option>
  );
};

const SiteSingleValue = (props: SingleValueProps<SiteOption>) => {
  const { data } = props;
  return (
    <components.SingleValue {...props}>
      <div>
        <div>{data.label}</div>
        {data.location && (
          <div style={{ color: 'grey' }}>{data.location.name}</div>
        )}
      </div>
    </components.SingleValue>
  );
};

interface DefaultSiteAsync {
  loading: boolean;
  option?: SiteOption;
}

export const SiteSelect: React.FC<SiteSelectProps> = ({
  title,
  theme,
  onChange,
  defaultValue = null,
  defaultValueAsync,
  coordinates = null,
  organisationId = null,
  isClearable = true,
  isCreatable = false,
  placeholder = 'Search sites',
  containerStyles = {},
  controlStyles = {},
  selectedOption = null,
  global = false,
  inputId,
  onResolveInitial,
}) => {
  const [defaultValAsync, setDefaultValAsync] = useState<DefaultSiteAsync>({
    loading: true,
  });
  useEffect(() => {
    if (
      typeof defaultValueAsync !== 'string' ||
      defaultValueAsync.length === 0
    ) {
      setDefaultValAsync({ loading: false });
    }
    if (typeof defaultValueAsync === 'string') {
      sitesAPI
        .getSiteInfo(stroot('idoe9i'), defaultValueAsync)
        .then((res) => {
          const option = {
            label: res.body.name,
            value: res.body.id,
            location: res.body.location,
            data: res.body,
          };
          const val = {
            loading: false,
            option,
          };
          setDefaultValAsync(val);
        })
        .catch((err) => {
          console.log(err);
          setDefaultValAsync({ loading: false });
        });
    }
  }, []);

  const loadOptions = async (search = '', callback) => {
    let res;
    if (global) {
      const properties = {
        search,
      };
      res = await adminSitesAPI.searchSites(stroot('zae4ol'), properties);
    } else {
      const properties = {
        ...(search && { search }),
        ...(organisationId && { organisationId }),
        ...(coordinates && { coordinates }),
      };
      res = await sitesAPI.searchSites(stroot('kuo9qu'), properties);
    }
    const options = res.body?.sites.map((site) => ({
      label: site.name,
      value: site.id,
      location: site.location,
      data: site,
      global,
    }));
    callback(options);
  };

  // Used to prevent creatable options
  const isValidNewOption = (inputValue: string) => {
    return isCreatable && inputValue !== '';
  };

  if (defaultValAsync.loading === true) {
    return false;
  }

  return (
    <AsyncCreatableSelect
      theme={theme}
      defaultValue={defaultValAsync.option || defaultValue}
      loadOptions={loadOptions}
      onChange={(option) => onChange(option)}
      placeholder={placeholder}
      isClearable={isClearable}
      isValidNewOption={isValidNewOption}
      styles={{
        ...reactSelect,
        container: (base) => ({
          ...base,
          ...containerStyles,
        }),
        input: (base) => ({
          ...base,
          padding: '0.75rem',
        }),
        control: (base) => ({
          ...base,
          height: '100%',
          borderRadius: '3px',
          border: `solid #e0e0e0 1px`,
          ...controlStyles,
        }),
      }}
      key={'site-select'} // reload trigger
      inputId={inputId || 'site-select'}
      instanceId={'site-select'}
      // cacheOptions
      // defaultOptions
      // getOptionValue={(option) => option.value}
      components={{
        Option: SiteOption,
        SingleValue: SiteSingleValue,
      }}
    />
  );
};
