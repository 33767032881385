import { css } from '@emotion/react';

// TODO: perhaps adjust line height
export const root = css`
  display: grid;
  grid-template-columns: auto 1fr;
  box-sizing: border-box;
  column-gap: 0.5rem;
  margin: 0;
`;

export const ddiv = css`
  display: grid;
  grid-template-columns: subgrid;
  grid-column: var(--dl-item-col, span 2);
  box-sizing: border-box;
  align-items: var(--datalist-item-align, auto);
`;

export const dl = css`
  box-sizing: border-box;
  margin: 0;
  font-weight: 700;
`;

export const dt = css`
  box-sizing: border-box;
  margin: 0;
  font-weight: 700;

  &::after {
    content: ': ';
  }
`;

export const dd = css`
  box-sizing: border-box;
  font-weight: 400;
  justify-self: var(--datalist-value-justify, auto);
`;
