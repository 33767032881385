import React from 'react';
import { formStyles } from '@birdi/theme/blocks';
import { css } from '@emotion/react';
import ReactCodeInput from 'react-verification-code-input';
import { BirdiTheme, buttonBlue } from '@birdi/theme';
import ErrorDisplay from '../common/ErrorDisplay';
import { LoadZXCVBN, ZXCVBNDisplay } from '../common/password/zxcvbn';
import Password from '../common/password/Password';
import { Length } from '../common/password/Length';

const EnterCode = ({
  form,
  auth,
  _updateField,
  onSubmit,
  setPassword,
  password,
  zxcvbnResult,
  search,
  label = 'Enter password',
}) => (
  <form
    ref={form}
    onSubmit={onSubmit}
    css={(theme: BirdiTheme) => css(formStyles(theme))}
  >
    <LoadZXCVBN onLoad={() => {}} />
    {!search && (
      <div>
        <label htmlFor="code">
          Reset Code
          <div
            /**
             * Hacky overrides for global input styles
             * TODO: Remove global input styles
             */
            css={css`
              & input {
                display: inline !important;
                text-transform: uppercase;
              }
            `}
          >
            <ReactCodeInput
              id="code"
              type="text"
              onChange={(code) => _updateField({ code: code.toUpperCase() })}
              required
            />
          </div>
        </label>
      </div>
    )}
    <ErrorDisplay error={auth.error} path="code" />
    <Password label={label} password={password} setPassword={setPassword} />
    <ErrorDisplay error={auth.error} path="password" />
    <Length password={password} />
    <ZXCVBNDisplay zxcvbnResult={zxcvbnResult} />
    <div
      css={css`
        margin-top: 1rem;
      `}
    >
      <input
        type="submit"
        css={(theme: BirdiTheme) => css`
          ${buttonBlue(theme, { baseSize: 'md', fullWidth: true })};
          font: inherit;
        `}
        value={label}
      />
    </div>
    <ErrorDisplay error={auth.error} />
  </form>
);

export default EnterCode;
