import { stroot, missionSharing } from '@birdi/js-sdk';
import React from 'react';
import { css } from '@emotion/react';
import { button, selectContainer, formStyles } from '@birdi/theme/blocks';
import { useForm } from 'react-hook-form';
import { errorMessage } from '@birdi/theme/form';
import CaretSVG from '@birdi/icons/caret.svg';
import { isEmail } from 'validator';

interface InviteViaEmailProps {
  administrator;
  missionId: string;
  setUsers: (any) => void;
}

export const InviteViaEmail: React.FunctionComponent<InviteViaEmailProps> = ({
  administrator,
  missionId,
  setUsers,
}) => {
  const formMethods = useForm();

  const submit = () => {
    const obj = formMethods.getValues();
    if (typeof obj.email !== 'string' || !isEmail(obj.email)) {
      formMethods.setError('emailInvalid');
    } else {
      missionSharing
        .addUser(
          stroot('ahth5u'),
          missionId,
          Object.assign(obj, administrator && { readOnly: true }),
        )
        .then((res) => {
          setUsers(res.body);
          formMethods.reset();
        })
        .catch((err) => {
          if (err.status === 400) {
            formMethods.setError('notUniqueEmail');
          } else {
            console.log(err);
          }
        });
    }
  };

  return (
    <form
      onSubmit={formMethods.handleSubmit(submit)}
      css={(theme) => css`
        display: flex;
        flex-direction: column;
        border-top: 1px solid ${theme.mono20};
        padding: 1em 0 0 0;
      `}
    >
      <h3>Invite someone via email</h3>
      <div
        css={(theme) => css`
          ${formStyles(theme)} display: flex;
        `}
      >
        <div
          css={css`
            flex: 1 1 auto;
          `}
        >
          <input
            type="text"
            id="email"
            placeholder="example@email.com"
            {...formMethods.register('email', { required: true })}
            onChange={() => {
              formMethods.clearErrors('emailInvalid');
              formMethods.clearErrors('notUniqueEmail');
            }}
          />
          {formMethods.formState.errors.email && (
            <div css={errorMessage}>Field is required</div>
          )}
          {formMethods.formState.errors.emailInvalid && (
            <div css={errorMessage}>Email is invalid</div>
          )}
          {formMethods.formState.errors.notUniqueEmail && (
            <div css={errorMessage}>
              This map is already being shared with this user
            </div>
          )}

          <textarea
            style={{ resize: 'none' }}
            type="message"
            id="message"
            placeholder="Enter personalised message (Optional)"
            {...formMethods.register('message')}
          />
        </div>
      </div>
      <div
        css={css`
          display: flex;
          justify-content: flex-end;
        `}
      >
        <div css={selectContainer}>
          <select type="role" id="role" {...formMethods.register('role')}>
            <option value="view">View only</option>
            <option value="edit">Edit</option>
            <option value="inspect">Inspect</option>
          </select>
          <CaretSVG />
        </div>
        <button
          type="submit"
          css={(theme) => css`
            ${button(theme)} padding: 0 2rem 0 2em;
          `}
        >
          Share
        </button>
      </div>
    </form>
  );
};
