/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/interactive-supports-focus */
import React, { useState, forwardRef } from 'react';
import { css } from '@emotion/react';
import DatePicker from 'react-datepicker';
import { format } from 'date-fns';
import BirdiIcon, { Icons, ICONS_CODEPOINTS } from '@birdi/icons';
import { BirdiTheme, useTheme } from '@birdi/theme';

type CustomInputProps = {
  value: string;
  onClick: (event: React.MouseEvent) => void;
  icon: Icons;
  placeholder;
};
const CustomInput = forwardRef<HTMLButtonElement, CustomInputProps>(
  ({ value, onClick, icon, placeholder }, ref) => {
    const { theme: currentTheme } = useTheme() as { theme: BirdiTheme };
    return (
      <button
        type="button"
        css={(theme: BirdiTheme) => css`
          padding: 1rem 0.5rem;
          color: ${theme.darkGrey};
          background: none;
          display: flex;
          align-items: center;
          min-width: 18em;
        `}
        ref={ref}
        onClick={onClick}
      >
        {icon && (
          <>
            <BirdiIcon icon={icon} color={currentTheme.darkGrey} />
            <span>&nbsp;</span>
          </>
        )}
        <span
          css={css`
            display: inline-block;
            font-size: 1rem;
            text-align: left;
          `}
        >
          {value || placeholder}
        </span>
      </button>
    );
  },
);

interface DatePickerRangeProps {
  type: 'range';
  onChange: (dates: [string, string] | null) => void;
}

interface DatePickerSingleProps {
  type: 'single';
  onChange: (dates: string) => void;
}

type DatePickerProps = (DatePickerRangeProps | DatePickerSingleProps) & {
  minDate?: Date;
  defaultStartDate?: Date | null;
  defaultEndDate?: Date | null;
  icon?: Icons;
  placeholder?: string;
};

const clearIcon = `'\\${parseInt(ICONS_CODEPOINTS[Icons.Close], 10).toString(16)}'`;

const BirdiDatePicker = ({
  type,
  onChange,
  icon,
  placeholder,
  defaultStartDate,
  defaultEndDate,
  minDate,
}: DatePickerProps): JSX.Element => {
  const [localDates, setLocalDates] = useState([
    defaultStartDate,
    defaultEndDate,
  ]);

  const updateDate = (dates: [Date, Date]) => {
    setLocalDates(dates);
    if (type === 'range') {
      if (dates[0] && dates[1]) {
        onChange([
          format(dates[0], 'yyyy/MM/dd'),
          format(dates[1], 'yyyy/MM/dd'),
        ]);
      } else if (!dates[0] && !dates[1]) {
        onChange(null);
      }
    }
    if (type === 'single') onChange(format(dates[0], 'yyyy/MM/dd'));
  };

  if (type === 'range') {
    return (
      <div
        css={(theme: Theme) => css`
          .react-datepicker__input-container {
            display: flex;
            align-items: center;
            border: solid 1px ${theme.mono30};
            min-width: calc(20em - 5px);
            height: 3em;
            border-radius: 3px;
            &:hover {
              border: solid 1px ${theme.mono50};
            }
          }
          .react-datepicker__close-icon {
            font-family: icons !important;
            position: relative;
            margin: 0;
            padding: 0;
            margin-right: 0.5rem;
            &:after {
              content: ${clearIcon};
              background-color: transparent;
              color: ${theme.darkGrey};
            }
          }
        `}
      >
        <DatePicker
          id="dates"
          selectsRange
          minDate={minDate}
          startDate={localDates[0]}
          endDate={localDates[1]}
          onChange={(dates) => updateDate(dates)}
          shouldCloseOnSelect={localDates[0] && !localDates[1]}
          customInput={<CustomInput icon={icon} />}
          isClearable
          placeholderText={placeholder}
        />
      </div>
    );
  }

  if (type === 'single') {
    throw new Error('Not yet implemented');
  }

  throw new Error('Invalid type for DatePicker');
};

BirdiDatePicker.defaultProps = {
  minDate: null,
  defaultStartDate: null,
  defaultEndDate: null,
  icon: '',
  placeholder: '',
};

export { BirdiDatePicker };
