/* global fetch */

import { config } from '../config';
import { headers, APIResponse, stleaf, fetchAPI, SpanTag } from '../helpers';
import querystring from 'querystring';

export const getSelf = (st: SpanTag): Promise<APIResponse> => {
  const url = `${config.API_URL}/v1/users/me`;
  return fetchAPI(stleaf(st, 'uph8ie'), url, {
    method: 'GET',
    credentials: 'include',
    headers,
  });
};



export const getMeta = (st: SpanTag): Promise<APIResponse> => {
  const url = `${config.API_URL}/v2/user/meta`;
  
  return fetchAPI(stleaf(st, 'iewe6o'), url, {
    method: 'GET',
    credentials: 'include',
    headers: headers,
  });
};

export const getScopes = (st: SpanTag): Promise<APIResponse> => {
  const url = `${config.API_URL}/v1/users/me/scopes`;
  return fetchAPI(stleaf(st, 'oem8di'), url, {
    method: 'GET',
    credentials: 'include',
    headers,
  });
};

export const stats = (st: SpanTag): Promise<APIResponse> => {
  const url = `${config.API_URL}/v1/users/me/stats`;
  return fetchAPI(stleaf(st, 'cuf5on'), url, {
    method: 'GET',
    credentials: 'include',
    headers,
  });
};

interface ParamsOpts {
  email: string;
  fname: string;
  lname: string;
  password: string;
}
export const create = (
  st: SpanTag,
  params: ParamsOpts,
): Promise<APIResponse> => {
  const url = `${config.API_URL}/v2/user`;
  return fetchAPI(stleaf(st, 'hosha0'), url, {
    method: 'POST',
    credentials: 'include',
    headers,
    body: JSON.stringify(params),
  });
};

interface FieldsToUpdateOpts {
  fname: string;
  lname: string;
  phone: string;
  jobNotifications: string;
  // anything else?
}
export const update = (
  st: SpanTag,
  id = 'me',
  fieldsToUpdate: FieldsToUpdateOpts,
): Promise<APIResponse> => {
  const url = `${config.API_URL}/v1/users/${id}`;
  return fetchAPI(stleaf(st, 'begh2t'), url, {
    method: 'PUT',
    credentials: 'include',
    headers,
    body: JSON.stringify(fieldsToUpdate),
  });
};

export const updateOrganisation = (
  st: SpanTag,
  organisation,
): Promise<APIResponse> => {
  const url = `${config.API_URL}/v1/users/organisation`;
  return fetchAPI(stleaf(st, 'lei4ae'), url, {
    method: 'PUT',
    credentials: 'include',
    headers,
    body: JSON.stringify(organisation),
  });
};

interface LocationFieldsToUpdateOpts {
  center: Array<number>;
  country: string;
  name: string;
  state: string;
  suburb: string;
}

export const updateLocation = (
  st: SpanTag,
  fieldsToUpdate: LocationFieldsToUpdateOpts,
): Promise<APIResponse> => {
  const url = `${config.API_URL}/v1/users/location`;
  return fetchAPI(stleaf(st, 'eeth1u'), url, {
    method: 'PUT',
    credentials: 'include',
    headers,
    body: JSON.stringify(fieldsToUpdate),
  });
};

export const updateUserLocation = (
  st: SpanTag,
  scopeId: string,
  fieldsToUpdate: LocationFieldsToUpdateOpts,
): Promise<APIResponse> => {
  const url = `${config.API_URL}/v1/users/location/${scopeId}`;
  return fetchAPI(stleaf(st, 'eeth1u'), url, {
    method: 'PUT',
    credentials: 'include',
    headers,
    body: JSON.stringify(fieldsToUpdate),
  });
};

export const getLocation = (st: SpanTag): Promise<APIResponse> => {
  const url = `${config.API_URL}/v1/users/location`;
  return fetchAPI(stleaf(st, 'doo8we'), url, {
    method: 'GET',
    credentials: 'include',
    headers,
  });
};

/** Sends cookies */
export const syncFirstPromoter = (
  st: SpanTag,
  tid: string,
): Promise<APIResponse> => {
  const url = `${config.API_URL}/v2/user/first-promoter/${tid}`;
  return fetchAPI(stleaf(st, 'fpromo'), url, {
    method: 'GET',
    credentials: 'include',
    headers,
  });
};

export const addRegion = (
  st: SpanTag,
  regionId: string,
): Promise<APIResponse> => {
  const url = `${config.API_URL}/v1/users/me/regions`;
  return fetchAPI(stleaf(st, 'ol2cha'), url, {
    method: 'POST',
    credentials: 'include',
    headers,
    body: JSON.stringify({ regionId }),
  });
};

interface ProfileFieldsToUpdateOpts {
  bio: string;
  software: Array<string>;
  experience: Array<string>;
}

export const updateProfile = (
  st: SpanTag,
  fieldsToUpdate: ProfileFieldsToUpdateOpts,
): Promise<APIResponse> => {
  const url = `${config.API_URL}/v1/users/me/profile`;
  return fetchAPI(stleaf(st, 'wae6ni'), url, {
    method: 'PUT',
    credentials: 'include',
    headers,
    body: JSON.stringify(fieldsToUpdate),
  });
};

/**
 * updateEmailSubscriptions
 * @param {boolean} subscriptionStatus Whether to subscribe or not
 */
export const updateEmailSubscriptions = (
  st: SpanTag,
  subscriptionStatus: boolean,
): Promise<APIResponse> => {
  const url = `${config.API_URL}/v1/users/me/marketing-emails`;
  return fetchAPI(stleaf(st, 'pae4ke'), url, {
    method: 'PUT',
    credentials: 'include',
    headers,
    body: JSON.stringify({
      marketingEmails: subscriptionStatus,
    }),
  });
};

export const getSubscriptionStatus = (st: SpanTag): Promise<APIResponse> => {
  const url = `${config.API_URL}/v1/users/me/marketing-emails`;
  return fetchAPI(stleaf(st, 'ohqu9e'), url, {
    method: 'GET',
    credentials: 'include',
    headers,
  });
};

export const getSubscriptionTerm = (st: SpanTag): Promise<APIResponse> => {
  const url = `${config.API_URL}/v1/users/me/subscription-term`;
  return fetchAPI(stleaf(st, 'shago2'), url, {
    method: 'GET',
    credentials: 'include',
    headers,
  });
};

export const updateSubscriptionTerm = (
  st: SpanTag,
  annualSubscription: any,
): Promise<APIResponse> => {
  const url = `${config.API_URL}/v1/users/me/subscription-term`;
  return fetchAPI(stleaf(st, 'eire2m'), url, {
    method: 'PUT',
    credentials: 'include',
    headers,
    body: JSON.stringify({
      annualSubscription,
    }),
  });
};

export const getOne = (
  st: SpanTag,
  { scopeId, userId },
): Promise<APIResponse> => {
  if ((scopeId && userId) || (!scopeId && !userId)) {
    throw new Error('Must select one of either scopeId or userId');
  }
  const opts = scopeId ? { scopeId } : { userId };
  const query = querystring.stringify(opts);
  const url = `${config.API_URL}/v1/users/?${query}`;
  return fetchAPI(stleaf(st, 'ovee9o'), url, {
    method: 'GET',
    credentials: 'include',
    headers,
  });
};

export const getCurrency = (st: SpanTag): Promise<APIResponse> => {
  const url = `${config.API_URL}/v2/user/currency`;
  return fetchAPI(stleaf(st, 'hoop2e'), url, {
    method: 'GET',
    credentials: 'include',
    headers,
  });
};
