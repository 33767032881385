import React from 'react';
import { connect } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';
import { useMetaSelector } from '../meta/reducer';

function AuthenticatedRouteInternal({
  component: Component,
  layout: Layout,
  page,
  sidebar = null,
  url,
  permission,
  auth,
  profile,
  ...rest
}) {
  const meta = useMetaSelector();
  const isPaywalled =
    permission &&
    !meta.permissions.some(
      (passedPermission) => passedPermission === permission,
    );
  // TODO: More sophisticated approach for changing the title
  document.title = 'Birdi';
  return (
    <Route
      {...rest}
      render={(props) => {
        // User is paywalled
        if (isPaywalled && auth.status === 'authenticated') {
          const url = new URL(window.location.href);
          url.searchParams.append('redirect', props.match.url);
          return <Redirect to={`/sign-in${url.search}`} />;
        }
        // User has successfully authenticated or is awaiting authentication (i.e. preloading)
        if (
          auth.status === 'authenticated' ||
          auth.status === 'authenticating'
        ) {
          if (Layout) {
            return (
              <Layout sidebar={sidebar}>
                <Component {...props} />
              </Layout>
            );
          }
          return <Component {...props} />;
        }
        // User is unauthenticated
        const url = new URL(window.location.href);
        url.searchParams.append('redirect', props.match.url);
        return <Redirect to={`/sign-in${url.search}`} />;
      }}
    />
  );
}

export const AuthenticatedRoute = connect((state) => ({
  auth: state.auth,
  profile: state.profile,
}))(AuthenticatedRouteInternal);
