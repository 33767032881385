import React from 'react';
import { css, Theme } from '@emotion/react';
import { breakpoints, BirdiTheme } from '@birdi/theme';
import { CloudHeaderV2 } from '../common/HeaderV2';
import SideNavigationDashboard from '../common/side-menu/SideNavigationDashboard';
import SideNavigationProfilePrev from '../common/side-menu/SideNavigationProfile-prev';
import SideNavigationOrganisation from '../common/side-menu/SideNavigationOrganisation';
import SideNavigationAdmin from '../common/side-menu/SideNavigationAdmin';
import SideNavigationUserProfile from '../common/side-menu/SideNavigationProfile';
import SideNavigationWorkspace from '../common/side-menu/SideNavigationWorkspace';
import SideNavigationPilot from '../common/side-menu/SideNavigationPilot';
import SideNavigationInspectionTools from '../common/side-menu/SideNavigationInspectionTools';
import { MobileDrpdownMenu } from '../common/side-menu/mobile-menu/dropdown-menu';
import { BackButtonV2 } from '../common/side-menu/side-bar-utils';

export const layoutCSS = (theme: Theme) => css`
  @media (min-width: ${breakpoints.m}) {
    background-color: ${theme.sand};
    display: grid;
    grid-template-areas:
      'header  header'
      'sidebar content';
    grid-template-columns: 260px 1fr;
    height: 100vh;
    .header-wrapper {
      grid-area: header;
    }
    .sidebar-wrapper {
      grid-area: sidebar;
      margin: 0 10px 10px 10px;
      background-color: white;
      border-radius: 15px;
      overflow: hidden;
    }
    .content-wrapper {
      grid-area: content;
      min-width: 0;
      overflow-y: auto;
      background-color: white;
      border-radius: 15px;
      margin: 0 10px 10px 0;
      .mobile-menu {
        display: none;
      }
    }
  }
`;

export const MobileHeader = ({
  title,
  backToPage = '/',
}: {
  title: string;
  backToPage?: string;
}) => (
  <div>
    <div
      css={css`
        padding: 1em;
      `}
    >
      <BackButtonV2 backToPage={backToPage} />
    </div>
    <div
      css={(theme: BirdiTheme) => css`
        background-color: ${theme.mono20};
        width: 100%;
        min-height: 3.5em;
        font-weight: bold;
        display: flex;
        align-items: center;
      `}
    >
      <span
        css={css`
          margin-left: 1em;
        `}
      >
        {title}
      </span>
    </div>
  </div>
);

export const SidebarLayout: React.FC = ({ sidebar, children }) => {
  let sidebarComponent;
  let mobileMenu = <></>;
  let mobileHeader = <></>;

  switch (sidebar) {
    case 'dashboard':
      sidebarComponent = <SideNavigationDashboard />;
      break;
    case 'prev-profile':
      sidebarComponent = <SideNavigationProfilePrev />;
      break;
    case 'organisation':
      sidebarComponent = <SideNavigationOrganisation />;
      break;
    case 'admin':
      sidebarComponent = <SideNavigationAdmin />;
      mobileHeader = <MobileHeader title="Administration" />;
      mobileMenu = <MobileDrpdownMenu title="admin" />;
      break;
    case 'user-profile':
      sidebarComponent = <SideNavigationUserProfile />;
      mobileHeader = (
        <MobileHeader title="User Details" backToPage="/admin-users" />
      );
      mobileMenu = <MobileDrpdownMenu title="user-profile" />;
      break;
    case 'workspace':
      sidebarComponent = <SideNavigationWorkspace />;
      break;
    case 'pilot-settings':
      sidebarComponent = <SideNavigationPilot />;
      mobileHeader = <MobileHeader title="Pilot Settings" />;
      mobileMenu = <MobileDrpdownMenu title="pilot-settings" />;
      break;
    case 'inspection-tools':
      sidebarComponent = <SideNavigationInspectionTools />;
      mobileHeader = <MobileHeader title="Inspection Tools" />;
      mobileMenu = <MobileDrpdownMenu title="inspection-tools" />;
      break;
    default:
      sidebarComponent = <SideNavigationDashboard />;
      break;
  }

  return (
    <main css={layoutCSS}>
      <div className="header-wrapper">
        <CloudHeaderV2 />
      </div>
      <div className="sidebar-wrapper">{sidebarComponent}</div>
      <div className="content-wrapper">
        <div className="mobile-menu">
          {mobileHeader}
          {mobileMenu}
        </div>
        {children}
      </div>
    </main>
  );
};
