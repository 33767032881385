import { config } from '../config';
import { headers, APIResponse, stleaf, fetchAPI, SpanTag } from '../helpers';
import { Site } from '../public/sites';

interface SearchSitesParams {
  start?: number;
  limit?: number;
  searchTerm?: string;
  organisationId?: string;
}

// search sites globally
export const searchSites = (
  st: SpanTag,
  params: SearchSitesParams,
): Promise<APIResponse<{ sites: Site[] }>> => {
  const url = new URL(`${config.API_URL}/v2/admin/site/`);
  const searchParams = new URLSearchParams(params);
  url.search = searchParams.toString();
  return fetchAPI(stleaf(st, 'aigh5t'), url, {
    method: 'GET',
    credentials: 'include',
    headers,
  });
};

export const createAndAssignSite = (
  st: SpanTag,
  params,
): Promise<APIResponse> => {
  const url = `${config.API_URL}/v2/admin/site`;
  return fetchAPI(stleaf(st, 'dohn6o'), url, {
    method: 'POST',
    credentials: 'include',
    headers,
    body: JSON.stringify(params),
  });
};

export const deleteSite = (
  st: SpanTag,
  siteId: string,
): Promise<APIResponse> => {
  const url = `${config.API_URL}/v2/admin/site/${siteId}`;
  return fetchAPI(stleaf(st, 'ahquo8'), url, {
    method: 'DELETE',
    credentials: 'include',
    headers,
  });
};

export const getOne = (st: SpanTag, siteId: string): Promise<APIResponse> => {
  const url = new URL(`${config.API_URL}/v2/admin/site/${siteId}`);
  return fetchAPI(stleaf(st, 'phah2a'), url, {
    method: 'GET',
    credentials: 'include',
    headers,
  });
};

export const editSite = (
  st: SpanTag,
  siteId: string,
  params,
): Promise<APIResponse> => {
  const url = `${config.API_URL}/v2/admin/site/${siteId}`;
  return fetchAPI(stleaf(st, 'ahng8s'), url, {
    method: 'PUT',
    credentials: 'include',
    headers,
    body: JSON.stringify(params),
  });
};
