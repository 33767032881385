import { useEffect, useState } from 'react';

/**
 * React aware debounce function
 * @param {function to call} handler
 * @param {number} delay ms
 * @returns
 */
export function useDebounce(value, delay) {
  const [debouncedValue, setDebouncedValue] = useState(value);
  useEffect(
    () => {
      // Update debounced value after delay
      const handler = setTimeout(() => setDebouncedValue(value), delay);
      // Cancel timeout
      return () => clearTimeout(handler);
    },
    [value, delay], // Only re-call effect if value or delay changes
  );
  return debouncedValue;
}

export const objectToCsv = async (
  headers: { label: string; key: string }[],
  data: { [key: string]: unknown }[],
): Promise<string> =>
  new Promise((resolve, reject) => {
    setTimeout(() => {
      try {
        const csvRows: string[] = [];
        const headerLabels = headers.map((header) => header.label).join(',');
        const headerKeys = headers.map((header) => header.key);
        csvRows.push(headerLabels);
        data.forEach((row) => {
          const val = headerKeys.map((key) => {
            const colVal = row[key] ? row[key].toString() : '';
            const escaped = colVal.replace(/"/g, '\\"');
            return `"${escaped}"`;
          });
          csvRows.push(val.join(','));
        });
        resolve(csvRows.join('\n'));
      } catch (e) {
        reject(e);
      }
    }, 10);
  });

export const downloadContent = (
  filename: string,
  data: string,
  mimeType: string,
) => {
  const url = URL.createObjectURL(new Blob([data], { type: mimeType }));
  const a = document.createElement('a');
  a.href = url;
  a.download = filename;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};
