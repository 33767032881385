/* global fetch */

import React, { useState } from 'react';
import querystring from 'querystring';
import { components } from 'react-select';
import AsyncSelect from 'react-select/async';
import { css } from '@emotion/react';
import { Location } from '@birdi/types/location';
import { debounce } from 'lodash-es';
import google from './powered_by_google_on_white_hdpi.png';

const formatPredictions = (predictions) =>
  predictions.map((prediction) => ({
    value: prediction.place_id,
    label: prediction.description,
  }));

const getAddressComponentOfType = (addressComponents, type) =>
  addressComponents.filter((component) => component.types.includes(type));

const MenuList = (props) => {
  const { children } = props;
  return (
    <components.MenuList {...props}>
      {children}
      {Array.isArray(children) && (
        <div
          css={css`
            padding: 0.6rem 0.8rem;
          `}
        >
          <img
            css={css`
              max-width: 8rem;
            `}
            alt="Location lookup powered by Google"
            src={google}
          />
        </div>
      )}
    </components.MenuList>
  );
};

interface Geocode {
  location: Location;
}

interface LocationSelectProps {
  placeholder?: string;
  onLocationSelect: (geocode: Geocode) => void;
}

// API opts, see: https://developers.google.com/maps/documentation/javascript/reference/places-autocomplete-service
export const LocationSelect = (
  props: LocationSelectProps & { [key: string]: any },
) => {
  const {
    placeholder,
    placeTypes,
    defaultValue,
    onLocationSelect,
    onClear,
    isClearable,
    apiOpts,
    inputId,
    baseUrl,
    theme,
    instanceId = 'location-select',
    isLoadingOverride,
    disabled = false,
    styles,
    borderColor = '#e0e0e0',
    controlStyles = {},
  } = props;
  const [formattedAddress, setFormattedAddress] = useState(defaultValue);
  const [isLoading, setLoadingState] = useState(false);
  const debouncedFetchPredictions = debounce((input, callback) => {
    const queryString = querystring.encode({ input, ...apiOpts });
    const url = `${baseUrl}/v1/location/?${queryString}`;
    fetch(url, { credentials: 'include' })
      .then((response) => response.json())
      .then((data) => callback(formatPredictions(data.predictions)));
  }, 250);

  const fetchPredictions = (input, callback) => {
    if (!input) {
      return Promise.resolve({ options: [] });
    }
    return debouncedFetchPredictions(input, callback);
  };

  const handleSelect = (selectedLocation) => {
    if (!selectedLocation) {
      setFormattedAddress(null);
      return onClear();
    }
    setLoadingState(true);
    const { value: placeid } = selectedLocation;
    const url = `${baseUrl}/v1/location/${placeid}`;
    setFormattedAddress(selectedLocation.label);
    return fetch(url, { credentials: 'include' })
      .then((response) => response.json())
      .then((geocode) => {
        if (!Object.keys(geocode).length) return;
        const center = [
          geocode.geometry.location.lng,
          geocode.geometry.location.lat,
        ];
        const bbox = [
          [
            geocode.geometry.viewport.northeast.lng,
            geocode.geometry.viewport.northeast.lat,
          ],
          [
            geocode.geometry.viewport.southwest.lng,
            geocode.geometry.viewport.southwest.lat,
          ],
        ];
        const city = getAddressComponentOfType(
          geocode.address_components,
          'political',
        )[0];
        const state = getAddressComponentOfType(
          geocode.address_components,
          'administrative_area_level_1',
        )[0];
        const country = getAddressComponentOfType(
          geocode.address_components,
          'country',
        )[0];
        let address;
        if (city && city.long_name !== geocode.name) {
          address = geocode.name;
        }
        // TODO: Add ts definition
        const location: Location = {
          name: selectedLocation.label,
          suburb: city ? city.long_name : '',
          state: state ? state.short_name : '',
          country: country ? country.long_name : '',
          center,
          bbox,
        };
        onLocationSelect({
          location,
        });
        setLoadingState(false);
      });
  };

  return (
    <AsyncSelect
      // menuPortalTarget={document.body}
      loadOptions={fetchPredictions}
      onChange={handleSelect}
      placeholder={placeholder}
      isClearable={isClearable}
      placeTypes={placeTypes}
      isLoading={isLoading}
      value={
        formattedAddress && {
          label: formattedAddress,
          value: formattedAddress,
        }
      }
      components={{ MenuList }}
      theme={theme}
      inputId={inputId}
      css={css`
        min-width: 14rem;
        font-weight: normal;
      `}
      styles={{
        ...styles,
        menuPortal: (base) => ({
          ...base,
          zIndex: 9999,
          pointerEvents: 'visible',
        }),
        control: (base) => ({
          ...base,
          height: '100%',
          borderRadius: '2px',
          border: `solid ${borderColor} 1px`,
          ...controlStyles,
        }),
      }}
      instanceId={instanceId}
      isDisabled={disabled}
    />
  );
};

LocationSelect.defaultProps = {
  apiOpts: {},
  baseUrl: process.env.API_URL,
  styles: {},
};
