import { stroot, missionSharing } from '@birdi/js-sdk';
import React, { useState, useEffect } from 'react';
import { css } from '@emotion/react';
import * as Dialog from '@radix-ui/react-dialog';
import { closeButtonCSS, contentCSS, portalCSS } from '@birdi/modal-kit';
import { ThemeOverride } from '@birdi/theme';
import CloseSVG from '@birdi/icons/close.svg';
import { ShareInvitation } from './ShareInvitation';
import { PlatformUsersShare } from './PlatformUsersShare';
import { OrganisationShare } from './OrganisationShare';
import { InviteViaEmail } from './InviteViaEmail';
import ExistingShares from './ExistingShares';

interface ShareModalControllerProps {
  mission: any;
  open: boolean;
  setOpen: (value: boolean) => void;
}

export const ShareModalController: React.FunctionComponent<
  ShareModalControllerProps
> = ({ mission, open, setOpen }) => {
  const [administrator, setAdministator] = useState(false);
  const [privateSharing, setPrivateSharing] = useState(false);
  const [users, setUsers] = useState(null);

  useEffect(() => {
    missionSharing
      .getContext(stroot('et6pai'), mission.id)
      .then((response) => {
        setUsers(response.body.users);
        setAdministator(response.body.adminSharing);
        setPrivateSharing(response.body.privateSharing);
      })
      .catch((err) => console.log(err));
  }, [mission.id]);

  const updateUserRole = (sharedMissionId, role) =>
    missionSharing
      .updateRole(stroot('neit3x'), sharedMissionId, { role })
      .then(() => {
        const updatedUser = users.filter((user) => {
          if (user.sharedMissionId === sharedMissionId) {
            return Object.assign(user, { role });
          }
        });
        const filteredUsers = users.filter(
          (user) => user.sharedMissionId !== sharedMissionId,
        );
        return setUsers([...filteredUsers, ...updatedUser]);
      });

  const removeSharedUser = (userToRemove) => {
    const email = userToRemove.pendingEmail || userToRemove.email;
    return missionSharing
      .deleteUser(stroot('edel3i'), userToRemove.sharedMissionId)
      .then(() => {
        const filteredUsers = users.filter(
          (user) => user.sharedMissionId !== userToRemove.sharedMissionId,
        );
        return setUsers(filteredUsers);
      })
      .catch((err) => console.log(err));
  };

  return (
    <Dialog.Root open={open} onOpenChange={setOpen}>
      <Dialog.Portal>
        <Dialog.Overlay />

        <div css={portalCSS}>
          <ThemeOverride>
            <Dialog.Content css={contentCSS}>
              <Dialog.Close css={closeButtonCSS}>
                <CloseSVG />
              </Dialog.Close>
              <Dialog.Title>
                {`Share ${mission.name || 'Untitled'} with others`}
              </Dialog.Title>
              <OrganisationShare
                administrator={administrator}
                missionId={mission.id}
                setUsers={(newUser) => setUsers([...users, newUser])}
              />
              <ShareInvitation missionId={mission.id} />
              {privateSharing && (
                <div>
                  {administrator && (
                    <PlatformUsersShare
                      administrator={administrator}
                      missionId={mission.id}
                      setUsers={(newUser) => setUsers([...users, newUser])}
                    />
                  )}
                  <InviteViaEmail
                    administrator={administrator}
                    missionId={mission.id}
                    setUsers={(newUser) => setUsers([...users, newUser])}
                  />
                  {users && users.length > 0 && (
                    <div
                      css={css`
                        margin: 1em 0;
                      `}
                    >
                      <h3>
                        Shared with {users.length}{' '}
                        {users.length > 1 ? 'people' : 'person'}
                      </h3>
                      <div
                        css={css`
                          max-height: 20vh;
                        `}
                      >
                        {users.map((user) => (
                          <ExistingShares
                            user={user}
                            removeSharedUser={removeSharedUser}
                            key={user.email}
                            updateUserRole={updateUserRole}
                            administrator={administrator}
                          />
                        ))}
                      </div>
                    </div>
                  )}
                </div>
              )}
            </Dialog.Content>
          </ThemeOverride>
        </div>
      </Dialog.Portal>
    </Dialog.Root>
  );
};
