/* global fetch */

import { config } from '../config';
import { APIResponse, fetchAPI, headers, SpanTag, stleaf } from '../helpers';
import { getPublicTokens } from './user';

export interface Map {
  id: string;
  name: string;
  createdBy: string;
  createdAt: string;
  suburb: string;
  mapCover: string;
  organisationName: string;
}

export interface GetManyMapsOptions {
  start?: number;
  limit?: number;
}

type GetMapsResponse = Promise<APIResponse<Map[]>>;
export const getMaps = (
  st: SpanTag,
  opts?: GetManyMapsOptions,
): GetMapsResponse => {
  const url = new URL(`${config.API_URL}/v1/maps/`);
  if (opts) url.search = new URLSearchParams(opts as any).toString();
  return fetchAPI(stleaf(st, 'getmps'), url, {
    method: 'GET',
    credentials: 'include',
    headers,
  }) as GetMapsResponse;
};

type PostMapResponse = Promise<APIResponse<{ id: string }>>;
export const postMap = (st: SpanTag): PostMapResponse => {
  const url = `${config.API_URL}/v1/maps/`;
  return fetchAPI(stleaf(st, 'pstmap'), url, {
    method: 'POST',
    credentials: 'include',
    headers,
  }) as PostMapResponse;
};

/**
 * BEWARE, e2e these events need to broadcasted to all ws2 connected clients
 */
type UpdateMapResponse = Promise<APIResponse<Map>>;
export const updateMap = (
  st: SpanTag,
  id: string,
  body: Partial<Map>,
): UpdateMapResponse => {
  const url = `${config.API_URL}/v1/maps/${id}`;
  return fetchAPI(stleaf(st, 'updmap'), url, {
    method: 'PUT',
    credentials: 'include',
    headers,
    body: JSON.stringify(body),
  }) as UpdateMapResponse;
};

type DeleteMapResponse = Promise<APIResponse<void>>;
export const deleteMap = (st: SpanTag, id: string): DeleteMapResponse => {
  const url = `${config.API_URL}/v1/maps/${id}`;
  return fetchAPI(stleaf(st, 'dltemp'), url, {
    method: 'DELETE',
    credentials: 'include',
    headers,
  }) as DeleteMapResponse;
};

type GetLayerMediaInfoResponse = Promise<
  APIResponse<{ thumbnailUrl?: string; fullImageUrl?: string; s3Key?: string }>
>;
export const getLayerMediaInfo = (
  st: SpanTag,
  mapId: string,
  layerId: string,
): GetLayerMediaInfoResponse => {
  const url = `${config.API_URL}/v1/maps/${mapId}/layers/${layerId}/media-info`;
  return fetchAPI(stleaf(st, 'iosh9b'), url, {
    method: 'GET',
    credentials: 'include',
    headers,
  });
};

type GetOrganisationMapsResponse = Promise<APIResponse<Map[]>>;
export const getOrganisationMaps = (
  st: SpanTag,
  orgId: string,
  opts?: { start?: number; limit?: number },
): GetOrganisationMapsResponse => {
  const url = new URL(`${config.API_URL}/v1/maps/organisation/${orgId}`);
  if (opts) url.search = new URLSearchParams(opts as any).toString();
  return fetchAPI(stleaf(st, 'gorgmp'), url, {
    method: 'GET',
    credentials: 'include',
    headers,
  }) as GetOrganisationMapsResponse;
};

export const getMapLocation = (
  st: SpanTag,
  mapId: string,
): Promise<APIResponse<{ center: [number, number]; boundingBox: [number, number, number, number] }>> => {
  const url = `${config.API_URL}/v1/maps/${mapId}/location`;
  return fetchAPI(stleaf(st, 'moo8wl'), url, {
    method: 'GET',
    credentials: 'include',
    headers: headers,
  });
};

export const generateMapShareLink = (st: SpanTag, mapId: string, expiryDays: number): Promise<APIResponse<{ signedShareUrl: string }>> => {
  const url = `${config.API_URL}/v1/maps/create-share-link`;
  return fetchAPI(stleaf(st, 'gmapsh'), url, {
    method: 'POST',
    credentials: 'include',
    headers,
    body: JSON.stringify({ mapId }),
  });
};

export const getMapShareLink = (st: SpanTag, mapId: string): Promise<APIResponse<{ signedShareUrl: string }>> => {
  const url = `${config.API_URL}/v1/maps/${mapId}/active-share-link`;
  return fetchAPI(stleaf(st, 'gmapsh'), url, {
    method: 'GET',
    credentials: 'include',
    headers,
  });
};

export const deleteMapShareLink = (st: SpanTag, mapId: string): Promise<APIResponse<{ signedShareUrl: string }>> => {
  const url = `${config.API_URL}/v1/maps/${mapId}/share-link`;
  return fetchAPI(stleaf(st, 'l40em2'), url, {
    method: 'DELETE',
    credentials: 'include',
    headers,
  });
};

export const patchMapShareCount = (st: SpanTag, shareId: string): Promise<APIResponse<{ signedShareUrl: string }>> => {
  const url = `${config.API_URL}/v1/maps/${shareId}/share-link-viewed`;
  return fetchAPI(stleaf(st, 'a7b3c9'), url, {
    method: 'PATCH',
    credentials: 'include',
    headers,
  });
};

export const getMapLayerLocation = (
  st: SpanTag,
  mapId: string,
  layerId: string,
): Promise<APIResponse<{ center: [number, number]; boundingBox: [number, number, number, number] }>> => {
  const url = `${config.API_URL}/v1/maps/${mapId}/layers/${layerId}/location`;
  return fetchAPI(stleaf(st, 'soo8wl'), url, {
    method: 'GET',
    credentials: 'include',
    headers,
  });
};
