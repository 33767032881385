import { next } from '@birdi/theme';
import { css } from '@emotion/react';
import React from 'react';

export interface RootProps {
  itemDirection?: 'horizontal' | 'vertical';
}

export const Root = React.forwardRef<
  HTMLDListElement,
  JSX.IntrinsicElements['dl'] & RootProps
>(({ itemDirection = 'horizontal', ...props }, ref) => (
  <dl
    css={[
      next.datalist.root,
      itemDirection === 'vertical'
        ? css`
            grid-template-columns: 1fr;
            --dl-item-col: span 1;
          `
        : undefined,
    ]}
    ref={ref}
    {...props}
  />
));

export const Item = React.forwardRef<
  HTMLDivElement,
  JSX.IntrinsicElements['div']
>((props, ref) => <div css={next.datalist.ddiv} ref={ref} {...props} />);

export const SectionLabel = React.forwardRef<
  HTMLElement,
  JSX.IntrinsicElements['dt']
>((props, ref) => <dt css={next.datalist.dl} ref={ref} {...props} />);

export const Label = React.forwardRef<HTMLElement, JSX.IntrinsicElements['dt']>(
  (props, ref) => <dt css={next.datalist.dt} ref={ref} {...props} />,
);

export const Value = React.forwardRef<HTMLElement, JSX.IntrinsicElements['dd']>(
  (props, ref) => <dd css={next.datalist.dd} ref={ref} {...props} />,
);
