import { stroot } from '@birdi/js-sdk';
import React, { useEffect, useState } from 'react';
import AsyncSelect from 'react-select/async';
import { experience } from '@birdi/js-sdk/src/public';

export const ExperienceSelect = ({
  onChange,
  value,
  theme,
  isMulti,
  inputId,
}) => {
  const [experienceTags, loadExperienceTags] = useState([]);
  useEffect(() => {
    experience
      .getTags(stroot('wei8ne'))
      .then((data) =>
        loadExperienceTags(
          data.body.items.map((item) => ({ value: item.id, label: item.name })),
        ),
      );
  }, []);

  async function getOptions(name, callback) {
    const experienceRequest = await experience.findByName(
      stroot('yiu0ai'),
      name,
    );
    const finalExperience = experienceRequest.body;
    const options = finalExperience.map((item) => ({
      label: item.name,
      value: item.id,
    }));
    return callback(options);
  }

  return (
    <AsyncSelect
      type="checkbox"
      theme={theme}
      styles={{
        option: (provided, state) => ({
          ...provided,
        }),
        control: (base, theme) => ({
          ...base,
          height: '3em',
          minWidth: '20em',
        }),
      }}
      isMulti={isMulti}
      isClearable
      onChange={(option, action) => onChange(option, action)}
      onClear
      defaultOptions={experienceTags}
      loadOptions={(input, callback) => getOptions(input, callback)}
      value={
        value
          ? experienceTags.filter((item) =>
              value.some((valueItem) =>
                valueItem.label
                  ? item.label === valueItem.label
                  : item.value === valueItem,
              ),
            )
          : null
      }
      placeholder="Experience..."
      inputId={inputId}
    />
  );
};
