import React from 'react';
import * as Dialog from '@radix-ui/react-dialog';
import { css } from '@emotion/react';
import CloseSVG from '@birdi/icons/close.svg';
import { closeButtonCSS, contentCSS, portalCSS } from '@birdi/modal-kit';
import { useForm, Controller } from 'react-hook-form';
import { errorMessage, textInput, blockLabel } from '@birdi/theme/form';
import { formStyles } from '@birdi/theme/blocks';
import { BirdiTheme, buttonBlue, buttonGrey } from '@birdi/theme';
import {
  stroot,
  auth,
  organisation as organisationAPI,
  user,
} from '@birdi/js-sdk';

interface AddWorkspaceModalProps {
  open: boolean;
  setOpen: (value: boolean) => void;
}

export const AddWorkspaceModal: React.FC<AddWorkspaceModalProps> = ({
  open,
  setOpen,
}) => {
  const { control, formState, clearErrors, handleSubmit, getValues } =
    useForm();

  const confirm = async () => {
    const values = getValues();
    const newWorkspace = await organisationAPI.addWorkspace(
      stroot('ca0ohc'),
      values,
    );
    const scopes = await user.getScopes(stroot('ior5ah'));
    const newScope = scopes.body.items.filter(
      (scope) => scope.organisationId === newWorkspace.body.id,
    );
    const newScopeId = newScope[0].id;
    setOpen(false);
    auth
      .switchContext(stroot('aigee1'), {
        scopeId: newScopeId,
      })
      .then(() => (window.location = '/settings/customisation/branding'));
  };
  const cancel = () => {
    setOpen(false);
  };

  return (
    <Dialog.Root open={open} onOpenChange={setOpen}>
      <Dialog.Portal>
        <Dialog.Overlay />

        <div css={portalCSS}>
          <Dialog.Content
            css={(theme: BirdiTheme) => css`
              ${contentCSS(theme)};
              overflow-y: auto;
            `}
          >
            <Dialog.Close css={closeButtonCSS}>
              <CloseSVG />
            </Dialog.Close>
            <h2
              css={css`
                text-align: center;
              `}
            >
              Please give your workspace a name.
            </h2>
            <hr />
            <p>
              You are about to add an additional workspace to your account. As
              the creator you can invite &nbsp;
              <span
                css={css`
                  font-weight: bold;
                `}
              >
                1 team member with full access
              </span>
              &nbsp; and as many guests as needed.
            </p>
            <p>
              The organisation owner will need to upgrade the workspace to add
              addtional paid seats.
            </p>
            <form
              css={(theme: BirdiTheme) => css(formStyles(theme))}
              onSubmit={handleSubmit(confirm)}
            >
              <label
                css={blockLabel}
                htmlFor="workspaceName"
                id="add-workspace-name"
              >
                Workspace name
                <Controller
                  render={({ field: { onChange } }) => (
                    <input
                      css={textInput}
                      type="text"
                      id="workspaceName"
                      placeholder="Workspace name"
                      onChange={(event) => {
                        onChange(event.target.value);
                        clearErrors('workspaceName');
                      }}
                    />
                  )}
                  control={control}
                  name="workspaceName"
                  rules={{ required: true }}
                />
                {formState.errors.workspaceName && (
                  <span css={errorMessage}>Workspace name is required.</span>
                )}
              </label>
              <div
                css={css`
                  display: flex;
                  flex-direction: row;
                  align-items: center;
                  justify-content: center;
                  margin: 2em 0 0 0;
                  gap: 0.5rem;
                `}
              >
                <button
                  type="button"
                  css={(theme: BirdiTheme) => css`
                    ${buttonGrey(theme)}
                    height: 2.5rem;
                    width: 7rem;
                    background: ${theme.mono0};
                    color: ${theme.deepOcean};
                    border: solid 1px ${theme.deepOcean};
                  `}
                  onClick={cancel}
                >
                  <span>Cancel</span>
                </button>
                <button
                  type="submit"
                  css={(theme: BirdiTheme) => css`
                    ${buttonBlue(theme)}
                    height: 2.5rem;
                    width: 7rem;
                  `}
                >
                  <span>Confirm</span>
                </button>
              </div>
            </form>
          </Dialog.Content>
        </div>
      </Dialog.Portal>
    </Dialog.Root>
  );
};
