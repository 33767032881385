import { css, Theme } from '@emotion/react';

export const root = css`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const field = {
  root: (theme: Theme) => css`
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    &:has(:disabled) {
      color: ${theme.midGrey};
    }
  `,
  error: (theme: Theme) => css`
    margin: 0;
    color: ${theme.crimsonSunbird};
  `,
  // label: (theme: Theme) => css`
  // `,
};
