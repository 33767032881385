/* global window */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { css } from '@emotion/react';
import {
  stroot,
  missionShareInvitations as missionShareInvitationsAPI,
} from '@birdi/js-sdk/src/public';
import { button } from '@birdi/theme/blocks';
import BirdiLogoSVG from '@birdi/icons/birdi-logo-navy.svg';
import history from '../state/history';

const UnauthenticatedShareInternal = (props): React.ReactElement => {
  const [missionName, setMissionName] = useState('');

  const { id: invitationId } = props.match.params;

  useEffect(() => {
    // User is Authenticated
    // add the user to mission shares, then redirect to mission
    if (
      props.auth.status === 'authenticated' ||
      props.auth.status === 'authenticating'
    ) {
      missionShareInvitationsAPI
        .acceptInvitation(stroot('eniev0'), invitationId)
        .then((res) => {
          window.location.href = `${process.env.MAP_APP_URL}/missions/${res.body.missionId}`;
        });
    } else {
      // User is Unauthenticated
      // resolve mission name and ask for sign in/up
      missionShareInvitationsAPI
        .getMissionInfo(stroot('ooro7n'), invitationId)
        .then((res) => {
          setMissionName(res.body.name);
        })
        .catch(() => {
          // the invitationId doesn't exist or is expired
          window.location.replace('/sign-in');
        });
    }
  }, []);

  if (!missionName) return <div />;

  return (
    <div
      css={css`
        margin: auto;
        margin-top: 8%;
        width: 100%;
        text-align: center;
      `}
    >
      <BirdiLogoSVG
        css={(theme) => css`
          font-size: 9em;
          color: ${theme.mono50};
        `}
      />
      <p
        css={css`
          margin: 2em 0 2.5em 0;
          font-size: 1.25em;
        `}
      >
        You have been invited to view map
        <br />
        &quot;
        <span
          css={(theme) => css`
            font-weight: bold;
            color: ${theme.deepOcean};
          `}
        >
          {missionName}
        </span>
        &quot;
        <br />
        <br />
        Please sign in or sign up to continue
      </p>
      <div
        css={css`
          display: flex;
          flex-direction: row;
          justify-content: center;
        `}
      >
        <Link
          to={`/sign-up?redirect=/share/${invitationId}&invitation=${invitationId}`}
          css={(theme) => css`
            ${button(theme)};
            margin: 0 0.5em;
            padding: 0.5em 2em;
          `}
        >
          Sign Up
        </Link>
        <Link
          to={`/sign-in?redirect=/share/${invitationId}&invitation=${invitationId}`}
          css={(theme) => css`
            ${button(theme)};
            margin: 0 0.5em;
            padding: 0.5em 2em;
          `}
        >
          Sign In
        </Link>
      </div>
    </div>
  );
};

export const UnauthenticatedShare = connect((state) => ({
  auth: state.auth,
}))(UnauthenticatedShareInternal);
