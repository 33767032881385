import { format, parseISO, differenceInMilliseconds } from 'date-fns';

export const getLapsedTime = (startTime, endTime) => {
  if (startTime && endTime) {
    const start = new Date(startTime);
    const end = new Date(endTime);
    // Get the difference in milliseconds
    const differenceMs = differenceInMilliseconds(end, start);
    // Convert the difference to hours, minutes, and seconds
    const hours = Math.floor(differenceMs / (1000 * 60 * 60));
    const minutes = Math.floor((differenceMs % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((differenceMs % (1000 * 60)) / 1000);
    // Format the result as hh:mm:ss
    const formattedResult = `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
    return formattedResult;
  } else {
    return '-';
  }
};

export function formatPrice(cost: number, currency: string): string {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: currency,
  }).format(cost);
}

export const formating = (item) => ({
  id: item.id,
  name: item.name,
  missionName: item.mission?.name,
  mapId: item.mission?.id ?? item.requestConfig?.target?.id,
  status: item.state,
  gp: item.gigapixelCount,
  cost:
    item.totalCost && item.currency
      ? formatPrice(item.totalCost / 100, item.currency)
      : '-',
  createdDate: format(parseISO(item.createdAt), 'yyyy-MM-dd'),
  gcp: item.requestConfig?.config?.useReferences ? 'Yes' : 'No',
  requestedBy: item.requesterName
    ? `${item.requesterName.fname} ${item.requesterName.lname}`
    : '-',
});
