/* globals window */

import React, { useState } from 'react';
import {
  QueryClient,
  QueryClientProvider,
  useInfiniteQuery,
} from 'react-query';
import {
  stroot,
  organisationMissions as organisationMissionsAPI,
  mission as missionAPI,
} from '@birdi/js-sdk';
import { css } from '@emotion/react';
import { breakpoints } from '@birdi/theme/variables';
import { input, pageDescription, reactSelectLight } from '@birdi/theme/blocks';
import { reactSelect } from '@birdi/theme/form';
import { useHistory, Link } from 'react-router-dom';
import { FeatureFlagNames } from '@birdi/js-sdk/src/public/organisation-features';
import { useMetaSelector } from '../meta/reducer';
import { INTERCOM_TOUR_CODES } from '../common/consts';
import PlayCircleSVG from '@birdi/icons/play-circle.svg';
import BookOpenSVG from '@birdi/icons/book-open.svg';
import VideoMidnightSVG from '@birdi/icons/video-midnight.svg';
import { SiteSelect } from '@birdi/select/SiteSelect';
import { BirdiTheme, buttonOutlineBlue, fonts } from '@birdi/theme';
import { getInitialSearchParams } from '@birdi/utils/src/search-params';
import { NewMapList } from '../missions/new-map-list';
import { DateField } from './date-picker';
import { LocationSelect } from '@birdi/google-places-autocomplete';
import { Location } from '@birdi/types';
import { maps as mapsAPI } from '@birdi/js-sdk';
import { MapDashboardHeader } from '../common/map-dashboard-header';
import { NewMapBadge } from '@birdi/components-common';

const queryClient = new QueryClient();

const LOAD_AMOUNT = 6;

type MapsProps = {
  orgId: string;
};
function Maps({ orgId }: MapsProps) {
  const history = useHistory();
  const meta = useMetaSelector();

  const { data, fetchNextPage, hasNextPage, isFetchingNextPage } =
    useInfiniteQuery(
      [],
      async ({ pageParam = 1 }) => {
        const apiCall = mapsAPI.getOrganisationMaps;
        return apiCall(stroot('qgtmps'), orgId, {
          start: pageParam,
          limit: LOAD_AMOUNT,
        }).then((res) => res.body);
      },
      {
        getNextPageParam: (lastPage, allPages) => {
          return lastPage.length === LOAD_AMOUNT
            ? allPages.length + 1
            : undefined;
        },
        keepPreviousData: true,
      },
    );

  // const anySearchParams = !!(state.search || state.site || state.location || state.startDate || state.endDate);
  const anySearchParams = false;

  const handleLoadMore = () => {
    fetchNextPage();
  };

  return (
    <div
      css={css`
        padding: 0 1rem;
        box-sizing: border-box;
      `}
    >
      <div>
        {data &&
          data.pages.map((pageData, pageIndex) => (
            <div key={pageIndex}>
              <NewMapList
                maps={pageData || []}
              />
            </div>
          ))}
        {hasNextPage ? (
          isFetchingNextPage ? (
            <span>Loading more...</span>
          ) : (
            <button
              css={(theme) => css`
                ${buttonOutlineBlue(theme)};
                width: 100%;
              `}
              onClick={handleLoadMore}
            >
              Load More ...
            </button>
          )
        ) : null}
        {data && data.pages[0].length === 0 && anySearchParams && (
          <p
            css={css`
              width: calc(100% - 1rem);
            `}
          >
            No maps found
          </p>
        )}
        {data && data.pages[0].length === 0 && !anySearchParams && (
          <p
            css={(theme: BirdiTheme) => css`
              width: calc(100% - 1rem);
              color: ${theme.mono50};
            `}
          >
            You have no Birdi 2.0 maps.
          </p>
        )}
      </div>
    </div>
  );
}

const AllNewMaps: React.FC = () => {
  const meta = useMetaSelector();
  const orgId = meta.organisation.id;
  return (
    <div>
      <MapDashboardHeader
        titleComponent={
          <h2
            css={css`
              margin: 0;
              display: flex;
              align-items: center;
              gap: 8px;
            `}
          >
            Your Workspace's Birdi 2.0 maps
            <NewMapBadge />
          </h2>
        }
      />
      <QueryClientProvider client={queryClient}>
        {orgId && <Maps orgId={orgId} />}
      </QueryClientProvider>
    </div>
  );
};

export { AllNewMaps };
