import React from 'react';
import { css } from '@emotion/react';

interface Props {
  title: string;
  style?: any;
}

export default (props: Props): React.ReactElement => {
  const { title, style } = props;
  return (
    <h1
      css={css`
        font-size: 1.25em;
        margin: 0;
        ${style}
      `}
    >
      {title}
    </h1>
  );
};
