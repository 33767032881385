/** @jsxImportSource @emotion/react */
import React from 'react';
import { css } from '@emotion/react';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { NewMapBadge } from '@birdi/components-common';
import ChevronDownSVG from '@birdi/icons/chevron-down.svg';
import ExternalLinkSVG from '@birdi/icons/external-link.svg';
import { maps as mapsAPI, stroot } from '@birdi/js-sdk';
import { useHistory } from 'react-router-dom';
import { useMetaSelector } from '../../meta/reducer';
import { FeatureFlagNames } from '@birdi/js-sdk/src/public/organisation-features';

interface Option {
  value: string;
  label: string;
  testId: string;
  link: string;
  externalLink: boolean;
  beta?: boolean;
  onClick: () => void;
}

const triggerStyles = css`
  display: flex;
  align-items: center;
  padding: 8px 16px;
  font-weight: 600;
  color: white;
  background-color: #4ad294;
  border-radius: 40px;
  cursor: pointer;
  &:focus {
    outline: none;
  }
`;

const contentStyles = css`
  background-color: white;
  border-radius: 14px;
  box-shadow:
    0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
  z-index: 1000;
  overflow: hidden;
`;

const itemStyles = css`
  display: flex;
  align-items: center;
  padding: 8px 16px;
  gap: 0.5rem;
  color: #374151;
  cursor: pointer;
  outline: none;
  &:hover {
    background-color: #f3f4f6;
  }
  &:focus {
    background-color: #e5e7eb;
  }
`;

const separatorStyles = css`
  height: 1px;
  margin: 4px 0;
  background-color: #e5e7eb;
`;

const iconStyles = css`
  width: 20px;
  height: 20px;
  margin-left: 8px;
  margin-right: -4px;
`;

const externalLinkIconStyles = css`
  width: 16px;
  height: 16px;
  margin-left: 8px;
`;

export const CreateDropdown: React.FC = () => {
  const history = useHistory();

  const meta = useMetaSelector();
  const mapsV2 = meta.orgFeatureFlags.includes(FeatureFlagNames.MapsV2);

  const onNewMap = async () => {
    const res = (await mapsAPI.postMap(stroot('nwmap2')))?.body;
    if (res && res.id) {
      history.push(`/map/next/${res.id}`);
    }
  };

  const options: Option[] = [];
  
  if (mapsV2) {
    options.push({
      value: 'Birdi 2.0 map',
      label: 'Birdi 2.0 map',
      testId: 'select-create-new-map',
      link: '',
      externalLink: false,
      beta: true,
      onClick: onNewMap,
    });
  }
  
  options.push({
    value: 'Original Birdi map',
    label: 'Original Birdi map',
    testId: 'select-create-mission-map',
    link: '/map/missions/plan',
    externalLink: false,
    onClick: () => null,
  });

  return (
    <DropdownMenu.Root>
      <DropdownMenu.Trigger asChild>
        <button css={triggerStyles} data-testid="dashboard-create-button">
          <span>Create</span>
          <ChevronDownSVG css={iconStyles} aria-hidden="true" />
        </button>
      </DropdownMenu.Trigger>

      <DropdownMenu.Portal>
        <DropdownMenu.Content css={contentStyles} align="end" sideOffset={5}>
          {options.map((option) => (
            <React.Fragment key={option.value}>
              <DropdownMenu.Item
                css={itemStyles}
                data-testid={option.testId}
                onSelect={() => {
                  if (option.link) {
                    if (option.externalLink) {
                      window.open(option.link, '_blank');
                    } else {
                      window.location.href = option.link;
                    }
                  }
                  option.onClick();
                }}
              >
                {option.label}
                {option.externalLink && (
                  <ExternalLinkSVG
                    css={externalLinkIconStyles}
                    aria-hidden="true"
                  />
                )}
                {option.beta && (
                  <NewMapBadge />
                  // <BetaSVG width="40px" aria-label="(beta)"/>
                )}
              </DropdownMenu.Item>
            </React.Fragment>
          ))}
        </DropdownMenu.Content>
      </DropdownMenu.Portal>
    </DropdownMenu.Root>
  );
};
