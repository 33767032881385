/* globals window, CSS, requestAnimationFrame */

import React, { useRef, useState } from 'react';
import { css, useTheme } from '@emotion/react';
import { BirdiTheme, breakpoints } from '@birdi/theme';
import HelloSVG from '@birdi/icons/illustration-hello.svg';
import { MapDashboardHeader } from '../common/map-dashboard-header';
import { useMetaSelector } from '../meta/reducer';

export const GetStarted: React.FC = () => {
  const meta = useMetaSelector();

  return (
    <div>
      <MapDashboardHeader
        titleComponent={
          <>
            <HelloSVG
              css={css`
                font-size: 2.5em;
                margin-right: 0.25em;
              `}
            />
            <h2
              css={css`
                margin: 0;
              `}
            >
              {`Hello ${meta.fname},`}
            </h2>
          </>
        }
      />
    </div>
  );
};
