/* global fetch */

import { Organisations } from '@birdi/types';
import { config } from '../config';
import { headers, APIResponse, stleaf, fetchAPI, SpanTag } from '../helpers';
import {
  ProcessingPlatformRequest,
  SimpleProcessingConfig,
  ProcessingPreset,
} from './processing-jobs-types';

export const get = (st: SpanTag): Promise<APIResponse> => {
  const url = new URL(`${config.API_URL}/v1/organisation/`).toString();
  return fetchAPI(stleaf(st, 'aiyoo6'), url, {
    method: 'GET',
    credentials: 'include',
    headers,
  });
};

export const getRoles = (st: SpanTag): Promise<APIResponse> => {
  const url = `${config.API_URL}/v1/organisation/roles`;
  return fetchAPI(stleaf(st, 'eej4sa'), url, {
    method: 'GET',
    credentials: 'include',
    headers,
  });
};

export const getCurrentUserOrgCampaigns = (
  st: SpanTag,
): Promise<APIResponse> => {
  const url = `${config.API_URL}/v1/organisation/campaigns`;
  return fetchAPI(stleaf(st, 'eo4toh'), url, {
    method: 'GET',
    credentials: 'include',
    headers,
  });
};

export const create = (st: SpanTag, params): Promise<APIResponse> => {
  const url = `${config.API_URL}/v1/organisation`;
  return fetchAPI(stleaf(st, 'hoh9ah'), url, {
    method: 'POST',
    credentials: 'include',
    headers,
    body: JSON.stringify(params),
  });
};

export const update = (st: SpanTag, params): Promise<APIResponse> => {
  const url = `${config.API_URL}/v1/organisation`;
  return fetchAPI(stleaf(st, 'aa6uog'), url, {
    method: 'PUT',
    credentials: 'include',
    headers,
    body: JSON.stringify(params),
  });
};

export const getSeatsCount = (st: SpanTag) => {
  const url = `${config.API_URL}/v1/organisation/seats`;
  return fetchAPI(stleaf(st, 'foh9on'), url, {
    method: 'GET',
    credentials: 'include',
    headers,
  });
};

export interface StorageInfoType {
  id: string;
  name: string;
  mediaCount: number;
  mediaGb: string | null;
}
export const getStorageInfo = (
  st: SpanTag,
): Promise<APIResponse<StorageInfoType>> => {
  const url = `${config.API_URL}/v1/organisation/storage`;
  return fetchAPI(stleaf(st, 'wu0oze'), url, {
    method: 'GET',
    credentials: 'include',
    headers,
  });
};

export const getProcessingGPInfo = (
  st: SpanTag,
): Promise<APIResponse<number>> => {
  const url = `${config.API_URL}/v1/organisation/processing-gp`;
  return fetchAPI(stleaf(st, 'kug2oh'), url, {
    method: 'GET',
    credentials: 'include',
    headers,
  });
};

export const getProcessingPresets = (
  st: SpanTag,
): Promise<APIResponse<ProcessingPreset[]>> => {
  const url = `${config.API_URL}/v1/organisation/processing/presets`;
  return fetchAPI(stleaf(st, 'q33kel'), url, {
    method: 'GET',
    credentials: 'include',
    headers,
  });
};
export const updateBranding = (
  st: SpanTag,
  id: string,
  params,
): Promise<APIResponse> => {
  const url = `${config.API_URL}/v1/organisation/${id}/branding`;
  return fetchAPI(stleaf(st, 'aek0du'), url, {
    method: 'PUT',
    credentials: 'include',
    headers,
    body: JSON.stringify(params),
  });
};

interface AddWorspace {
  workspaceName: string;
}
export const addWorkspace = (
  st: SpanTag,
  params: AddWorspace,
): Promise<APIResponse> => {
  const url = `${config.API_URL}/v1/organisation/new-workspace`;
  return fetchAPI(stleaf(st, 'shai3e'), url, {
    method: 'POST',
    credentials: 'include',
    headers,
    body: JSON.stringify(params),
  });
};

type ChartConfig = Record<string, any>;

export const getChartConfigs = (
  st: SpanTag,
): Promise<APIResponse<ChartConfig[]>> => {
  const url = `${config.API_URL}/v1/organisation/ground-references-chart-configs`;
  return fetchAPI(stleaf(st, 'wb64fl'), url, {
    method: 'GET',
    credentials: 'include',
    headers,
  });
};

export interface OrganisationTag {
  id: string;
  organisationId: string;
  name: string;
  createdAt: string;
}

export const getTags = (
  st: SpanTag,
  organisationId: string
): Promise<APIResponse<OrganisationTag[]>> => {
  const url = `${config.API_URL}/v1/organisation/${organisationId}/tags`;
  return fetchAPI(stleaf(st, 'caem8o'), url, {
    method: 'GET',
    credentials: 'include',
    headers,
  });
};

export const createTag = (
  st: SpanTag,
  organisationId: string,
  name: string,
): Promise<APIResponse<OrganisationTag>> => {
  const url = `${config.API_URL}/v1/organisation/${organisationId}/tags`;
  return fetchAPI(stleaf(st, 'ba1ail'), url, {
    method: 'PUT',
    credentials: 'include',
    headers,
    body: JSON.stringify({ name }),
  });
};

export const deleteTag = (st: SpanTag, tagId: string): Promise<APIResponse> => {
  const url = `${config.API_URL}/v1/organisation/tags/${tagId}`;
  return fetchAPI(stleaf(st, 'ba1ail'), url, {
    method: 'DELETE',
    credentials: 'include',
    headers,
  });
};
