import { stroot, organisation } from '@birdi/js-sdk';
import React from 'react';
import AsyncSelect from 'react-select/async';
import { css } from '@emotion/react';
import { debounce } from 'lodash-es';

const formatPredictions = (campaigns) =>
  campaigns.map((item) => ({
    value: item.id,
    label: item.name,
  }));

export const OrganisationCampaignSelect = (props) => {
  const { onSelect, onClear } = props;

  const debouncedFetchCampaigns = debounce((callback) => {
    organisation
      .getCurrentUserOrgCampaigns(stroot('fot4os'))
      .then((response) => response.body.items)
      .then((data) => callback(formatPredictions(data)));
  });
  const fetchCampaigns = (name, callback) => debouncedFetchCampaigns(callback);
  const handleSelect = (selectedName) => {
    if (!selectedName) return onClear();
    onSelect(selectedName);
  };
  return (
    <AsyncSelect
      cacheOptions
      defaultOptions
      loadOptions={fetchCampaigns}
      onChange={handleSelect}
      placeholder="Select batch to view inspections"
      css={css`
        max-width: 24rem;
        margin-bottom: 1em;
      `}
      isClearable
    />
  );
};
