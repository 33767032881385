import React, { useEffect, useState } from 'react';
import { css, Theme } from '@emotion/react';
import { withRouter } from 'react-router-dom';
import { useMetaSelector } from '../meta/reducer';
import { Toggle } from '@birdi/toggle';
import { breakpoints } from '@birdi/theme';
import ChevronDownSVG from '@birdi/icons/chevron-down.svg';

export enum PermissionNames {
  CanAddEditAndRemoveUser = 'Can add, edit and remove users',
  CanUpdateUserPermissions = 'Can update user permissions',
  CanInviteGuests = 'Can invite guests',
  CanShareMaps = 'Can share maps',
  CanCreateUpdateAndManageWorkspaces = 'Can create, update and manage workspaces',
  CanUpdateBranding = 'Can update branding',
  CanAccessAccountInformation = 'Can access account information',
  CanUpgradeDowngradePlans = 'Can upgrade/downgrade plans',
  CanAddStorage = 'Can add storage',
  CanAddSeats = 'Can add seats',
  CanAddWorkspaces = 'Can add workspaces',
  CanRequestProcessing = 'Can request processing',
  CanCreateUpdateAndManageMaps = 'Can create, update and manage maps',
  CanViewAllMaps = 'Can view all maps',
  CanUploadToMaps = 'Can upload to maps',
  CanDownloadFromMaps = 'Can download from maps',
  CanShareMapsWithWorkspaceUsers = 'Can share maps with workspace users',
  CanShareMapsWithGuestsViewOnly = 'Can share maps with guests (View only)',
  CanDeleteMaps = 'Can delete maps',
  CanCreateSites = 'Can create sites',
  CanViewSharedMaps = 'Can view maps shared with them',
}

const generalOptions = [
  PermissionNames.CanAddEditAndRemoveUser,
  PermissionNames.CanUpdateUserPermissions,
  PermissionNames.CanInviteGuests,
  PermissionNames.CanShareMaps,
  PermissionNames.CanCreateUpdateAndManageWorkspaces,
  PermissionNames.CanUpdateBranding,
];
const billingOptions = [
  PermissionNames.CanAccessAccountInformation,
  PermissionNames.CanUpgradeDowngradePlans,
  PermissionNames.CanAddStorage,
  PermissionNames.CanAddSeats,
  PermissionNames.CanAddWorkspaces,
  PermissionNames.CanRequestProcessing,
];
const mapOptions = [
  PermissionNames.CanCreateUpdateAndManageMaps,
  PermissionNames.CanViewAllMaps,
  PermissionNames.CanUploadToMaps,
  PermissionNames.CanDownloadFromMaps,
  PermissionNames.CanShareMapsWithWorkspaceUsers,
  PermissionNames.CanShareMapsWithGuestsViewOnly,
  PermissionNames.CanDeleteMaps,
  PermissionNames.CanCreateSites,
  PermissionNames.CanViewSharedMaps,
];

const allOptions = [
  {
    title: 'General',
    value: generalOptions,
  },
  {
    title: 'Billing & Subscription',
    value: billingOptions,
  },
  {
    title: 'Maps',
    value: mapOptions,
  },
];

const rolePermissions = {
  Operations: [
    PermissionNames.CanAddEditAndRemoveUser,
    PermissionNames.CanUpdateUserPermissions,
    PermissionNames.CanInviteGuests,
    PermissionNames.CanShareMaps,
    PermissionNames.CanCreateUpdateAndManageWorkspaces,
    PermissionNames.CanCreateUpdateAndManageMaps,
    PermissionNames.CanViewAllMaps,
    PermissionNames.CanUploadToMaps,
    PermissionNames.CanDownloadFromMaps,
    PermissionNames.CanShareMapsWithWorkspaceUsers,
    PermissionNames.CanShareMapsWithGuestsViewOnly,
  ],
  Creator: [...generalOptions, ...billingOptions, ...mapOptions],
  Admin: [...generalOptions, ...billingOptions, ...mapOptions],
  'Organisation User': [
    PermissionNames.CanViewAllMaps, // Viewer role?
  ],
  Guest: [PermissionNames.CanViewSharedMaps],
};

interface Props {
  title: string;
  role: string;
}

const PermissionSelect = ({ title, role }: Props) => (
  <fieldset
    css={css`
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      border: none;
      padding: 0.5em 1em;
      label {
        margin-right: 1em;
      }
    `}
  >
    <label>{title}</label>
    <Toggle
      checked={
        rolePermissions[role] ? rolePermissions[role].includes(title) : false
      }
      onChange={() => null}
      // disabled
    />
  </fieldset>
);

export const PermissionsComponent = ({ role }) => {
  const meta = useMetaSelector();

  // useEffect(() => {
  //   console.log('role: ', role);
  // }, [role]);

  return (
    <div
      css={(theme) => css`
        border: solid 1px ${theme.mono30};
        border-radius: 10px;
        max-width: 30em;
        @media (max-width: ${breakpoints.xs}) {
          max-width: 100%;
        }
      `}
    >
      <ul
        css={css`
          padding: 0;
        `}
      >
        {allOptions.map((option) => (
          <div key={option.title}>
            <div
              css={(theme) => css`
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                border-bottom: solid 1px ${theme.mono30};
                padding: 1.25em;
                padding-top: ${option.title === 'General' ? '0.5em' : '1.25em'};
              `}
            >
              <span
                css={css`
                  font-weight: bold;
                `}
              >
                {option.title}
              </span>
              <ChevronDownSVG />
            </div>
            {option.value.map((item) => (
              <PermissionSelect key={item} title={item} role={role} />
            ))}
          </div>
        ))}
      </ul>
    </div>
  );
};
