import React from 'react';
import { css } from '@emotion/react';
import Script from 'react-load-script';

export const LoadZXCVBN = ({ onLoad }) => (
  <Script
    url="https://cdnjs.cloudflare.com/ajax/libs/zxcvbn/4.4.2/zxcvbn.js"
    onLoad={onLoad}
    async
  />
);

const ScoreWords = [
  <div>
    <span>&#10008; Weak password</span>
  </div>,
  <div>
    <span>&#10004; Ok password</span>
  </div>,
  <div>
    <span>&#10004; Good password</span>
  </div>,
  <div>
    <span>&#10004; Very good password</span>
  </div>,
  <div>
    <span>&#10004; Excellent password</span>
  </div>,
];

function getScoreWord(score) {
  if (typeof score !== 'number' || score < 0 || score >= 5) return '';
  return ScoreWords[score];
}

export const ZXCVBNDisplay = ({ zxcvbnResult }) => {
  if (!zxcvbnResult) return <div />;
  return (
    <div
      className="zxcvbn__msg"
      css={css`
        font-size: 0.9rem;
      `}
    >
      {getScoreWord(zxcvbnResult.score)}
    </div>
  );
};

ZXCVBNDisplay.defaultProps = {
  zxcvbnResult: {},
};
