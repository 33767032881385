import React from 'react';
import { css, Theme } from '@emotion/react';
import { withRouter } from 'react-router-dom';
import { useMetaSelector } from '../meta/reducer';
import { PermissionsComponent } from './permissions-component';
import RolesComponent from './roles-component';
import { breakpoints } from '@birdi/theme/variables';

export const Permissions = withRouter(({ match }): React.ReactElement => {
  const meta = useMetaSelector();
  const { organisation } = meta;

  return (
    <div>
      <h2
        css={css`
          margin: 1em;
        `}
      >
        Permissions
      </h2>
      <div
        css={css`
          padding: 1em;
        `}
      >
        <p
          css={(theme) => css`
            color: ${theme.flora};
            font-weight: bold;
          `}
        >
          Coming Soon ...
        </p>

        <div
          css={css`
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: flex-start;
            width: calc(100% - 3em);
            gap: 1em;
            @media (max-width: ${breakpoints.xs}) {
              flex-direction: column;
              width: 100%;
            }
          `}
        >
          <div
            css={css`
              display: flex;
              flex: 1;
              flex-direction: column;
              width: 100%;
            `}
          >
            <h3
              css={css`
                margin-top: 0;
              `}
            >
              Default account roles
            </h3>
            <RolesComponent details={null} />
          </div>
          <div
            css={css`
              display: flex;
              flex: 1;
              flex-direction: column;
              width: 100%;
            `}
          >
            <h3
              css={css`
                margin-top: 0;
              `}
            >
              Admin permissions
            </h3>
            <PermissionsComponent />
          </div>
        </div>
      </div>
    </div>
  );
});
