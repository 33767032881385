import { css } from '@emotion/react';
import { Link } from 'react-router-dom';
import { BirdiTheme, breakpoints } from '@birdi/theme';

const cardStyle = (theme: BirdiTheme) => css`
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${theme.mono20};
  border-radius: 3px;
  height: 21.5rem;
  box-shadow: 3px 3px 10px ${theme.mono30};
  transition:
    0.15s shadow,
    0.15s transform;
  box-sizing: border-box;
  width: 100%;
  margin: 0 0.1rem 1rem 0;
  @media (min-width: ${breakpoints.xs}) {
    width: calc((100% / 2) - 1rem);
    margin: 0 1rem 1rem 0;
  }
  @media (min-width: ${breakpoints.s}) {
    width: calc((100% / 3) - 1rem);
  }
`;

export const CreateMissionCard = () => (
  <Link
    to="/map/missions/plan"
    css={(theme: BirdiTheme) => css`
      ${cardStyle(theme)};
      cursor: pointer;
      background-image: url('https://birdi-public.s3-ap-southeast-2.amazonaws.com/street-background.png');
      background-position: center;
      background-size: cover;
      opacity: 0.5;
    `}
  >
    <span
      css={(theme: BirdiTheme) => css`
        color: ${theme.mono98};
        font-weight: bold;
      `}
    >
      + Create a map
    </span>
  </Link>
);

export const EmptyCard = () => (
  <div
    css={(theme: BirdiTheme) => css`
      ${cardStyle(theme)};
      background-color: ${theme.mono20};
    `}
  />
);
