export const loadHubspot = () => {
  const script = document.createElement('script');
  script.type = 'text/javascript';
  script.id = 'hs-script-loader';
  script.async = true;
  script.defer = true;
  script.src = 'https://js.hs-scripts.com/20496106.js';
  const head = document.getElementsByTagName('head')[0];
  head.appendChild(script);

  document.body.appendChild(script);
};
