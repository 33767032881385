interface NumberToCurrencyOpts {
  removeTrailing00?: boolean;
  showGST?: boolean;
}

export const numberToCurrencyStr = (
  val: number,
  currency: string,
  opts?: NumberToCurrencyOpts,
) => {
  const defaults = {
    removeTrailing00: true,
    showGST: false,
  };
  const formatting = {
    ...defaults,
    ...opts,
  };
  let formatted = `$${(val / 100).toFixed(2)}`;

  if (currency === 'aud') formatted = `A${formatted}`;
  if (formatting.removeTrailing00) formatted = formatted.replace('.00', '');
  if (formatting.showGST && currency === 'aud')
    formatted = `${formatted} (incl. GST)`;

  return formatted;
};

export const sanitizedPrice = (amount: number, currency: string) => {
  const fixedAmount = (amount / 100).toFixed(2);
  const prefix = currency === 'usd' ? '' : 'A';
  const suffix = currency === 'usd' ? ' USD' : '';
  const gst = currency === 'usd' ? '' : ' (incl. GST)';
  const result = `${prefix}$${fixedAmount}${suffix}${gst}`;
  return result;
};

export const sanitizedPAYGProcessingPrice = (
  amount: number,
  currency: string,
) => {
  const fixedAmount = (amount / 100).toFixed(2);
  const prefix = currency === 'usd' ? '' : 'A';
  const suffix = currency === 'usd' ? ' USD' : '';
  const result = `${prefix}$${fixedAmount}${suffix} (excl. GST)`;
  return result;
};

export const storagePricing = {
  free: {
    usd: 10000,
    aud: 13750,
  },
  paid: {
    usd: 5000,
    aud: 6875,
  },
};
