/* global fetch */

import querystring from 'querystring';
import { config } from '../config';
import {
  headers,
  stleaf,
  SpanTag,
  fetchAPIJSON,
  APIResponse,
} from '../helpers';

export interface OrganisationUser {
  id: string;
  scopeId: string;
  email: string;
  fname: string;
  lname: string;
}

/**
 * Simplified route for looking up users within organisation
 * @params search
 * @returns list of users
 */
export const getCurrentOrganisationUsers = (
  st: SpanTag,
  search: string,
): Promise<APIResponse<{ items: OrganisationUser[] }>> => {
  const queryString = querystring.stringify({ search: search });
  const url = `${config.API_URL}/v1/organisation/users/option?${queryString}`;
  return fetchAPIJSON(stleaf(st, 'cai5oo'), url, {
    method: 'GET',
    credentials: 'include',
    headers,
  });
};

export const getUsers = (st: SpanTag, opts) => {
  const queryString = querystring.stringify(opts);
  const url = `${config.API_URL}/v2/organisation/user?${queryString}`;
  return fetchAPIJSON(stleaf(st, 'quac0e'), url, {
    method: 'GET',
    credentials: 'include',
    headers,
  });
};

export const getMyDetails = (st: SpanTag) => {
  const url = `${config.API_URL}/v2/organisation/user/me`;
  return fetchAPIJSON(stleaf(st, 'iey6ai'), url, {
    method: 'GET',
    credentials: 'include',
    headers,
  });
};

export const getUserDetails = (st: SpanTag, scopeId) => {
  const url = `${config.API_URL}/v2/organisation/user/${scopeId}`;
  return fetchAPIJSON(stleaf(st, 'roo4vo'), url, {
    method: 'GET',
    credentials: 'include',
    headers,
  });
};

export const create = (
  st: SpanTag,
  params: {
    fname: string;
    lname: string;
    email: string;
    organisationId?: string;
    roleId?: string;
    sendInvitation?: boolean;
    position: string;
    phone: string;
    location: any;
  },
) => {
  const url = `${config.API_URL}/v2/organisation/user`;
  return fetchAPIJSON(stleaf(st, 'meox5p'), url, {
    method: 'POST',
    credentials: 'include',
    headers,
    body: JSON.stringify(params),
  });
};

export const deleteUser = (st: SpanTag, id) => {
  const url = `${config.API_URL}/v1/organisation/users/${id}`;
  return fetchAPIJSON(stleaf(st, 'waequ0'), url, {
    method: 'DELETE',
    credentials: 'include',
    headers,
  });
};

export const changeRole = (st: SpanTag, id, roleId) => {
  const url = `${config.API_URL}/v1/organisation/users/${id}/role/${roleId}`;
  return fetchAPIJSON(stleaf(st, 'phahy6'), url, {
    method: 'PUT',
    credentials: 'include',
    headers,
  });
};
