/* global fetch */

import { config } from '../config';
import { headers, APIResponse, stleaf, fetchAPI, SpanTag } from '../helpers';

export const getTags = (st: SpanTag): Promise<APIResponse> => {
  const url = `${config.API_URL}/v1/experience-tags`;
  return fetchAPI(stleaf(st, 'un8nee'), url, {
    method: 'GET',
    credentials: 'include',
    headers,
  });
};

export const add = (st: SpanTag, name: string): Promise<APIResponse> => {
  const url = `${config.API_URL}/v1/experience/${name}`;
  return fetchAPI(stleaf(st, 'wah5ta'), url, {
    method: 'POST',
    credentials: 'include',
    headers,
  });
};

export const findByName = (st: SpanTag, name: string): Promise<APIResponse> => {
  const url = `${config.API_URL}/v1/experience-tags/${name}`;
  return fetchAPI(stleaf(st, 'po2ohk'), url, {
    method: 'GET',
    credentials: 'include',
    headers,
  });
};

export const remove = (st: SpanTag, name: string): Promise<APIResponse> => {
  const url = `${config.API_URL}/v1/experience/${name}`;
  return fetchAPI(stleaf(st, 'fuzou4'), url, {
    method: 'DELETE',
    credentials: 'include',
    headers,
  });
};

export const getAll = (st: SpanTag): Promise<APIResponse> => {
  const url = `${config.API_URL}/v1/experience`;
  return fetchAPI(stleaf(st, 'gaegi6'), url, {
    method: 'GET',
    credentials: 'include',
    headers,
  });
};
