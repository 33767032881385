import { withRouter, Link } from 'react-router-dom';
import cx from 'classnames';
import { FeatureFlagNames } from '@birdi/js-sdk/src/public/organisation-features';
import { css } from '@emotion/react';
import { PoweredByBirdi } from '@birdi/branding/powered-by-birdi';
import {
  SideBar,
  SidebarLink,
  dividerStyle,
  TitleStyle,
  TitleHolder,
  iconStyle,
  poweredByBirdiStyle,
} from './side-bar-utils';
import { useMetaSelector } from '../../meta/reducer';
import DashboardSVG from '@birdi/icons/2022-home.svg';
import MapSVG from '@birdi/icons/map.svg';
import PilotSVG from '@birdi/icons/user.svg';
import SiteSVG from '@birdi/icons/globe.svg';
import { NewMapBadge } from '@birdi/components-common';

const SideNavigationV2 = withRouter(({ history }) => {
  const meta = useMetaSelector();
  const manageOrg = meta.permissions.includes('org:default'); // My Maps?
  const jobNotif = meta.jobNotifications;
  const sites = meta.orgFeatureFlags.includes(FeatureFlagNames.Sites);
  const mapsV2 = meta.orgFeatureFlags.includes(FeatureFlagNames.MapsV2);

  return (
    <div
      css={css`
        position: relative;
      `}
    >
      <SideBar>
        <Link to={'/'}>
          <div
            css={(theme) => css`
              display: flex;
              justify-content: flex-start;
              align-items: center;
              gap: 0.75em;
              padding: 0.75em 0.25em;
              width: cal(100% - 2em);
              background-color: ${'/' === history.location.pathname
                ? theme.lightOcean
                : theme.mono0};
              color: ${theme.midnight};
              border-radius: 7px;
              &:hover {
                background-color: ${'/' !== history.location.pathname
                  ? theme.sand
                  : theme.lightOcean};
              }
            `}
          >
            <DashboardSVG css={iconStyle} />
            <span
              css={css`
                font-weight: bold;
              `}
            >
              Dashboard
            </span>
          </div>
        </Link>
        <hr css={dividerStyle} />
        <div css={TitleHolder}>
          <MapSVG css={iconStyle} />
          <span css={TitleStyle}>Maps</span>
        </div>
        {mapsV2 && (
          <SidebarLink
            to="/new-maps/all"
            match={history.location.pathname}
            dashboard
          >
            <span
              css={css`
                display: inline-flex;
                align-items: center;
                gap: 8px;
              `}
            >
              Birdi 2.0 Maps
              <NewMapBadge />
            </span>
          </SidebarLink>
        )}
        <SidebarLink
          to="/missions/mine"
          match={history.location.pathname}
          dashboard
        >
          <span>My Maps</span>
        </SidebarLink>
        <SidebarLink
          to="/missions/shared"
          match={history.location.pathname}
          dashboard
        >
          <span>Shared With Me</span>
        </SidebarLink>
        <SidebarLink
          to="/missions/organisation"
          match={history.location.pathname}
          dashboard
        >
          <span>All Maps</span>
        </SidebarLink>
        <SidebarLink
          to="/missions/demo-missions"
          match={history.location.pathname}
          dashboard
        >
          <span>Demo Maps</span>
        </SidebarLink>
        <hr css={dividerStyle} />
        {jobNotif && (
          <>
            <div css={TitleHolder}>
              <PilotSVG css={iconStyle} />
              <span css={TitleStyle}>Pilot Network</span>
            </div>
            <SidebarLink
              to="/job-requests/incoming"
              match={history.location.pathname}
              dashboard
            >
              <span>Capture Requests</span>
            </SidebarLink>
            <hr css={dividerStyle} />
          </>
        )}
        {sites && (
          <>
            <div css={TitleHolder}>
              <SiteSVG css={iconStyle} />
              <span css={TitleStyle}>Sites</span>
            </div>
            <SidebarLink
              to="/sites"
              match={history.location.pathname}
              dashboard
            >
              <span>All Sites</span>
            </SidebarLink>
            <hr css={dividerStyle} />
          </>
        )}
      </SideBar>
      <div css={poweredByBirdiStyle}>
        <PoweredByBirdi mode="dark" />
      </div>
    </div>
  );
});

export default SideNavigationV2;
