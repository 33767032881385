import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { stroot, organisationUsers, user as userAPI } from '@birdi/js-sdk';
import { connect } from 'react-redux';
import * as userActions from '../account/actions';
import { withRouter } from 'react-router-dom';
import { css } from '@emotion/react';
import { useMetaSelector } from '../meta/reducer';
import { BirdiTheme, buttonBlue, buttonOutlineBlack } from '@birdi/theme';
import {
  pageTitleHolder,
  pageTitle,
  pageGraySection,
} from '@birdi/theme/blocks';
import { format, parseISO } from 'date-fns';
import { breakpoints } from '@birdi/theme/variables';
import {
  blockLabel,
  textInput,
  errorMessage,
  reactSelect,
} from '@birdi/theme/form';
import { useForm, Controller } from 'react-hook-form';
import { validatePhoneNumber } from '../onboarding/utils';
import * as orgUserActions from '../organisation-users/reducer';
import UploadAvatar from './upload-avatar';
import * as Toast from '@radix-ui/react-toast';
import * as styles from '../admin/styles';
import HandsSVG from '@birdi/icons/thank-you.svg';
import {
  LocationSelect,
  blankLocationWithName,
  blacklistedAreas,
} from '@birdi/google-places-autocomplete';
import { LoadingSpinner } from '@birdi/loading';

const GeneralInternal = withRouter((props) => {
  const { updateStateAndPatch, updateAvatarAndPatch, removeUser, match } =
    props;

  const history = useHistory();

  const meta = useMetaSelector();
  // if (meta.scopeId === match.params.scopeId) => myself
  const mySelf = meta.scopeId === match.params.scopeId;

  const { register, handleSubmit, reset, control, formState, watch } =
    useForm();

  const [details, setDetails] = useState(null);
  const [initialValues, setInitialValues] = useState({});
  const [toastOpen, setToastOpen] = React.useState(false);

  const [updateLoading, setUpdateLoading] = useState(false);
  const [locationHasChanged, setLocationHasChanged] = useState(false);

  const setData = (response) => {
    // console.log('response: ', response);
    setDetails(response);
    const initialData = {
      fname: response.fname,
      lname: response.lname,
      phone: response.phone,
      email: response.email,
      company: response.company,
      position: response.position,
      location: response.location,
    };
    reset(initialData);
    setInitialValues(initialData);
  };

  useEffect(() => {
    // if (mySelf) {
    //   organisationUsers.getMyDetails(stroot('if9eey'))
    //     .then((res) => setData(res.body));
    // }
    organisationUsers
      .getUserDetails(stroot('as7oo7'), match.params.scopeId)
      .then((res) => setData(res.body));
  }, [meta.loaded]);

  const currentValues = watch(); // Monitor form values

  const hasChanges = Object.keys(initialValues).some((key) => {
    const initial = initialValues[key];
    const current = currentValues[key];

    if (key === 'location') return initial.name !== current.name;
    return initial !== current;
  });

  const finishSave = () => {
    setInitialValues(currentValues);
    setDetails({ ...details, ...currentValues });
    setToastOpen(true);
    window.setTimeout(() => setToastOpen(false), 2000);
    setUpdateLoading(false);
  };

  const save = async () => {
    const initialValuesExceptLocation = initialValues;
    delete initialValuesExceptLocation.location;

    const valuesToUpdate = Object.keys(initialValuesExceptLocation).reduce(
      (acc, key) => {
        if (currentValues[key] !== initialValuesExceptLocation[key])
          acc[key] = currentValues[key];
        return acc;
      },
      {},
    );
    if (!Object.keys(valuesToUpdate).length && !locationHasChanged)
      return false;

    setUpdateLoading(true);

    if (locationHasChanged) {
      await userAPI.updateUserLocation(
        stroot('gu2ohm'),
        match.params.scopeId,
        currentValues.location,
      );
      setLocationHasChanged(false);
    }

    if (Object.keys(valuesToUpdate).length) {
      if (mySelf) {
        await updateStateAndPatch(valuesToUpdate);
      } else {
        await userAPI.update(
          stroot('ohp4om'),
          match.params.scopeId,
          valuesToUpdate,
        );
      }
    }

    finishSave();
  };

  if (!details) return <div />;

  const deleteUser = async (userId: string) => {
    await removeUser(userId);
    history.push('/admin-users');
  };

  return (
    <div>
      <div css={pageTitleHolder}>
        <h2 css={pageTitle}>
          {details.fname} {details.lname}
          <span
            css={css`
              font-weight: normal;
            `}
          >
            &nbsp;&nbsp;&nbsp;
            {details.roleName}
          </span>
        </h2>
      </div>
      <div
        css={(theme) => css`
          ${pageGraySection(theme)};
          justify-content: space-between;
          position: relative;
          @media (max-width: ${breakpoints.xs}) {
            padding-bottom: 1em;
            p {
              margin-bottom: 0;
            }
          }
        `}
      >
        <p>Manage users account information.</p>
        {!mySelf && (
          <div
            css={css`
              @media (max-width: ${breakpoints.xs}) {
                width: 100%;
                display: flex;
                justify-content: flex-end;
              }
            `}
          >
            <button
              type="button"
              onClick={() => {
                confirm('Are you sure you want to delete this user?') &&
                  deleteUser(details.id);
              }}
              css={(theme) => css`
                ${buttonOutlineBlack(theme)};
                background-color: ${theme.mono0};
                border: 1px solid ${theme.mono40};
                border-radius: 4px;
                height: 2em;
                span {
                  color: ${theme.mono50};
                }
              `}
            >
              <span>Delete user</span>
            </button>
          </div>
        )}
      </div>

      <div
        css={css`
          padding: 1em;
        `}
      >
        <div
          css={(theme: BirdiTheme) => css`
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            gap: 3em;
            padding: 1em 3em;
            border: solid 1px ${theme.mono20};
            border-radius: 5px;
            width: 80%;
            @media (max-width: ${breakpoints.xs}) {
              width: calc(100% - 2em);
              gap: 1em;
              padding: 1em;
            }
          `}
        >
          <UploadAvatar
            scopeId={match.params.scopeId}
            mySelf={mySelf}
            details={details}
            updateAvatarAndPatch={updateAvatarAndPatch}
          />
          <div
            css={css`
              span {
                display: block;
              }
            `}
          >
            <span
              css={css`
                font-size: 1.1em;
                font-weight: bold;
              `}
            >
              {`${details.fname} ${details.lname}`}
            </span>
            <span>{details.email}</span>
            <span>{`Phone: ${details.phone || '-'}`}</span>
            <span>{`Job Title: ${details.position || '-'}`}</span>
            <span>{`Location: ${details.locationId ? `${details.location?.suburb}, ${details.location?.state}` : '-'}`}</span>
            <span>{`Member Since: ${format(parseISO(details.createdAt), 'dd MMM yyyy')}`}</span>
            <span>Last Active: -</span>
          </div>
        </div>

        <form onSubmit={handleSubmit(save)}>
          <div
            css={css`
              margin-top: 2em;
              display: flex;
              flex-direction: row;
              justify-content: flex-start;
              align-items: flex-start;
              width: calc(100% - 3em);
              gap: 1em;
              @media (max-width: ${breakpoints.xs}) {
                flex-direction: column;
                gap: 0;
                width: 100%;
              }
            `}
          >
            <div
              css={css`
                display: flex;
                flex: 1;
                flex-direction: column;
                width: 100%;
              `}
            >
              <label css={blockLabel} htmlFor="company">
                Company Name
                <input css={textInput} type="text" {...register('company')} />
              </label>
              <label css={blockLabel} htmlFor="fname">
                First Name
                <input
                  css={textInput}
                  type="text"
                  id="fname"
                  {...register('fname')}
                />
              </label>
              <label css={blockLabel} htmlFor="lname">
                Last Name
                <input
                  css={textInput}
                  type="text"
                  id="lname"
                  {...register('lname')}
                />
              </label>
              <label css={blockLabel} htmlFor="email">
                Email
                <input
                  css={textInput}
                  type="text"
                  id="email"
                  {...register('email')}
                  disabled
                />
              </label>
            </div>
            <div
              css={css`
                display: flex;
                flex: 1;
                flex-direction: column;
                width: 100%;
              `}
            >
              <label css={blockLabel} htmlFor="position">
                Job Title
                <input css={textInput} type="text" {...register('position')} />
              </label>
              <label css={blockLabel} htmlFor="phone">
                Phone
                <Controller
                  render={({ field: { onChange } }) => (
                    <input
                      css={textInput}
                      type="text"
                      id="phone"
                      onChange={(event) => {
                        onChange(event.target.value);
                      }}
                      defaultValue={details.phone}
                    />
                  )}
                  control={control}
                  name="phone"
                  rules={{
                    validate: (value: string) =>
                      value !== null && validatePhoneNumber(value),
                  }}
                />
                {formState.errors.phone && (
                  <span css={errorMessage}>
                    Please provide a valid mobile number.
                  </span>
                )}
              </label>
              <label css={blockLabel} htmlFor="location">
                Location
                <Controller
                  control={control}
                  name="location"
                  {...register('location')}
                  render={({ field: { onChange } }) => (
                    <LocationSelect
                      inputId="location"
                      defaultValue={details?.location?.name}
                      isClearable
                      onClear={() => {
                        onChange(blankLocationWithName);
                        setLocationHasChanged(true);
                      }}
                      onLocationSelect={(geocode) => {
                        onChange(geocode.location);
                        setLocationHasChanged(true);
                      }}
                      placeholder="Look up suburbs"
                      styles={reactSelect}
                    />
                  )}
                />
              </label>
              <div
                css={css`
                  display: flex;
                  justify-content: flex-end;
                `}
              >
                <Toast.Provider>
                  <button
                    type="submit"
                    css={(theme) => css`
                      ${buttonBlue(theme)};
                      height: 2.5em;
                      gap: 0.5em;
                    `}
                    disabled={!hasChanges}
                  >
                    Save changes
                    {updateLoading && <LoadingSpinner />}
                  </button>
                  <Toast.Root css={styles.toastRoot} open={toastOpen}>
                    <Toast.Description css={styles.toastContent}>
                      <HandsSVG />
                      Profile data updated
                    </Toast.Description>
                  </Toast.Root>
                  <Toast.Viewport css={styles.toastViewport} />
                </Toast.Provider>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
});

export const General = connect(
  (state) => ({
    account: state.account,
  }),
  (dispatch) => ({
    updateStateAndPatch: (field) =>
      dispatch(userActions.updateStateAndPatch(field)),
    updateAvatarAndPatch: (field) =>
      dispatch(userActions.updateAvatarAndPatch(field)),
    removeUser: (id) => dispatch(orgUserActions.remove(id)),
  }),
)(GeneralInternal);
