import { stroot } from '@birdi/js-sdk';
import Select from 'react-select';
import { css } from '@emotion/react';
import React, { useState, useEffect } from 'react';
import { user as userAPI } from '@birdi/js-sdk/src/admin';
import { reactSelect } from '@birdi/theme/form';

interface UserScopeSelectProps {
  onSelect: (val: object, action: object) => void;
  userId: string;
  theme: object;
}

function formatScope(user) {
  return `${user.fname} ${user.lname} (${user.organisationName || 'Private'})`;
}

export const UserScopeSelect = ({
  userId,
  onSelect,
  theme,
}: UserScopeSelectProps): React.ReactElement => {
  const [scopes, setUserScopes] = useState([]);
  function handleSelect(val, action) {
    onSelect(val, action);
  }
  async function resolveUserData(userId) {
    await userAPI.getScopes(stroot('ib5of3'), userId).then((res) => {
      setUserScopes(
        res.body.items.map((item) => ({
          value: item.id,
          label: formatScope(item),
        })),
      );
      onSelect(
        { value: res.body.items[0].id, label: formatScope(res.body.items[0]) },
        { action: 'select-option' },
      );
    });
  }
  useEffect(() => {
    resolveUserData(userId);
  }, [userId]);
  if (!scopes.length) return false;
  return (
    <Select
      onChange={(val, action) => handleSelect(val, action)}
      options={scopes}
      defaultValue={scopes[0]}
      isClearable
      placeholder="Select user scope"
      valueKey="id"
      labelKey="label"
      theme={theme}
      css={css`
        min-width: 18rem;
      `}
      styles={reactSelect}
    />
  );
};
