import React from 'react';
import { css } from '@emotion/react';

export function Length({ password }) {
  if (!password || password.length >= 8) return <div />;
  return (
    <div className="length__msg">
      <p
        css={css`
          font-size: 0.9rem;
        `}
      >
        <span className="red">
          &#10008; Your password must be at least 8 characters long
        </span>
      </p>
    </div>
  );
}

Length.defaultProps = {
  password: null,
};
