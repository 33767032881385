import React from 'react';
import { css } from '@emotion/react';
import { BirdiTheme } from '@birdi/theme';
import BirdiLogoSVG from '@birdi/icons/birdi-logo-white.svg';
import BirdiLogoNavySVG from '@birdi/icons/birdi-logo-navy.svg';

interface BrandingProps {
  meta: any;
  onClick: () => void;
}

export const Branding = ({ meta, onClick }: BrandingProps) => (
  <button
    onClick={onClick}
    css={css`
      margin-right: auto;
    `}
  >
    {(meta.organisation?.displayBranding ||
      (meta.organisation?.displayLogo && meta.organisation?.logo)) && (
      <div
        css={css`
          display: flex;
          flex-direction: row;
          align-items: center;
        `}
      >
        {meta.organisation.displayLogo && meta.organisation.logo && (
          <img
            src={meta.organisation.logo}
            alt={`${meta.organisation.name} logo`}
            css={css`
              // logo size is 100px/80px
              width: 70px;
              height: 56px;
              margin-right: 0.5em;
              border-radius: 3px;
              object-fit: contain;
            `}
          />
        )}
        {meta.organisation?.displayBranding && (
          <div
            css={(theme: BirdiTheme) => css`
              font-weight: 600;
              color: ${theme.midnight};
              margin-right: 0.25em;
            `}
          >
            {meta.organisation.name}
          </div>
        )}
      </div>
    )}

    {!meta.organisation?.displayBranding &&
      (!meta.organisation?.displayLogo || !meta.organisation?.logo) && (
        <BirdiLogoNavySVG
          css={css`
            font-size: 4em;
            margin-right: 0.1em;
          `}
        />
      )}
  </button>
);
