/* globals confirm */

import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { css } from '@emotion/react';
import { ContextMenu, contextStyles } from '@birdi/context-menu/ContextMenu';
import { BirdiTheme, breakpoints } from '@birdi/theme';
import * as userActions from './reducer';
import history from '../state/history';
import { format, parseISO } from 'date-fns';
import { ResponsiveTable } from '../common/responsive-table';
import { useMetaSelector } from '../meta/reducer';
import { Link } from 'react-router-dom';

const formating = (item) => ({
  id: item.id,
  scopeId: item.scopeId,
  name: `${item.fname} ${item.lname}`,
  email: `${item.email}${item.emailVerified ? '' : ' (invited)'}`,
  roleName: item.roleName || '-',
  hasOpsAccess: item.hasOpsAccess,
  status: item.lockedAccount ? 'Locked' : 'Active',
  joined: item.createdAt
    ? format(parseISO(item.createdAt), 'dd MMM yyyy')
    : '-',
});

export const UserTable = ({ users, permissions }) => {
  const meta = useMetaSelector();
  const dispatch = useDispatch();
  const removeUser = (id: string) => dispatch(userActions.remove(id));

  const prepareFormatedData = (item) => [
    {
      title: 'Name',
      value: '',
      component: (
        <div
          css={css`
            min-width: 10em;
          `}
        >
          {permissions.includes('org:manage-users') ? (
            <Link to={`/profile-general/${item.scopeId}`}>
              <span
                css={(theme: BirdiTheme) => css`
                  color: ${theme.deepOcean};
                `}
              >
                {item.name}
              </span>
            </Link>
          ) : (
            <span>{item.name}</span>
          )}
        </div>
      ),
    },
    {
      title: 'Email',
      value: '',
      component: (
        <div
          css={css`
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 15em;
          `}
        >
          {item.email}
        </div>
      ),
      laptopOnly: true,
    },
    {
      title: 'Email',
      value: item.email,
      mobileOnly: true,
    },
    {
      title: 'Role',
      value: item.roleName,
    },
    {
      title: 'Type',
      value: '',
      component: (
        <div
          css={(theme: BirdiTheme) => css`
            background-color: ${item.roleName === 'Guest' || item.hasOpsAccess
              ? theme.lightOcean
              : theme.lightFlora};
            color: ${item.roleName === 'Guest' || item.hasOpsAccess
              ? theme.deepOcean
              : theme.flora};
            padding: 0.5em;
            border-radius: 4px;
            max-width: 4em;
            text-align: center;
          `}
        >
          {item.roleName === 'Guest' || item.hasOpsAccess ? 'Free' : 'Paid'}
        </div>
      ),
    },
    {
      title: 'Status',
      value: item.status,
    },
    {
      title: 'Joined',
      value: item.joined,
    },
    {
      title: '',
      value: '',
      component: (
        <div>
          {permissions.includes('org:manage-users') && item.id !== meta.id && (
            <ContextMenu alignRight>
              {(props) => (
                <div css={(theme: BirdiTheme) => css(contextStyles(theme))}>
                  <button
                    type="button"
                    onClick={() => {
                      history.push(`/profile-general/${item.scopeId}`);
                      props.hide();
                    }}
                  >
                    <span>Edit</span>
                  </button>
                  <button
                    onClick={() => {
                      confirm('Are you sure you want to delete this user?') &&
                        removeUser(item.id);
                      props.hide();
                    }}
                    type="button"
                  >
                    <span>Delete User</span>
                  </button>
                </div>
              )}
            </ContextMenu>
          )}
        </div>
      ),
    },
  ];
  const data = users.map((item) => formating(item));
  const formatedData = data.map((item) => prepareFormatedData(item));

  return (
    <div
      css={(theme: BirdiTheme) => css`
        flex-grow: 1;
        margin: 1em;
        padding: 1em 0;
        border: solid 1px ${theme.mono20};
        border-radius: 10px;
        @media (max-width: ${breakpoints.xs}) {
          border: none;
        }
      `}
    >
      <ResponsiveTable
        data={formatedData}
        tableStyles={css`
          tr {
            border: none;
          }
          th {
            padding: 0 1em 1em 1em;
          }
          td {
            padding: 1em 1em 0 1em;
          }
        `}
      />
    </div>
  );
};

// role select
// component: ({
//   isSelf, user, permissions, updateRole, seats, loadSeats, guestsOnly,
// }) => (
//   <td
//     key="role"
//     css={css`
//       margin-top: 25px;
//       width: 150px;
//     `}
//   >
//     {permissions.includes('org:manage-users') && (
//       <RoleSelect
//         onChange={(role) => {
//           const roleId = role ? role.value : null;
//           updateRole(user.id, roleId).then(() => {
//             loadSeats();
//           });
//         }}
//         defaultValue={{ label: user.roleName, value: user.roleId }}
//         isDisabled={isSelf || (user.roleName === 'Creator')}
//         guestsOnly={guestsOnly}
//       />
//     )}
//   </td>
// ),
