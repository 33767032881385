import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { css } from '@emotion/react';
import { BirdiTheme, breakpoints } from '@birdi/theme';
import { ManageSeatsButton } from '../billing/add-seats-modal';
import {
  loadSeats,
  useSeatsSelector,
  totalSeats,
} from '../billing/seats-reducer';
import { useMetaSelector } from '../meta/reducer';

const seatsInfoStyle = (theme: BirdiTheme) => css`
  display: flex;
  // width: fit-content;
  align-items: center;
  padding: 0.5em;
  background-color: ${theme.lightOcean};
  border-radius: 5px;
  margin-bottom: 1em;
  @media (max-width: ${breakpoints.m}) {
    margin: 0;
  }
`;

interface SeatsBadgeProps {
  modalOpen: boolean;
  setModalOpen: (value: boolean) => void;
}

/**
 * This component shows how many seats used & in total
 */
export const SeatsBadge = ({ modalOpen, setModalOpen }: SeatsBadgeProps) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const meta = useMetaSelector();
  // TODO: Type this at a higher level
  const seats = useSeatsSelector();
  useEffect(() => {
    loadSeats(dispatch);
  }, []);
  if (!seats.loaded) return <div css={seatsInfoStyle}>...</div>;
  return (
    <div>
      {!meta.organisation.externalBilling && !seats.unlimitedSeats && (
        <div css={seatsInfoStyle}>
          <span>{`Seats    ${seats.userCount}/${totalSeats(seats)} used`}</span>
          {meta.organisation.plan === 'growth' &&
            !meta.organisation.externalBilling &&
            meta.permissions.includes('org:manage-org') && (
              <ManageSeatsButton
                modalOpen={modalOpen}
                setModalOpen={setModalOpen}
              />
            )}
          {(meta.organisation.plan === 'free' ||
            meta.organisation.plan === 'plus') &&
            meta.permissions.includes('org:manage-org') && (
              <button
                type="button"
                css={(theme: BirdiTheme) => css`
                  color: ${theme.deepOcean};
                  text-decoration: underline;
                `}
                onClick={() => history.push('/billing/options')}
              >
                Upgrade
              </button>
            )}
        </div>
      )}
    </div>
  );
};
