import React, { useEffect, useState, useRef } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import BirdiLogoSVG from '@birdi/icons/birdi-logo-navy.svg';
import { css } from '@emotion/react';
import { formStyles, link } from '@birdi/theme/blocks';
import { BirdiTheme, buttonBlue } from '@birdi/theme';
import { updateField, signInWithPassword } from './actions';
import ErrorDisplay from '../common/ErrorDisplay';
import Password from '../common/password/Password';
import { GoogleAuthButton } from './GoogleAuthButton';
import { saveAuthSearchParams } from './utils';

const SignIn = (props) => {
  const [password, setPassword] = useState('');
  const formRef = useRef();

  useEffect(() => {
    // componentWillUnmount
    setPassword('');
    saveAuthSearchParams();
  }, []);

  const handleSignIn = (event) => {
    event.preventDefault();
    const { _signInWithPassword, auth } = props;
    if (!formRef.current.checkValidity())
      return formRef.current.reportValidity();
    return _signInWithPassword({ email: auth.email, password });
  };

  return (
    <div>
      <div
        css={css`
          text-align: center;
        `}
      >
        <BirdiLogoSVG
          css={css`
            width: 7em;
            height: 5em;
          `}
        />
      </div>
      <form
        ref={formRef}
        onSubmit={handleSignIn}
        css={(theme: BirdiTheme) => css(formStyles(theme))}
      >
        <div>
          <label htmlFor="email">
            Email
            <input
              id="email"
              type="email"
              value={props.auth.email}
              onChange={(event) =>
                props._updateField({ email: event.target.value })
              }
            />
          </label>
        </div>
        <Password
          password={password}
          setPassword={(value) => setPassword(value)}
        />
        <ErrorDisplay
          error={props.auth.error}
          css={(theme: BirdiTheme) => css`
            a {
              color: ${theme.deepOcean};
              font-weight: 500;
            }
          `}
        />
        <div
          css={css`
            margin: 1em 0;
          `}
        >
          <Link to="/reset-password" css={css(link)}>
            Forgot password?
          </Link>
        </div>
        <button
          type="submit"
          css={(theme: BirdiTheme) => css`
            ${buttonBlue(theme, { baseSize: 'md', fullWidth: true })};
            font: inherit;
          `}
        >
          Login
        </button>
      </form>
      <div
        css={(theme: BirdiTheme) => css`
          display: flex;
          justify-content: center;
          align-items: center;
          div {
            background-color: ${theme.mono20};
            width: 3rem;
            height: 1px;
            transform: translate(0, 2px);
          }
        `}
      >
        <div />
        <p
          css={(theme: BirdiTheme) => css`
            color: ${theme.mono50};
            font-weight: 500;
            padding: 0 0.5rem;
            margin: 1em 0;
          `}
        >
          or
        </p>
        <div />
      </div>
      <GoogleAuthButton />
      <p
        css={css`
          margin-bottom: 0;
        `}
      >
        Don&rsquo;t have an account?&nbsp;
        <a
          href="https://www.birdi.io/get-access"
          target="_blank"
          rel="noopener noreferrer"
          css={css(link)}
        >
          Get access
        </a>
      </p>
    </div>
  );
};

export default connect(
  (state) => ({
    auth: state.auth,
    profile: state.profile,
  }),
  (dispatch) => ({
    _updateField: (field) => dispatch(updateField(field)),
    _signInWithPassword: (obj) => dispatch(signInWithPassword(obj)),
  }),
)(withRouter(SignIn));
