import { withRouter } from 'react-router-dom';
import { css } from '@emotion/react';
import {
  SidebarLink,
  SidebarExternalLink,
  externalLinksHolder,
  externalLinkStyle,
} from './side-bar-utils';
import { useMetaSelector } from '../../meta/reducer';
import ContactUsSVG from '@birdi/icons/menu-contactus2.svg';
import SuggestSVG from '@birdi/icons/menu-suggest2.svg';
import UpgradeSVG from '@birdi/icons/menu-upgrade2.svg';

const BottomSection = withRouter(({ history }) => {
  const meta = useMetaSelector();
  const upgradeDisabled =
    meta.organisation.externalBilling ||
    !meta.permissions.includes('org:manage-users');

  return (
    <div
      css={css`
        position: relative;
      `}
    >
      <div css={externalLinksHolder}>
        <a href="mailto:support@birdi.io" css={externalLinkStyle}>
          <ContactUsSVG />
          <span>Contact us</span>
        </a>
        <SidebarExternalLink
          to={{
            pathname:
              'https://forms.monday.com/forms/0d33a85fe9a0f250f31fe772487c2978?r=use1',
          }}
        >
          <SuggestSVG />
          <span>Suggest a feature</span>
        </SidebarExternalLink>
        <SidebarLink
          to="/billing/options"
          match={history.location.pathname}
          disabled={upgradeDisabled}
        >
          <UpgradeSVG
            css={(theme) => css`
              // font-size: 2em;
              color: ${upgradeDisabled ? theme.mono30 : '#2287FA'};
            `}
          />
          <span
            css={(theme) => css`
              color: ${upgradeDisabled ? theme.mono30 : theme.deepOcean};
              font-weight: bold;
            `}
          >
            Upgrade
          </span>
        </SidebarLink>
      </div>
    </div>
  );
});

export default BottomSection;
