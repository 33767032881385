/* global zxcvbn */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import BirdiLogoSVG from '@birdi/icons/birdi-logo-navy.svg';
import { css } from '@emotion/react';
import queryString from 'querystring';
import { updateField, resetPassword } from './actions';
import EnterCode from './EnterCode';
import { getZxcvbnResult } from './resetpasswordutils';

class ResetOrganisationPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      password: '',
      zxcvbnResult: null,
    };
    this.form = React.createRef();
  }
  componentWillUnmount() {
    const { _updateField } = this.props;
    this.setState(() => ({ password: '' }));
    _updateField({
      pendingCode: false,
      code: '',
      error: null,
    });
  }
  setPassword = (value) => {
    const { auth } = this.props;
    if (typeof zxcvbn === 'function') {
      const zxcvbnResult = getZxcvbnResult(value, auth.email, auth.name);
      this.setState(() => ({ zxcvbnResult }));
    }
    this.setState({ password: value });
  };
  handlePasswordReset = (event) => {
    event.preventDefault();
    const { password } = this.state;
    const { auth, _resetPassword, location } = this.props;
    const newSearch = location.search.replace('?', '');
    const queryObject = queryString.parse(newSearch);
    const { id, organisationid } = queryObject;
    const code = queryObject.code ? queryObject.code : auth.code;
    if (!this.form.current.checkValidity())
      return this.form.current.reportValidity();
    return _resetPassword({
      ...(auth.email && { email: auth.email }),
      ...(id && { id }),
      code,
      password,
      ...(organisationid && { organisationId: organisationid }),
    });
  };
  render() {
    const { auth, _updateField, location } = this.props;
    const { password, zxcvbnResult } = this.state;
    // Enter code and new password
    return (
      <div>
        <div
          css={css`
            text-align: center;
          `}
        >
          <BirdiLogoSVG
            css={css`
              width: 7em;
              height: 5em;
            `}
          />
        </div>
        <h1>Set up your Birdi account</h1>
        <p>Before you can login, setup a password for your account.</p>
        <EnterCode
          form={this.form}
          auth={auth}
          _updateField={_updateField}
          onSubmit={this.handlePasswordReset}
          setPassword={this.setPassword}
          password={password}
          zxcvbnResult={zxcvbnResult}
          search={location.search}
          label="Create password"
        />
      </div>
    );
  }
}

export default connect(
  (state) => ({
    auth: state.auth,
  }),
  (dispatch) => ({
    _updateField: (field) => dispatch(updateField(field)),
    _resetPassword: (obj) => dispatch(resetPassword(obj)),
  }),
)(withRouter(ResetOrganisationPassword));
