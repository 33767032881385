/* global fetch */

import { config } from '../config';
import { headers, APIResponse, stleaf, fetchAPI, SpanTag } from '../helpers';

interface SignedUploadOpts {
  fileName: string;
  contentType: string;
  userId?: string;
  public?: boolean;
}

export const getSignedSinglePartUpload = (
  st: SpanTag,
  opts: SignedUploadOpts,
): Promise<APIResponse> => {
  const url = `${config.API_URL}/v1/uppy`;
  return fetchAPI(stleaf(st, 'wahf2e'), url, {
    method: 'POST',
    credentials: 'include',
    headers,
    body: JSON.stringify(opts),
  });
};
