import { Button, Modal } from '@birdi/components-common';
import BriefSVG from '@birdi/icons/brief.svg';
import { next } from '@birdi/theme';
import { SubmitHandler, useForm } from 'react-hook-form';
import { stroot, maps as mapsApi } from '@birdi/js-sdk';

interface MapRenameInputs {
    name: string;
}

interface MapRenameFormProps {
    initialName: string;
    onSuccess: () => void;
    mapId: string;
}

const MapRenameForm = ({ initialName, onSuccess, mapId }: MapRenameFormProps) => {
    const { register, handleSubmit, formState: { errors } } = useForm<MapRenameInputs>({
        defaultValues: {
            name: initialName,
        }
    });

    const onSubmit: SubmitHandler<MapRenameInputs> = async (props) => {
        await mapsApi.updateMap(stroot('k1665s'), mapId, { name: props.name });
        onSuccess();
    }

    return (
        <form id="map-details" css={next.form.root} onSubmit={handleSubmit(onSubmit)}>
            <label css={next.form.field.root}>
                <span>Map name</span>
                <input
                    {...register('name', { required: 'Map name cannot be empty.', setValueAs: (x: string) => x.trim() })}
                    css={next.inputs.text}
                    aria-invalid={errors.name ? 'true' : 'false'}
                />
                {errors.name && <p role="alert" css={next.form.field.error}>{errors.name.message}</p>}
            </label>
        </form>
    );
}

export interface MapRenameProps {
    open: boolean;
    onChange: (value: boolean) => void;
    initialName: string;
    mapId: string;
}

const MapRename = ({ open, onChange, initialName, mapId }: MapRenameProps) => {
    return (
        <Modal.Root open={open} onOpenChange={onChange}>
            <Modal.Header leftIcon={<BriefSVG width="24px" height="24px" />}>
                Details
            </Modal.Header>

            <Modal.Body>
                <MapRenameForm initialName={initialName} onSuccess={() => {
                    onChange(false);
                    window.location.reload();
                }} mapId={mapId} />
            </Modal.Body>

            <Modal.Footer buttons>
                <Button variant='outline' onClick={() => onChange(false)}>Cancel</Button>
                <Button variant='solid' type="submit" form="map-details">Save</Button>
            </Modal.Footer>
        </Modal.Root>
    );
};

export default MapRename;