/* global fetch */

import { config } from '../config';
import {
  headers,
  stleaf,
  fetchAPIJSON,
  SpanTag,
  APIResponse,
} from '../helpers';

export const getPrices = (st: SpanTag) => {
  const url = `${config.API_URL}/v2/subscription/prices`;
  return fetchAPIJSON(stleaf(st, 'oox1fi'), url, {
    method: 'GET',
    credentials: 'include',
    headers,
  });
};

export const checkoutSession = (st: SpanTag, subscriptionData) => {
  const url = `${config.API_URL}/v2/subscription/checkout`;
  return fetchAPIJSON(stleaf(st, 'aiy1de'), url, {
    method: 'POST',
    credentials: 'include',
    headers,
    body: JSON.stringify(subscriptionData),
  });
};

export const updateSubscription = (st: SpanTag, subscriptionData) => {
  const url = `${config.API_URL}/v2/subscription/plan`;
  return fetchAPIJSON(stleaf(st, 'un1ool'), url, {
    method: 'PUT',
    credentials: 'include',
    headers,
    body: JSON.stringify(subscriptionData),
  });
};

export const saveSubscriptionData = (st: SpanTag, id) => {
  const url = `${config.API_URL}/v2/subscription/session/${id}`;
  return fetchAPIJSON(stleaf(st, 'bow7ya'), url, {
    method: 'POST',
    credentials: 'include',
    headers,
  });
};

export const getCurrentPlan = (st: SpanTag) => {
  const url = `${config.API_URL}/v2/subscription/plan`;
  return fetchAPIJSON(stleaf(st, 'ao5jei'), url, {
    method: 'GET',
    credentials: 'include',
    headers,
  });
};

export const getPaymentMethods = (st: SpanTag) => {
  const url = `${config.API_URL}/v2/subscription/payment-methods`;
  return fetchAPIJSON(stleaf(st, 'iefei3'), url, {
    method: 'GET',
    credentials: 'include',
    headers,
  });
};

export const getCustomerInfo = (st: SpanTag) => {
  const url = `${config.API_URL}/v2/subscription/customer-info`;
  return fetchAPIJSON(stleaf(st, 'ad4cik'), url, {
    method: 'GET',
    credentials: 'include',
    headers,
  });
};

export const getInvoices = (st: SpanTag) => {
  const url = `${config.API_URL}/v2/subscription/invoices`;
  return fetchAPIJSON(stleaf(st, 'hae8oo'), url, {
    method: 'GET',
    credentials: 'include',
    headers,
  });
};

export const cancelSubscription = (st: SpanTag) => {
  const url = `${config.API_URL}/v2/subscription/plan`;
  return fetchAPIJSON(stleaf(st, 'hie6wa'), url, {
    method: 'delete',
    credentials: 'include',
    headers,
  });
};

export const getUpcomingInvoice = (st: SpanTag) => {
  const url = `${config.API_URL}/v2/subscription/upcoming-invoice`;
  return fetchAPIJSON(stleaf(st, 'raek1x'), url, {
    method: 'GET',
    credentials: 'include',
    headers,
  });
};

interface UpdateSeatsRequestOptions {
  quantity: number;
}

export const updateSeats = (st: SpanTag, opts: UpdateSeatsRequestOptions) => {
  const url = `${config.API_URL}/v2/subscription/seats`;
  return fetchAPIJSON(stleaf(st, 'hie6wa'), url, {
    method: 'PUT',
    credentials: 'include',
    headers,
    body: JSON.stringify(opts),
  });
};

export const getSeatPricing = (st: SpanTag, newSeatsQuantity: number) => {
  const url = `${config.API_URL}/v2/subscription/seats/price`;
  return fetchAPIJSON(stleaf(st, 'hie6wa'), url, {
    method: 'POST',
    credentials: 'include',
    headers,
    body: JSON.stringify({
      quantity: newSeatsQuantity,
    }),
  });
};

/**
 * Currently used for starting processing trial id 2022
 * @param trialId
 */
export const startTrial = (st: SpanTag, trialId = 'teams-2022-11') => {
  const url = `${config.API_URL}/v2/subscription/trial`;
  return fetchAPIJSON(stleaf(st, 'hie6wa'), url, {
    method: 'POST',
    credentials: 'include',
    headers,
    body: JSON.stringify({
      trialId,
    }),
  });
};

export const processingCheckout = (st: SpanTag, jobId: string) => {
  const url = `${config.API_URL}/v2/subscription/processing-checkout/${jobId}`;
  return fetchAPIJSON(stleaf(st, 'john8z'), url, {
    method: 'POST',
    credentials: 'include',
    headers,
  });
};

export const updateUpcomingInvoice = (st: SpanTag, jobId: string) => {
  const url = `${config.API_URL}/v2/subscription/upcoming-invoice/${jobId}`;
  return fetchAPIJSON(stleaf(st, 'oqu9ie'), url, {
    method: 'POST',
    credentials: 'include',
    headers,
  });
};

export const getStripePortal = (st: SpanTag) => {
  const url = `${config.API_URL}/v2/subscription/stripe-portal`;
  return fetchAPIJSON(stleaf(st, 'ohxu2l'), url, {
    method: 'GET',
    credentials: 'include',
    headers,
  }) as Promise<APIResponse<{ data: string }>>;
};
