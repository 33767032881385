import { config } from '../config';
import { APIResponse, fetchAPI, headers, SpanTag, stleaf } from '../helpers';
import {
  GetProcessingJobsFiltersV2,
  ProcessingPlatformRequest,
} from './processing-jobs-types';

export * from './processing-jobs-types';

export const getGPEstimationByFolder = (
  st: SpanTag,
  folderId: string,
): Promise<APIResponse> => {
  const url = `${config.API_URL}/v2/processing-jobs/folder/${folderId}/cost-estimation`;
  return fetchAPI(stleaf(st, 'cpooec'), url, {
    method: 'GET',
    credentials: 'include',
    headers,
  });
};

export const postPaygJobV2 = (
  st: SpanTag,
  requestObj: ProcessingPlatformRequest,
): Promise<APIResponse> => {
  const url = `${config.API_URL}/v2/processing-jobs/payg`;
  return fetchAPI(stleaf(st, 'picy35'), url, {
    method: 'POST',
    credentials: 'include',
    body: JSON.stringify(requestObj),
    headers,
  });
};

export const postOpsJobV2 = (
  st: SpanTag,
  requestObj: ProcessingPlatformRequest,
): Promise<APIResponse> => {
  const url = `${config.API_URL}/v2/processing-jobs`;
  return fetchAPI(stleaf(st, 'mhcy35'), url, {
    method: 'POST',
    credentials: 'include',
    body: JSON.stringify(requestObj),
    headers,
  });
};

export const postEnterpriseAssistedJobV2 = (
  st: SpanTag,
  requestObj: ProcessingPlatformRequest,
): Promise<APIResponse> => {
  const url = `${config.API_URL}/v2/processing-jobs/enterprise/assisted`;
  return fetchAPI(stleaf(st, 'locy35'), url, {
    method: 'POST',
    credentials: 'include',
    body: JSON.stringify(requestObj),
    headers,
  });
};

export const postEnterpriseJobV2 = (
  st: SpanTag,
  requestObj: ProcessingPlatformRequest,
): Promise<APIResponse> => {
  const url = `${config.API_URL}/v2/processing-jobs/enterprise/self-serve`;
  return fetchAPI(stleaf(st, 'kycy35'), url, {
    method: 'POST',
    credentials: 'include',
    body: JSON.stringify(requestObj),
    headers,
  });
};

export const getJobsV2 = (
  st: SpanTag,
  filters: GetProcessingJobsFiltersV2 = {},
): Promise<APIResponse> => {
  const url = new URL(`${config.API_URL}/v2/processing-jobs/list`);
  const searchParams = new URLSearchParams(filters);
  url.search = searchParams.toString();
  return fetchAPI(stleaf(st, 'nau5no'), url, {
    method: 'GET',
    credentials: 'include',
    headers,
  });
};
