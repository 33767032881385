// https://developers.intercom.com/installing-intercom/docs/basic-javascript
export function loadIntercom(hide_default_launcher = false) {
  window.intercomSettings = {
    api_base: 'https://api-iam.intercom.io',
    app_id: 'b0ht41y7',
    hide_default_launcher,
  };
  (function () {
    var w = window;
    var ic = w.Intercom;
    if (typeof ic === 'function') {
      ic('reattach_activator');
      ic('update', w.intercomSettings);
    } else {
      var d = document;
      var i = function () {
        i.c(arguments);
      };
      i.q = [];
      i.c = function (args) {
        i.q.push(args);
      };
      w.Intercom = i;
      var l = function () {
        var s = d.createElement('script');
        s.type = 'text/javascript';
        s.async = true;
        s.src = 'https://widget.intercom.io/widget/b0ht41y7';
        var x = d.getElementsByTagName('script')[0];
        x.parentNode.insertBefore(s, x);
      };
      if (document.readyState === 'complete') {
        l();
      } else if (w.attachEvent) {
        w.attachEvent('onload', l);
      } else {
        w.addEventListener('load', l, false);
      }
    }
  })();
}

// We need this explicit settings code otherwise
// windows.intercomSettings in loadIntercom will overwrite this
// this is racey as this is only relevant (ontop of the explict Intercom('update')
// when Intercom loads AFTER this is called
// TL;DR we need both the settings & the update
export function hideIntercom() {
  if (window.intercomSettings) {
    window.intercomSettings.hide_default_launcher = true;
  }
  window.Intercom &&
    window.Intercom('update', {
      hide_default_launcher: true,
    });
}

export function showIntercom() {
  window?.Intercom &&
    window.Intercom('update', {
      hide_default_launcher: false,
    });
}
