import { stroot } from '@birdi/js-sdk';
import AsyncSelect from 'react-select/async';
import { components } from 'react-select';
import { css } from '@emotion/react';
import { formatName } from '@birdi/utils/src/text';
import React, { useState, useEffect } from 'react';
import { user as userAPI } from '@birdi/js-sdk/src/admin';

// TODO: return full name on api side
function formatScopedUser(user) {
  return `${formatName(user.fname, user.lname)} (${user.organisationName || 'Private Account'})`;
}

async function getOptions(input, callback, filterByJobNotifications) {
  const usersRequest = await userAPI.get(stroot('ve5eim'), {
    search: input,
    scope: true,
    jobNotifications: filterByJobNotifications,
  });
  let users = usersRequest.body.items;
  const options = users.map((user) => {
    const label = formatScopedUser(user);
    return {
      label,
      value: user.id,
      data: user,
    };
  });
  return callback(options);
}

const Option = ({ ...props }) => {
  const user = props.data.data;
  return (
    <components.Option {...props}>
      <div>{formatScopedUser(user)}</div>
      <div
        css={(theme) => css`
          color: ${theme.mono70};
        `}
      >
        {user.email}
      </div>
    </components.Option>
  );
};

const SingleValue = ({ ...props }) => {
  const user = props.data.value;
  return (
    <components.SingleValue {...props}>
      <div
        css={css`
          div {
            margin: 0.25em 0;
          }
        `}
      >
        <div>{formatScopedUser(user)}</div>
        <div
          css={(theme) => css`
            color: ${theme.mono70};
          `}
        >
          {user.email}
        </div>
      </div>
    </components.SingleValue>
  );
};

/**
 * UserScopeSelect: Selects scoped users based on scopeId
 * @param {Array} usersToFilter list of users to exclude from search
 * @param {function} onChange function to call when changed
 * @param {object} theme react-select theme
 */
export function UserScopeSelect({
  userScopeId,
  onChange,
  isLoading,
  theme,
  isDisabled = false,
  filterByJobNotifications = false,
  isClearable = true,
  styles,
  placeholder,
  marginBottom = '1em',
  controlStyles = {},
}) {
  const [user, setUserData] = useState({
    fname: '',
    lname: '',
    email: '',
    organisationName: '',
    scopeId: userScopeId,
  });

  function clearUserData() {
    setUserData({
      fname: '',
      lname: '',
      email: '',
      organisationName: '',
      resolved: false,
    });
  }

  async function resolveUserData(scopeId) {
    const response = await userAPI.getOne(stroot('uox9sh'), { scopeId });
    const { fname, lname, email } = response.body;
    const { name } = response.body.organisation;
    setUserData({
      fname,
      lname,
      email,
      organisationName: name,
      resolved: true,
    });
  }

  useEffect(() => {
    if (!userScopeId) {
      clearUserData();
    }
    if (userScopeId && !user.id) {
      resolveUserData(userScopeId);
    }
  }, [userScopeId]);

  return (
    <AsyncSelect
      value={
        user && user.fname !== ''
          ? {
              label: formatScopedUser(user),
              value: user,
            }
          : null
      }
      onChange={(val, action) => onChange(val, action)}
      loadOptions={(input, callback) =>
        getOptions(input, callback, filterByJobNotifications)
      }
      isClearable={isClearable}
      isLoading={isLoading}
      placeholder={placeholder || 'Search users'}
      valueKey="id"
      labelKey="label"
      theme={theme}
      css={
        styles
          ? null
          : css`
              min-width: 18rem;
              font-weight: normal;
            `
      }
      styles={
        styles
          ? styles
          : {
              // TODO: On emotion based react-select,
              // use flex props on valueContainer to move input up to
              // first line
              container: (base) => ({
                ...base,
                marginBottom: marginBottom,
              }),
              valueContainer: (base) => ({
                ...base,
              }),
              control: (base) => ({
                ...base,
                height: '100%',
                ...controlStyles,
              }),
            }
      }
      components={{
        SingleValue,
        Option,
      }}
      isDisabled={isDisabled}
    />
  );
}
