import { css, Theme } from '@emotion/react';

export const containerStyle = (() => {
  const base = css`
    display: flex;
    box-sizing: border-box;
    gap: 5px;
    width: 100%;
  `;

  return {
    base,
    flexHorizontal: css`
      ${base};
      justify-content: space-between;
      flex-direction: row;
      align-items: center;
    `,
    flexVertical: css`
      ${base};
      flex-direction: column;
      // padding: 1rem 0;
    `,
  };
})();

export const titleStyle = (theme: Theme) => css`
  margin-right: auto;
  font-size: 1rem;
  color: ${theme.mono3};
`;

export const svgStyle = css`
  width: 1.5rem;
  height: 1.5rem;
`;

/** color-picker and color-preset */
export const colorSelectStyles = {
  container: css`
    display: flex;
    align-items: center;
    gap: 0.5rem;
    align-self: center;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 5px;
  `,
  box: (theme) => css`
    width: 1.5rem;
    height: auto;
    aspect-ratio: 1;
    border-radius: 4px;
    border: solid 1px ${theme.midGrey};
    background: var(--picked-colour);
    align-self: center;
  `,
  valueInput: (theme) => css`
    height: 1.5rem;
    padding: 0 5px;
    border-radius: 6px;
    font-size: 14px;
    flex: 1;
    border: 1px solid ${theme.midGrey};
    box-sizing: border-box;
  `,
  hexInput: (theme) => css`
    ${colorSelectStyles.valueInput(theme)}
    text-transform: uppercase;
    width: 9ch;
  `,
  addBoxButton: (theme) => css`
    background: transparent;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    margin-left: auto;
  `,
  /** colorboxes insite presetSelect options */
  selectOptionBoxesWrapper: (theme) => css`
    display: flex;
    margin-left: 10px;
  `,
  selectOptionBox: (theme) => css`
    display: inline-block;
    width: 1rem;
    height: 1.3rem;
    margin-left: 0;
    border-top: 1px solid ${theme.midGrey};
    border-bottom: 1px solid ${theme.midGrey};
    `,
  selectOptionBoxFirst: (theme) => css`
    ${colorSelectStyles.selectOptionBox(theme)};
    border-radius: 5px 0 0 5px;
    border-left: 1px solid ${theme.midGrey};
  `,
  selectOptionBoxLast: (theme) => css`
    ${colorSelectStyles.selectOptionBox(theme)};
    border-radius: 0 5px 5px 0;
    border-right: 1px solid ${theme.midGrey};
  `,
};
