import { stroot } from '@birdi/js-sdk';
import { equipment, organisationEquipment } from '@birdi/js-sdk';
import {
  EquipmentParams,
  EquipmentAssociation,
  EquipmentType,
} from '@birdi/js-sdk/src/public/equipment';
import { createAction, Dispatch } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';

interface FetchOptionType {
  start: number;
  association: EquipmentAssociation;
  search: string;
  limit: number;
}

interface FetchEquipmentListPayload {
  items: EquipmentType[];
  reset: boolean;
}

export const enum EquipmentActions {
  FETCHING = 'equipment/FETCHING',
  FETCH_EQUIPMENT_LIST = 'equipment/FETCH_EQUIPMENT_LIST',
  ADD_EQUIPMENT = 'equipment/ADD_EQUIPMENT',
  DELETE_EQUIPMENT = 'equipment/DELETE_EQUIPMENT',
  ERROR = 'equipment/ERROR',
}

interface EquipmentState {
  loaded: boolean;
  list: EquipmentType[];
  error: string | null;
  allEquipmentLoaded: boolean;
}

export const useEquipmentSelector = () =>
  useSelector<{ equipment: EquipmentState }, EquipmentState>(
    (state) => state.equipment,
  );
export const initialState: EquipmentState = {
  loaded: false,
  list: [],
  error: null,
  allEquipmentLoaded: false,
};

const fetchingAction = createAction(EquipmentActions.FETCHING);
const fetchEquipmentListAction = createAction<
  Partial<FetchEquipmentListPayload>
>(EquipmentActions.FETCH_EQUIPMENT_LIST);
const addEquipmentAction = createAction<Partial<EquipmentType>>(
  EquipmentActions.ADD_EQUIPMENT,
);
const deleteEquipmentAction = createAction<string>(
  EquipmentActions.DELETE_EQUIPMENT,
);
const errorAction = createAction<unknown>(EquipmentActions.ERROR);

const fetchEquipment = async (opts: Partial<FetchOptionType>) => {
  if (opts.association === EquipmentAssociation.Mine) {
    return equipment.list(stroot('hiex3x'), opts);
  }
  if (opts.association === EquipmentAssociation.Organisation) {
    return organisationEquipment.get(stroot('ir4ei9'), opts);
  }
  // return missionSharing.getMissions(opts);
};

export const loadMore = (dispatch: Dispatch, opts: FetchOptionType) => {
  fetchEquipment(opts).then((response) => {
    dispatch(fetchEquipmentListAction({ items: response.body }));
  });
};

export const listEquipment = (
  dispatch: Dispatch,
  opts: Partial<FetchOptionType>,
) => {
  dispatch(fetchingAction());
  fetchEquipment(opts).then((response) => {
    dispatch(fetchEquipmentListAction({ items: response.body, reset: true }));
  });
};

export const addEquipment = (dispatch: Dispatch, params: EquipmentParams) => {
  equipment
    .add(stroot('viej0c'), params)
    .then((response) => {
      if (response.body) dispatch(addEquipmentAction(response.body));
    })
    .catch((err) => dispatch(errorAction(err)));
};

export const deleteEquipment = (dispatch: Dispatch, id: string) => {
  equipment
    .deleteEquipment(stroot('pi2jie'), id)
    .then(() => dispatch(deleteEquipmentAction(id)))
    .catch((err) => dispatch(errorAction(err)));
};

export const equipmentReducer = (
  state = initialState,
  { type, payload }: { type: EquipmentActions; payload: any },
) => {
  switch (type) {
    case EquipmentActions.FETCHING:
      return { ...initialState, loaded: false, allMissionsLoaded: false };
    case EquipmentActions.FETCH_EQUIPMENT_LIST: {
      const { reset = false } = payload;
      if (payload.items.length === 0) {
        return { ...state, loaded: true, allEquipmentLoaded: true };
      }
      let newItems: EquipmentType[] = [];
      if (reset) newItems = newItems.concat(payload.items);
      if (!reset) newItems = newItems.concat(state.list).concat(payload.items);
      return { ...state, loaded: true, list: newItems };
    }
    case EquipmentActions.ADD_EQUIPMENT: {
      const equipmentList = [...state.list];
      equipmentList.push(payload);
      return { ...state, list: equipmentList, allEquipmentLoaded: false };
    }
    case EquipmentActions.DELETE_EQUIPMENT: {
      const equipmentList = [...state.list];
      const filteredEquipment = equipmentList.filter(
        (eachEquipment) => eachEquipment.id !== payload,
      );
      return { ...state, list: filteredEquipment };
    }
    case EquipmentActions.ERROR: {
      return { ...state, error: payload };
    }
    default:
      return state;
  }
};
