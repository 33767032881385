import React, { useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Formik, Form, Field } from 'formik';
import { isEmail } from 'validator';
import { useDispatch } from 'react-redux';
import * as Dialog from '@radix-ui/react-dialog';
import { css } from '@emotion/react';
import CloseSVG from '@birdi/icons/close.svg';
import { closeButtonCSS, contentCSS, portalCSS } from '@birdi/modal-kit';
import { BirdiTheme, buttonBlue } from '@birdi/theme';
import { formStyles } from '@birdi/theme/blocks';
import { errorMessage, textInput } from '@birdi/theme/form';
import { stroot, organisationUsers } from '@birdi/js-sdk';
import RoleSelect from '@birdi/select/RoleSelect';
import { breakpoints } from '@birdi/theme/variables';
import { LocationSelect } from '@birdi/google-places-autocomplete';
import {
  loadSeats,
  useSeatsSelector,
  totalSeats,
} from '../billing/seats-reducer';
import * as userActions from './reducer';

interface InviteUserModalProps {
  open: boolean;
  setOpen: (value: boolean) => void;
}

export const InviteUserModal = ({ open, setOpen }: InviteUserModalProps) => {
  // TODO: Reimplement dummy vals
  // TODO: Seats reducer
  const history = useHistory();
  const dispatch = useDispatch();
  const seats = useSeatsSelector();
  useEffect(() => {
    dispatch(userActions.getUsers());
    loadSeats(dispatch);
  }, []);
  // end dummy vals
  const addUser = async (userObj, { setSubmitting, setFieldError }) => {
    setSubmitting(false);

    const preparedUser = {
      ...userObj,
      roleId: userObj.role.value,
    };
    delete preparedUser.role;

    try {
      // TODO: Add user directly into state
      const res = await organisationUsers.create(
        stroot('no8ief'),
        preparedUser,
      );
      if (res.status === 201) {
        window.location.reload();
      }
    } catch (err) {
      if (err.status === 409) {
        setFieldError('email', (err as Error).message);
      } else {
        const error = (err as Error).message;
        if (typeof error.message === 'string') {
          setFieldError('general', error.message);
        } else {
          setFieldError('general', 'something went wrong');
        }
      }
    }
  };

  const validate = (values: Record<string, unknown>) => {
    const errors: Record<string, string> = {};
    if (!values.fname) errors.fname = 'First name is required';
    if (!values.lname) errors.lname = 'Last name is required';
    if (!values.email) errors.email = 'Email is required';
    if (!values.role?.label) {
      // role id is required
      errors.role = 'required';
    } else if (
      values.role.label !== 'Guest' &&
      (seats.plan === 'free' || seats.plan === 'plus') &&
      totalSeats(seats) <= seats.userCount
    ) {
      // User with free subscription shouldn't be able to invite non guest members
      errors.role = 'upgrade';
    } else if (
      values.role.label !== 'Guest' &&
      seats.plan === 'growth' &&
      totalSeats(seats) <= seats.userCount
    ) {
      // User with 0 remained paid seats shouldn't be able to invite non guest members
      errors.role = 'addSeat';
    }
    if (typeof values.email !== 'string' || !isEmail(values.email)) {
      errors.email = 'Please use a valid email address';
    }
    return errors;
  };

  return (
    <Dialog.Root open={open} onOpenChange={setOpen}>
      <Dialog.Portal>
        <Dialog.Overlay />

        <div css={portalCSS}>
          <Dialog.Content
            css={(theme: BirdiTheme) => css`
              ${contentCSS(theme)};
              overflow-y: auto;
            `}
          >
            <Dialog.Close css={closeButtonCSS}>
              <CloseSVG />
            </Dialog.Close>
            <Formik
              initialValues={{
                fname: '',
                lname: '',
                email: '',
              }}
              onSubmit={addUser}
              validate={validate}
            >
              {({ handleSubmit, errors, touched, setFieldValue, isValid }) => (
                <Form css={formStyles}>
                  <div
                    css={css`
                      display: flex;
                      flex-direction: column;
                      justify-content: center;
                      align-items: center;
                    `}
                  >
                    <div
                      css={css`
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        justify-content: center;
                      `}
                    >
                      <h1
                        css={css`
                          align-text: center;
                          margin: 1.5em 0 0.5em 0;
                          font-size: 1.5em;
                        `}
                      >
                        Invite team member
                      </h1>
                    </div>
                    <p
                      css={css`
                        margin: 0;
                      `}
                    >
                      Add the people in your organization who will be using
                      Birdi.
                    </p>
                  </div>
                  <div
                    css={css`
                      display: flex;
                      flex-direction: row;
                      width: 100%;
                      margin-top: 2em;
                      @media (max-width: ${breakpoints.xs}) {
                        flex-direction: column;
                      }
                    `}
                  >
                    <div
                      css={css`
                        display: flex;
                        flex: 1;
                        flex-direction: column;
                        margin-right: 0.5em;
                      `}
                    >
                      <label htmlFor="fname">
                        First name
                        <Field
                          type="text"
                          name="fname"
                          id="fname"
                          placeholder="Enter first name"
                        />
                      </label>
                      {errors.fname && touched.fname && (
                        <div css={errorMessage}>{errors.fname}</div>
                      )}
                      <label htmlFor="lname">
                        Last name
                        <Field
                          type="text"
                          name="lname"
                          id="lname"
                          placeholder="Enter last name"
                        />
                      </label>
                      {errors.lname && touched.lname && (
                        <div css={errorMessage}>{errors.lname}</div>
                      )}
                      <label htmlFor="email">
                        Email
                        <Field
                          type="text"
                          name="email"
                          id="email"
                          placeholder="Enter email"
                        />
                      </label>
                      {errors.email && touched.email && (
                        <div css={errorMessage}>{errors.email}</div>
                      )}
                      <label htmlFor="location">
                        Location
                        <LocationSelect
                          inputId="location"
                          onLocationSelect={(geocode) => {
                            setFieldValue('location', geocode.location);
                          }}
                          placeholder="Select location"
                        />
                      </label>
                    </div>
                    <div
                      css={css`
                        display: flex;
                        flex: 1;
                        flex-direction: column;
                        margin-left: 0.5em;
                      `}
                    >
                      <label htmlFor="phone">
                        Phone Number
                        <Field
                          type="text"
                          name="phone"
                          id="phone"
                          placeholder="Enter phone number"
                        />
                      </label>
                      <label htmlFor="position">
                        Job Title
                        <Field
                          type="text"
                          name="position"
                          id="position"
                          placeholder="Enter job title"
                        />
                      </label>
                      <label htmlFor="role">
                        Role
                        <RoleSelect
                          placement="top"
                          onChange={(role) => {
                            setFieldValue('role', role);
                          }}
                          placeholder="Select role"
                          // isAdmin={seats.plan === 'free' && seats.baseSeatQuantity === 2 && seats.userCount === 1}
                        />
                      </label>
                      {errors.role === 'required' && (
                        <div
                          css={css`
                            ${errorMessage};
                            margin-top: 0.25em;
                          `}
                        >
                          Role is required
                        </div>
                      )}
                      {errors.role === 'upgrade' && (
                        <div>
                          <span
                            css={css`
                              ${errorMessage};
                              margin-top: 0.25em;
                            `}
                          >
                            For inviting non guest members, you need to &nbsp;
                            <Link
                              to="/billing/options"
                              css={(theme: BirdiTheme) =>
                                `color: ${theme.deepOcean}; text-decoration: underline`
                              }
                            >
                              upgrade your plan
                            </Link>
                          </span>
                        </div>
                      )}
                      {errors.role === 'addSeat' && (
                        <div>
                          <span
                            css={css`
                              ${errorMessage};
                              margin-top: 0.25em;
                            `}
                          >
                            You have used all your paid seats, You can either
                            invite a new user as a guest or &nbsp;
                            <Link
                              to="/admin-users?modal=seats"
                              css={(theme: BirdiTheme) =>
                                `color: ${theme.deepOcean}; text-decoration: underline; cursor: pointer`
                              }
                            >
                              add additional seats
                            </Link>
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                  {errors.general && (
                    <div
                      css={css`
                        ${errorMessage};
                        margin-top: 1em;
                      `}
                    >
                      {errors.general}
                    </div>
                  )}
                  <button
                    type="button"
                    onClick={handleSubmit}
                    disabled={!isValid}
                    css={(theme: BirdiTheme) => css`
                      ${buttonBlue(theme)}
                      margin-top: 1rem;
                      margin-left: auto;
                      border-radius: 1.5em;
                    `}
                  >
                    Invite to platform
                  </button>
                </Form>
              )}
            </Formik>
          </Dialog.Content>
        </div>
      </Dialog.Portal>
    </Dialog.Root>
  );
};
