import { css } from '@emotion/react';
import React from 'react';
import { useTheme } from '@emotion/react';
import { colorSelectStyles } from './styles';
// import { numericInputStyle } from "./numericInputStyle";

export const InputNumeric = React.forwardRef<
  HTMLInputElement,
  Omit<JSX.IntrinsicElements['input'], 'type'> & {
    value: number;
    onChange: (value: number) => void;
  }
>(({ value, onChange, ...props }, ref) => {
  const theme = useTheme();
  const [localValue, setLocalValue] = React.useState<string>(value.toString());

  // Update local value when prop value changes
  React.useEffect(() => {
    setLocalValue(value.toString());
  }, [value]);

  const handleCommit = (inputValue: string) => {
    if (inputValue !== '') {
      onChange(parseFloat(inputValue));
    }
  };

  return (
    <div css={numericInputStyle.container(theme)}>
      <input
        ref={ref}
        type="number"
        css={numericInputStyle.input(theme)}
        value={localValue}
        onChange={(e) => {
          setLocalValue(e.target.value);
        }}
        onBlur={(e) => handleCommit(e.target.value)}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            handleCommit(e.currentTarget.value);
            e.currentTarget.blur();
          }
        }}
        {...props}
      />

      {/* Up/Down Controls */}
      <div css={numericInputStyle.controls(theme)}>
        <button
          type="button"
          css={numericInputStyle.button}
          onClick={() => onChange(value + 1)}
        >
          <UpArrowIcon color={theme.deepOcean} />
        </button>
        <button
          type="button"
          css={numericInputStyle.button}
          onClick={() => onChange(value - 1)}
        >
          <DownArrowIcon color={theme.deepOcean} />
        </button>
      </div>
    </div>
  );
});

const UpArrowIcon = ({ color }: { color: string }) => (
  <svg
    width="10"
    height="6"
    viewBox="0 0 10 6"
    xmlns="http://www.w3.org/2000/svg"
  >
    <line x1="0" y1="6" x2="5" y2="0" stroke={color} strokeWidth="2" />
    <line x1="5" y1="0" x2="10" y2="6" stroke={color} strokeWidth="2" />
  </svg>
);

const DownArrowIcon = ({ color }: { color: string }) => (
  <svg
    width="10"
    height="6"
    viewBox="0 0 10 6"
    xmlns="http://www.w3.org/2000/svg"
  >
    <line x1="0" y1="0" x2="5" y2="6" stroke={color} strokeWidth="2" />
    <line x1="5" y1="6" x2="10" y2="0" stroke={color} strokeWidth="2" />
  </svg>
);

export const numericInputStyle = {
  container: (theme) => css`
    display: flex;
    box-sizing: border-box;
    gap: 5px;
    width: 9ch;
    overflow: hidden;
    margin-bottom: 5px;
    ${colorSelectStyles.valueInput(theme)}
    padding: 0;
  `,
  input: (theme) => css`
    width: 100%;
    border: none;
    padding: 2px 6px;
    text-align: right;
    appearance: textfield;

    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
      display: none;
    }
  `,
  controls: (theme) => css`
    display: flex;
    flex-direction: column;
    border-left: 1px solid ${theme.midGrey};
    background: #f5f5f5;
    height: 100%;
  `,
  button: (theme) => css`
    width: 18px;
    height: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border: none;
    background: transparent;
    transition: background 0.2s;

    &:hover {
      background: ${theme.midGrey};
    }
  `,
};
