import { stroot, subscription as subscriptionAPI } from '@birdi/js-sdk';
import React, { useEffect, useState, useRef } from 'react';
import * as Dialog from '@radix-ui/react-dialog';
import { toTitleCase } from '@birdi/utils/src/text';
import { useDispatch } from 'react-redux';
import { css } from '@emotion/react';
import { debounce } from 'lodash-es';
import { breakpoints } from '@birdi/theme/variables';
import CloseSVG from '@birdi/icons/close.svg';
import { closeButtonCSS, contentCSS, portalCSS } from '@birdi/modal-kit';
import { itemsTable, button } from '@birdi/theme/blocks';
import { BirdiTheme } from '@birdi/theme';
import { parseISO, format } from 'date-fns';
import { loadSeats, useSeatsSelector, totalSeats } from './seats-reducer';
import { numberToCurrencyStr } from './utils';

const blueButton = (theme: BirdiTheme) => css`
  ${button(theme)}
  margin: 0 0.5em;
  width: 10em;
`;
const whiteButton = (theme: BirdiTheme) => css`
  ${button(theme)}
  margin: 0 0.5em;
  width: 10em;
  background-color: ${theme.mono0};
  color: ${theme.deepOcean};
  border: solid 1px ${theme.deepOcean};
  &:active {
    color: ${theme.mono70};
  }
  &:disabled {
    background: ${theme.mono40};
    color: ${theme.mono0};
    border: none;
  }
`;

interface SeatsAdjustmentProps {
  extraSeatQuantity: number;
  close: () => void;
}

const SeatsAdjustment = ({
  extraSeatQuantity,
  close,
}: SeatsAdjustmentProps) => {
  const [isConfirming, setIsConfirming] = useState<boolean>(false);
  const [counter, setCounter] = useState<number>(extraSeatQuantity);
  // TODO: Type
  const [projection, setProjection] = useState<{
    loading: boolean;
    lines: any[];
    amountDue: number | null;
    periodStart: string | null;
    periodEnd: string | null;
    currency: string | null;
  }>({
    loading: true,
    lines: [],
    amountDue: null,
    periodStart: null,
    periodEnd: null,
    currency: '',
  });

  const getSeatsPricing = useRef(
    debounce((seatCount: number) => {
      setProjection((prev) => ({ ...prev, loading: true }));
      return subscriptionAPI
        .getSeatPricing(stroot('seatsp'), seatCount)
        .then((res) => setProjection({ ...res.body, loading: false }));
    }, 400),
  );

  useEffect(() => {
    getSeatsPricing.current(counter);
  }, [counter]);

  const confirm = () => {
    setIsConfirming(true);
    subscriptionAPI
      .updateSeats(stroot('phahc4'), {
        quantity: counter,
      })
      .then((res) => {
        window.location.reload();
      });
  };

  return (
    <div>
      <div
        css={(theme: BirdiTheme) => css`
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          border-radius: 2px;
          padding: 0.3em 0;
          margin: 1em 25% 0 25%;
          width: 50%;
          background-color: ${theme.deepOcean};
          color: ${theme.mono0};
          button {
            padding: 0 1em;
            color: ${theme.mono0};
          }
        `}
      >
        <button
          onClick={() => setCounter((prev) => (prev -= 1))}
          disabled={counter === 0}
        >
          -
        </button>
        <span>{counter}</span>
        <button onClick={() => setCounter((prev) => (prev += 1))}>+</button>
      </div>
      <hr />
      {!projection.loading && (
        <div>
          <h2>Upcoming invoice preview</h2>
          <table
            css={css`
              th {
                text-align: left;
              }
            `}
          >
            <thead>
              <th>Item</th>
              <th>Amount</th>
            </thead>
            {projection.lines.map((line) => (
              <tr key={line.id}>
                <td>{line.description}</td>
                <td>{`${numberToCurrencyStr(line.amount, projection.currency)}`}</td>
              </tr>
            ))}
          </table>
          <hr />
          {projection.periodEnd && projection.amountDue && (
            <div
              css={css`
                font-weight: bold;
              `}
            >
              {`${numberToCurrencyStr(projection.amountDue, projection.currency, { showGST: true })} total due ${format(parseISO(projection.periodEnd), 'yyyy-MM-dd')}`}
            </div>
          )}
        </div>
      )}
      <div
        css={css`
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          margin-top: 1em;
        `}
      >
        <button
          type="button"
          css={blueButton}
          onClick={confirm}
          disabled={
            counter === extraSeatQuantity || isConfirming || projection.loading
          }
        >
          <span>Confirm</span>
        </button>
        <button type="button" css={whiteButton} onClick={close}>
          <span>Cancel</span>
        </button>
      </div>
    </div>
  );
};

interface AddSeatsModalProps {
  open: boolean;
  close: () => void;
}

export const AddSeatsModal = ({ open, close }: AddSeatsModalProps) => {
  const dispatch = useDispatch();
  const seats = useSeatsSelector();
  useEffect(() => {
    // TODO: Improve loading awareness
    if (!seats.loaded) loadSeats(dispatch);
  }, []);
  if (!seats.loaded) return <></>;

  return (
    <Dialog.Root open={open} onOpenChange={close}>
      <Dialog.Portal>
        <Dialog.Overlay />

        <div css={portalCSS}>
          <Dialog.Content css={contentCSS}>
            <Dialog.Close css={closeButtonCSS}>
              <CloseSVG />
            </Dialog.Close>
            <div
              css={(theme) => css`
                display: flex;
                align-items: center;
                justify-content: center;
                padding-bottom: 0.5em;
                border-bottom: solid 1px ${theme.mono30};
              `}
            >
              <h1
                css={css`
                  font-size: 1em;
                `}
              >
                Manage Seats
              </h1>
            </div>
            <div
              css={css`
                display: flex;
                align-items: center;
                justify-content: center;
                margin: 1em 0;
                padding: 0.5em 2em;
                background-color: #ebf4ff;
                border-radius: 5px;
              `}
            >
              <table
                css={(theme) => css`
                  ${itemsTable(theme)};
                  width: 50%;
                  @media (max-width: ${breakpoints.xs}) {
                    width: 100%;
                  }
                  tr {
                    padding: 0.25em;
                    border-bottom: none;
                  }
                  td {
                    padding: 0.25em;
                    text-align: center;
                  }
                `}
              >
                <tbody>
                  <tr key="current-plan">
                    <td key="title">Current Plan</td>
                    <td key="value">{toTitleCase(seats.plan)}</td>
                  </tr>
                  <tr key="seats-included">
                    <td key="title">Seats Included</td>
                    <td key="value">{seats.baseSeatQuantity}</td>
                  </tr>
                  <tr key="additional-seats">
                    <td key="title">Additional Seats</td>
                    <td key="value">{seats.extraSeatQuantity}</td>
                  </tr>
                  <tr
                    key="used-seats"
                    css={(theme) => css`
                      border-top: solid 1px ${theme.mono50};
                    `}
                  >
                    <td key="title">Used Seats</td>
                    <td key="value">
                      {seats.userCount}/{totalSeats(seats)}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <p
              css={css`
                text-align: center;
                font-weight: bold;
                font-size: 0.9em;
              `}
            >
              Would you like to add or remove seats from your plan?
            </p>
            <SeatsAdjustment
              extraSeatQuantity={seats.extraSeatQuantity}
              close={() => close()}
            />
          </Dialog.Content>
        </div>
      </Dialog.Portal>
    </Dialog.Root>
  );
};

interface ManageSeatsButtonProps {
  modalOpen: boolean;
  setModalOpen: (value: boolean) => void;
  label?: string;
}

export const ManageSeatsButton = ({
  modalOpen,
  setModalOpen,
  label = 'Manage seats',
}: ManageSeatsButtonProps) => (
  <>
    <AddSeatsModal open={modalOpen} close={() => setModalOpen(false)} />
    <button
      css={(theme: BirdiTheme) => css`
        text-decoration: underline;
        color: ${theme.deepOcean} !important;
        font-size: 14px;
      `}
      onClick={() => setModalOpen(true)}
      type="button"
    >
      {label}
    </button>
  </>
);
