import React from 'react';
import { css } from '@emotion/react';
import { themeLight } from '@birdi/theme/theme';

const statusMap = {
  loading: {
    label: 'Loading',
    color: 'white',
    background: themeLight.sunConure,
  },
  awaitingPilot: {
    label: 'Pending',
    color: 'white',
    background: themeLight.sunConure,
  },
  accepted: {
    label: 'Awaiting approval',
    color: 'white',
    background: themeLight.sunConure,
  },
};

function getProp(jobRequest, attr) {
  // awaiting pilot
  if (!jobRequest) {
    return statusMap.loading[attr];
  }
  // accepted
  if (jobRequest.paymentAccepted) {
    return statusMap.accepted[attr];
  }
}

export const JobRequestStatus = ({ jobRequest }) => {
  if (!jobRequest) return false;
  return (
    <div
      css={css`
        display: inline-block;
        padding: 0.5rem;
        border-radius: 2px;
        white-space: nowrap;
        background: ${getProp(jobRequest, 'background')};
        color: ${getProp(jobRequest, 'color')};
      `}
    >
      {getProp(jobRequest, 'label')}
    </div>
  );
};
