/* globals window */

import React, { useState } from 'react';
import {
  QueryClient,
  QueryClientProvider,
  useInfiniteQuery,
} from 'react-query';
import {
  stroot,
  organisationMissions as organisationMissionsAPI,
  mission as missionAPI,
} from '@birdi/js-sdk';
import { css } from '@emotion/react';
import { breakpoints } from '@birdi/theme/variables';
import { input, pageDescription, reactSelectLight } from '@birdi/theme/blocks';
import { reactSelect } from '@birdi/theme/form';
import { useHistory, Link } from 'react-router-dom';
import { FeatureFlagNames } from '@birdi/js-sdk/src/public/organisation-features';
import { useMetaSelector } from '../meta/reducer';
import { INTERCOM_TOUR_CODES } from '../common/consts';
import PlayCircleSVG from '@birdi/icons/play-circle.svg';
import BookOpenSVG from '@birdi/icons/book-open.svg';
import VideoMidnightSVG from '@birdi/icons/video-midnight.svg';
import { SiteSelect } from '@birdi/select/SiteSelect';
import { BirdiTheme, buttonOutlineBlue } from '@birdi/theme';
import { getInitialSearchParams } from '@birdi/utils/src/search-params';
import { MissionList } from './List';
import { DateField } from './date-picker';
import { LocationSelect } from '@birdi/google-places-autocomplete';
import { Location } from '@birdi/types';
import { MapDashboardHeader } from '../common/map-dashboard-header/';

const queryClient = new QueryClient();

const LOAD_AMOUNT = 12;

type AllMissionProps = {
  association: 'mine' | 'organisation' | 'sample';
};

interface SearchState {
  search: string | null;
  site: string | null;
  startDate: Date | null;
  endDate: Date | null;
  location: Location | null;
}

interface SiteOption {
  label: string;
  value: string;
}

const missionSearchSchema = {
  search: {
    type: String,
    default: '',
  },
  site: {
    type: String || null,
    default: null,
  },
  startDate: {
    type: Date || null,
    default: null,
  },
  endDate: {
    type: Date || null,
    default: null,
  },
  location: {
    type: Location || null,
    default: null,
  },
};

function Maps({ association }) {
  const history = useHistory();
  const meta = useMetaSelector();
  const sitesEnable = meta.orgFeatureFlags.includes(FeatureFlagNames.Sites);
  const [state, setState] = useState<SearchState>({
    search: null,
    site: null,
    startDate: null,
    endDate: null,
    location: null,
    ...getInitialSearchParams(missionSearchSchema),
  });

  const { data, fetchNextPage, hasNextPage, isFetchingNextPage } =
    useInfiniteQuery(
      [
        'missionsData',
        state.search,
        state.site,
        state.startDate,
        state.endDate,
        state.location,
        association,
      ],
      ({ pageParam = 1 }) => {
        const apiCall =
          association === 'organisation' || association === 'sample'
            ? organisationMissionsAPI.getMissions
            : missionAPI.list;
        const strootChars =
          association === 'organisation' ? 'tee0fo' : 'oon5ge';
        return apiCall(stroot(strootChars), {
          start: pageParam,
          limit: LOAD_AMOUNT,
          association,
          ...(state.search && { search: state.search }),
          ...(state.site && { site: state.site }),
          ...(state.startDate && { startDate: state.startDate.toISOString() }),
          ...(state.endDate && { endDate: state.endDate.toISOString() }),
          ...(state.location && {
            locationBbox: JSON.stringify(state.location?.bbox),
          }),
        }).then((res) => res.body.items);
      },
      {
        getNextPageParam: (lastPage, allPages) => {
          return lastPage.length === LOAD_AMOUNT
            ? allPages.length + 1
            : undefined;
        },
        keepPreviousData: true,
      },
    );

  const handleLoadMore = () => {
    fetchNextPage();
  };

  const fetchFunction = (
    item: string | null = null,
    value: string | Date | Location | null = null,
  ) => {
    const updatedArray = [
      'missionsData',
      item === 'search' ? value : state.search,
      item === 'site' ? value : state.site,
      item === 'startDate' ? value : state.startDate,
      item === 'endDate' ? value : state.endDate,
      item === 'location' ? value : state.location,
      association,
    ];
    queryClient.invalidateQueries(updatedArray);
  };

  const updateSearchParams = (search: string) => {
    // update url
    const searchParams = new URLSearchParams({
      ...(search && { search }),
      ...(state.site && { site: state.site }),
    });
    history.replace(`?${searchParams.toString()}`);
    // update state
    setState((prevState) => ({ ...prevState, search }));
    // reset fetch
    fetchFunction('search', search);
  };

  const handleSiteChange = (site: SiteOption | null) => {
    // update url
    const searchParams = new URLSearchParams({
      ...(state.search && { search: state.search }),
      ...(site && { site: site.value }),
    });
    history.replace(`?${searchParams.toString()}`);
    // update state
    const searchedSite = site?.value || null;
    setState((prevState) => ({ ...prevState, site: searchedSite }));
    // reset fetch
    fetchFunction('site', searchedSite);
  };

  const deleteMission = (id: string) => {
    const result = window.confirm(
      'Are you sure you would like to delete the mission?',
    );
    if (result) {
      missionAPI.destroy(stroot('yoor7z'), id).then((res) => {
        fetchFunction();
      });
    }
  };

  const handleDateChange = (label: string, selectedDate: Date) => {
    if (label === 'startDate') {
      setState((prevState) => ({ ...prevState, startDate: selectedDate }));
      fetchFunction('startDate', selectedDate);
    } else if (label === 'endDate') {
      setState((prevState) => ({ ...prevState, endDate: selectedDate }));
      fetchFunction('endDate', selectedDate);
    }
  };

  const handleLocationChange = (selectedLocation: Location | null) => {
    setState((prevState) => ({ ...prevState, location: selectedLocation }));
    fetchFunction('location', selectedLocation);
  };

  return (
    <div>
      {association !== 'sample' && (
        <div
          css={(theme: BirdiTheme) => css`
            border-bottom: solid 1px ${theme.mono30};
          `}
        >
          <form
            role="search"
            css={css`
              padding: 1rem;
              max-width: 50rem;
            `}
            onSubmit={(event) => event.preventDefault()}
          >
            <div
              css={css`
                display: flex;
                flex-direction: row;
                gap: 1em;
                width: 100%;
                @media (max-width: ${breakpoints.xs}) {
                  flex-direction: column;
                }
              `}
            >
              <div
                css={css`
                  display: flex;
                  flex: 1;
                `}
              >
                <input
                  type="text"
                  value={state.search || undefined}
                  placeholder="Search maps by name ..."
                  css={(theme: BirdiTheme) => css`
                    ${input(theme)};
                    width: 100%;
                  `}
                  onChange={(event) => {
                    event.preventDefault();
                    updateSearchParams(event.target.value);
                  }}
                />
              </div>
              {sitesEnable && (
                <div
                  css={css`
                    display: flex;
                    flex: 1;
                  `}
                >
                  <SiteSelect
                    theme={reactSelectLight}
                    onChange={handleSiteChange}
                    defaultValueAsync={state.site || undefined}
                    containerStyles={{ width: '100%' }}
                  />
                </div>
              )}
            </div>
            <div
              css={css`
                display: flex;
                flex-direction: row;
                gap: 1rem;
                margin-top: 1rem;
                @media (max-width: ${breakpoints.xs}) {
                  flex-direction: column;
                }
              `}
            >
              <div
                css={css`
                  display: flex;
                  flex: 1;
                `}
              >
                <LocationSelect
                  inputId="location"
                  styles={{
                    ...reactSelect,
                    container: (base) => ({
                      ...base,
                      width: '100%',
                    }),
                  }}
                  defaultValue={state.location}
                  placeholder="Search maps by location ..."
                  isClearable
                  onLocationSelect={(geocode) =>
                    handleLocationChange(geocode.location)
                  }
                  onClear={() => handleLocationChange()}
                />
              </div>
              <div
                css={css`
                  display: flex;
                  flex: 1;
                  align-items: center;
                  gap: 0.5rem;
                `}
              >
                <span>Date:</span>
                <span>From</span>
                <DateField
                  label="startDate"
                  selectedDate={state.startDate}
                  onChange={handleDateChange}
                  endDate={state.endDate}
                />
                <span>To</span>
                <DateField
                  label="endDate"
                  selectedDate={state.endDate}
                  onChange={handleDateChange}
                  startDate={state.startDate}
                />
              </div>
            </div>
          </form>
        </div>
      )}
      <div
        css={css`
          box-sizing: border-box;
          padding: 1rem 1.5rem;
          width: 100%;
        `}
      >
        {data &&
          data.pages.map((pageData, pageIndex) => (
            <div key={pageIndex}>
              <MissionList missions={pageData} deleteMission={deleteMission} />
            </div>
          ))}
        {hasNextPage ? (
          isFetchingNextPage ? (
            <span>Loading more...</span>
          ) : (
            <button
              css={(theme) => css`
                ${buttonOutlineBlue(theme)};
                width: 100%;
              `}
              onClick={handleLoadMore}
            >
              Load More ...
            </button>
          )
        ) : null}
        {data &&
          data.pages[0].length === 0 &&
          (state.search ||
            state.site ||
            state.location ||
            state.startDate ||
            state.endDate) && (
            <p
              css={css`
                width: calc(100% - 1rem);
              `}
            >
              No maps found
            </p>
          )}
        {data &&
          data.pages[0].length === 0 &&
          !state.search &&
          !state.site &&
          !state.location &&
          !state.startDate &&
          !state.endDate && (
            <p
              css={(theme: BirdiTheme) => css`
                width: calc(100% - 1rem);
                color: ${theme.mono50};
              `}
            >
              You have no private maps. You can get started by selecting{' '}
              <span
                css={(theme: BirdiTheme) => css`
                  text-decoration: underline;
                  color: ${theme.deepOcean};
                `}
              >
                <Link to="/map/missions/plan">+ Create a map</Link>
              </span>{' '}
              to upload and visualise your own data.
            </p>
          )}
      </div>
    </div>
  );
}

export const AllMissions: React.FC<AllMissionProps> = ({
  association = 'mine',
}) => {
  let title = '';
  switch (association) {
    case 'mine':
      title = 'My Maps';
      break;
    case 'organisation':
      title = 'All Maps';
      break;
    default:
      title = 'Demo Maps';
      break;
  }
  let description = '';
  switch (association) {
    case 'mine':
      description = 'These are maps you have created.';
      break;
    case 'organisation':
      description =
        'These are all your maps, created by you or shared with you.';
      break;
    default:
      description =
        'View and interact with our demo maps to get familiar with the platform. ';
      break;
  }

  const MAX_WIDTH = '414px';
  return (
    <div>
      <MapDashboardHeader
        titleComponent={
          <h2
            css={css`
              margin: 0;
            `}
          >
            {title}
          </h2>
        }
      />
      <div css={pageDescription}>
        <p>{description}</p>
        {association === 'mine' && (
          <div>
            <button
              type="button"
              onClick={() => {
                window?.Intercom &&
                  window.Intercom(
                    'startTour',
                    INTERCOM_TOUR_CODES.CREATE_MISSION,
                  );
              }}
            >
              <PlayCircleSVG />
              <span>Take a quick tour on creating a map</span>
            </button>
            <a
              href="https://help.birdi.io/en/articles/6958894-creating-a-mapping-mission-uploading-data-for-processing"
              target="_blank"
              rel="noopener noreferrer"
            >
              <BookOpenSVG />
              <span>How to create a map</span>
            </a>
          </div>
        )}
        {association === 'sample' && (
          <div>
            <a
              href="https://help.birdi.io/en/articles/6983146-getting-started-a-quick-5-min-demo-video"
              target="_blank"
              rel="noopener noreferrer"
            >
              <VideoMidnightSVG />
              <span>Watch a quick 5-min platform demo video</span>
            </a>
          </div>
        )}
      </div>
      <QueryClientProvider client={queryClient}>
        <Maps association={association} />
      </QueryClientProvider>
    </div>
  );
};
