import React from 'react';
import { Formik, Form, Field } from 'formik';
import { css } from '@emotion/react';
import { Dispatch } from '@reduxjs/toolkit';
import * as Dialog from '@radix-ui/react-dialog';
import CloseSVG from '@birdi/icons/close.svg';
import PlusSVG from '@birdi/icons/plus.svg';
import EquipmentSVG from '@birdi/icons/equipment.svg';
import { closeButtonCSS, contentCSS, portalCSS } from '@birdi/modal-kit';
import { buttonBlue, buttonOutlineBlue, ThemeOverride } from '@birdi/theme';
import { ModelSelect } from './ModelSelect';
// import CapabilitySelect from './CapabilitySelect';
import TypeSelect from './TypeSelect';
import { UserScopeSelect } from './UserScopeSelect';
import { isUUID } from 'validator';
import { useDispatch } from 'react-redux';
import { EquipmentParams } from '@birdi/js-sdk/src/public/equipment';
import {
  errorMessage,
  textInput,
  blockLabel,
  textArea,
} from '@birdi/theme/form';

const initialValues = {
  type: '',
  modelId: '',
  name: '',
  serialNo: '',
  // capability: '',
  batteryCount: '',
  userScopeId: '',
};

interface NewEquipmentModalProps {
  addEquipment: (dispatch: Dispatch, params: EquipmentParams) => void;
  setOpen: (value: boolean) => void;
  open: boolean;
  userId?: string; // Admin side
  userScopeId?: string; // Public side
  item?: object | null; // Existing item
}

export const NewEquipmentModal = (props: NewEquipmentModalProps) => {
  const dispatch = useDispatch();

  const validate = (values) => {
    const errors: any = {};
    if (
      (!values.modelId && !values.model) ||
      (!values.model && values.type === 'Battery')
    )
      errors.modelId = 'Model description is required';
    //if (values.model && !isUUID(values.model) && !values.capability) errors.capability = 'Capability is required';
    if (!values.type) errors.type = 'Type description is required';
    if (!values.userScopeId && props.userId)
      errors.userScopeId = 'User scope is required';
    // Checking if the number is whole/positive number
    if (
      values.type === 'Drone' &&
      (!values.batteryCount ||
        values.batteryCount <= 0 ||
        values.batteryCount - Math.floor(values.batteryCount) !== 0)
    )
      errors.batteryCount = 'A valid number is required';
    return errors;
  };

  const submit = (values: Partial<EquipmentParams>) => {
    // TODO: Alert if scopeId does not exist yet
    const params = Object.assign({}, values);
    // delete battery count if the type is not a drone
    if (values.type !== 'Drone' || !values.batteryCount)
      delete params.batteryCount;
    if (values.type === 'Battery') delete params.modelId;
    props.setOpen(false);
    return props.addEquipment(dispatch, params);
  };

  const { open, item, setOpen } = props;
  const defaultValue = item || initialValues;
  return (
    <Dialog.Root open={open} onOpenChange={setOpen}>
      <Dialog.Trigger asChild>
        <button
          type="button"
          css={(theme) => css`
            ${buttonBlue(theme)};
            display: flex;
            align-items: center;
          `}
        >
          <span>Add equipment</span>
          <PlusSVG
            css={css`
              margin-left: 2em;
              stroke-width: 2px;
            `}
          />
        </button>
      </Dialog.Trigger>

      <Dialog.Portal>
        <Dialog.Overlay />

        <div css={portalCSS}>
          <ThemeOverride>
            <Dialog.Content
              css={(theme) => css`
                ${contentCSS(theme)};
                padding: 1em 2em;
              `}
            >
              <Dialog.Close css={closeButtonCSS}>
                <CloseSVG />
              </Dialog.Close>

              <div
                css={(theme) => css`
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                  border-bottom: solid 1px ${theme.mono30};
                  margin-bottom: 1em;
                `}
              >
                <EquipmentSVG
                  css={css`
                    display: block;
                    font-size: 7em;
                  `}
                />
                <Dialog.Title
                  css={css`
                    text-align: center;
                  `}
                >
                  {!item ? 'Add your equipment' : 'Edit equipment'}
                </Dialog.Title>
              </div>

              <Formik
                initialValues={defaultValue}
                validate={validate}
                onSubmit={submit}
              >
                {({ values, handleSubmit, setFieldValue, errors, touched }) => (
                  <Form>
                    {props.userId && (
                      <label htmlFor="userScopeId" css={blockLabel}>
                        User scope
                        <UserScopeSelect
                          userId={props.userId}
                          onSelect={(option) =>
                            setFieldValue(
                              'userScopeId',
                              option ? option.value : null,
                            )
                          }
                        />
                      </label>
                    )}
                    {errors.userScopeId && touched.userScopeId && (
                      <div css={errorMessage}>{errors.userScopeId}</div>
                    )}

                    <label htmlFor="type" css={blockLabel}>
                      Type
                      <TypeSelect
                        onChange={(option) => {
                          setFieldValue('type', null);
                          setTimeout(() => {
                            setFieldValue('type', option ? option.value : null);
                          });
                        }}
                        // defaultValue={{
                        //   value: defaultValue.type,
                        //   label: defaultValue.type.charAt(0)
                        //     + defaultValue.type.slice(1).toLowerCase(),
                        // }}
                        edit={!!item}
                      />
                    </label>
                    {errors.type && touched.type && (
                      <div css={errorMessage}>{errors.type}</div>
                    )}

                    {values.type && (
                      <div>
                        <label htmlFor="model" css={blockLabel}>
                          Model (select from the list or enter a model)
                          <ModelSelect
                            equipmentType={values.type}
                            onSelect={(option) => {
                              // When deleting a model
                              if (!option) {
                                setFieldValue('modelId', null);
                                return;
                              }
                              // When a default model
                              if (isUUID(option.value))
                                setFieldValue('modelId', option.value);
                              // When a custom model
                              else {
                                setFieldValue('modelId', null);
                                setFieldValue('model', {
                                  model: option.value,
                                  type: values.type,
                                });
                              }
                            }}
                          />
                        </label>
                        {errors.modelId && touched.modelId && (
                          <div css={errorMessage}>{errors.modelId}</div>
                        )}
                      </div>
                    )}

                    {/*
                      {values.type && values.model && !isUUID(values.model) && (
                        <div>
                          <label htmlFor="capability" css={blockLabel}>
                            {values.type === 'GCP' ? 'Size' : 'Capability'}
                            <CapabilitySelect
                              equipmentType={values.type}
                              onChange={(option) => {
                                setFieldValue('capability', option ? option.value : null )}}
                              css={reactSelect}
                            />
                          </label>
                          {errors.capability && touched.capability && (
                            <div css={errorMessage}>{errors.capability}</div>
                          )}
                        </div>
                      )}
                    */}

                    {values.type === 'Drone' && (
                      <label htmlFor="battery" css={blockLabel}>
                        Number of batteries
                        <Field
                          type="text"
                          name="batteryCount"
                          id="batteryCount"
                          css={textInput}
                          placeholder="Add number of batteries ..."
                        />
                      </label>
                    )}
                    {errors.batteryCount && touched.batteryCount && (
                      <div css={errorMessage}>{errors.batteryCount}</div>
                    )}

                    <label htmlFor="serialNo" css={blockLabel}>
                      Serial number (optional)
                      <Field
                        type="text"
                        name="serialNo"
                        id="serialNo"
                        css={textInput}
                        placeholder="Add serial number ..."
                      />
                    </label>

                    <label htmlFor="name" css={blockLabel}>
                      Notes (optional)
                      <Field
                        type="text"
                        component="textarea"
                        name="name"
                        id="name"
                        css={textArea}
                        placeholder="Add additional notes if required ..."
                      />
                    </label>

                    <div
                      css={css`
                        display: flex;
                        flext-direction: row;
                        align-items: center;
                        justify-content: center;
                      `}
                    >
                      <Dialog.Close>
                        <button
                          type="button"
                          css={(theme) => css`
                            ${buttonOutlineBlue(theme)};
                            width: 7rem;
                          `}
                        >
                          Cancel
                        </button>
                      </Dialog.Close>
                      <button
                        type="button"
                        onClick={handleSubmit as any}
                        css={(theme) => css`
                          ${buttonBlue(theme)};
                          width: 7rem;
                        `}
                      >
                        Confirm
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
            </Dialog.Content>
          </ThemeOverride>
        </div>
      </Dialog.Portal>
    </Dialog.Root>
  );
};
