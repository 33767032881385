import { stroot, missionSharing } from '@birdi/js-sdk';
import React from 'react';
import { css } from '@emotion/react';
import { button, selectContainer } from '@birdi/theme/blocks';
import { Controller, useForm } from 'react-hook-form';
import { errorMessage } from '@birdi/theme/form';
import CaretSVG from '@birdi/icons/caret.svg';
import { UserScopeSelect } from '@birdi/select/UserScopeSelect';

interface PlatformUsersShareProps {
  administrator;
  missionId: string;
  setUsers: (any) => void;
}

export const PlatformUsersShare: React.FunctionComponent<
  PlatformUsersShareProps
> = ({ administrator, missionId, setUsers }) => {
  const formMethods = useForm();

  const submit = () => {
    const obj = formMethods.getValues();
    missionSharing
      .addUser(
        stroot('joom1h'),
        missionId,
        Object.assign(obj, administrator && { readOnly: true }),
      )
      .then((res) => {
        setUsers(res.body);
        formMethods.reset();
      })
      .catch((err) => {
        if (err.status === 400) {
          formMethods.setError('notUniqueUserId');
        } else {
          console.log(err);
        }
      });
  };

  return (
    <form
      onSubmit={formMethods.handleSubmit(submit)}
      css={(theme) => css`
        display: flex;
        flex-direction: column;
        border-top: 1px solid ${theme.mono20};
        padding: 1em 0;
      `}
    >
      <h3>Share with platform users (ops only)</h3>
      <Controller
        name="scopeId"
        control={formMethods.control}
        rules={{ required: true }}
        render={({ field: { onChange, value } }) => (
          <UserScopeSelect
            id="scopeId"
            userScopeId={value}
            onChange={(option, action) => {
              formMethods.clearErrors('notUniqueUserId');
              if (action.action === 'select-option') {
                onChange(option.data.scopeId);
              } else if (action.action === 'clear') {
                onChange(null);
              }
            }}
          />
        )}
      />
      {formMethods.formState.errors.scopeId && (
        <div css={errorMessage}>Field is required</div>
      )}
      {formMethods.formState.errors.notUniqueUserId && (
        <div css={errorMessage}>
          This mission is already being shared with this user
        </div>
      )}
      <div
        css={css`
          display: flex;
          justify-content: flex-end;
        `}
      >
        <div css={selectContainer}>
          <select type="role" id="role" {...formMethods.register('role')}>
            <option value="view">View only</option>
            <option value="edit">Edit</option>
            <option value="inspect">Inspect</option>
            {administrator && <option value="partner">Partner</option>}
            {administrator && <option value="qa">QA</option>}
          </select>
          <CaretSVG />
        </div>
        <button
          type="submit"
          css={(theme) => css`
            ${button(theme)} padding: 0 2rem 0 2em;
          `}
        >
          Share
        </button>
      </div>
    </form>
  );
};
