import ChevronDownSVG from '@birdi/icons/chevron-down.svg';
import { next } from '@birdi/theme';
import React from 'react';

export const Select = React.forwardRef<
  HTMLSelectElement,
  JSX.IntrinsicElements['select']
>((props, ref) => (
  <div css={next.inputs.select.container}>
    <select {...props} ref={ref} css={next.inputs.select.select} />
    <ChevronDownSVG css={next.inputs.select.icon} />
  </div>
));
