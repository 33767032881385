import CloseSVG from '@birdi/icons/close.svg';
import ChevronLeftSVG from '@birdi/icons/chevron-left.svg';
import { css, Theme } from '@emotion/react';
import * as Dialog from '@radix-ui/react-dialog';
import { next } from '@birdi/theme';

const portalCSS = css`
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9000;
  inset: 0;
  width: 100%;
  height: 100%;
  padding: 2rem;
  box-sizing: border-box;
  background: hsl(0, 0%, 0%, 0.2);
`;

// TODO: see where we've used `.success` and `.error`.
const contentCSS = (theme: Theme) => css`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 36em;
  max-height: 100%;
  overflow-y: auto;
  border-radius: 0.5em;
  box-sizing: border-box;
  box-shadow:
    0 28px 55px #0006,
    -28px -28px 55px #ffffff08;
  h1,
  h2,
  h3,
  h4 {
    color: ${theme.displayFont};
    margin-top: 0;
  }
  .error {
    color: ${theme.crimsonSunbird};
  }
  .success {
    color: ${theme.flora};
  }
  background: ${theme.mono0};
  color: ${theme.displayFont};
`;

export interface ModalProps extends Dialog.DialogProps {
  trigger?: React.ReactNode;
  className?: string;
}

export const Root = ({
  trigger = null,
  children,
  className,
  ...props
}: ModalProps) => (
  <Dialog.Root {...props}>
    {!!trigger && <Dialog.Trigger asChild>{trigger}</Dialog.Trigger>}

    <Dialog.Portal>
      <Dialog.Overlay />
      <div css={portalCSS}>
        <Dialog.Content css={contentCSS} {...{ className }}>
          {children}
        </Dialog.Content>
      </div>
    </Dialog.Portal>
  </Dialog.Root>
);

// TODO: support for decorative icons that need to float a bit.
export interface ModalHeaderProps {
  children: React.ReactNode;
  className?: string;
  onBack?: () => void;
  leftIcon?: React.ReactNode;
  // backIcon?: string | (() => React.ReactNode);
  closeable?: boolean;
  divider?: boolean;
}

export const Header = ({
  onBack,
  leftIcon,
  closeable = true,
  className,
  children,
  divider = true,
}: ModalHeaderProps) => (
  <header
    css={css`
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: stretch;
      width: 100%;
      padding: 1.5rem 2rem;
      padding-bottom: 0;
      box-sizing: border-box;
    `}
    {...{ className }}
  >
    {onBack && (
      <button
        css={(theme) => css`
          ${next.buttons.icon(theme)};
          position: absolute;
          top: 1.4rem;
          left: 2rem;
        `}
        aria-label="Go back"
        onClick={onBack}
      >
        <ChevronLeftSVG width="24px" height="24px" />
      </button>
    )}
    {leftIcon && (
      <div
        css={css`
          position: absolute;
          left: 2rem;
          top: 1.4rem;
          display: flex;
        `}
      >
        {leftIcon}
      </div>
    )}
    <Dialog.Title
      css={css`
        flex: 1;
        margin: 0;
        ${(!!onBack || !!leftIcon) && 'margin-left: 2rem;'}
        ${closeable && 'margin-right: 2rem;'}
      `}
    >
      {children}
    </Dialog.Title>
    {closeable && (
      <Dialog.Close
        css={(theme) => css`
          ${next.buttons.icon(theme)};
          position: absolute;
          top: 1.5rem;
          right: 2rem;
          transform: translateY(4px);
        `}
      >
        <CloseSVG />
      </Dialog.Close>
    )}
    {divider && (
      <hr
        css={css`
          margin: 0;
          margin-top: 1rem;
        `}
      />
    )}
  </header>
);

export interface ModalBodyProps {
  children: React.ReactNode;
  className?: string;
}

export const Body = ({ children, className }: ModalBodyProps) => (
  <div
    css={css`
      display: flex;
      flex-direction: column;
      box-sizing: border-box;
      padding: 1rem 2rem;
    `}
    {...{ className }}
  >
    {children}
  </div>
);

export interface ModalFooterProps {
  children: React.ReactNode;
  className?: string;
  buttons?: boolean;
}

const buttonsFooter = css`
  justify-content: flex-end;
`;

export const Footer = ({
  children,
  className,
  buttons = false,
}: ModalFooterProps) => (
  <footer
    css={[
      css`
        display: flex;
        gap: 0.5rem;
        padding: 1rem 2rem 2rem;
      `,
      buttons && buttonsFooter,
    ]}
    {...{ className }}
  >
    {children}
  </footer>
);
