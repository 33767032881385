import React, { useContext } from 'react';
import { css } from '@emotion/react';
import { CategoryList } from './category-list';
import { CategoryContext } from '.';

export const CategoryContainer: React.FunctionComponent = () => {
  const { path } = useContext(CategoryContext);
  return (
    <div
      css={css`
        display: flex;
        gap: 2rem;
        overflow-x: auto;
        flex: 1;
      `}
    >
      {path.map((item, index) => {
        const isParent = index === 0;
        const disabled = isParent
          ? false
          : Object.keys(path[index - 1]).length === 0;
        return (
          <CategoryList key={index} disabled={disabled} categoryIndex={index} />
        );
      })}
    </div>
  );
};
