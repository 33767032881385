import { Button, Modal, Spinner } from '@birdi/components-common';
import LinkSVG from '@birdi/icons/link-2.svg';
import TrashSVG from '@birdi/icons/trash.svg';
import { next } from '@birdi/theme';
import { SubmitHandler, useForm, Controller } from 'react-hook-form';
import { stroot, maps as mapsApi } from '@birdi/js-sdk';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import React from 'react';
import { css } from '@emotion/react';

interface MapShareInputs {
    expiryDays: number;
}

interface MapShareFormProps {
    onSuccess: () => void;
    mapId: string;
}

export const MapShareForm = ({onSuccess, mapId }: MapShareFormProps) => {
    const { register, handleSubmit, control, watch, setValue, formState: { errors } } = useForm<MapShareInputs>({
        defaultValues: {
            expiryDays: 1,
        }
    });
    const [shareLink, setShareLink] = useState<string>('');
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [copyButtonText, setCopyButtonText] = useState<string>('Copy');

    const getSharedLink = async () => {
        try {
            const response = await mapsApi.getMapShareLink(stroot('m1589p'), mapId);
            setShareLink(response?.body?.signedShareUrl || '');
        } catch (error) {
            console.error('Error getting share link:', error);
        }
    }

    const onSubmit: SubmitHandler<MapShareInputs> = async (props) => {
        try {
            const response = await mapsApi.generateMapShareLink(stroot('k1529p'), mapId, Number(props.expiryDays));
            setShareLink(response?.body?.signedShareUrl || '');
            toast.success('Share link created');
        } catch (error) {
            console.error('Error generating share link:', error);
            toast.error('Failed to create share link');
        }
    }

    const deleteShareLink = async () => {
        try {
            if (window.confirm('Are you sure you want to delete the share link for this map?')) {
                await mapsApi.deleteMapShareLink(stroot('h91n45'), mapId);
                setShareLink('');
                toast.success('Share link deleted');
            } else {
                toast.error('Share link not deleted');
            }
        } catch (error) {
            console.error('Error deleting share link:', error);
            toast.error('Failed to delete share link');
        }
    }

    const copyShareLink = () => {
        navigator.clipboard.writeText(shareLink);
        toast.success('Share link copied to clipboard');
        setCopyButtonText('Copied');
    
        setTimeout(() => {
            setCopyButtonText('Copy');
        }, 2000);
    }

    useEffect(() => {
        getSharedLink().then(() => {
            setIsLoading(false);
        });
    }, [mapId]);

    return (
        <>
            {isLoading && <div css={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '50px', height: '50px' }}><Spinner /></div>}
            {!isLoading && (
                <>
            <form id="map-details" css={next.form.root} onSubmit={handleSubmit(onSubmit)}>
                {!shareLink && <div css={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '10px' }}>
                    <span>Create a link to your map and share with anyone. View only access.</span>
                    <span css={{ fontWeight: 'bold' }}>No active share links have been created for this map.</span>
                    <Button type="submit" variant="solid" css={{marginTop: '1rem', width: '80px'}}>Create</Button>
                </div>}
                
                {shareLink && (
                <div css={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                    <label css={[next.form.field.root, { flex: 1 }]}>
                        <span css={{ fontWeight: 'bold' }}>Shareable Link</span>
                        <input
                            css={next.inputs.text}
                            value={shareLink}
                            readOnly
                            onClick={(e) => e.currentTarget.select()}
                        />
                    </label>
                    <Button type="button" variant="outline" css={{ marginTop: 'auto', width: '80px' }} onClick={copyShareLink}>{copyButtonText}</Button>
                    <button type="button" data-testid="delete-share-link-button" onClick={deleteShareLink} css={{ marginTop: 'auto', width: '40px', }}><TrashSVG width="25px" height="25px" /></button>
                </div>
                )}
            </form> 
            </>
            )}
        </>
    );
}

export interface MapShareProps {
    open: boolean;
    onChange: (value: boolean) => void;
    mapId: string;
}

export const MapShare = ({ open, onChange, mapId }: MapShareProps) => {
    return (
        <Modal.Root open={open} onOpenChange={onChange}>
            <Modal.Header leftIcon={<LinkSVG width="24px" height="24px" />}>
                Create a shareable link
            </Modal.Header>

            <Modal.Body>
                <MapShareForm onSuccess={() => {
                    onChange(false);
                }} mapId={mapId} />
            </Modal.Body>

            <Modal.Footer buttons>
            </Modal.Footer>
        </Modal.Root>
    );
};