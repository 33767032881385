import React, { useRef, useState, useEffect, useContext } from 'react';
import { css } from '@emotion/react';
import BirdiIcon, { Icons } from '@birdi/icons';
import TrashSVG from '@birdi/icons/trash.svg';
import ArrowRightSVG from '@birdi/icons/chevron-right.svg';
import { CategoryItem } from './utils';
import { CategoryContext } from '.';

interface CategoryCellProps {
  selected: boolean;
  hasChildren: boolean;
  categoryIndex: number;
  categoryItem: CategoryItem;
  renameItem: (id: string, name: string) => void;
  deleteItem: (id: string) => void;
  dragProvided: any;
}

const cellStyle = css`
  width: 100%;
  min-height: 3rem;
  margin: 0;
  padding: 0 1rem;
  font-family: ProximaNova;
  font-size: 1rem;
`;

export const CategoryCell: React.FunctionComponent<CategoryCellProps> = ({
  selected,
  hasChildren,
  categoryIndex,
  categoryItem,
  renameItem,
  deleteItem,
  dragProvided,
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [showIcons, setShowIcons] = useState(false);
  const [renamingItem, setRenamingItem] = useState(false);
  const [itemName, setItemName] = useState(
    categoryItem.name?.replaceAll('_', ' '),
  );
  const { selectItem } = useContext(CategoryContext);
  const handleKeyPress = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter' || event.key === 'NumpadEnter') {
      selectItem(categoryIndex, categoryItem);
    }
  };
  useEffect(() => {
    if (inputRef.current) inputRef.current.focus();
  });
  useEffect(() => {
    const listener = (event: KeyboardEvent) => {
      if (event.code === 'Enter' || event.code === 'NumpadEnter') {
        event.preventDefault();
        if (renamingItem)
          renameItem(categoryItem.id, itemName.replaceAll(' ', '_'));
        if (!itemName) setItemName(categoryItem.name?.replaceAll('_', ' '));
        setRenamingItem(false);
      } else if (event.code === 'Escape') {
        setItemName(categoryItem.name?.replaceAll('_', ' '));
        setRenamingItem(false);
      }
    };
    document.addEventListener('keydown', listener);
    return () => {
      document.removeEventListener('keydown', listener);
    };
  }, [renamingItem, itemName]);

  return (
    <div
      css={(theme) => css`
        display: flex;
        align-items: center;
        ${selected && !renamingItem && `border: 1px solid ${theme.deepOcean}`}
      `}
      onMouseEnter={() => setShowIcons(true)}
      onMouseLeave={() => setShowIcons(false)}
    >
      {!renamingItem && (
        <div
          role="button"
          tabIndex={0}
          css={(theme) => css`
            ${cellStyle}
            ${selected && 'font-weight: 700; background-color: #EBF4FF;'}
            ${showIcons &&
            `padding-right: 5rem; font-weight: 700; background-color: ${theme.mono30 || 'hsl(260, 5%, 85%)'};`}
            display: flex;
            align-items: center;
          `}
          onClick={() => selectItem(categoryIndex, categoryItem)}
          onKeyDown={(e) => handleKeyPress(e)}
        >
          {categoryItem.name && categoryItem.name.replaceAll('_', ' ')}
        </div>
      )}
      {renamingItem && (
        <div
          css={css`
            position: relative;
            width: 100%;
          `}
        >
          <div
            css={css`
              position: absolute;
              bottom: 20%;
              left: 0.5rem;
            `}
          >
            <BirdiIcon icon={Icons.EditNew} color="#000000" size="md" />
          </div>
          <input
            id="name_input"
            type="text"
            ref={inputRef}
            value={itemName}
            onChange={(e) => setItemName(e.target.value)}
            onBlur={() => {
              renameItem(categoryItem.id, itemName.replaceAll(' ', '_'));
              if (!itemName)
                setItemName(categoryItem.name?.replaceAll('_', ' '));
              setRenamingItem(false);
            }}
            css={cellStyle}
          />
          <div
            css={css`
              position: absolute;
              bottom: 20%;
              right: 0.5rem;
            `}
          >
            <button
              type="button"
              css={css`
                padding: 0;
              `}
              onClick={() => {
                setItemName(categoryItem.name?.replaceAll('_', ' '));
                setRenamingItem(false);
              }}
              onMouseDown={(e) => e.preventDefault()}
            >
              <TrashSVG
                css={css`
                  font-size: 1.1rem;
                  stroke-width: 2.5px;
                  color: black;
                `}
              />
            </button>
          </div>
        </div>
      )}
      <div
        css={css`
          position: absolute;
          right: 0.5rem;
          ${(!showIcons || renamingItem) && 'display: none;'}
        `}
      >
        <button
          type="button"
          css={css`
            padding: 0;
          `}
          onClick={() => setRenamingItem(true)}
        >
          <BirdiIcon icon={Icons.EditNew} color="#000000" size="md" />
        </button>
        <button
          type="button"
          css={css`
            padding: 0 0 0 0.5rem;
          `}
          onClick={() => deleteItem(categoryItem.id)}
        >
          <TrashSVG
            css={css`
              font-size: 1.1rem;
              stroke-width: 2.5px;
              color: black;
            `}
          />
        </button>
        <span
          css={css`
            padding: 0 0 0 0.5rem;
          `}
          {...dragProvided.dragHandleProps}
        >
          <BirdiIcon icon={Icons.DragHandle} color="#000000" size="md" />
        </span>
      </div>
      <div
        css={css`
          position: absolute;
          left: 100%;
        `}
      >
        {selected && hasChildren && (
          <ArrowRightSVG
            css={css`
              font-size: 2rem;
              stroke-width: 1.8px;
              padding-top: 0.5rem;
            `}
          />
        )}
      </div>
    </div>
  );
};
