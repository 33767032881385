import React, { useState, useEffect, useRef, useContext } from 'react';
import { Link } from 'react-router-dom';
import cx from 'classnames';
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
} from 'react-beautiful-dnd';
import { generateKeyBetween } from 'fractional-indexing';
import { css, Theme, useTheme } from '@emotion/react';
import { assessmentType as assessmentTypeAPI, stroot } from '@birdi/js-sdk';
import BirdiIcon, { Icons } from '@birdi/icons';
import TrashSVG from '@birdi/icons/trash.svg';
import ExternalLinkSVG from '@birdi/icons/external-link.svg';
import { ToolTipWrapper } from '@birdi/shared-deprecated/tool-tip-wrapper';
import { CategoryCell } from './category-cell';
import { CategoryItem, KNOWLEDGE_BASE_LINK } from './utils';
import { CategoryContext } from '.';

interface CategoryListProps {
  disabled: boolean;
  categoryIndex: number;
}

const cellStyle = (theme: Theme) => css`
  width: 100%;
  height: 3rem;
  padding: 0 1.5rem;
  font-family: ProximaNova;
  font-size: 1rem;
  font-weight: 700;
  &.error {
    outline: none;
    border-color: ${theme.crimsonSunbird};
  }
`;

export const CategoryList: React.FunctionComponent<CategoryListProps> = ({
  disabled,
  categoryIndex,
}) => {
  const birdiTheme = useTheme();
  const inputRef = useRef<HTMLInputElement>(null);
  const [currentCategoryItems, setCurrentCategoryItems] = useState<
    CategoryItem[]
  >([]);
  const [addingItem, setAddingItem] = useState<boolean>(false);
  const [itemName, setItemName] = useState<string>('');
  const {
    selectedAssessmentType,
    path,
    setPathFragment,
    setFullPath,
    nameError,
    setNameError,
  } = useContext(CategoryContext);
  const isNameValid = (value: string) => /^[a-z0-9\s]+$/gi.test(value);
  const assessmentTypeId = selectedAssessmentType?.value || '';
  const getCategoryItems = async () => {
    if (categoryIndex === 0) {
      if (!assessmentTypeId) return [];
      const res = await assessmentTypeAPI.getAssessmentTypeById(
        stroot('phu9va'),
        assessmentTypeId,
      );
      if (!res.body)
        throw Error(
          'Could not get category items for parent: invalid response',
        );
      return res.body.categories || [];
    }
    const selectedParentCategory = path[categoryIndex - 1];
    if (Object.keys(selectedParentCategory).length === 0) return [];
    if (!(selectedParentCategory as CategoryItem).id)
      throw Error('Could not get category items: selected parent has no id.');
    const res = await assessmentTypeAPI.getCategoryById(
      stroot('iuch6m'),
      (selectedParentCategory as CategoryItem).id,
    );
    if (!res.body)
      throw Error('Could not get category items: invalid response');
    return res.body.children || [];
  };
  useEffect(() => {
    let cancelled = false;
    if (disabled) {
      setCurrentCategoryItems([]);
    } else {
      getCategoryItems()
        .then((res) => {
          if (!cancelled) setCurrentCategoryItems(res);
        })
        .catch((err) => console.log(err));
    }
    return () => {
      cancelled = true;
    };
  }, [assessmentTypeId, path[categoryIndex - 1]]);
  const addItem = () => {
    if (!isNameValid(itemName)) {
      setNameError(true);
      return;
    }
    setNameError(false);
    if (
      currentCategoryItems.some(
        (categoryItem) =>
          categoryItem.name &&
          categoryItem.name.replaceAll('_', ' ') === itemName,
      )
    ) {
      window.alert('This name already exists in this category');
    } else if (itemName) {
      const currentPath = [];
      for (let i = 0; i < categoryIndex; i += 1) {
        if (!(path[i] as CategoryItem).name)
          throw Error(
            `Error adding new category: path at index ${i} has no name`,
          );
        currentPath.push((path[i] as CategoryItem).name);
      }
      currentPath.push(itemName);
      const newPath = currentPath.join('.');
      // get sortKey
      const prevItem =
        currentCategoryItems.length > 0 ? currentCategoryItems.at(-1) : null;
      const prevSortKey = prevItem ? prevItem.sortKey : null;
      const generatedSortKey = generateKeyBetween(prevSortKey, null);
      assessmentTypeAPI
        .createCategory(stroot('ra3ahg'), assessmentTypeId, {
          sortKey: `${generatedSortKey}`,
          path: newPath,
        })
        .then((res) => {
          setCurrentCategoryItems((prev) => [
            ...prev,
            {
              id: (res.body as CategoryItem).id,
              name: itemName,
              sortKey: `${generatedSortKey}`,
            },
          ]);
        })
        .catch((err) => console.log(err));
    }
    setItemName('');
    setAddingItem(false);
  };
  useEffect(() => {
    const listener = (event: KeyboardEvent) => {
      if (event.code === 'Enter' || event.code === 'NumpadEnter') {
        event.preventDefault();
        if (addingItem) addItem();
      } else if (event.code === 'Escape') {
        setItemName('');
        setAddingItem(false);
      }
    };
    document.addEventListener('keydown', listener);
    return () => {
      document.removeEventListener('keydown', listener);
    };
  }, [addingItem, itemName]);
  useEffect(() => {
    if (inputRef.current) inputRef.current.focus();
  });
  const renameItem = (id: string, name: string) => {
    if (!isNameValid) {
      setNameError(true);
      return;
    }
    setNameError(false);
    if (
      currentCategoryItems.some(
        (categoryItem) => categoryItem.name && categoryItem.name === name,
      )
    ) {
      return;
    }
    if (name) {
      const currentPath = [];
      for (let i = 0; i < categoryIndex; i += 1) {
        if (!(path[i] as CategoryItem).name)
          throw Error(
            `Error adding new category: path at index ${i} has no name`,
          );
        currentPath.push((path[i] as CategoryItem).name);
      }
      currentPath.push(name.replaceAll(' ', '_'));
      const newPath = currentPath.join('.');
      assessmentTypeAPI
        .updateCategory(stroot('quua6o'), id, { path: newPath })
        .then(() => {
          setCurrentCategoryItems((prev) =>
            prev.map((categoryItem) => {
              if (categoryItem.id === id)
                return { id, name, sortKey: categoryItem.sortKey };
              return categoryItem;
            }),
          );
          setPathFragment(categoryIndex, { id, name });
        })
        .catch((err) => console.log(err));
    }
  };
  const deleteItem = (id: string): void => {
    if (
      window.confirm(
        'Are you sure you want to delete this item? All sub-categories will also be deleted.',
      )
    ) {
      if (id) {
        assessmentTypeAPI
          .deleteCategory(stroot('oogh9e'), id)
          .then(() => {
            setCurrentCategoryItems((prev) =>
              prev.filter((item) => item.id !== id),
            );
            setFullPath((prev) =>
              prev.map((item, index) => (index >= categoryIndex ? {} : item)),
            );
          })
          .catch((err) => console.log(err));
      }
    }
  };
  const getCategoryTitle = () => {
    if (categoryIndex === 0) return 'Primary Category';
    return `Sub-category ${categoryIndex}`;
    // const firstCategory = (path[0] as CategoryItem).name;
    // if (!firstCategory) return;
    // if (categoryIndex === 1) return `${firstCategory.replaceAll('_', ' ')}`;
    // const parentCategory = (path[categoryIndex - 1] as CategoryItem).name;
    // if (!parentCategory) return;
    // const maxChars = 6;
    // const formattedFirstCategory = firstCategory.length > maxChars ? firstCategory.slice(0, maxChars).concat('...') : firstCategory;
    // return `${formattedFirstCategory.replaceAll('_', ' ')} > ... > ${parentCategory.replaceAll('_', ' ')}`;
  };

  // Reordering functionality
  const handleOnDragEnd = (result: DropResult): void => {
    const { destination, source } = result;
    if (!destination) return;
    if (destination.index === source.index) return;
    const srcPos = source.index;
    const destPos = destination.index;
    let sortKeyBefore = null;
    let sortKeyAfter = null;
    if (srcPos > destPos) {
      // Dragging item up
      sortKeyAfter = currentCategoryItems[destPos].sortKey;
      sortKeyBefore =
        destPos === 0 ? null : currentCategoryItems[destPos - 1].sortKey;
    } else {
      // Dragging item down
      sortKeyAfter =
        destPos === currentCategoryItems.length - 1
          ? null
          : currentCategoryItems[destPos + 1].sortKey;
      sortKeyBefore = currentCategoryItems[destPos].sortKey;
    }
    // update sortkey
    const newSortKey = generateKeyBetween(sortKeyBefore, sortKeyAfter);
    const featureToMove = structuredClone(currentCategoryItems[srcPos]);
    featureToMove.sortKey = newSortKey;
    setCurrentCategoryItems((prev) => {
      assessmentTypeAPI.updateCategory(stroot('fiez3i'), featureToMove.id, {
        sortKey: featureToMove.sortKey,
      });
      const newArray = prev;
      newArray.splice(srcPos, 1); // Remove source item
      newArray.splice(destPos, 0, featureToMove); // Add feature
      return newArray;
    });
  };
  return (
    <div
      css={css`
        position: relative;
        flex: 0 0 200px;
        margin-bottom: 2rem;
        background-color: ${birdiTheme.mono0};
        display: flex;
        flex-direction: column;
        ${(disabled || !!assessmentTypeId === false) && 'opacity: 0.4;'}
        #name_input {
          padding: 0 2rem 0 2rem;
          font-size: 1rem;
          border-radius: 3px;
        }
      `}
    >
      <div
        css={css`
          display: flex;
          align-items: center;
          height: 3rem;
          background-color: ${birdiTheme.dove};
          border-bottom: 2px solid black;
        `}
      >
        <div
          css={css`
            display: flex;
            align-items: center;
            overflow: hidden;
          `}
        >
          <h3
            id={
              categoryIndex === 0
                ? 'assessment-type-cat-primary'
                : `assessment-type-cat-${categoryIndex}`
            }
            css={css`
              margin-left: 0.5rem;
              white-space: nowrap;
              width: 100%;
              font-size: 1rem;
              overflow: hidden;
              -o-text-overflow: ellipsis;
              text-overflow: ellipsis;
            `}
          >
            {getCategoryTitle()}
          </h3>
        </div>
      </div>
      <div
        id="menu_item_names"
        css={css`
          flex: 1;
          overflow-y: auto;
        `}
      >
        {currentCategoryItems.length === 0 &&
          !addingItem &&
          !!assessmentTypeId && (
            <p
              css={css`
                font-size: 0.85rem;
                padding: 0 1rem;
              `}
            >
              You currently do not have any categories created. Click{' '}
              <b>+ Add New</b> to get started.
            </p>
          )}
        <DragDropContext onDragEnd={handleOnDragEnd}>
          <Droppable droppableId="categoryItems">
            {(dropProvided) => (
              <ul
                {...dropProvided.droppableProps}
                ref={dropProvided.innerRef}
                css={css`
                  margin: 0;
                  padding: 0;
                  list-style-type: none;
                `}
              >
                {currentCategoryItems.length > 0 &&
                  currentCategoryItems.map((categoryItem, index) => {
                    const selected =
                      (path[categoryIndex] as CategoryItem).id ===
                      categoryItem.id;
                    const hasChildren =
                      categoryIndex < path.length - 1 &&
                      Object.keys(path[categoryIndex + 1]).length > 0;
                    return (
                      <Draggable
                        key={categoryItem.id}
                        draggableId={categoryItem.id}
                        index={index}
                      >
                        {(dragProvided) => (
                          <li
                            {...dragProvided.draggableProps}
                            ref={dragProvided.innerRef}
                          >
                            <CategoryCell
                              key={categoryItem.id}
                              selected={selected}
                              hasChildren={hasChildren}
                              categoryIndex={categoryIndex}
                              categoryItem={categoryItem}
                              renameItem={renameItem}
                              deleteItem={deleteItem}
                              dragProvided={dragProvided}
                            />
                          </li>
                        )}
                      </Draggable>
                    );
                  })}
                {dropProvided.placeholder}
              </ul>
            )}
          </Droppable>
        </DragDropContext>
        {addingItem && (
          <div
            css={css`
              position: relative;
            `}
          >
            <div
              css={css`
                position: absolute;
                bottom: 20%;
                left: 0.5rem;
              `}
            >
              <BirdiIcon icon={Icons.EditNew} color="#000000" size="md" />
            </div>
            <input
              className={cx({ error: nameError })}
              id="name_input"
              type="text"
              ref={inputRef}
              onChange={(e) => setItemName(e.target.value)}
              onBlur={addItem}
              css={cellStyle}
            />
            <div
              css={css`
                position: absolute;
                bottom: 20%;
                right: 0.5rem;
              `}
            >
              <button
                type="button"
                css={css`
                  padding: 0;
                `}
                onClick={() => {
                  setItemName('');
                  setAddingItem(false);
                }}
                onMouseDown={(e) => e.preventDefault()}
              >
                <TrashSVG
                  css={css`
                    font-size: 1.1rem;
                    stroke-width: 2.5px;
                    color: black;
                  `}
                />
              </button>
            </div>
          </div>
        )}
        <div
          css={css`
            height: 3rem;
          `}
        />
      </div>
      {!!assessmentTypeId && (
        <div>
          <button
            type="button"
            disabled={disabled || addingItem}
            onClick={() => setAddingItem(true)}
            css={(theme) => css`
              ${cellStyle(theme)};
              text-align: left;
              background-color: ${birdiTheme.dove};
            `}
          >
            + Add New
          </button>
        </div>
      )}
    </div>
  );
};
