/* global fetch */

import querystring from 'querystring';
import { config } from '../config';
import {
  headers,
  APIResponse,
  stleaf,
  fetchAPI,
  fetchAPIJSON,
  fetchAPIAttachment,
  SpanTag,
} from '../helpers';

// TODO: Opts?
export const getByCampaignId = (
  st: SpanTag,
  campaignId: string,
): Promise<APIResponse> => {
  const queryString = querystring.stringify({ campaign: campaignId });
  const query = queryString ? `?${queryString}` : '';
  const url = new URL(`${config.API_URL}/v1/inspections${query}`);
  return fetchAPI(stleaf(st, 'kaex7a'), url.toString(), {
    method: 'GET',
    credentials: 'include',
    headers,
  });
};

export const generateReport = (
  st: SpanTag,
  inspectionId: string,
): Promise<void> => {
  return fetchAPIAttachment(
    stleaf(st, 'eepaj9'),
    `${config.API_URL}/v1/inspections/${inspectionId}/report`,
    {
      method: 'GET',
      credentials: 'include',
      headers,
    },
  );
};
