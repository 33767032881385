import {
  useGoogleLogin,
  GoogleOAuthProvider,
  GoogleLogin,
} from '@react-oauth/google';
import { connect, useDispatch, useSelector } from 'react-redux';
import { css } from '@emotion/react';
import { buttonOutlined } from '@birdi/theme/blocks';
import { BirdiTheme } from '@birdi/theme';
import { signInWithGoogleToken } from './actions';
import { useAuthSelector } from './reducer';
import GoogleLogoSVG from '../icons/google-logo.svg';
import { useMetaSelector } from '../meta/reducer';

interface GoogleAuthButtonProps {
  label: string;
}

// useGoogleLogin({
//   onSuccess: (credentialResponse) => {
//     console.log('credentialResponse: ', credentialResponse);
//     // _signInWithGoogleToken();
//   },
// });

const GoogleButtonInternal: React.FC<GoogleAuthButtonProps> = ({ label }) => {
  const dispatch = useDispatch();
  const login = useGoogleLogin({
    flow: 'auth-code',
    onSuccess: (response) => {
      signInWithGoogleToken(dispatch)(response.code);
    },
  });
  return (
    <button
      type="button"
      onClick={() => {
        login();
      }}
      css={(theme: BirdiTheme) => css`
        ${buttonOutlined(theme)};
        width: 100%;
        border: 1px solid ${theme.mono20};
        padding: 1rem;
      `}
    >
      <GoogleLogoSVG
        css={css`
          transform: translateY(2px);
        `}
      />
      <span
        css={css`
          margin-left: 0.5em;
        `}
      >
        {label || 'Sign in with Google'}
      </span>
    </button>
  );
};

export const GoogleAuthButton: React.FC<GoogleAuthButtonProps> = ({
  label,
}) => {
  const meta = useMetaSelector();
  if (meta.loaded) return null;

  return (
    <GoogleOAuthProvider clientId={process.env.GOOGLE_CLIENT_ID}>
      <GoogleButtonInternal label={label} />
    </GoogleOAuthProvider>
  );

  // return (
  //   <GoogleOAuthProvider clientId={process.env.GOOGLE_CLIENT_ID}>
  //     <GoogleLogin
  //       onSuccess={(credentialResponse) => {
  //         console.log('credentialResponse: ', credentialResponse);
  //         // signInWithGoogleToken(dispatch);
  //       }}
  //       onError={() => console.log('Login Failed')}
  //       text={label ? 'signup_with' : 'signin_with'}
  //       size="large"
  //       theme="outline"
  //       logo_alignment="center"
  //       width="600px"
  //       style={{ textAlign: 'center' }}
  //     />
  //   </GoogleOAuthProvider>
  // );
};
