import React, { useState, useEffect } from 'react';
import {
  stroot,
  missionShareInvitations as missionShareInvitationsAPI,
} from '@birdi/js-sdk';
import { css } from '@emotion/react';
import { button, selectContainer, formStyles } from '@birdi/theme/blocks';
import { useForm } from 'react-hook-form';
import copy from 'clipboard-copy';
import CaretSVG from '@birdi/icons/caret.svg';
import { formatDuration, intervalToDuration, addDays } from 'date-fns';

interface ShareInvitationProps {
  missionId: string;
}

interface InvitationProps {
  id: string;
  link: string;
  role: string;
  expiry: string;
  linkTextbox: any;
}

const remainedTime = (expireAt) => {
  // find the duration between now and expiry
  const duration = intervalToDuration({
    start: new Date(),
    end: new Date(expireAt),
  });
  // take the first nonzero unit
  const units = [
    'years',
    'months',
    'weeks',
    'days',
    'hours',
    'minutes',
    'seconds',
  ];
  const nonzero = Object.entries(duration)
    .filter(([_, value]) => value)
    .map(([unit, _]) => unit);
  // find the remained time
  const formatedRemainedTime = formatDuration(duration, {
    format: units.filter((i) => new Set(nonzero).has(i)).slice(0, 1),
    delimiter: ', ',
  });
  return formatedRemainedTime;
};

export const ShareInvitation: React.FunctionComponent<ShareInvitationProps> = ({
  missionId,
}) => {
  const formMethods = useForm();
  const [invitations, setInvitations] = useState([]);

  const prepareInvitationFormat = (invitation) => ({
    id: invitation.id,
    link: `${process.env.APP_URL}/share/${invitation.id}`,
    role:
      invitation.role === 'view'
        ? `(${invitation.role} only)`
        : `(${invitation.role})`,
    expiry: `Expires in ${remainedTime(invitation.expireAt)}`,
    linkTextbox: React.createRef(),
  });

  useEffect(() => {
    missionShareInvitationsAPI
      .getInvitations(stroot('xe2ta0'), missionId)
      .then((res) => {
        const updatedList = res.body.map((invitation) =>
          prepareInvitationFormat(invitation),
        );
        setInvitations(updatedList);
      });
  }, [missionId]);

  const generateInvitation = () => {
    const values = formMethods.getValues();
    // calculate expireAt from duration
    const expireAt = addDays(new Date(), parseInt(values.duration));
    const params = {
      role: values.role,
      expireAt,
    };
    missionShareInvitationsAPI
      .create(stroot('cae5ui'), missionId, params)
      .then((res) => {
        const updatedList = [...invitations, prepareInvitationFormat(res.body)];
        setInvitations(updatedList);
      });
  };

  const removeInvitation = (invitationId) => {
    missionShareInvitationsAPI
      .deleteInvitation(stroot('ohc6ee'), invitationId)
      .then((res) => {
        const updatedList = invitations.filter(
          (invitation) => invitation.id !== invitationId,
        );
        setInvitations(updatedList);
      });
  };

  const copyLink = (link, linkTextbox): void => {
    linkTextbox.current && linkTextbox.current.select();
    copy(link);
  };

  return (
    <div
      css={(theme) => css`
        padding: 1em 0;
        border-top: 1px solid ${theme.mono20};
      `}
    >
      <form
        onSubmit={formMethods.handleSubmit(generateInvitation)}
        css={(theme) => css(formStyles(theme))}
      >
        <h3
          css={css`
            margin: 0;
          `}
        >
          Create an invitation
        </h3>
        <p>
          Create and send invitation links to guests that allow them to view or
          edit a map. Note invites can be used multiple times.
        </p>
        <div
          css={css`
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            margin: 1em 0;
          `}
        >
          <div
            css={(theme) => css`
              ${selectContainer(theme)};
              width: 33%;
              height: 4em;
            `}
          >
            <select
              type="duration"
              id="duration"
              {...formMethods.register('duration')}
            >
              <option value={1}>1 day</option>
              <option value={7}>7 days</option>
              <option value={30}>30 days</option>
            </select>
            <CaretSVG />
          </div>
          <div
            css={(theme) => css`
              ${selectContainer(theme)};
              width: 33%;
              height: 4em;
            `}
          >
            <select type="role" id="role" {...formMethods.register('role')}>
              <option value="view">View only</option>
              <option value="edit">Edit</option>
            </select>
            <CaretSVG />
          </div>
          <button
            type="submit"
            css={(theme) => css`
              ${button(theme)} width: 30%;
              height: 3.5em;
            `}
          >
            Create invitation
          </button>
        </div>
      </form>
      <h3
        css={css`
          margin: 2em 0 1em 0;
        `}
      >
        {invitations.length
          ? 'Existing invitations'
          : 'No invitations at the moment!'}
      </h3>
      {invitations.map((invitation: InvitationProps) => (
        <div key={invitation.id}>
          <p
            css={css`
              margin: 0 0 0.5em 0;
            `}
          >
            {invitation.expiry}
            &nbsp;
            {invitation.role}
          </p>
          <div
            css={(theme) => css`
              ${formStyles(theme)}
              display: flex;
              align-items: flex-start;
              justify-content: flex-start;
              button {
                height: 3em;
                margin: 0 0 0 0.5em;
                background-color: ${theme.mono0};
                color: ${theme.midnight};
                border: solid 1px ${theme.mono20};
                border-radius: 2px;
              }
            `}
          >
            <input
              ref={invitation.linkTextbox}
              type="text"
              value={invitation.link}
              readOnly
              css={css`
                height: 3em;
              `}
            />
            <button
              type="button"
              onClick={() => copyLink(invitation.link, invitation.linkTextbox)}
            >
              Copy
            </button>
            <button
              type="button"
              onClick={() => removeInvitation(invitation.id)}
            >
              Delete
            </button>
          </div>
        </div>
      ))}
    </div>
  );
};
