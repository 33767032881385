import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { css } from '@emotion/react';
import BookOpenSVG from '@birdi/icons/book-open.svg';
import {
  headerContainer,
  input,
  buttonOutlined,
  listContainer,
  cardList,
  listSearchForm,
  pageTitleHolder,
  pageTitle,
  pageGraySection,
  pageDescription,
} from '@birdi/theme/blocks';
import {
  getInitialSearchParams,
  marshallToSchema,
} from '@birdi/utils/src/search-params';
import { NewEquipmentModal } from '@birdi/equipment/NewEquipmentModal';
import { EquipmentCard } from '@birdi/equipment/EquipmentCard';
import { BirdiTheme, buttonBlue, buttonOutlineBlue } from '@birdi/theme';
import { useDispatch } from 'react-redux';
import { EquipmentAssociation } from '@birdi/js-sdk/src/public/equipment';
import { EquipmentAssociationSelect } from './EquipmentAssociationSelect';
import {
  addEquipment,
  listEquipment,
  deleteEquipment,
  loadMore,
  useEquipmentSelector,
} from './equipment-reducer';
import { useMetaSelector } from '../meta/reducer';

const LOAD_AMOUNT = 4;

const equipmentSearchSchema = {
  search: {
    type: String,
    default: '',
  },
  association: {
    type: String,
    default: EquipmentAssociation.Mine,
  },
  limit: {
    type: Number,
    default: LOAD_AMOUNT,
  },
};

export const Equipment = () => {
  const equipment = useEquipmentSelector();
  const meta = useMetaSelector();
  const history = useHistory();
  const dispatch = useDispatch();
  const [searchText, setSearchText] = useState<string>('');
  const [state, setState] = useState({
    search: '',
    association: EquipmentAssociation.Mine,
    limit: 0,
    start: 0,
    showModal: false,
    item: null,
    ...getInitialSearchParams(equipmentSearchSchema),
  });

  const getEquipment = () => {
    setState((prevState) => ({ ...prevState, start: 0 }));
    const params = marshallToSchema(state, equipmentSearchSchema);
    listEquipment(dispatch, params);
  };
  useEffect(() => {
    getEquipment();
  }, [state.search, state.association]);

  const loadMoreEquipment = () => {
    const nextCursor = state.start + LOAD_AMOUNT;
    loadMore(dispatch, { ...state, start: nextCursor });
    setState((prevState) => ({ ...prevState, start: nextCursor }));
  };

  const updateFilterImmediate = (modifiedSearchParams: {
    association: EquipmentAssociation;
  }) => {
    const searchParams = { ...modifiedSearchParams, start: 0 };
    setState((prevState) => ({ ...prevState, ...searchParams }));
  };

  const setShowModal = (showModal: boolean) =>
    setState((prevState) => ({ ...prevState, showModal }));
  const { item, showModal } = state;
  return (
    <div>
      <div css={pageTitleHolder}>
        <h2 css={pageTitle}>Equipment</h2>
      </div>
      <div css={pageGraySection}>
        <p>Add, update and manage your drone equipment.</p>
      </div>

      {/* <div css={pageDescription}>
        <div>
          <a
            href="http://help.birdi.io/en/articles/6648078-how-do-i-add-and-manage-my-equipment"
            target="_blank"
            rel="noopener noreferrer"
          >
            <BookOpenSVG />
            <span>Learn how to add equipment</span>
          </a>
        </div>
      </div> */}

      <div>
        <div css={headerContainer}>
          <NewEquipmentModal
            open={showModal}
            setOpen={setShowModal}
            addEquipment={addEquipment}
            item={item}
          />
          <form
            role="search"
            onSubmit={(event) => {
              event.preventDefault();
              setState((prevState) => ({ ...prevState, search: searchText }));
              getEquipment();
            }}
            css={listSearchForm}
          >
            <input
              type="text"
              value={searchText}
              placeholder="Search equipment by model or type..."
              css={(theme) => css`
                width: 100%;
                ${input(theme)}
              `}
              onChange={(event) => setSearchText(event.target.value)}
            />
            <EquipmentAssociationSelect
              isLoading={!equipment.loaded}
              onChange={updateFilterImmediate}
              selectedAssociation={state.association}
              permissions={meta.permissions}
            />
            <button
              type="submit"
              css={(theme) => css`
                ${buttonBlue(theme)};
                width: 8em;
              `}
            >
              Search
            </button>
          </form>
        </div>
        <div css={listContainer}>
          {equipment && equipment.list && (
            <div css={cardList}>
              {equipment.list.length > 0 &&
                equipment.list.map((item) => (
                  <EquipmentCard
                    key={item.id}
                    item={item}
                    handleCardClick={() =>
                      history.push(`/equipment/${item.id}`)
                    }
                    deleteEquipment={deleteEquipment}
                  />
                ))}
            </div>
          )}
          {/* {!equipment.allEquipmentLoaded && equipment.list.length >= LOAD_AMOUNT && ( */}
          <button
            type="button"
            css={(theme) => css`
              ${buttonOutlineBlue(theme)} width: 100%;
            `}
            onClick={loadMoreEquipment}
          >
            Load more equipment
          </button>
          {/* )} */}
          {equipment.allEquipmentLoaded && (
            <p
              css={css`
                width: calc(100% - 1rem);
              `}
            >
              No equipment found
            </p>
          )}
        </div>
      </div>
    </div>
  );
};
