/* globals document */

import React from 'react';
import { createRoot } from 'react-dom/client';
import loadable from '@loadable/component';
import { Provider, connect } from 'react-redux';
import posthog from 'posthog-js';
import { PostHogProvider } from 'posthog-js/react';
import {
  Router,
  Route,
  Switch,
  Redirect,
  RouteComponentProps,
} from 'react-router-dom';
import { loadGTM } from '@birdi/gtm';
import { loadFirstPromoter } from '@birdi/first-promoter';
import { loadHubspot } from '@birdi/hubspot';
import { Toaster } from 'react-hot-toast';
import { config } from '@birdi/js-sdk';
import { ThemeProvider, themeLight } from '@birdi/theme';
import { MetaBar } from '@birdi/dev-tools/MetaBar';
import { CrashScreen } from '@birdi/crash-screen';
import { loadIntercom, showIntercom } from '@birdi/intercom';
import AuthContainer from './authentication';
import SignIn from './authentication/SignInPage';
import SignUp from './authentication/SignUpPage';
import ResetPassword from './authentication/ResetPassword';
import ResetOrganisationPassword from './authentication/ResetOrganisationPassword';
import { getInitialAuthState } from './authentication/actions';
import ResendVerification from './authentication/ResendVerification';
import VerifyEmailWithToken from './authentication/VerifyEmailWithToken';
import { AuthenticatedRoute } from './authentication/AuthenticatedRoute';
import history from './state/history';
import { store } from './state';
import { AssessmentType } from './assessment-type';
import { Inspections } from './inspections';
import Onboarding1Welcome from './onboarding/Step1Welcome';
import Onboarding2Workspace from './onboarding/Step2Workspace';
import Onboarding3ThankYou from './onboarding/Step3ThankYou';
import { Dashboard } from './dashboard/main';
import { AllMissions } from './missions/all-missions';
import { AllNewMaps } from './missions/all-maps';
import { IncomingJobRequests } from './incoming-request/index';
import { SharedMissions } from './missions/SharedMissions';
import { UserDetails } from './account/UserDetails';
import { PilotSettings } from './account/PilotSettings';
import { MarketingPreferences } from './account/MarketingPreferences';
import { Credentials } from './credentials';
import Assets from './assets';
import { EquipmentSingle } from './equipment-single';
import { Equipment } from './equipment';
import { Users } from './organisation-users/main';
import { UserManagement } from './organisation-users/user-management';
import { PlanOptions } from './billing/plan-options';
import { PlanOverview } from './billing/plan-overview/main';
import { PlanAndUsage } from './billing/plan-overview/plan-and-usage';
import { Invoices } from './billing/plan-overview/invoices';
import { BillingInfo } from './billing/plan-overview/billing-info';
import { PaymentMethod } from './billing/plan-overview/payment-method';
import { CustomisationBranding } from './settings/branding';
import { UnauthenticatedShare } from './unauthenticated-share/UnauthenticatedShare';
import { NotFoundScreen } from './meta/NotFoundScreen';
// import ProcessingView from './processing-prev/dashboard';
import ProcessingView from './processing/dashboard';
import { AssessmentSeverity } from './assessment-severity';
import { CostCalculator } from './processing/cost-calculator';
import { Sites } from './sites';
import { General } from './admin/general';
import { Workspaces } from './admin/workspaces';
import { Permissions } from './admin/permissions';
import { Security } from './admin/security';
import WorkspacesGeneral from './workspaces';
import { General as ProfileGeneral } from './profile/general';
import ProfilePermissions from './profile/permissions';
import ProfileWorkspaces from './profile/workspaces';
// layouts
import { SidebarLayout } from './layouts/sidebar-layout';
import { DefaultLayout } from './layouts/default-layout';

// Set API routes from Webpack injected defaults
config.setOpts({
  API_URL: process.env.API_URL,
  JWT_NAME: process.env.JWT_NAME,
  S3_ZIP_URL: process.env.S3_ZIP_URL,
  POINTS_URL: process.env.POINTS_URL,
});

if (process.env.COOKIE_DOMAIN) {
  config.setOpts({
    COOKIE_DOMAIN: process.env.COOKIE_DOMAIN,
  });
}

let DevTools = () => <div>404</div>;
if (process.env.DEVTOOLS) {
  DevTools = loadable(() =>
    import('@birdi/dev-tools/DevTools').then((module) => module.DevTools),
  );
}

if (process.env.INTERCOM_ENABLED) {
  loadIntercom(false);
  showIntercom();
}

if (process.env.GTM_ENABLED) {
  loadGTM();
}

if (process.env.FIRST_PROMOTER_ENABLED) {
  loadFirstPromoter({
    domain: process.env.COOKIE_DOMAIN,
    testMode: process.env.FIRST_PROMOTER_TEST_MODE,
    proxy: `${process.env.API_URL}/v2/service/fprom_proxy`,
  });
}

if (process.env.HUBSPOT_TRACKING_ENABLED) {
  loadHubspot();
}

if (process.env.POSTHOG_API_KEY) {
  posthog.init(process.env.POSTHOG_API_KEY, {
    api_host: process.env.POSTHOG_API_HOST,
  });
}

const MetaBarConnected = connect((state) => ({ meta: state.meta }))(
  ({ meta }) => (
    <MetaBar
      id={meta.id}
      scopeRole={meta.scopeRole}
      organisation={meta.organisation}
      masked={meta.masked}
      missionRoleOverride={meta.missionRoleOverride}
      opsActions={meta.opsActions}
    />
  ),
);

const RedirectToMap = ({ location }: RouteComponentProps) => {
  // Possible to get this from the route path?
  const path = location.pathname.replace('/map', '');
  const href = `${process.env.MAP_APP_URL}${path}${location.search}${location.hash}`;
  window.location.href = href;

  return null;
};

const Index = () => (
  <CrashScreen>
    <PostHogProvider client={posthog}>
      <Provider store={store}>
        <ThemeProvider>
          <Router history={history}>
            <Switch>
              {/* Identity & auth layout */}
              <Route
                exact
                path="/sign-in"
                component={() => <AuthContainer Section={SignIn} />}
              />
              <Route
                exact
                path="/sign-up"
                component={() => <AuthContainer Section={SignUp} />}
              />
              <Route
                exact
                path="/reset-password"
                component={() => <AuthContainer Section={ResetPassword} />}
              />
              <Route
                exact
                path="/set-password"
                component={() => (
                  <AuthContainer Section={ResetOrganisationPassword} />
                )}
              />
              <Route
                exact
                path="/resend-verification"
                component={() => <AuthContainer Section={ResendVerification} />}
              />
              <Route
                exact
                path="/email-verification"
                component={() => (
                  <AuthContainer Section={VerifyEmailWithToken} />
                )}
              />
              {/* onboarding */}
              <AuthenticatedRoute
                exact
                path="/onboarding"
                component={Onboarding1Welcome}
              />
              <AuthenticatedRoute
                exact
                path="/onboarding/workspace"
                component={Onboarding2Workspace}
              />
              <AuthenticatedRoute
                exact
                path="/onboarding/thank-you"
                component={Onboarding3ThankYou}
              />
              {/* map */}
              <Route
                exact
                path="/missions/:id/map"
                component={(props: RouteComponentProps) => {
                  // This is a redirect from our old map page link to our new one
                  // TODO: Remove in 2023
                  window.location.href = `${process.env.MAP_APP_URL}/missions/${props.match.params.id}`;
                  return <></>;
                }}
              />
              {/* default layout */}
              <AuthenticatedRoute
                exact
                path="/billing/options"
                component={PlanOptions}
                layout={DefaultLayout}
              />
              <AuthenticatedRoute
                exact
                path="/cost-calculator"
                component={CostCalculator}
                layout={DefaultLayout}
              />
              {/* dashboard */}
              <AuthenticatedRoute
                exact
                path="/"
                component={Dashboard}
                layout={SidebarLayout}
                sidebar="dashboard"
              />
              <AuthenticatedRoute
                exact
                path="/sites"
                component={Sites}
                layout={SidebarLayout}
                sidebar="dashboard"
              />
              <AuthenticatedRoute
                exact
                path="/assets"
                component={Assets}
                layout={SidebarLayout}
                sidebar="dashboard"
              />
              {/* dashboard - filtered missions */}
              <AuthenticatedRoute
                exact
                path="/missions/mine"
                component={() => <AllMissions association="mine" />}
                layout={SidebarLayout}
                sidebar="dashboard"
              />
              <Redirect exact from="/missions" to="/missions/mine" />
              <AuthenticatedRoute
                exact
                path="/missions/organisation"
                component={() => <AllMissions association="organisation" />}
                layout={SidebarLayout}
                sidebar="dashboard"
              />
              <AuthenticatedRoute
                exact
                path="/missions/demo-missions"
                component={() => <AllMissions association="sample" />}
                layout={SidebarLayout}
                sidebar="dashboard"
              />
              <AuthenticatedRoute
                exact
                path="/missions/shared"
                component={() => <SharedMissions />}
                layout={SidebarLayout}
                sidebar="dashboard"
              />
              <AuthenticatedRoute
                exact
                path="/new-maps/all"
                component={() => <AllNewMaps />}
                layout={SidebarLayout}
                sidebar="dashboard"
              />
              {/* mission share invitation */}
              <Route exact path="/share/:id" component={UnauthenticatedShare} />
              {/* dashboard - Job requests */}
              <AuthenticatedRoute
                exact
                path="/job-requests/incoming"
                component={() => <IncomingJobRequests />}
                layout={SidebarLayout}
                sidebar="dashboard"
              />
              {/* prev profile */}
              {/* <AuthenticatedRoute exact path="/user/details" component={UserDetails} layout={SidebarLayout} sidebar="prev-profile" />
              <AuthenticatedRoute exact path="/user/marketing" component={MarketingPreferences} layout={SidebarLayout} sidebar="prev-profile" /> */}
              {/* user profile */}
              <AuthenticatedRoute
                exact
                path="/profile-general/:scopeId"
                component={ProfileGeneral}
                layout={SidebarLayout}
                sidebar="user-profile"
              />
              <AuthenticatedRoute
                exact
                path="/profile-permissions/:scopeId"
                component={ProfilePermissions}
                layout={SidebarLayout}
                sidebar="user-profile"
              />
              {/* <AuthenticatedRoute exact path="/profile-workspaces/:scopeId" component={ProfileWorkspaces} layout={SidebarLayout} sidebar="profile" /> */}
              {/* prev organisations */}
              {/* <AuthenticatedRoute exact path="/users" component={Users} layout={SidebarLayout} sidebar="organisation" />
              <AuthenticatedRoute exact path="/users/:scopeId" component={UserManagement} layout={SidebarLayout} sidebar="organisation" />
              <AuthenticatedRoute exact path="/billing/plan" component={PlanOverview} layout={SidebarLayout} sidebar="organisation" />
              <AuthenticatedRoute exact path="/billing/invoices" component={Invoices} layout={SidebarLayout} sidebar="organisation" />
              <AuthenticatedRoute exact path="/billing/billing-info" component={BillingInfo} layout={SidebarLayout} sidebar="organisation" />
              <AuthenticatedRoute exact path="/billing/payment-method" component={PaymentMethod} layout={SidebarLayout} sidebar="organisation" />
              <AuthenticatedRoute exact path="/settings/customisation/branding" component={CustomisationBranding} layout={SidebarLayout} sidebar="organisation" />
              <AuthenticatedRoute exact path="/processing" component={ProcessingView} layout={SidebarLayout} sidebar="organisation" /> */}
              {/* admin */}
              <AuthenticatedRoute
                exact
                path="/admin-general"
                component={General}
                layout={SidebarLayout}
                sidebar="admin"
              />
              <AuthenticatedRoute
                exact
                path="/admin-users"
                component={Users}
                layout={SidebarLayout}
                sidebar="admin"
              />
              <AuthenticatedRoute
                exact
                path="/admin-workspaces"
                component={Workspaces}
                layout={SidebarLayout}
                sidebar="admin"
              />
              <AuthenticatedRoute
                exact
                path="/admin-billing"
                component={Invoices}
                layout={SidebarLayout}
                sidebar="admin"
              />
              <AuthenticatedRoute
                exact
                path="/admin-billing/payment-method"
                component={PaymentMethod}
                layout={SidebarLayout}
                sidebar="admin"
              />
              <AuthenticatedRoute
                exact
                path="/admin-plan"
                component={PlanAndUsage}
                layout={SidebarLayout}
                sidebar="admin"
              />
              <AuthenticatedRoute
                exact
                path="/admin-processing"
                component={ProcessingView}
                layout={SidebarLayout}
                sidebar="admin"
              />
              <AuthenticatedRoute
                exact
                path="/admin-permissions"
                component={Permissions}
                layout={SidebarLayout}
                sidebar="admin"
              />
              <AuthenticatedRoute
                exact
                path="/admin-security"
                component={Security}
                layout={SidebarLayout}
                sidebar="admin"
              />
              <AuthenticatedRoute
                exact
                path="/admin-customization"
                component={CustomisationBranding}
                layout={SidebarLayout}
                sidebar="admin"
              />
              {/* workspaces */}
              {/* <AuthenticatedRoute exact path="/workspace-general" component={WorkspacesGeneral} layout={SidebarLayout} sidebar="workspace" />
              <AuthenticatedRoute exact path="/workspace-customisation" component={WorkspacesGeneral} layout={SidebarLayout} sidebar="workspace" />
              <AuthenticatedRoute exact path="/workspace-users" component={WorkspacesGeneral} layout={SidebarLayout} sidebar="workspace" />
              <AuthenticatedRoute exact path="/workspace-processing" component={WorkspacesGeneral} layout={SidebarLayout} sidebar="workspace" />
              <AuthenticatedRoute exact path="/workspace-permissions" component={WorkspacesGeneral} layout={SidebarLayout} sidebar="workspace" />
              <AuthenticatedRoute exact path="/workspace-data-tags" component={WorkspacesGeneral} layout={SidebarLayout} sidebar="workspace" /> */}
              {/* pilot network */}
              <AuthenticatedRoute
                exact
                path="/user/pilot"
                component={PilotSettings}
                layout={SidebarLayout}
                sidebar="pilot-settings"
              />
              <AuthenticatedRoute
                exact
                path="/user/credentials"
                component={Credentials}
                layout={SidebarLayout}
                sidebar="pilot-settings"
              />
              <AuthenticatedRoute
                exact
                path="/equipment"
                component={Equipment}
                layout={SidebarLayout}
                sidebar="pilot-settings"
              />
              <AuthenticatedRoute
                exact
                path="/equipment/:id"
                component={EquipmentSingle}
              />
              {/* inspection tools */}
              <AuthenticatedRoute
                exact
                path="/assessment-types"
                component={AssessmentType}
                layout={SidebarLayout}
                sidebar="inspection-tools"
              />
              <AuthenticatedRoute
                exact
                path="/assessment-severity"
                component={AssessmentSeverity}
                layout={SidebarLayout}
                sidebar="inspection-tools"
              />
              <AuthenticatedRoute
                exact
                path="/inspections"
                component={Inspections}
                layout={SidebarLayout}
                sidebar="inspection-tools"
              />
              {/* dev */}
              <Route exact path="/dev/:resource" component={DevTools} />
              <Route path="/map/*" component={RedirectToMap} />
              {/* 404 not found */}
              <Route component={NotFoundScreen} />
            </Switch>
            <MetaBarConnected />
          </Router>
          <Toaster position="top-right" containerStyle={{ top: 80 }} />
        </ThemeProvider>
      </Provider>
    </PostHogProvider>
  </CrashScreen>
);

getInitialAuthState()(store.dispatch);

const root = createRoot(document.getElementById('root')!);
root.render(<Index />);
