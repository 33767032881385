/* global fetch */

import { formatISO } from 'date-fns';
import { config } from '../config';
import { APIResponse, fetchAPI, headers, SpanTag, stleaf } from '../helpers';
import {
  SearchProcessingJobResult,
  ProcessingPlatformRequest,
} from './processing-jobs-types';

export * from './processing-jobs-types';

export const postJob = (
  st: SpanTag,
  requestObj: ProcessingPlatformRequest,
): Promise<APIResponse> => {
  const url = `${config.API_URL}/v1/processing-jobs/`;
  return fetchAPI(stleaf(st, 'opspjb'), url, {
    method: 'POST',
    credentials: 'include',
    body: JSON.stringify(requestObj),
    headers,
  });
};

export const postPaygJob = (
  st: SpanTag,
  requestObj: ProcessingPlatformRequest,
): Promise<APIResponse> => {
  const url = `${config.API_URL}/v1/processing-jobs/payg`;
  return fetchAPI(stleaf(st, 'paygj'), url, {
    method: 'POST',
    credentials: 'include',
    body: JSON.stringify(requestObj),
    headers,
  });
};
export const postEnterpriseSelfServeJob = (
  st: SpanTag,
  requestObj: ProcessingPlatformRequest,
): Promise<APIResponse> => {
  const url = `${config.API_URL}/v1/processing-jobs/enterpise/self-serve`;
  return fetchAPI(stleaf(st, 'ensspj'), url, {
    method: 'POST',
    credentials: 'include',
    body: JSON.stringify(requestObj),
    headers,
  });
};
export const postEnterpriseAssistedJob = (
  st: SpanTag,
  requestObj: ProcessingPlatformRequest,
): Promise<APIResponse> => {
  const url = `${config.API_URL}/v1/processing-jobs/enterprise/assisted`;
  return fetchAPI(stleaf(st, 'enaspj'), url, {
    method: 'POST',
    credentials: 'include',
    body: JSON.stringify(requestObj),
    headers,
  });
};

export interface GetProcessingJobsFilters {
  start?: number;
  limit?: number;
  organisationId?: string;
  dates?: [string, string];
  missionId?: string;
  state?: string;
  mapName?: string;
}

export interface ProcessingJob {
  id: string;
  name: string;
  mission: {
    id: string;
    name: string;
    organisationId: string;
  };
  publishableState?: string;
  objective?: { id: string; name: string };
  startTime: string;
  state: string;
  working: boolean;
  stateUpdatedAt: string;
  processingConfig: { [key: string]: unknown };
  projectOutputS3Key: string;
  requestConfig: ProcessingPlatformRequest;
  gigapixelCount: number;
}

export const getJobs = (
  st: SpanTag,
  filters: GetProcessingJobsFilters = {},
): Promise<APIResponse> => {
  const url = new URL(`${config.API_URL}/v1/processing-jobs/list`);
  const searchParams = new URLSearchParams(filters);
  url.search = searchParams.toString();
  return fetchAPI(stleaf(st, 'mau5mo'), url, {
    method: 'GET',
    credentials: 'include',
    headers,
  });
};

export const getProcessingJobs = (
  st: SpanTag,
  filters: GetProcessingJobsFilters = {},
): Promise<
  APIResponse<{
    items: ProcessingJob[];
    totalMissions: number;
    totalCount: number;
    totalGpCount: number;
  }>
> => {
  const url = new URL(`${config.API_URL}/v1/processing-jobs`);
  const searchParams = new URLSearchParams(filters);
  url.search = searchParams.toString();
  return fetchAPI(stleaf(st, 'owifa9'), url, {
    method: 'GET',
    credentials: 'include',
    headers,
  });
};

export const getJob = (st: SpanTag, jobId: string): Promise<APIResponse> => {
  const url = `${config.API_URL}/v1/processing-jobs/${jobId}`;
  return fetchAPI(stleaf(st, 'eki1ah'), url, {
    method: 'GET',
    credentials: 'include',
    headers,
  });
};

export const deleteJob = (st: SpanTag, jobId): Promise<APIResponse> => {
  const url = `${config.API_URL}/v1/processing-jobs/${jobId}/`;
  return fetchAPI(stleaf(st, 'opoof1'), url, {
    method: 'DELETE',
    credentials: 'include',
    headers,
  });
};

// TODO refactor to use newer getJobs with missionId filter. No use in double code
export const getJobsByMissionId = (
  st: SpanTag,
  missionId: string,
): Promise<APIResponse> => {
  const url = `${config.API_URL}/v2/missions/${missionId}/processing-jobs/`;
  return fetchAPI(stleaf(st, 's7lu44'), url, {
    method: 'GET',
    credentials: 'include',
    headers,
  });
};

export const getGPEstimationByObjective = (
  st: SpanTag,
  objectiveId: string,
): Promise<APIResponse> => {
  const url = `${config.API_URL}/v1/processing-jobs/objective/${objectiveId}/cost-estimation`;
  return fetchAPI(stleaf(st, 'uix2j3'), url, {
    method: 'GET',
    credentials: 'include',
    headers,
  });
};

export const transitionToComplete = (
  st: SpanTag,
  jobId: string,
): Promise<APIResponse> => {
  const url = `${config.API_URL}/v1/processing-jobs/${jobId}/transition/complete`;
  return fetchAPI(stleaf(st, '8s9t4d'), url, {
    method: 'PUT',
    credentials: 'include',
    headers,
  });
};

export const markRequestReviewed = (
  st: SpanTag,
  jobId: string,
  requestObj: any,
): Promise<APIResponse> => {
  const url = `${config.API_URL}/v1/processing-jobs/${jobId}/actions/assisted-request-reviewed`;
  return fetchAPI(stleaf(st, 'ms8t31'), url, {
    method: 'PUT',
    credentials: 'include',
    body: JSON.stringify(requestObj),
    headers,
  });
};

export const releaseToClient = (
  st: SpanTag,
  jobId: string,
): Promise<APIResponse> => {
  const url = `${config.API_URL}/v1/processing-jobs/${jobId}/actions/release`;
  return fetchAPI(stleaf(st, '8itl4d'), url, {
    method: 'POST',
    credentials: 'include',
    headers,
  });
};

export const markNonbillable = (
  st: SpanTag,
  jobId: string,
): Promise<APIResponse> => {
  const url = `${config.API_URL}/v1/processing-jobs/${jobId}/actions/mark-nonbillable`;
  return fetchAPI(stleaf(st, '8itl4d'), url, {
    method: 'POST',
    credentials: 'include',
    headers,
  });
};

export const updatePaymentStatus = (
  st: SpanTag,
  jobId: string,
  obj: any,
): Promise<APIResponse> => {
  const url = `${config.API_URL}/v1/processing-jobs/${jobId}/payment-status`;
  return fetchAPI(stleaf(st, 'eequ7i'), url, {
    method: 'PUT',
    credentials: 'include',
    body: JSON.stringify(obj),
    headers,
  });
};

export const cancel = (st: SpanTag, jobId: string): Promise<APIResponse> => {
  const url = `${config.API_URL}/v1/processing-jobs/free/${jobId}/cancelled`;
  return fetchAPI(stleaf(st, 'k39k8q'), url, {
    method: 'POST',
    credentials: 'include',
    headers,
  });
};

interface SearchProcessingJobsOptions {
  search?: string;
  siteSearch?: string;
  start: number;
  requestedBy?: string;
  processedOn?: Date;
}

export const searchProcessingJobs = (
  st: SpanTag,
  organisationId: string | undefined,
  {
    search,
    siteSearch,
    start,
    requestedBy,
    processedOn,
  }: SearchProcessingJobsOptions,
): Promise<
  APIResponse<{
    jobs: SearchProcessingJobResult[];
    count: number;
    limit: number;
  }>
> => {
  const params = new URLSearchParams({ start: start as any });

  // Only ops are allowed to pass an undefined org ID.
  if (organisationId) params.set('organisationId', organisationId);
  if (search) params.set('search', search);
  if (siteSearch) params.set('siteSearch', siteSearch);
  if (requestedBy) params.set('requestedBy', requestedBy);
  if (processedOn) params.set('processedOn', formatISO(processedOn));

  const url = `${config.API_URL}/v1/processing-jobs/search?${params.toString()}`;
  return fetchAPI(stleaf(st, `ruth6o`), url, {
    method: 'GET',
    credentials: 'include',
    headers,
  });
};
