import {
  stroot,
  auth,
  user,
  onboarding,
  organisationFeatures as orgFeaturesAPI,
} from '@birdi/js-sdk';
import { formatName } from '@birdi/utils/src/text';
import { getFirstPromoterData } from '@birdi/first-promoter';
import { posthog } from '../lib/posthog';
import { store } from '../state/index';
import { updateMeta, loadMeta } from '../meta/reducer';
import * as onboardingActions from '../onboarding/reducer';
import history from '../state/history';
import pino from '../lib/pino';
import {
  AUTHORISING,
  AUTHORISE,
  ANONYMOUS,
  ERROR,
  UPDATE_FIELD,
} from './reducer';
import { syncFirstPromoter } from '@birdi/js-sdk/src/public/user';

// TODO: This check should probably be performed as part of getting user
const checkOnboarding = (dispatch) =>
  onboarding
    .get(stroot('oogoy2'))
    .then((response) => {
      // Direct meta state to display onboarding process
      dispatch(
        updateMeta({
          onboardingCompleted: response.body.complete,
        }),
      );
      // Hydrate onboarding state
      dispatch({
        type: onboardingActions.FETCH,
        payload: {
          ...response.body.answers,
        },
      });
      return response.body.complete;
    })
    .catch((err) => {});

/**
 * setUserAndMetaState called when authenticating
 * from an auth flow (e.g. email login, google login, reset password)
 * or loading the app initially
 * @param {function} dispatch redux dispatcher
 */
function setUserAndMetaState(dispatch) {
  return Promise.all([
    user
      .getMeta(stroot('xof9ci'))
      .then((response) => {
        dispatch(loadMeta(response.body));
        dispatch({ type: AUTHORISE });
        // Identify user in analytics etc here
        const intercomIdentity = {
          app_id: 'b0ht41y7',
          user_id: response.body.id,
          user_hash: response.body.intercomVerification,
          name: `${process.env.INTERCOM_PREFIX === true ? '[test] ' : ''}${formatName(response.body.fname, response.body.lname)}`,
          email: response.body.email,
          meta: response.body.organisation.plan,
          job_notifications: response.body.jobNotifications,
          company: {
            company_id: response.body.organisation.id,
            name: response.body.organisation.name,
            plan: response.body.organisation.plan,
          },
        };
        window?.Intercom && window.Intercom('update', intercomIdentity);
        window._hsq?.push(['identify', { email: response.body.email }]);
        posthog?.identify(
          response.body.id,
          {},
          {
            name: formatName(response.body.fname, response.body.lname),
            email: response.body.email,
          },
        );
        posthog?.group('organisation', response.body.organisation.id, {
          name: response.body.organisation.name,
        });
        getFirstPromoterData()
          .then((data) => {
            if (data.tid) return syncFirstPromoter(stroot('syncfp'), data.tid);
            return false;
          })
          .catch((err) => {
            console.log(err);
          });
        // TODO: review websockets later on
        // if (!process.env.WEBSOCKETS_OFF) websocketInit(dispatch);
        return true;
      })
      .catch((err) => {
        if (err.message === 'Network Error') {
          pino.info(
            'Network error when attempting to authenticate with existing JWT',
          );
          const url = new URL(window.location.href); // retain search
          return history.push(`/sign-in${url.search}`);
        }
        if (err.status === 401) {
          auth.clearCookie(stroot('asdzxb'), false);
          dispatch({ type: ANONYMOUS });
        }
        console.log(err);
        return false;
      }),
    checkOnboarding(dispatch), // TODO: Combine this with meta state

    orgFeaturesAPI
      .getAll(stroot('aa4azm'))
      .then((res) => {
        dispatch(
          updateMeta({
            orgFeatureFlags: res.body,
          }),
        );
      })
      .catch((err) => {
        // TODO: Let org features be consumed by meta state API
      }),
    // features.getByName(FeatureFlagNames.VolumetricRequest)
    //   .then((res) => {
    //     if (res.body.enabled) setBookingEnabled(true);
    //   }).catch((err) => console.log(err));
  ]).then(([authenticated, onboardingCompleted]) => {
    // If user already in onboarding flow, don't kick them out
    const inOnboardingFlow = /^\/onboarding\/.*$/.test(
      window.location.pathname,
    );
    const unauthenticatedOnlyRoutes = [
      /^\/sign-in\/?.*$/,
      /^\/sign-up\/?.*$/,
      /^\/onboarding\/.*$/,
      /^\/reset-password\/?.*$/,
      /^\/set-password\/?.*$/,
      /^\/email-verification\/?.*$/,
    ];
    // Authenticated and is part of onboarding
    if (!onboardingCompleted && authenticated) {
      if (inOnboardingFlow) return;
      return history.push('/onboarding');
    }
    // If redirect already specified
    // TODO: Middleware could be used here instead
    const metaState = store.getState().meta;
    if (metaState.redirect && authenticated) {
      return history.push(metaState.redirect);
    }
    // If authenticated & in anonymous only routes
    // TODO: Define this behaviour in the components that are anonymous only
    // rather than as a list here.
    if (
      authenticated &&
      unauthenticatedOnlyRoutes.some((r) => r.test(window.location.pathname))
    ) {
      return history.push('/');
    }
    // Default behaviour is to do nothing, i.e. do not change the location
  });
}

/**
 *
 * @param {object} { username, password }
 */
export const signInWithPassword = (obj) => (dispatch) => {
  dispatch({ type: AUTHORISING });
  return auth
    .signInWithPassword(stroot('yo8mol'), obj)
    .then(() => setUserAndMetaState(dispatch))
    .catch((error) => dispatch({ type: ERROR, payload: error }));
};

export const signInWithGoogleToken = (dispatch) => (code) => {
  dispatch({ type: AUTHORISING });
  auth
    .signInWithGoogleIdToken(stroot('ohch1n'), code)
    .then(() => setUserAndMetaState(dispatch))
    .catch((error) => dispatch({ type: ERROR, payload: error }));
};

// Email verification
export const verifyEmailWithTokenAndSignIn =
  (userId, verificationToken) => (dispatch) =>
    auth
      .verifyEmailWithToken(stroot('sahz6f'), userId, verificationToken)
      .then((res) => {
        setUserAndMetaState(dispatch);
      });

export const getVerificationToken = (email) => () =>
  auth.getVerificationToken(stroot('da2ye5'), email);

// Reset password (Get code)
export const sendPasswordResetCodeToEmail = (email) => (dispatch) =>
  auth
    .sendPasswordResetCodeToEmail(stroot('hain4i'), email)
    .then((res) =>
      dispatch({
        type: UPDATE_FIELD,
        payload: { pendingCode: true, codeId: res.body.id },
      }),
    )
    .catch((error) => dispatch({ type: UPDATE_FIELD, payload: { error } }));

// Reset password (& Automatic login)
export const resetPassword = (obj) => (dispatch) =>
  auth
    .resetPassword(stroot('pix1ao'), obj)
    .then(() => setUserAndMetaState(dispatch))
    .catch((error) => dispatch({ type: UPDATE_FIELD, payload: { error } }));

// Update auth state
export const updateField = (obj) => (dispatch) =>
  dispatch({ type: UPDATE_FIELD, payload: obj });

/**
 * Assumes initial auth state is good
 * Checks if bad and redirects to homepage
 */
export const getInitialAuthState = () => (dispatch) => {
  // Skip if user is on email-verification screen to prevent race conditions i.e. cookie clearing
  if (/^\/email-verification\/.*$/.test(window.location.pathname)) {
    return;
  }
  return setUserAndMetaState(dispatch);
};
