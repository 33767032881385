import React from 'react';
import { css } from '@emotion/react';

interface SeverityIconProps {
  className?: string;
  severity: number;
  colour: string;
}

export const SeverityIcon = ({
  className,
  severity,
  colour,
}: SeverityIconProps) => {
  return (
    <div
      css={css`
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        font-weight: 700;
        color: ${colour};
        border: solid 1px ${colour};
        background: color-mix(in srgb, ${colour} 10%, white);
        font-size: 16px;
        line-height: 24px;
        padding: 3px 12px;
        border-radius: 2px;
        cursor: default;
      `}
      className={className}
    >
      {severity}
    </div>
  );
};
