import React, { useState } from 'react';
import { css } from '@emotion/react';
import { breakpoints } from '@birdi/theme/variables';
import { normalisedRegionText } from '@birdi/utils/src/text';
import { preferredDate } from '@birdi/mission-kit/PreferredDate';
import { JobRequestStatus } from './MetaData';
import { BirdiTheme } from '@birdi/theme';

interface JobRequestCardProps {
  jobRequest: any;
  handleCardClick: () => void;
}

/* Enabling focus styles on keyboard only
 * https://stackoverflow.com/questions/31402576/enable-focus-only-on-keyboard-use-or-tab-press
 */
const cardCss = (theme: BirdiTheme) => css`
  display: inline-block;
  box-sizing: border-box;
  margin: 0 1.1rem 1.1rem 0;
  border-radius: 2px;
  box-shadow: 0 0 10px ${theme.mono20};
  border: solid 1px ${theme.mono20};
  transition:
    0.15s shadow,
    0.15s transform;
  cursor: pointer;
  width: 100%;
  @media (min-width: ${breakpoints.xs}) {
    width: calc((100% / 2) - 1.1rem);
  }
  @media (min-width: ${breakpoints.s}) {
    width: calc((100% / 3) - 1.1rem);
  }
  &:focus,
  & > span:focus {
    outline: 0;
  }
  &:focus > span {
    outline: -webkit-focus-ring-color auto 5px;
  }
`;

function getBgImageString(url) {
  return url ? `background-image: url('${url}');` : false;
}

export const JobRequestCard: React.FunctionComponent<JobRequestCardProps> = ({
  jobRequest,
  handleCardClick,
}) => {
  const [mouseOver, setMouseOver] = useState(false);
  const jobRequestCardRef = React.createRef();
  return (
    <div
      role="button"
      tabIndex={0}
      onFocus={() => 0}
      key={jobRequest.id}
      css={cardCss}
      ref={jobRequestCardRef}
      id={jobRequest.id}
      onMouseOver={() => setMouseOver(() => !mouseOver && { mouseOver: true })}
      onMouseLeave={() => setMouseOver(() => ({ mouseOver: false }))}
      onClick={(event) => {
        if (event.preventCardClick === true) {
          // This is needed because it seems the property on the event persists
          event.preventCardClick = false;
          return;
        }
        handleCardClick();
      }}
      onKeyDown={(event) => event.key === 'Enter' && handleCardClick()}
    >
      <span>
        <div
          css={css`
            position: relative;
            height: 12.5rem;
            background-color: #f5f5f5;
            background-size: cover;
            background-position: center;
            text-align: center;
            border-radius: 2px;
            ${getBgImageString(jobRequest.coverImageURL)}
          `}
        >
          <div
            css={css`
              position: absolute;
              top: 0.5rem;
              right: 0.5rem;
            `}
          >
            <JobRequestStatus jobRequest={jobRequest} />
          </div>
        </div>
        <div
          css={css`
            position: relative;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            height: 12.5rem;
            padding: 1em;
          `}
        >
          <h3
            css={css`
              text-transform: capitalize;
              margin-bottom: 0px;
              margin-top: 0;
            `}
          >
            {jobRequest.name || 'Untitled job request'}
          </h3>
          <dl
            css={(theme: BirdiTheme) => css`
              color: ${theme.mono50};
              line-height: 2em;
              margin: 0.5rem 0;
              dt {
                display: none;
              }
              dd,
              dt {
                margin: 0;
              }
            `}
          >
            {jobRequest.location && (
              <>
                <dt>Location</dt>
                <dd>{normalisedRegionText(jobRequest.location)}</dd>
              </>
            )}
            {jobRequest.jobType && (
              <div
                css={css`
                  text-transform: capitalize;
                `}
              >
                <dt>Job type</dt>
                <dd>{`${jobRequest.jobType}`}</dd>
              </div>
            )}
            {jobRequest.jobType && (
              <div
                css={css`
                  text-transform: capitalize;
                `}
              >
                <dt>Payment</dt>
                <dd>{`${jobRequest.pilotPayment}`}</dd>
              </div>
            )}
            <>
              <dt>Preferred Date</dt>
              <dd>{preferredDate(jobRequest.preferredDate)}</dd>
            </>
          </dl>
        </div>
      </span>
    </div>
  );
};
