import { withRouter } from 'react-router-dom';
import cx from 'classnames';
import { css } from '@emotion/react';
import { PoweredByBirdi } from '@birdi/branding/powered-by-birdi';
import {
  SideBar,
  SidebarLink,
  dividerStyle,
  TitleHolder,
  TitleStyle,
  poweredByBirdiStyle,
  BackButton,
} from './side-bar-utils';
import { FeatureFlagNames } from '@birdi/js-sdk/src/public/organisation-features';
import { useMetaSelector } from '../../meta/reducer';
import { hasPermission } from '@birdi/utils/src/helpers';

const SideNavigationOrganisation = withRouter(({ history }) => {
  const meta = useMetaSelector();
  const manageUsers =
    meta.organisation && hasPermission(meta.permissions, 'org:manage-users');
  const inspection =
    meta.orgFeatureFlags.includes(
      FeatureFlagNames.PowercorInspectionDashboard,
    ) && meta.permissions.includes('org:manage-missions');
  const hasProcessing = meta.organisation.processingFlavour !== 'none';
  const customBranding = meta.orgFeatureFlags.includes(
    FeatureFlagNames.CustomBranding,
  );
  const canViewProcessing =
    hasPermission(meta.permissions, 'org:manage-missions') && hasProcessing;
  const manageOrgOrOps =
    meta.permissions.includes('org:manage-org') ||
    meta.opsActions.includes('access_ops');
  return (
    <div
      css={css`
        position: relative;
      `}
    >
      <SideBar>
        <BackButton label="Organization Settings" id="organisation-settings" />
        <hr css={dividerStyle} />
        {(manageUsers || manageOrgOrOps) && (
          <div css={TitleHolder}>
            <span css={TitleStyle}>General</span>
          </div>
        )}
        {manageUsers && (
          <SidebarLink to="/users" match={history.location.pathname}>
            <span>User Management</span>
          </SidebarLink>
        )}
        {manageOrgOrOps && (
          <SidebarLink
            to="/settings/customisation/branding"
            match={history.location.pathname}
            disabled={!customBranding}
          >
            <span id="custom-branding">Customization</span>
          </SidebarLink>
        )}
        <hr css={dividerStyle} />
        {(manageOrgOrOps || canViewProcessing) && (
          <div css={TitleHolder}>
            <span css={TitleStyle}>Billing & Subscription</span>
          </div>
        )}
        {manageOrgOrOps && (
          <SidebarLink to="/billing/plan" match={history.location.pathname}>
            <span>Plan Overview</span>
          </SidebarLink>
        )}
        {canViewProcessing && (
          <SidebarLink to="/processing" match={history.location.pathname}>
            <span>Processing Dashboard</span>
          </SidebarLink>
        )}
        <hr css={dividerStyle} />
        <div css={TitleHolder}>
          <span css={TitleStyle}>Inspection Tools</span>
        </div>
        <SidebarLink
          id="assessment-types"
          to="/assessment-types"
          match={history.location.pathname}
        >
          <span
            css={css`
              margin-right: 0.25rem;
            `}
          >
            Assessment Types
          </span>
        </SidebarLink>
        <SidebarLink
          id="assessment-severity"
          to="/assessment-severity"
          match={history.location.pathname}
          className={cx('item', 'sub', 'severity-scale')}
        >
          <span>Assessment Severity</span>
        </SidebarLink>
        <SidebarLink
          to={inspection ? '/inspections' : '#'}
          match={history.location.pathname}
          disabled={!inspection}
        >
          <span id="inspection-summary">Inspection Summary</span>
        </SidebarLink>
        <hr css={dividerStyle} />
      </SideBar>
      <div css={poweredByBirdiStyle}>
        <PoweredByBirdi mode="dark" />
      </div>
    </div>
  );
});

export default SideNavigationOrganisation;
