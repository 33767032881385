/* global fetch */
import { config } from '../config';
import { headers, APIResponse, stleaf, fetchAPI, SpanTag } from '../helpers';

export interface Invitation {
  id: string;
  missionId?: string;
  userId?: string;
  expireAt: string;
  expired?: boolean;
  role: 'view' | 'edit'; // TODO validation api side
}

export const getInvitations = (
  st: SpanTag,
  missionId: string,
): Promise<APIResponse<Invitation[]>> => {
  const url = new URL(`${config.API_URL}/v2/share/${missionId}`);
  return fetchAPI(stleaf(st, 'jee8ah'), url.toString(), {
    method: 'GET',
    credentials: 'include',
    headers,
  });
};

interface CreateInvitation {
  expireAt: Date;
  role: string;
}
export const create = (
  st: SpanTag,
  missionId: string,
  opts: CreateInvitation,
): Promise<APIResponse<Invitation>> => {
  const url = `${config.API_URL}/v2/share/${missionId}`;
  return fetchAPI(stleaf(st, 'lee4ah'), url, {
    method: 'POST',
    credentials: 'include',
    headers,
    body: JSON.stringify(opts),
  });
};

export const deleteInvitation = (
  st: SpanTag,
  id: string,
): Promise<APIResponse> => {
  const url = `${config.API_URL}/v2/share/${id}`;
  return fetchAPI(stleaf(st, 'eil7ee'), url, {
    method: 'DELETE',
    credentials: 'include',
    headers,
  });
};

export const getMissionInfo = (
  st: SpanTag,
  invitationId: string,
): Promise<APIResponse> => {
  const url = new URL(`${config.API_URL}/v2/share/${invitationId}/mission`);
  return fetchAPI(stleaf(st, 'joo4ei'), url.toString(), {
    method: 'GET',
    credentials: 'include',
    headers,
  });
};

export const acceptInvitation = (
  st: SpanTag,
  invitationId: string,
): Promise<APIResponse> => {
  const url = `${config.API_URL}/v2/share/${invitationId}/accept`;
  return fetchAPI(stleaf(st, 'gah1ch'), url, {
    method: 'POST',
    credentials: 'include',
    headers,
  });
};
