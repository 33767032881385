import { stroot, organisation as organisationAPI } from '@birdi/js-sdk';
import { connect, useDispatch } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { css } from '@emotion/react';
import { BirdiTheme } from '@birdi/theme';
import * as userActions from '../organisation-users/reducer';
import { useMetaSelector } from '../meta/reducer';
import {
  loadSeats,
  useSeatsSelector,
  totalSeats,
} from '../billing/seats-reducer';

const RolesComponent = ({ details, updateRole, setSelectedRole, isMyself }) => {
  const dispatch = useDispatch();
  const [userTypeOptions, setUserTypeOptions] = useState([]);
  const [roleId, setRoleId] = useState(null);
  const meta = useMetaSelector();
  const seats = useSeatsSelector();
  const [disableCheckboxes, setDisableCheckboxes] = useState<boolean>(null);

  useEffect(() => {
    loadSeats(dispatch);
  }, []);

  useEffect(() => {
    setRoleId(details?.roleId);
    let isGuest = false;
    organisationAPI.getRoles(stroot('io3rae')).then((response) => {
      const items = [];
      response.body.items.map((role) => {
        items.push({
          id: role.id,
          title: role.name,
          description: role.description,
        });
        if (role.id === details?.roleId) {
          setSelectedRole(role.name);
          // console.log('role: ', role);
        }
      });
      setUserTypeOptions(items);
      const guestRole = items.filter((item) => item.title === 'Guest');
      isGuest = guestRole[0].id === details?.roleId;
      const noAvaiableSeats =
        seats.plan === 'free' ||
        seats.plan === 'plus' ||
        (seats.plan === 'growth' && totalSeats(seats) <= seats.userCount);
      setDisableCheckboxes(noAvaiableSeats && isGuest);
    });
  }, [seats.loaded]);

  if (!userTypeOptions.length || disableCheckboxes === null) return <div />;

  return (
    <div
      css={css`
        position: relative;
      `}
    >
      {userTypeOptions.map((option, index) => (
        <div
          key={option.id}
          css={(theme: BirdiTheme) => css`
            display: flex;
            align-items: center;
            padding: 0.5em;
            margin: 0.25em;
            background: ${theme.mono20};
            border-radius: 5px;
          `}
        >
          <div>
            <input
              disabled={isMyself || disableCheckboxes} // {(details?.id === meta.id) || disableCheckboxes}
              type="radio"
              name="userTypes"
              id={option.id}
              checked={option.id === roleId}
              onChange={() => {
                updateRole(details.id, option.id);
                setRoleId(option?.id);
                setSelectedRole(option.title);
              }}
              css={(theme: BirdiTheme) => css`
                margin-right: 1.5rem;
                color: ${theme.mono30};
                height: 1.5em;
                width: 1.5em;
              `}
            />
          </div>
          <div>
            <label htmlFor={option.id}>
              <span>{option.title}</span>
            </label>
          </div>
        </div>
      ))}
    </div>
  );
};

export default connect(
  (state) => ({}),
  (dispatch) => ({
    updateRole: (id, roleId) => dispatch(userActions.updateRole(id, roleId)),
  }),
)(RolesComponent);
