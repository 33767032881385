import React from 'react';
import { Link } from 'react-router-dom';

export const definitions = {
  'password.tooShort': "The password you've chosen is too short",
  'password.zxcvbn': "The password you've chosen is too short",
  // TODO: a better flow would just move to the next screen
  'password.requiresReset': (
    <span>
      For security reasons the password related to this account needs to be
      reset. <Link to="/reset-password">Reset your password</Link>.
    </span>
  ),
  'auth.alreadyExists': (
    <span>
      There&#8217;s already an account with that email.
      <br />
      <Link to="/reset-password">Reset your password</Link> or{' '}
      <Link to="/sign-in">Sign in</Link>.
    </span>
  ),
  'auth.invalidCredentials': (
    <span>
      The password you&#8217;ve entered is invalid.{' '}
      <Link to="/reset-password">Reset your password.</Link>
    </span>
  ),
  'email.emailNotVerified': (
    <span>
      This email needs to be verified before you can login. Check your email or{' '}
      <Link to="/resend-verification">resend verification link.</Link>
    </span>
  ),
  'email.nonExistent': 'Email address not found.',
  'account.lockedAccount': 'This account has been locked',
};
