/* global fetch */

import querystring from 'querystring';
import { config } from '../config';
import {
  headers,
  APIResponse,
  SpanTag,
  stleaf,
  fetchAPI,
  fetchAPIJSON,
} from '../helpers';

export const getSelectionList = (st: SpanTag, opts): Promise<APIResponse> => {
  const queryString = querystring.stringify(opts);
  const query = queryString ? `?${queryString}` : '';
  return fetchAPI(
    stleaf(st, 'hai9ae'),
    `${config.API_URL}/v2/admin/organisations/select${query}`,
    {
      method: 'GET',
      credentials: 'include',
      headers,
    },
  );
};

export const getAll = (st: SpanTag, opts): Promise<APIResponse> => {
  const queryString = querystring.stringify(opts);
  const query = queryString ? `?${queryString}` : '';
  return fetchAPI(
    stleaf(st, 'ooc5ea'),
    `${config.API_URL}/v2/admin/organisations${query}`,
    {
      method: 'GET',
      credentials: 'include',
      headers,
    },
  );
};

export const assignUserToOrganisation = (
  st: SpanTag,
  organisationId: string,
  fields,
): Promise<APIResponse> => {
  const url = `${config.API_URL}/v2/admin/organisations/${organisationId}/user`;
  return fetchAPI(stleaf(st, 'uqu0yo'), url, {
    method: 'POST',
    credentials: 'include',
    headers,
    body: JSON.stringify(fields),
  });
};

export const create = (st: SpanTag, params): Promise<APIResponse> => {
  const url = `${config.API_URL}/v2/admin/organisations`;
  return fetchAPI(stleaf(st, 'theiv2'), url, {
    method: 'POST',
    credentials: 'include',
    headers,
    body: JSON.stringify(params),
  });
};

export const getOne = (st: SpanTag, id: string): Promise<APIResponse> => {
  const url = `${config.API_URL}/v2/admin/organisations/${id}`;
  return fetchAPI(stleaf(st, 'uosh8h'), url, {
    method: 'GET',
    credentials: 'include',
    headers,
  });
};

export const update = (
  st: SpanTag,
  id: string,
  fieldsToUpdate: Record<string, unknown>,
): Promise<APIResponse> => {
  const url = `${config.API_URL}/v2/admin/organisations/${id}`;
  return fetchAPI(stleaf(st, 'lo8pha'), url, {
    method: 'PUT',
    credentials: 'include',
    headers,
    body: JSON.stringify(fieldsToUpdate),
  });
};

export const updateDomain = (
  st: SpanTag,
  id: string,
  fieldsToUpdate: { domain: string | null },
): Promise<APIResponse> => {
  const url = `${config.API_URL}/v2/admin/organisations/${id}/domain`;
  return fetchAPI(stleaf(st, 'ohh3ah'), url, {
    method: 'PUT',
    credentials: 'include',
    headers,
    body: JSON.stringify(fieldsToUpdate),
  });
};

export const updateBranding = (
  st: SpanTag,
  id: string,
  params,
): Promise<APIResponse> => {
  const url = `${config.API_URL}/v2/admin/organisations/${id}/branding`;
  return fetchAPI(stleaf(st, 'xoo5ti'), url, {
    method: 'PUT',
    credentials: 'include',
    headers,
    body: JSON.stringify(params),
  });
};

export const addUser = (
  st: SpanTag,
  id: string,
  params: {
    fname: string;
    lname: string;
    email: string;
    organisationId?: string;
    roleId?: string;
    sendInvitation?: boolean;
    position: string;
    phone: string;
    location: any;
  },
) => {
  const url = `${config.API_URL}/v2/admin/organisations/${id}/invite-user`;
  return fetchAPIJSON(stleaf(st, 'mai4do'), url, {
    method: 'POST',
    credentials: 'include',
    headers,
    body: JSON.stringify(params),
  });
};

export const getUserCount = (st: SpanTag, id: string): Promise<APIResponse> => {
  const url = `${config.API_URL}/v2/admin/organisations/${id}/user-count`;
  return fetchAPI(stleaf(st, 'eloo4u'), url, {
    method: 'GET',
    credentials: 'include',
    headers,
  });
};
