//
export default function errorReducer(error, path) {
  if (!error) return [];
  if (error.message === 'Network Error') {
    return [
      {
        code: 'network',
        message:
          "We couldn't connect to Birdi servers. Are you connected to the internet?",
      },
    ];
  }
  if (error.name === 'APIError') {
    // Handle multiple errors
    if (Array.isArray(error.body)) {
      return error.body.filter((err) => err.path === path);
    }
    // Handle single errors
    if (path === 'meta') {
      return [
        {
          code: error.body.code,
          message: error.body.message,
        },
      ];
    }
  }
  // TODO: Handle generic errors
  if (path === 'meta') return [{ code: 'generic' }];
  return [];
}
