import filesize from 'file-size';

/**
 * Pretty name
 * @param {*} fname
 * @param {*} lname
 */
export function formatName(fname: string, lname: string): string {
  if (fname && lname) return `${fname} ${lname}`;
  if (fname && !lname) return `${fname}`;
  if (!fname && lname) return `${lname}`;
  return '';
}

/**
 * Human readable file size
 * @param {int} fileSize (bytes)
 */
export function formatSize(fileSize) {
  return Number(fileSize) >= 1073741824
    ? `${filesize(Number(fileSize), { fixed: 2 }).to('GB')} GB`
    : `${filesize(Number(fileSize), { fixed: 2 }).to('MB')} MB`;
}

/**
 * To Title case
 * TODO: throw error on non-strings
 * @param {string} string
 */
export function toTitleCase(string) {
  if (!string) return '';
  if (string.length === 1) string.charAt(0).toUpperCase();
  return string.charAt(0).toUpperCase() + string.substr(1).toLowerCase();
}

/**
 * Region text
 * DEPRECATED in favor of normalise locations!
 * @param {object} Mission or User
 */
export function regionText(obj) {
  return ['suburb', 'state', 'country']
    .filter((key) => obj[key])
    .map((key) => obj[key])
    .join(' ');
}

/**
 * Region text
 * INTERIM (Save full label for user & mission!)
 * @param {object} Mission or User
 */
export function normalisedRegionText(obj) {
  console.log('obj: ', obj);

  const result = ['suburb', 'state', 'country']
    .filter((key) => obj[key])
    .map((key) => obj[key])
    .join(' ');
  console.log('result: ', result);

  return result;

  // return ['suburb', 'state', 'country']
  //   .filter((key) => obj[key])
  //   .map((key) => obj[key]).join(' ');
}

/**
 * Region text (incl. street address)
 * @param {object} Mission or User
 */
export function regionTextFull(obj) {
  return ['streetAddress', 'suburb', 'state', 'country']
    .filter((key) => obj[key])
    .map((key) => obj[key])
    .join(' ');
}

export const toSnakeCase = (str = '') => {
  if (typeof str !== 'string') throw new TypeError('Argument is not a string');
  const strArr = str.split(' ');
  const snakeArr = strArr.reduce((acc, val) => {
    return acc.concat(val.toLowerCase());
  }, []);
  return snakeArr.join('_');
};

/**
 * A simple util that takes a string, removes / replaces anything nasty and returns.
 * Happy for someone to conjure the equiv pure regex for this.
 */
export const sanitiseString = (str: string): string => {
  if (!str || typeof str !== 'string')
    throw new Error(`Expected string, got: ${str}`);
  // generically deal with all other badies keeping - and _
  const strArr = str.replace(/\(/g, '-').split('');
  const sanitisedString = strArr
    .filter((c) => c.search(/^[A-Za-z0-9_-]+/) >= 0)
    .join('');
  return sanitisedString;
};
