import React from 'react';
import { css } from '@emotion/react';
import { borderRadius } from '@birdi/theme/variables';
import UploadSVG from '@birdi/icons/upload.svg';
import ClearSVG from '@birdi/icons/round-clear-24px.svg';

const uploadField = (theme) => css`
  display: flex;
  align-items: center;
  border-radius: ${borderRadius};
  border: solid 1px ${theme.mono30};
  box-sizing: border-box;
  padding: 1rem;
  margin: 0.2rem 0 0.5rem;
  width: 100%;
  height: 50px;
`;

export default ({ file, fileInputEl, clearField }) => (
  <div>
    {!file && (
      <button
        type="button"
        onClick={() => fileInputEl.current.click()}
        css={(theme) => css`
          ${uploadField(theme)}
          justify-content: center;
          color: ${theme.mono50};
        `}
      >
        <UploadSVG />
        <span
          css={css`
            margin-left: 0.5em;
          `}
        >
          Upload associated document
        </span>
      </button>
    )}
    {file && (
      <div
        css={(theme) => css`
          ${uploadField(theme)}
          justify-content: space-between;
          align-items: center;
        `}
      >
        <span
          css={css`
            text-overflow: ellipsis;
            max-width: 85%;
            overflow-x: hidden;
          `}
        >
          {file}
        </span>
        <button
          type="button"
          onClick={() => {
            clearField();
            fileInputEl.current.value = null;
          }}
          css={css`
            padding: 0;
            float: right;
          `}
        >
          <ClearSVG
            css={(theme) => css`
              fill: ${theme.mono50};
              width: 1.2rem;
              height: 1.2rem;
              transform: translate(0, 2px);
              &:hover {
                fill: ${theme.mono50};
              }
            `}
          />
        </button>
      </div>
    )}
  </div>
);
