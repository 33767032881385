import React, { useEffect, useState } from 'react';
import { css } from '@emotion/react';
import { pageDescription } from '@birdi/theme/blocks';
import { BirdiTheme } from '@birdi/theme';
import {
  stroot,
  jobRequests as jobRequestsAPI,
} from '@birdi/js-sdk/src/public';
import { JobRequestsListRouted } from './JobRequestsList';

export const IncomingJobRequests = () => {
  const [jobRequests, setJobRequests] = useState([]);

  useEffect(() => {
    jobRequestsAPI
      .getPilotJobRequests(stroot('hae8ku'))
      .then((res) => setJobRequests(res.body.items))
      .catch((err) => console.log(err));
  }, []);
  return (
    <div>
      <h2
        css={css`
          margin: 1em;
        `}
      >
        Capture Requests
      </h2>
      <div css={pageDescription}>
        <p>These are maps managed by Birdi&rsquo;s Pilot network.</p>
      </div>
      <div
        css={css`
          box-sizing: border-box;
          padding: 1rem 2rem;
          width: 100%;
        `}
      >
        {jobRequests.length !== 0 && (
          <p>{`${jobRequests.length} capture ${jobRequests.length === 1 ? 'request' : 'requests'}`}</p>
        )}
        {!jobRequests.length && (
          <div
            css={(theme: BirdiTheme) => css`
              margin: 0;
              text-align: left;
              color: ${theme.mono50};
            `}
          >
            We don&rsquo;t have any capture requests for you at the moment.
            <br />
            When we find a paid map for you, you&rsquo;ll get an email and it
            will show up here.
          </div>
        )}
        <JobRequestsListRouted jobRequests={jobRequests} />
      </div>
    </div>
  );
};
