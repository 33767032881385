import { SeverityIcon } from '@birdi/assessment-kit/SeverityIcon';
import BookOpenSVG from '@birdi/icons/book-open.svg';
import CloseSVG from '@birdi/icons/close.svg';
import { buttonBlue, buttonTransparent, forms, BirdiTheme } from '@birdi/theme';
import { css, useTheme } from '@emotion/react';
import {
  pageTitleHolder,
  pageTitle,
  pageGraySection,
} from '@birdi/theme/blocks';
import React, { useEffect, useState } from 'react';
import { Prompt } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { useList } from 'react-use';
import {
  organisationSeverityScales as severityScalesAPI,
  stroot,
} from '@birdi/js-sdk';
import { LoadingSpinner } from '@birdi/loading';
import toast from 'react-hot-toast';
import { BackBar } from '../common/HeaderV2/BackBar';

interface TutorialItemProps {
  icon: string;
  label: string;
  href: string;
}

const TutorialItem = ({ icon: Icon, label, href }: TutorialItemProps) => (
  <a
    href={href}
    css={(theme) => css`
      display: flex;
      align-items: center;
      gap: 10px;
      color: ${theme.deepOcean};
      text-decoration: underline;
    `}
    target="_blank"
    rel="noreferrer"
  >
    <Icon width="20px" height="20px" />
    <span>{label}</span>
  </a>
);

type Severity = severityScalesAPI.SeverityScaleInput;

interface SeverityTableProps {
  cantSave: boolean;
  severities: Severity[];
  remove(i: number): void;
  update(i: number, value: Severity): void;
  push(value: Severity): void;
  save(): void;
}

const SeverityTable = ({
  cantSave,
  severities,
  remove,
  update,
  push,
  save,
}: SeverityTableProps) => {
  const theme = useTheme();

  return (
    <section
      css={css`
        display: flex;
        flex-direction: column;
        padding: 1em 2rem;
        max-width: 800px;
        width: 100%;
        gap: 10px;

        &,
        & * {
          box-sizing: border-box;
        }
      `}
    >
      <table
        css={css`
          width: 100%;
          table-layout: fixed;
          /* border-spacing: 2rem 1rem;
          border-collapse: separate; */

          & td,
          & th {
            border: solid 0px transparent;
          }

          & th {
            border-bottom-width: 1rem;
          }

          & tr > td + td,
          & tr > th + th {
            border-left-width: 1rem;
          }

          & tr + tr > td {
            border-top-width: 0.5rem;
          }
        `}
      >
        <colgroup>
          <col
            css={css`
              width: 100px;
            `}
          />
          <col
            css={css`
              width: 100%;
            `}
          />
          <col
            css={css`
              width: 220px;
            `}
          />
        </colgroup>
        <thead>
          <tr>
            <th
              scope="col"
              css={css`
                text-align: left;
              `}
            >
              Rating
            </th>
            <th
              scope="col"
              css={css`
                text-align: left;
              `}
            >
              Severity Label
            </th>
            <th
              scope="col"
              css={css`
                text-align: left;
              `}
            >
              Colour
            </th>
          </tr>
        </thead>
        <tbody>
          {severities.map((s, i) => (
            // eslint-disable-next-line
            <tr key={i}>
              <td>
                <div
                  css={css`
                    display: flex;
                    align-items: center;
                    gap: 1rem;
                  `}
                >
                  <button
                    css={css`
                      padding: 0;
                      &:disabled {
                        opacity: 0.2;
                        cursor: not-allowed;
                      }
                    `}
                    type="button"
                    onClick={() => remove(i)}
                    disabled={severities.length === 1}
                  >
                    <CloseSVG />
                  </button>
                  <SeverityIcon
                    css={css`
                      flex: 1;
                    `}
                    severity={i + 1}
                    colour={s.colour}
                  />
                </div>
              </td>
              <td>
                <input
                  css={css`
                    ${forms.textInput(theme)};
                    margin: 0;
                  `}
                  value={s.label}
                  onChange={(e) => update(i, { ...s, label: e.target.value })}
                />
              </td>
              <td>
                {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                <label
                  css={css`
                    ${forms.textInput(theme)};
                    position: relative;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    background: #fff;
                    margin: 0;
                    cursor: pointer;
                  `}
                >
                  <input
                    css={css`
                      position: absolute;
                      bottom: 0;
                      left: 0;
                      opacity: 0;
                      width: 1px;
                      height: 1px;
                    `}
                    type="color"
                    value={s.colour}
                    onChange={(e) =>
                      update(i, { ...s, colour: e.target.value })
                    }
                  />
                  {s.colour.toUpperCase()}

                  <div
                    css={css`
                      position: absolute;
                      width: 32px;
                      height: 32px;
                      border-radius: 100%;
                      background: ${s.colour};
                      right: 8px;
                    `}
                  />
                </label>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <button
        css={css`
          ${buttonTransparent(theme)};
          color: ${theme.deepOcean};
          align-self: flex-end;
        `}
        type="button"
        onClick={() =>
          push({
            colour: '#38385C',
            label: `New Severity ${severities.length + 1}`,
          })
        }
      >
        + Add another category
      </button>
      <button
        css={css`
          ${buttonBlue(theme)};
          width: 120px;
          align-self: flex-end;
        `}
        type="submit"
        disabled={cantSave}
        onClick={save}
      >
        Save
      </button>
    </section>
  );
};

export const AssessmentSeverity = () => {
  const theme = useTheme();

  const [dirty, setDirty] = useState(false);
  const [severities, { updateAt: update, push, removeAt, set: setSeverities }] =
    useList<Severity>([]);

  const remove = (i: number) => {
    removeAt(i);
    setDirty(true);
  };

  // All labels need to be filled
  const cantSave = severities.some((s) => !s.label);
  const save = async () => {
    try {
      await severityScalesAPI.update(stroot('wag2iz'), severities);
      toast.success('Severities updated!', {
        duration: 3000,
        position: 'bottom-right',
      });
      setDirty(false);
    } catch (err) {
      toast.error('Failed to update severities.', {
        duration: 3000,
        position: 'bottom-right',
      });
      throw err;
    }
  };

  // Used to prevent the user reloading or closing the page if they haven't
  // saved changes. Unfortunately does not handle going back with buttons due to
  // React Router, but that gets handled by the `Prompt` component below.
  useEffect(() => {
    if (dirty) window.onbeforeunload = () => true;
    else window.onbeforeunload = null;

    return () => {
      window.onbeforeunload = null;
    };
  }, [dirty]);

  useEffect(() => {
    severityScalesAPI
      .getAll(stroot('izi9no'))
      .then((res) => {
        if (res.body) {
          const options = res.body.severities.map((s) => ({
            colour: s.colour,
            label: s.label,
          }));
          setSeverities(options);
        }
      })
      .catch(console.error);
  }, []);

  return (
    <div>
      <div css={pageTitleHolder}>
        <h2 css={pageTitle}>Severity Scale</h2>
      </div>
      <div css={pageGraySection}>
        <p>Create your own custom severity scale to use during assessments.</p>
      </div>
      <Prompt
        when={dirty}
        message="You have unsaved changes, are you sure you want to leave?"
      />

      {severities.length === 0 ? (
        <div
          css={css`
            padding: 1.5rem;
          `}
        >
          <LoadingSpinner color={theme.deepOcean} size="1.5em" />
        </div>
      ) : (
        <SeverityTable
          {...{
            cantSave,
            severities,
            remove,
            update,
            push,
            save,
          }}
        />
      )}
    </div>
  );
};
