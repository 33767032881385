import { onboarding, stroot } from '@birdi/js-sdk';

export const FETCHING = 'onboarding/FETCHING';
export const UPDATE_FIELD = 'onboarding/UPDATE_FIELD';
export const FETCH = 'onboarding/FETCH';
export const FETCH_ERROR = 'onboarding/FETCH_ERROR';

interface OnboardingState {
  team: string;
  industry: string;
  hearAboutUs: string;
  reference: string;
  // Local UI State
  loaded: boolean;
}

export const initialState: OnboardingState = {
  team: '',
  industry: '',
  hearAboutUs: '',
  reference: '',
  // Local UI State
  loaded: false,
};

// Note: Answers are initially retrieved from action in "meta" reducer

export const updateAnswers = (field) => (dispatch) => {
  // Update profile state
  dispatch({ type: UPDATE_FIELD, payload: field });
  return onboarding
    .updateAnswers(stroot('g34zeu'), field)
    .catch((err) => console.log(err)); // TODO: Log application area
};

export default (
  state = initialState,
  { type, payload }: { type: string; payload: Partial<OnboardingState> },
): OnboardingState => {
  switch (type) {
    case FETCHING:
      return { ...initialState, loaded: false };
    case FETCH:
      return { ...state, ...payload, loaded: true };
    case FETCH_ERROR:
      return { ...state, ...payload, loaded: false };
    case UPDATE_FIELD:
      return { ...state, ...payload, loaded: true };
    default:
      return state;
  }
};
