import { withRouter } from 'react-router-dom';
import cx from 'classnames';
import { css } from '@emotion/react';
import { PoweredByBirdi } from '@birdi/branding/powered-by-birdi';
import {
  SideBar,
  SidebarLink,
  dividerStyle,
  poweredByBirdiStyle,
  BackButton,
} from './side-bar-utils';
import { useMetaSelector } from '../../meta/reducer';
import GeneralSVG from '@birdi/icons/menu-general.svg';
import CustomizationSVG from '@birdi/icons/menu-customization.svg';
import UsersSVG from '@birdi/icons/menu-users.svg';
import ProcessingSVG from '@birdi/icons/menu-processing.svg';
import PermissionsSVG from '@birdi/icons/menu-permissions.svg';
import TagsSVG from '@birdi/icons/menu-tags.svg';

const SideNavigationWorkspace = withRouter(({ history }) => {
  const meta = useMetaSelector();
  return (
    <div
      css={css`
        position: relative;
      `}
    >
      <SideBar>
        <BackButton label="Workspace Settings" id="workspace-settings" />
        <hr css={dividerStyle} />
        <SidebarLink to="/workspace-general" match={history.location.pathname}>
          <GeneralSVG />
          <span>General</span>
        </SidebarLink>
        <SidebarLink to="/workspace-general" match={history.location.pathname}>
          <CustomizationSVG />
          <span>Customisation</span>
        </SidebarLink>
        <SidebarLink to="/workspace-general" match={history.location.pathname}>
          <UsersSVG />
          <span>Users</span>
        </SidebarLink>
        <SidebarLink to="/workspace-general" match={history.location.pathname}>
          <ProcessingSVG />
          <span>Processing</span>
        </SidebarLink>
        <SidebarLink to="/workspace-general" match={history.location.pathname}>
          <PermissionsSVG />
          <span>Permissions</span>
        </SidebarLink>
        <SidebarLink to="/workspace-general" match={history.location.pathname}>
          <TagsSVG />
          <span>Data Tags</span>
        </SidebarLink>
      </SideBar>
      <div css={poweredByBirdiStyle}>
        <PoweredByBirdi mode="dark" />
      </div>
    </div>
  );
});

export default SideNavigationWorkspace;
