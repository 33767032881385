import { css } from '@emotion/react';
import { cardList } from '@birdi/theme/blocks';
import { type maps } from '@birdi/js-sdk';
import { BirdiTheme, breakpoints } from '@birdi/theme';
import { ContextMenu, contextStyles } from '@birdi/context-menu/ContextMenu';
import { deleteMap } from '@birdi/js-sdk/src/public/maps';
import { APIResponse } from '@birdi/js-sdk/src/helpers';
import { useState } from 'react';
import MapRename from './MapRenameForm';
import { MapShare } from '@birdi/map-share';

export interface MissionListProps {
  maps: maps.Map[];
}

export const NewMapList = ({ maps }: MissionListProps) => {
  return (
    <div css={cardList}>
      {maps.map((map, index) => (
        <MapCard key={index} map={map} />
      ))}
    </div>
  );
};

type MapCardProps = {
  map: maps.Map;
};
const MapCard: React.FC<MapCardProps> = ({ map }) => {

  const handleDelete = () => {
    if (window.confirm('Are you sure you want to delete this map?')) {
      if (map.id) {
        console.log('deleting map: ', map.id);
        deleteMap({ tag: 'k1665s' }, map.id).then((response: APIResponse<void>) => {
          if (response.status === 200) {
            window.location.reload();
          } else {
            window.alert('Cannot delete map.');
          }
        });
      } else {
        console.log('delete failed');
      }
    }
  }

  const handleShareButtonClick = () => {
    setShareModalOpen(true);
    //window.alert('need to implement');

  }

  const [renameModalOpen, setRenameModalOpen] = useState(false);
  const [shareModalOpen, setShareModalOpen] = useState(false);

  const menuItems = [
    {
      label: 'Rename',
      onClick: () => setRenameModalOpen(true),
    },
    {
      label: 'Share',
      onClick: handleShareButtonClick,
    },
    {
      label: 'Delete',
      onClick: handleDelete,
    }
  ]

  return <div
    css={map2CardStyle}
    onClick={() => {
      if (!renameModalOpen && !shareModalOpen) { window.location.href = `/map/next/${map.id}` };
    }}>

    <MapRename open={renameModalOpen} onChange={setRenameModalOpen} initialName={map.name} mapId={map.id}></MapRename>
    <MapShare open={shareModalOpen} onChange={setShareModalOpen} mapId={map.id}></MapShare>
    <div
      className="card-cover"
      css={css`
              position: relative;
              height: 12.5rem;
              background-color: #f5f5f5;
              background-size: cover;
              background-position: center;
              text-align: center;
              border-radius: 2px;
              background-image: url('${map.mapCover}');
            `}
    >
    </div>
    <div
      className="card-body"
      css={css`
                position: relative;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                padding-bottom: 0.5em;
              `}
    >
      <div css={css`display: flex; flex-direction: row; justify-content: space-between; align-items: flex-start;`}>
        <div css={css`display: flex; flex-direction: column; font-size: 0.9rem; position: relative; margin: 1em; width: 70%;`}>
          <h3
            data-testid="name"
            css={css`
                text-transform: capitalize;
                margin: 0;
                margin-bottom: 0.5em;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                width: calc(100% - 2em);
              `}
          >
            {map.name || 'Untitled Map'}
          </h3>
          <span><strong>Location:</strong> {map.suburb ?? "Unknown"}</span>
          <span><strong>Date Created:</strong> {new Date(map.createdAt).toLocaleDateString('en-US', {
            day: 'numeric',
            month: 'long',
            year: 'numeric'
          })}</span>
          <span><strong>Map Creator:</strong> {map.organisationName}</span>
          {/* <span>Workspace: {map.workspaceId}</span> */}
        </div>
        <div css={css`position: relative; margin-right: 0.1em; margin-top: 0.1em;`} onClick={(event: any) => {
          // Prevent the click event from bubbling up to a click event on the card as a whole
          event.preventDefault();
          event.stopPropagation();
        }}>
          <ContextMenu alignRight>
            {() => (
              <div css={theme => css(contextStyles(theme))}>
                {menuItems.map((item, index) => (
                  <button
                    key={index}
                    css={css`display: flex;`}
                    type="button"
                    onClick={item.onClick}
                  >
                    <span>{item.label}</span>
                  </button>
                ))}
              </div>
            )}
          </ContextMenu>
        </div>
      </div>

      <div
        css={css`
                    width: 100%;
                    display: flex;
                    justify-content: flex-end;
                  `}>
        <div
          css={css`
                      width: 100px;
                      background-color: #EFFBF5;
                      border-radius: 8px;
                      padding: 3px;
                      margin-right: 0.5em;
                    `}>
          <p
            css={css`
                        margin: 0;
                      `}
          >Birdi map 2.0</p>
        </div>
      </div>

    </div>
  </div>


}

const map2CardStyle = (theme: BirdiTheme) => css`
  display: inline-block;
  box-sizing: border-box;
  border-radius: 2px;
  box-shadow: 3px 3px 10px ${theme.mono30};
  border: solid 1px ${theme.mono20};
  transition:
    0.15s shadow,
    0.15s transform;
  cursor: pointer;
  width: 100%;
  margin: 0 0.1rem 1rem 0;
  @media (min-width: ${breakpoints.xs}) {
    width: calc((100% / 2) - 1rem);
    margin: 0 1rem 1rem 0;
  }
  @media (min-width: ${breakpoints.s}) {
    width: calc((100% / 3) - 1rem);
  }
  &:focus,
  & > span:focus {
    outline: 0;
  }
  &:focus > span {
    outline: -webkit-focus-ring-color auto 5px;
  }
`;
