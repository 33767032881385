/* global fetch */

import { config } from '../config';
import { headers, APIResponse, stleaf, fetchAPI, SpanTag } from '../helpers';

export enum FeatureFlagNames {
  VolumetricRequest = 'volumetric-request',
  PowercorInspectionDashboard = 'powercor-inspection-dashboard',
  ManageUsers = 'manage-users',
  ThreeDViewer = '3d-viewer',
  CustomBranding = 'custom-branding',
  MultipleWorkspaces = 'multiple-workspaces',
  GigapixelCalculator = 'gigapixel-calculator',
  Sites = 'sites',
  ReferenceSets = 'reference-sets',
  AnnotationReports = 'annotation-reports',
  MapsV2 = 'maps-v2',
}

export const getByName = (
  st: SpanTag,
  featureName: FeatureFlagNames,
): Promise<APIResponse> => {
  const url = `${config.API_URL}/v1/features/organisation/${featureName}`;
  return fetchAPI(stleaf(st, 'sha8ir'), url, {
    method: 'GET',
    credentials: 'include',
    headers,
  });
};

export const getAll = (st: SpanTag): Promise<APIResponse> => {
  const url = `${config.API_URL}/v1/features/organisation`;

  return fetchAPI(stleaf(st, 'oot1fi'), url, {
    method: 'GET',
    credentials: 'include',
    headers: headers,
  });
};
