import React from 'react';
import { css } from '@emotion/react';
import { Link } from 'react-router-dom';
import { contextualHeader } from '@birdi/theme/blocks';
import LogoSVG from '@birdi/icons/birdi-logo-navy.svg';
import { BirdiTheme } from '@birdi/theme';

export const EquipmentHeader = ({ children }) => (
  <header css={(theme: BirdiTheme) => css(contextualHeader(theme))}>
    <Link
      to="/"
      css={css`
        padding: 0;
        margin-right: auto;
      `}
    >
      <LogoSVG
        css={css`
          width: 3em;
          height: 3em;
        `}
      />
    </Link>
    <div
      css={css`
        cursor: pointer;
        display: flex;
        & > * {
          margin-left: 0.5rem;
        }
      `}
    >
      {children}
    </div>
  </header>
);
