import { useEffect, useState } from 'react';
import { breakpoints } from '@birdi/theme/variables';

/**
 *
 * @param widthPixels
 * @param max
 */
export function useMatchWidth(widthCSS: string = breakpoints.xs) {
  const [matches, setMatches] = useState(false);
  function mqChange(mq: MediaQueryListEvent | MediaQueryList) {
    setMatches(mq.matches);
  }
  useEffect(() => {
    const mq = window.matchMedia(`screen and (max-width: ${widthCSS})`);
    mq.addEventListener('change', mqChange);
    mqChange(mq);
    return () => mq.removeEventListener('change', mqChange);
  }, []);
  return matches;
}
