/* global */
import React, { useState } from 'react';
import { css, useTheme } from '@emotion/react';
import { Link, LinkProps, useHistory } from 'react-router-dom';
import cx from 'classnames';
import { CSSTransition } from 'react-transition-group';
import { SignOutButton } from '@birdi/main-navigation/SignOut';
import { MobileMenu as MobileMenuBase } from '@birdi/main-navigation/MobileMenu';
import { BirdiTheme } from '@birdi/theme';
import BirdiIcon, { Icons } from '@birdi/icons';
import ExternalLinkSVG from '@birdi/icons/external-link.svg';
import UpgradeSVG from '@birdi/icons/upgrade.svg';
import { overlayMenuContainerCSS } from '@birdi/theme/blocks';
import { hasPermission } from '@birdi/utils/src/helpers';
import { FeatureFlagNames } from '@birdi/js-sdk/src/public/organisation-features';
import { useMetaSelector } from '../../meta/reducer';
import { NewMapBadge } from '@birdi/components-common';

type MenuItemLinkProps = LinkProps & {
  match: string;
  missions?: boolean;
  hide: () => void;
};

const MenuItemLink: React.FC<MenuItemLinkProps> = ({
  to,
  match,
  children,
  missions = false,
  className = '',
  hide,
  id = null,
}) => {
  const otherProps: Partial<LinkProps> = {};
  if (id) otherProps.id = id;
  return (
    <Link
      className={cx(className, { selected: to === match && !missions })}
      to={to}
      onClick={() => {
        hide();
      }}
      {...otherProps}
    >
      {children}
    </Link>
  );
};
MenuItemLink.defaultProps = { missions: false };

const lineStyle = (theme: BirdiTheme) => css`
  border: none;
  border-top: 1px solid ${theme.mono20};
`;

export const MobileMenu = () => {
  const meta = useMetaSelector();
  const history = useHistory();
  const appTheme = useTheme();
  const [expandedMenuItem, setExpandedMenuItem] = useState({
    missions: false,
    pilotNetwork: false,
    sites: false,
    help: false,
  });
  const manageUsers =
    meta.organisation && hasPermission(meta.permissions, 'org:manage-users');
  const inspection =
    meta.orgFeatureFlags.includes(
      FeatureFlagNames.PowercorInspectionDashboard,
    ) && meta.permissions.includes('org:manage-missions');
  const costCalculator = meta.orgFeatureFlags.includes(
    FeatureFlagNames.GigapixelCalculator,
  );
  const customBranding = meta.orgFeatureFlags.includes(
    FeatureFlagNames.CustomBranding,
  );
  const canViewProcessing = hasPermission(
    meta.permissions,
    'org:manage-missions',
  );
  const sites = meta.orgFeatureFlags.includes(FeatureFlagNames.Sites);
  const upgradeDisabled =
    meta.organisation.externalBilling ||
    !meta.permissions.includes('org:manage-users');
  const manageOrgOrOps =
    meta.permissions.includes('org:manage-org') ||
    meta.opsActions.includes('access_ops');
  const mapsV2 = meta.orgFeatureFlags.includes(FeatureFlagNames.MapsV2);

  const isAdmin = meta.permissions.includes('org:manage-users'); // (meta.scopeRole === 'Admin' || meta.scopeRole === 'Creator' || meta.scopeRole === 'Operations');

  return (
    <MobileMenuBase>
      {({ hide }) => (
        <div
          css={(theme: BirdiTheme) => css`
            .item {
              display: flex;
              width: 100%;
              color: ${theme.displayFont};
              padding: 0.5em;
              font-size: 0.9375em;
              align-items: center;
              gap: 0.5rem;
              &.selected {
                color: ${theme.deepOcean};
              }
              &.main {
                font-size: 1em;
                font-weight: 700;
              }
              &.link-disabled {
                pointer-events: none;
                color: ${theme.mono40};
              }
              .icon-chevron {
                margin-left: auto;
              }
            }
          `}
        >
          <MenuItemLink
            to="/"
            match={history.location.pathname}
            hide={hide}
            className={cx('item', 'main')}
          >
            <span>Dashboard</span>
          </MenuItemLink>
          <hr className="divider" />
          <button
            className={cx('item', 'main')}
            type="button"
            onClick={() =>
              setExpandedMenuItem((prev) => ({
                ...prev,
                missions: !prev.missions,
              }))
            }
            css={css`
              padding: 0;
            `}
          >
            <span>Maps</span>
            <BirdiIcon
              icon={Icons.Chevron}
              rotate={expandedMenuItem.missions ? '180' : '90'}
            />
          </button>
          <div
            css={css`
              ${overlayMenuContainerCSS}
            `}
          >
            <CSSTransition
              in={expandedMenuItem.missions}
              timeout={150}
              classNames="drop-menu"
              unmountOnExit
            >
              <div>
                {mapsV2 && (
                  <MenuItemLink
                    hide={hide}
                    to="/new-maps/all"
                    match={history.location.pathname}
                    className={cx('item', 'sub')}
                  >
                    <span
                      css={css`
                        display: inline-flex;
                        align-items: center;
                        gap: 8px;
                      `}
                    >
                      Birdi 2.0 Maps
                      <NewMapBadge />
                    </span>
                  </MenuItemLink>
                )}
                {meta.permissions.includes('org:default') && (
                  <MenuItemLink
                    hide={hide}
                    to="/missions/mine"
                    match={history.location.pathname}
                    className={cx('item', 'sub')}
                  >
                    <span>My Maps</span>
                  </MenuItemLink>
                )}
                <MenuItemLink
                  hide={hide}
                  to="/missions/shared"
                  match={history.location.pathname}
                  className={cx('item', 'sub')}
                >
                  <span>Shared With Me</span>
                </MenuItemLink>
                <MenuItemLink
                  hide={hide}
                  to="/missions/organisation"
                  match={history.location.pathname}
                  className={cx('item', 'sub')}
                >
                  <span>All Maps</span>
                </MenuItemLink>
                <MenuItemLink
                  hide={hide}
                  to="/missions/demo-missions"
                  match={history.location.pathname}
                  className={cx('item', 'sub')}
                >
                  <span>Demo Maps</span>
                </MenuItemLink>
              </div>
            </CSSTransition>
          </div>
          <hr className="divider" />
          {meta.jobNotifications && (
            <>
              <button
                className={cx('item', 'main')}
                type="button"
                onClick={() =>
                  setExpandedMenuItem((prev) => ({
                    ...prev,
                    pilotNetwork: !prev.pilotNetwork,
                  }))
                }
                css={css`
                  padding: 0;
                `}
              >
                <span>Pilot Network</span>
                <BirdiIcon
                  icon={Icons.Chevron}
                  rotate={expandedMenuItem.pilotNetwork ? '180' : '90'}
                />
              </button>
              <div
                css={css`
                  ${overlayMenuContainerCSS}
                `}
              >
                <CSSTransition
                  in={expandedMenuItem.pilotNetwork}
                  timeout={150}
                  classNames="drop-menu"
                  unmountOnExit
                >
                  <div>
                    <MenuItemLink
                      hide={hide}
                      to="/job-requests/incoming"
                      match={history.location.pathname}
                      className={cx('item', 'sub')}
                    >
                      <span>Capture Requests</span>
                    </MenuItemLink>
                  </div>
                </CSSTransition>
              </div>
              <hr className="divider" />
            </>
          )}
          {sites && (
            <>
              <button
                className={cx('item', 'main')}
                type="button"
                onClick={() =>
                  setExpandedMenuItem((prev) => ({
                    ...prev,
                    sites: !prev.sites,
                  }))
                }
                css={css`
                  padding: 0;
                `}
              >
                <span>Sites</span>
                <BirdiIcon
                  icon={Icons.Chevron}
                  rotate={expandedMenuItem.sites ? '180' : '90'}
                />
              </button>
              <div
                css={css`
                  ${overlayMenuContainerCSS}
                `}
              >
                <CSSTransition
                  in={expandedMenuItem.sites}
                  timeout={150}
                  classNames="drop-menu"
                  unmountOnExit
                >
                  <div>
                    <MenuItemLink
                      hide={hide}
                      to="/sites"
                      match={history.location.pathname}
                      className={cx('item', 'sub')}
                    >
                      <span>All Sites</span>
                    </MenuItemLink>
                  </div>
                </CSSTransition>
              </div>
              <hr className="divider" />
            </>
          )}
          <MenuItemLink
            to={isAdmin ? '/admin-general' : '#'}
            match={history.location.pathname}
            hide={hide}
            className={cx('item', 'main', { 'link-disabled': !isAdmin })}
          >
            <span>Administration</span>
          </MenuItemLink>
          <MenuItemLink
            to="/admin-users"
            match={history.location.pathname}
            hide={hide}
            className={cx('item', 'main')}
          >
            <span>User Management</span>
          </MenuItemLink>
          <MenuItemLink
            to={`/profile-general/${meta.scopeId}`}
            match={history.location.pathname}
            hide={hide}
            className={cx('item', 'main')}
          >
            <span>My Profile</span>
          </MenuItemLink>
          <MenuItemLink
            to="/assessment-types"
            match={history.location.pathname}
            hide={hide}
            className={cx('item', 'main')}
          >
            <span>Inspection Tools</span>
          </MenuItemLink>
          <MenuItemLink
            to="/user/pilot"
            match={history.location.pathname}
            hide={hide}
            className={cx('item', 'main')}
          >
            <span>Pilot Settings</span>
          </MenuItemLink>
          <hr className="divider" />
          <button
            className={cx('item', 'main')}
            type="button"
            onClick={() =>
              setExpandedMenuItem((prev) => ({ ...prev, help: !prev.help }))
            }
            css={css`
              padding: 0;
            `}
          >
            <span>Help</span>
            <BirdiIcon
              icon={Icons.Chevron}
              rotate={expandedMenuItem.help ? '180' : '90'}
            />
          </button>
          <CSSTransition
            in={expandedMenuItem.help}
            timeout={150}
            classNames="drop-menu"
            unmountOnExit
          >
            <div>
              {costCalculator && (
                <MenuItemLink
                  hide={hide}
                  to="/cost-calculator"
                  match={history.location.pathname}
                  className={cx('item', 'sub')}
                >
                  <span>Processing Cost Calculator</span>
                </MenuItemLink>
              )}
              <Link
                to={{ pathname: 'https://help.birdi.io/' }}
                target="_blank"
                className={cx('item', 'sub')}
              >
                <span>Knowledge Base</span>
                <ExternalLinkSVG />
              </Link>
              <Link
                to={{ pathname: 'https://www.birdi.io/partner-program' }}
                target="_blank"
                className={cx('item', 'sub')}
              >
                <span>Partner Program</span>
                <ExternalLinkSVG />
              </Link>
              <Link
                to={{ pathname: 'https://www.birdi.io/terms-and-conditions' }}
                target="_blank"
                className={cx('item', 'sub')}
              >
                <span>Terms & Conditions</span>
                <ExternalLinkSVG />
              </Link>
            </div>
          </CSSTransition>
          <hr className="divider" />
          <SignOutButton />
          {!upgradeDisabled && (
            <button
              type="button"
              onClick={() => history.push('/billing/options')}
            >
              <UpgradeSVG
                css={css`
                  font-size: 2.5em;
                  vertical-align: middle;
                  margin-right: 0;
                `}
              />
              <span
                css={(theme: BirdiTheme) => css`
                  vertical-align: middle;
                  color: ${theme.deepOcean};
                  font-weight: bold;
                `}
              >
                Upgrade
              </span>
            </button>
          )}
        </div>
      )}
    </MobileMenuBase>
  );
};
