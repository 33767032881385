import React, { createContext, SetStateAction, useState } from 'react';
import { AssessmentTypeComponent } from './assessment-type-component';
import {
  NUMBER_OF_CATEGORIES,
  CategoryItem,
  useArray,
  AssessmentTypeOption,
  CategoryContextType,
} from './utils';

export const CategoryContext = createContext<CategoryContextType>({
  selectedAssessmentType: null,
  setSelectedAssessmentType: () => undefined,
  path: [],
  defaultPath: [],
  setPathFragment: () => undefined,
  setFullPath: () => undefined,
  selectItem: () => undefined,
  nameError: false,
  setNameError: () => undefined,
});

export const AssessmentType = () => {
  const [selectedAssessmentType, setSelectedAssessmentType] =
    useState<AssessmentTypeOption | null>(null);
  const defaultPath = Array<CategoryItem | {}>(NUMBER_OF_CATEGORIES).fill({});
  const [path, setPathFragment, setFullPath] = useArray(defaultPath);
  const [nameError, setNameError] = useState(false);
  const selectItem = (
    categoryIndex: number,
    categoryItem: CategoryItem,
  ): void => {
    setFullPath((prev) =>
      prev.map((item, index) => {
        if (index === categoryIndex) {
          return categoryItem;
        }
        if (index > categoryIndex) {
          return {};
        }
        return item;
      }),
    );
  };
  const value = {
    selectedAssessmentType,
    setSelectedAssessmentType,
    path,
    defaultPath,
    setPathFragment,
    setFullPath,
    selectItem,
    nameError,
    setNameError,
  };
  return (
    <CategoryContext.Provider value={value}>
      <AssessmentTypeComponent />
    </CategoryContext.Provider>
  );
};
