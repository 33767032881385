import { withRouter } from 'react-router-dom';
import { css } from '@emotion/react';
import { SideBar, SidebarLink, SideMenuHeader } from './side-bar-utils';
import { useMetaSelector } from '../../meta/reducer';
import { FeatureFlagNames } from '@birdi/js-sdk/src/public/organisation-features';
import GeneralSVG from '@birdi/icons/menu-general.svg';
import UsersSVG from '@birdi/icons/menu-users.svg';
import WorkspacesSVG from '@birdi/icons/menu-workspaces.svg';
import BillingSVG from '@birdi/icons/menu-billing.svg';
import PlanSVG from '@birdi/icons/menu-plan.svg';
import ProcessingSVG from '@birdi/icons/menu-processing.svg';
import PermissionsSVG from '@birdi/icons/menu-permissions.svg';
import SecuritySVG from '@birdi/icons/menu-security.svg';
import CustomizationSVG from '@birdi/icons/menu-customization.svg';
import BottomSection from './bottom-section';

const SideNavigationAdmin = withRouter(({ history }) => {
  const meta = useMetaSelector();
  const upgradeDisabled =
    meta.organisation.externalBilling ||
    meta.organisation.plan === 'enterprise';
  const customBranding = meta.orgFeatureFlags.includes(
    FeatureFlagNames.CustomBranding,
  );

  const isAdmin = meta.permissions.includes('org:manage-users'); // (meta.scopeRole === 'Admin' || meta.scopeRole === 'Creator' || meta.scopeRole === 'Operations');

  return (
    <div
      css={css`
        position: relative;
      `}
    >
      <SideBar>
        <SideMenuHeader label="Administration" id="administration" />
        <SidebarLink
          to="/admin-general"
          match={history.location.pathname}
          disabled={!isAdmin}
        >
          <GeneralSVG />
          <span>General</span>
        </SidebarLink>
        <SidebarLink to="/admin-users" match={history.location.pathname}>
          <UsersSVG />
          <span>Users</span>
        </SidebarLink>
        {/* <SidebarLink to="/admin-workspaces" match={history.location.pathname} disabled={!isAdmin}>
          <WorkspacesSVG />
          <span>Workspaces</span>
        </SidebarLink> */}
        <SidebarLink
          to="/admin-billing"
          match={history.location.pathname}
          disabled={!isAdmin}
        >
          <BillingSVG />
          <span>Billing</span>
        </SidebarLink>
        <SidebarLink
          to="/admin-plan"
          match={history.location.pathname}
          disabled={!isAdmin}
        >
          <PlanSVG />
          <span>Plan & usage</span>
        </SidebarLink>
        <SidebarLink
          to="/admin-processing"
          match={history.location.pathname}
          disabled={!isAdmin}
        >
          <ProcessingSVG />
          <span>Processing</span>
        </SidebarLink>
        {/* <SidebarLink to="/admin-permissions" match={history.location.pathname} disabled={!isAdmin}>
          <PermissionsSVG />
          <span>Permissions</span>
        </SidebarLink> */}
        {/* <SidebarLink to="/admin-security" match={history.location.pathname} disabled={!isAdmin}>
          <SecuritySVG />
          <span>Security</span>
        </SidebarLink> */}
        <SidebarLink
          to="/admin-customization"
          match={history.location.pathname}
          disabled={!isAdmin || !customBranding}
        >
          <CustomizationSVG />
          <span id="customization">Customization</span>
        </SidebarLink>
        <div
          css={css`
            height: 7em;
          `}
        />
      </SideBar>
      <BottomSection />
    </div>
  );
});

export default SideNavigationAdmin;
