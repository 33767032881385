import { stroot, subscription as subscriptionAPI } from '@birdi/js-sdk';
import React, { useEffect, useState } from 'react';
import { css, SerializedStyles, Theme } from '@emotion/react';
import { LoadingSpinner } from '@birdi/loading';
import { breakpoints } from '@birdi/theme/variables';
import { withRouter, Link } from 'react-router-dom';
import { format } from 'date-fns';
import DownloadSVG from '@birdi/icons/download.svg';
import EyeSVG from '@birdi/icons/eye.svg';
import { itemsTable } from '@birdi/theme/blocks';
import { BirdiTheme } from '@birdi/theme';
import { TabNavigation, SubscriptionTabs } from './tab-navigation';
import { UpcomingInvoiceModal } from './upcoming-invoice-modal';
import { CardBrand } from './card-brand';
import { useMetaSelector } from '../../meta/reducer';
import {
  pageTitleHolder,
  pageTitle,
  pageGraySection,
} from '@birdi/theme/blocks';

const capitaliseFirst = (str: string) =>
  `${str[0].toUpperCase()}${str.slice(1)}`;

interface Invoice {
  id: string;
  status_transitions: { paid_at: number };
  invoice_pdf: string;
  amount_paid: number;
  currency: string;
  status: string;
  payment_method: {
    type: string;
    card: {
      brand: string;
      exp_month: number;
      exp_year: number;
      last4: string;
    };
  };
  lines: { data: InvoiceItem[] };
}

export interface InvoiceItem {
  id: string;
  object: 'line_item';
  amount: number;
  amount_excluding_tax: number;
  currency: string;
  description: string;
  discount_amounts: Array<{ amount: number; discount: string }>;
  discountable: boolean;
  invoice_item: string;
  metadata: Record<string, string>;
  period: { end: number; start: number };
  proration: boolean;
  quantity: number;
}

export interface UpcomingInvoice {
  // amount_due: number;
  period_end: number;
  period_start: number;
  currency: string;
  description: string;
  discount: null | {
    id: string;
    coupon: {
      id: string;
      amount_off: number | null;
      percent_off: number | null;
      currency: string | null;
      name: string;
    };
  };
  next_payment_attempt: number;
  status: string;
  // excluding_tax/tax fields?
  subtotal: number;
  total: number;
  total_discount_amounts: Array<{ amount: number; discount: string }>;
  lines: { data: InvoiceItem[] };
  total_tax_amounts?: { amount?: number }[];
}

const upcomingStyle = (theme: Theme) => css`
  background: ${theme.lightOcean};
  color: ${theme.ocean};
`;

const paidStyle = (theme: Theme) => css`
  background: ${theme.lightFlora};
  color: #009d91;
`;

const otherStyle = (theme: Theme) => css`
  background: ${theme.lightCrimsonSunbird};
  color: ${theme.crimsonSunbird};
`;

const InvoiceStatus = ({ status }: { status: string }) => {
  let style: (theme: Theme) => SerializedStyles;

  if (status === 'upcoming') style = upcomingStyle;
  else if (status === 'paid') style = paidStyle;
  else style = otherStyle;

  return (
    <span
      css={(theme) => css`
        display: inline-block;
        padding: 0.5rem 1rem;
        border-radius: 4px;
        font-weight: 700;
        ${style(theme)}
      `}
    >
      {`${status[0].toUpperCase()}${status.slice(1)}`}
    </span>
  );
};

export const Invoices = withRouter((): React.ReactElement => {
  const meta = useMetaSelector();
  const [loading, setLoading] = useState(true);
  const [showUpcomingInvoice, setShowUpcomingInvoice] = useState(false);
  const [invoices, setInvoices] = useState<Invoice[]>([]);
  const [upcomingInvoice, setUpcomingInvoice] =
    useState<UpcomingInvoice | null>(null);

  const anyItems = !!invoices.length || !!upcomingInvoice;

  useEffect(() => {
    Promise.all([
      subscriptionAPI.getInvoices(stroot('ahchi4')),
      // Ignore errors from getting upcoming invoices
      subscriptionAPI
        .getUpcomingInvoice(stroot('leish7'))
        .catch(() => ({ body: null })),
    ])
      .then(([invoiceRes, upcomingRes]) => {
        setInvoices(invoiceRes.body.data);
        setUpcomingInvoice(upcomingRes.body);
      })
      .finally(() => setLoading(false));
  }, []);

  const visitStripePortal = async () => {
    const stripePortal = await subscriptionAPI.getStripePortal(
      stroot('abeen7'),
    );
    if (!stripePortal.body) return;
    window.location.href = stripePortal.body.data;
  };

  return (
    <div>
      <div css={pageTitleHolder}>
        <h2 css={pageTitle}>Billing</h2>
      </div>
      <div
        css={(theme) => css`
          ${pageGraySection(theme)};
          @media (max-width: ${breakpoints.xs}) {
            padding-bottom: 1em;
            p {
              margin-bottom: 0;
            }
          }
        `}
      >
        <p>View subscription and usage invoices.</p>
        {!meta.organisation.externalBilling &&
          meta.organisation.stripeCustomerId && (
            <button
              type="button"
              onClick={visitStripePortal}
              css={(theme) => css`
                padding: 0.5em;
                background-color: ${theme.lightOcean};
                border-radius: 5px;
                span {
                  color: ${theme.deepOcean};
                  text-decoration: underline;
                }
              `}
            >
              <span>Update payment details</span>
            </button>
          )}
      </div>
      <div
        css={css`
          margin: 1.5em;
        `}
      />

      <div
        css={(theme: BirdiTheme) => css`
          padding: 0 1em 2em 1em;
        `}
      >
        {loading ? (
          <div
            css={css`
              margin: 1rem 0;
            `}
          >
            <LoadingSpinner color="#2287FA" size="24px" />
          </div>
        ) : (
          <>
            {!anyItems && meta.organisation.externalBilling && (
              <div
                css={css`
                  margin: 1rem 0;
                `}
              >
                You are paying externally.
              </div>
            )}
            {!anyItems &&
              !meta.organisation.externalBilling &&
              meta.organisation.plan !== 'free' && (
                <div
                  css={css`
                    margin: 1rem 0;
                  `}
                >
                  You don&apos;t have any invoices to view just yet. When you
                  do, they&apos;ll appear here.
                </div>
              )}

            {(anyItems || meta.organisation.plan === 'free') && (
              <div
                css={(theme: BirdiTheme) => css`
                  flex-grow: 1;
                  border: solid 1px ${theme.mono20};
                  border-radius: 10px;
                  overflow-x: auto;
                  overflow-y: auto;
                `}
              >
                <table
                  css={(theme) => css`
                    ${itemsTable(theme)};
                    tr {
                      padding: 0 1em;
                      border-bottom: none;
                    }
                    th {
                      padding: 1em;
                    }
                    td {
                      padding: 0.5em 1em;
                    }
                  `}
                >
                  <thead>
                    <tr>
                      <th
                        css={css`
                          min-width: 9em;
                        `}
                      >
                        Date
                      </th>
                      <th>Status</th>
                      <th>Amount</th>
                      <th>Payment Method</th>
                    </tr>
                  </thead>
                  <tbody>
                    {!!upcomingInvoice && (
                      <tr>
                        <td>
                          {/* TODO: Find upcoming date? */}
                          <span
                            css={css`
                              margin-right: 1rem;
                            `}
                          >
                            {format(
                              upcomingInvoice.period_end * 1000,
                              'dd MMM yyyy',
                            )}
                          </span>
                          <button
                            type="button"
                            onClick={() => setShowUpcomingInvoice(true)}
                          >
                            <EyeSVG />
                          </button>
                        </td>
                        <td>
                          <InvoiceStatus status="upcoming" />
                        </td>
                        <td>
                          ${upcomingInvoice.total / 100}
                          &nbsp;
                          {upcomingInvoice.currency.toUpperCase()}
                        </td>
                        <td />
                      </tr>
                    )}

                    {invoices.map((invoice) => (
                      <tr key={invoice.id}>
                        <td>
                          <span
                            css={css`
                              margin-right: 1em;
                            `}
                          >
                            {format(
                              invoice.status_transitions.paid_at * 1000,
                              'dd MMM yyyy',
                            )}
                          </span>
                          <a
                            href={invoice.invoice_pdf}
                            css={(theme) => css`
                              color: ${theme.mono70};
                            `}
                          >
                            <DownloadSVG />
                          </a>
                        </td>
                        <td>
                          <InvoiceStatus status={invoice.status} />
                        </td>
                        <td>
                          <span>
                            ${invoice.amount_paid / 100}
                            &nbsp;
                            {invoice.currency.toUpperCase()}
                          </span>
                        </td>
                        <td>
                          {invoice.payment_method &&
                          invoice.payment_method.card ? (
                            <div
                              css={css`
                                display: flex;
                                align-items: center;
                                gap: 1rem;
                              `}
                            >
                              <CardBrand
                                brand={invoice.payment_method.card.brand}
                              />
                              <span>
                                {/* &lowast;&lowast;&lowast;&lowast; */}
                                **** {invoice.payment_method.card.last4}
                              </span>
                            </div>
                          ) : (
                            <span>-</span>
                          )}
                        </td>
                      </tr>
                    ))}

                    {!anyItems && (
                      <tr>
                        <td colSpan={4}>
                          <p>
                            Invoices will display here if you are on a paid
                            plan.
                          </p>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            )}
          </>
        )}
      </div>

      {!!upcomingInvoice && (
        <UpcomingInvoiceModal
          invoice={upcomingInvoice}
          open={showUpcomingInvoice}
          onChange={setShowUpcomingInvoice}
        />
      )}
    </div>
  );
});
