import React from 'react';
import { format, isBefore, startOfToday, parseISO, isToday } from 'date-fns';

const dateTypeMap = {
  FLY_BY: 'Fly by',
  FLY_ON: 'Fly on',
};

export const Date = ({ date: dateRaw, dateType }) => {
  if (!dateRaw) return 'No date scheduled';
  let date = dateRaw;
  if (typeof dateRaw === 'string') date = parseISO(dateRaw);
  let dateFormatted = format(date, 'dd MMM yyyy');
  if (isToday(date)) dateFormatted = `${format(date, 'dd MMM yyyy')} (Today)`;
  const hasDatePassed = isBefore(date, startOfToday());
  return (
    <span data-testid="date">
      {hasDatePassed ? 'Flown' : dateTypeMap[dateType] || 'Scheduled'}
      {` ${dateFormatted}`}
    </span>
  );
};
