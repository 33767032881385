import React, { useState } from 'react';
import { connect } from 'react-redux';
import { button } from '@birdi/theme/blocks';
import { withRouter } from 'react-router-dom';
import BirdiLogoSVG from '@birdi/icons/birdi-logo-navy.svg';
import { css } from '@emotion/react';
import { getVerificationToken, updateField } from './actions';
import VerifyYourEmail from './VerifyYourEmail';
import { BirdiTheme } from '@birdi/theme';

const Verification = ({ auth, _updateField, _getVerificationToken }) => {
  const [error, setError] = useState(null);
  const getVerificationToken = () => {
    _getVerificationToken(auth.email)
      .then(() => _updateField({ awaitingEmailVerification: true }))
      .catch((err) => {
        if (err?.body?.message === 'userAlreadyVerified') {
          setError('This email has already been verified. Please login.');
        } else {
          setError(
            'Server error, if you keep having issues please email enquiries@birdi.io',
          );
        }
      });
  };
  if (auth.awaitingEmailVerification) {
    return <VerifyYourEmail />;
  }
  return (
    <div>
      <div
        css={css`
          margin-bottom: 1rem;
          text-align: center;
        `}
      >
        <BirdiLogoSVG
          css={css`
            width: 7em;
            height: 5em;
          `}
        />
      </div>
      <p>
        Enter your email where we will send your email verification link to:
      </p>
      <form
        onSubmit={(event) => {
          event.preventDefault();
          getVerificationToken();
        }}
      >
        <label htmlFor="email">
          Email
          <input
            id="email"
            type="email"
            value={auth.email}
            onChange={(event) => _updateField({ email: event.target.value })}
            required
            css={css`
              width: 100%;
              padding: 0.5em;
            `}
          />
        </label>
        <button
          type="submit"
          css={(theme: BirdiTheme) => css`
            ${button(theme)};
            width: 100%;
            margin-top: 0.5rem;
            padding: 1.5rem;
          `}
        >
          Resend verification link
        </button>
        {error && (
          <div
            css={css`
              color: red;
              margin-top: 0.5em;
            `}
          >
            {error}
          </div>
        )}
      </form>
    </div>
  );
};

export default connect(
  (state) => ({
    auth: state.auth,
  }),
  (dispatch) => ({
    _getVerificationToken: (verificationToken) =>
      dispatch(getVerificationToken(verificationToken)),
    _updateField: (field) => dispatch(updateField(field)),
  }),
)(withRouter(Verification));
