import React, { useEffect, useState } from 'react';
import { QueryClient, QueryClientProvider, useQuery } from 'react-query';
import {
  stroot,
  organisationMissions as organisationMissionsAPI,
  maps as mapsApi,
} from '@birdi/js-sdk';
import { css, Theme } from '@emotion/react';
import { MissionList } from '../missions/List';
import { breakpoints } from '@birdi/theme';
import { NewMapList } from '../missions/new-map-list';
import { useMetaSelector } from '../meta/reducer';
import { FeatureFlagNames } from '@birdi/js-sdk/src/public/organisation-features';
import { NewMapBadge } from '@birdi/components-common';

const queryClient = new QueryClient();

const sectionHeaderCSS = (theme: Theme) => css`
  background-color: ${theme.sand};
  display: flex;
  //gap: 0.5rem;
  margin: -0.5em -1.25em 1em -1.25em;
  padding: 0.75em 1.25em;
  @media (min-width: ${breakpoints.xs}) {
    background-color: ${theme.mono0};
    margin: 0 0 1em 0;
    padding: 0;
  }
`;

const Missions = () => {
  // prev approach => all (org + demo)
  const fetchMissions = async () => {
    const res = await organisationMissionsAPI.getMissions(stroot('tee0fo'), {
      association: 'organisation',
      start: 1,
      limit: 6,
    });
    return res.body?.items || [];
  };

  // prev approach => mine + demo
  // const fetchMissions = async () => {
  //   const res1 = await missionAPI.list(stroot('oon5ge'), {
  //     start: 1,
  //     limit: 5,
  //   });
  //   let myMissions = res1.body?.items || [];
  //   if (myMissions.length < 5) {
  //     const res2 = await organisationMissionsAPI.getMissions(stroot('tee0fo'), {
  //       association: 'sample',
  //       start: 1,
  //       limit: 2,
  //     });
  //     const demoMissions = res2.body?.items || [];
  //     myMissions = [...myMissions, ...demoMissions].slice(0, 5);
  //   }
  //   return myMissions;
  // };

  const { isLoading, data } = useQuery('missionsData', fetchMissions);
  if (isLoading) return <div>Loading...</div>;

  return (data && data.length > 0) ?  
    <MissionList missions={data!} isDashboard /> : 
    <div
      css={css`
      margin-bottom: 1rem;
    `}
    >
      You currently do not have any Original Birdi Maps. Select{' '}
      <em
        css={css`
        font-style: normal;
        font-weight: 700;
      `}
      >
        Create
      </em>{' '}
      to get started.
    </div>;
};

type MapsProps = {
  organisationId: string;
};
const Maps = ({ organisationId }: MapsProps) => {
  const fetchMaps = async () => {
    const res = await mapsApi.getOrganisationMaps(
      stroot('ma1oht'),
      organisationId,
      { start: 1, limit: 6 },
    );
    return res.body || [];
  };

  const { isLoading, data } = useQuery('dashboardMaps', fetchMaps);
  if (isLoading)
    return (
      <div
        css={css`
          margin-bottom: 1rem;
        `}
      >
        Loading...
      </div>
    );

  return data?.length ? (
    <NewMapList maps={data!} />
  ) : (
    <div
      css={css`
        margin-bottom: 1rem;
      `}
    >
      You currently do not have any Birdi 2.0 maps. Select{' '}
      <em
        css={css`
          font-style: normal;
          font-weight: 700;
        `}
      >
        Create
      </em>{' '}
      to get started.
    </div>
  );
};

export const DashboardMissions = () => {
  const meta = useMetaSelector();
  const mapsV2 = meta.orgFeatureFlags.includes(FeatureFlagNames.MapsV2);
  const orgId = meta.organisation?.id;

  return (
    <QueryClientProvider client={queryClient}>
      <div
        css={css`
          margin-top: 1.5rem;
          padding: 1rem;
          @media (max-width: ${breakpoints.xs}) {
            padding: 1rem 1.2rem;
            padding-top: 0.5rem;
            margin-top: 0;
          }
        `}
      >
        {mapsV2 && (
          <>
            <h3 id="recent-maps-v2" css={sectionHeaderCSS}>
              Recent 2.0 Maps <NewMapBadge />
            </h3>
            {orgId && <Maps organisationId={orgId} />}
          </>
        )}

        <h3 id="recent-missions" css={sectionHeaderCSS}>
          Recent Maps
        </h3>
        <Missions />
      </div>
    </QueryClientProvider>
  );
};
