import { stroot } from '@birdi/js-sdk';
import React, { ReactElement, useState } from 'react';
import { css } from '@emotion/react';
import { button } from '@birdi/theme/blocks';
import { inspection as inspectionAPI } from '@birdi/js-sdk';
import { BirdiTheme } from '@birdi/theme';

export const GenerateReportBtn = ({ inspectionId }): ReactElement => {
  const [generating, setGenerating] = useState<boolean>(false);
  const generateReport = () => {
    setGenerating(true);
    inspectionAPI.generateReport(stroot('yus9co'), inspectionId).then(() => {
      setGenerating(false);
    });
  };
  return (
    <button
      type="button"
      css={(theme: BirdiTheme) =>
        css(
          button(theme),
          css`
            margin-left: 8px;
          `,
        )
      }
      onClick={generateReport}
      disabled={generating}
    >
      Generate reports
    </button>
  );
};
