import { css } from '@emotion/react';
import * as RadixTooltip from '@radix-ui/react-tooltip';
import React from 'react';

interface TooltipPropsBase {
  children: React.ReactNode;
  trigger: React.ReactNode;
  delay?: number;
}

export type TooltipProps = TooltipPropsBase & RadixTooltip.TooltipContentProps;

export const Tooltip = ({
  children,
  trigger,
  delay = 0,
  ...props
}: TooltipProps) => (
  <RadixTooltip.Root delayDuration={delay}>
    <RadixTooltip.Trigger asChild>{trigger}</RadixTooltip.Trigger>
    <RadixTooltip.Portal>
      <RadixTooltip.Content
        css={(theme) => css`
          z-index: 10000;
          background: ${theme.midnight};
          color: white;
          padding: 0.25rem 0.5rem;
          border-radius: 4px;
          font-size: 12px;
          line-height: 1.25;
        `}
        {...props}
      >
        {children}
      </RadixTooltip.Content>
    </RadixTooltip.Portal>
  </RadixTooltip.Root>
);
