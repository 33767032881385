/* global zxcvbn, alert */

import React, { useEffect, useState, useRef } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { user, stroot } from '@birdi/js-sdk';
import BirdiLogoSVG from '@birdi/icons/birdi-logo-navy.svg';
import { css } from '@emotion/react';
import { formStyles, link } from '@birdi/theme/blocks';
import { BirdiTheme, buttonBlue } from '@birdi/theme';
import { GoogleAuthButton } from './GoogleAuthButton';
import ErrorDisplay from '../common/ErrorDisplay';
import { updateField } from './actions';
import VerifyYourEmail from './VerifyYourEmail';
import { Length } from '../common/password/Length';
import { LoadZXCVBN, ZXCVBNDisplay } from '../common/password/zxcvbn';
import Password from '../common/password/Password';
import { saveAuthSearchParams } from './utils';
import { useMetaSelector } from '../meta/reducer';

const SignUp = (props) => {
  const [password, setPassword] = useState('');
  const [zxcvbnResult, setZxcvbnResult] = useState(null);
  const [error, setError] = useState(null);
  const meta = useMetaSelector();

  const formRef = useRef();

  useEffect(() => {
    // componentWillUnmount
    setPassword('');
    // After leaving this page, we reset awaitingEmailVerification
    // as it is reused in ResendVerifiation too
    props._updateField({ awaitingEmailVerification: false });
    saveAuthSearchParams();
  }, []);

  const setPasswordValue = (value) => {
    const { auth } = props;
    if (typeof zxcvbn === 'function') {
      const zxcvbnResultValue = zxcvbn(value, [auth.email]);
      setZxcvbnResult(zxcvbnResultValue);
    }
    setPassword(value);
  };

  const handleSignUp = (event) => {
    event.preventDefault();
    const { _updateField, auth } = props;
    if (!formRef.current.checkValidity())
      return formRef.current.reportValidity();
    if (zxcvbnResult && zxcvbnResult.score < 1) {
      return alert(
        `The password you're using is too weak. ${zxcvbnResult.feedback.suggestions[0]}`,
      );
    }

    const userObj = {
      email: auth.email,
      fname: auth.fname,
      lname: auth.lname,
      password,
      // For accepting a mission share on sign up
      ...(meta.invitation && { invitationId: meta.invitation }),
    };

    return user
      .create(stroot('the7th'), Object.assign(userObj))
      .then(() =>
        _updateField({
          awaitingEmailVerification: true,
        }),
      )
      .catch((err) => setError(err));
  };

  if (props.auth.awaitingEmailVerification) {
    return <VerifyYourEmail />;
  }

  return (
    <div data-testid="sign-up">
      <LoadZXCVBN onLoad={() => {}} />
      <div
        css={css`
          text-align: center;
        `}
      >
        <BirdiLogoSVG
          css={css`
            width: 7em;
            height: 5em;
          `}
        />
        <span
          css={css`
            display: block;
            font-size: 1.1em;
            font-weight: bold;
            margin: 0 0 0.5em 0;
          `}
        >
          Create your account
        </span>
      </div>
      <form
        ref={formRef}
        onSubmit={handleSignUp}
        css={(theme: BirdiTheme) => css(formStyles(theme))}
      >
        <div>
          <label htmlFor="fname">
            First Name
            <input
              id="fname"
              type="text"
              value={props.auth.fname}
              onChange={(event) =>
                props._updateField({ fname: event.target.value })
              }
              required
            />
          </label>
          <ErrorDisplay error={error} path="fname" />
        </div>
        <div>
          <label htmlFor="lname">
            Last Name
            <input
              id="lname"
              type="text"
              value={props.auth.lname}
              onChange={(event) =>
                props._updateField({ lname: event.target.value })
              }
              required
            />
          </label>
          <ErrorDisplay error={error} path="lname" />
        </div>
        <div>
          <label htmlFor="email">
            Email
            <input
              id="email"
              type="email"
              value={props.auth.email}
              onChange={(event) =>
                props._updateField({ email: event.target.value })
              }
              required
            />
          </label>
        </div>
        <ErrorDisplay
          error={error}
          path="email"
          css={(theme: BirdiTheme) => css`
            a {
              color: ${theme.deepOcean};
              font-weight: 500;
            }
          `}
        />
        {error?.body?.message === 'auth.alreadyExists' && (
          <div
            css={css`
              color: red;
              margin: -0.5em 0 0.5em 0;
            `}
          >
            This email already exists
          </div>
        )}
        <Password password={password} setPassword={setPasswordValue} />
        <Length password={password} />
        <ZXCVBNDisplay zxcvbnResult={zxcvbnResult} />
        <p
          css={css`
            font-size: 0.9rem;
          `}
        >
          By signing up to Birdi, you are accepting our&#160;
          <a
            href="https://www.birdi.io/terms-and-conditions/"
            css={(theme: BirdiTheme) => css`
              text-decoration: underline;
              color: ${theme.deepOcean};
            `}
          >
            terms &amp; conditions.
          </a>
        </p>
        <button
          type="submit"
          css={(theme: BirdiTheme) => css`
            ${buttonBlue(theme, { baseSize: 'md', fullWidth: true })};
            font: inherit;
          `}
        >
          Sign up with email
        </button>
      </form>
      <div
        css={(theme: BirdiTheme) => css`
          display: flex;
          justify-content: center;
          align-items: center;
          margin: 1em 0;
          div {
            background-color: ${theme.mono20};
            width: 3rem;
            height: 1px;
            transform: translate(0, 2px);
          }
        `}
      >
        <div />
        <p
          css={(theme: BirdiTheme) => css`
            color: ${theme.mono50};
            font-weight: 500;
            padding: 0 0.5rem;
            margin: 0;
          `}
        >
          or
        </p>
        <div />
      </div>
      <GoogleAuthButton label="Sign up with Google" />
      <div>
        <p
          css={css`
            margin-bottom: 0;
          `}
        >
          Already have an account?&nbsp;
          <Link css={css(link)} to="/sign-in">
            Sign in
          </Link>
        </p>
      </div>
    </div>
  );
};

export default connect(
  (state) => ({
    auth: state.auth,
  }),
  (dispatch) => ({
    _updateField: (field) => dispatch(updateField(field)),
  }),
)(withRouter(SignUp));
