import React, { useEffect, useState } from 'react';
import * as Dialog from '@radix-ui/react-dialog';
import { Link } from 'react-router-dom';
import { css } from '@emotion/react';
import CloseSVG from '@birdi/icons/close.svg';
import PlanDowngradeSVG from '@birdi/icons/plan-downgrade.svg';
import PlanUpgradeSVG from '@birdi/icons/plan-upgrade.svg';
import { closeButtonCSS, contentCSS, portalCSS } from '@birdi/modal-kit';
import history from '../../state/history';

interface PlanChangedModalProps {
  open: boolean;
  close: () => void;
  oldPlan: string;
  newPlan: string;
  cancelPlan?: boolean;
}

export const PlanChangedModal = ({
  open,
  close,
  oldPlan,
  newPlan,
  cancelPlan,
}: PlanChangedModalProps) => {
  useEffect(() => {
    localStorage.removeItem('cancelPlan');
    localStorage.removeItem('localOldPlan');
  }, []);

  const contentOptions = {
    upgrade: {
      title: `Woohoo! You’re now on a ${newPlan} plan`,
      svg: (
        <PlanUpgradeSVG
          css={css`
            font-size: 8em;
          `}
        />
      ),
      text1: '',
      text2:
        'You’ve just unlocked some great additional features along with discounted PAYG processing.',
      footer: (
        <p>
          Visit
          <button
            type="button"
            onClick={close}
            css={(theme) => css`
              color: ${theme.deepOcean};
            `}
          >
            billing & subscription
          </button>
          to manage your plan.
        </p>
      ),
    },
    downgrade: {
      title: `You’ve downgraded to a ${newPlan} plan`,
      svg: (
        <PlanDowngradeSVG
          css={css`
            font-size: 8em;
          `}
        />
      ),
      text1: `Thanks for using our ${newPlan} plan!`,
      text2: `You’ve now been downgraded and will no longer be able to access ${oldPlan} plan features.`,
      footer: (
        <p>
          Changed your mind about downgrading?
          <br />
          <button
            type="button"
            onClick={() => {
              close();
              history.push('/billing/options');
            }}
            css={(theme) => css`
              color: ${theme.deepOcean};
            `}
          >
            Upgrade your subscription here.
          </button>
        </p>
      ),
    },
    cancel: {
      title: `You’ve cancelled your ${oldPlan} plan`,
      svg: (
        <PlanDowngradeSVG
          css={css`
            font-size: 8em;
          `}
        />
      ),
      text1: `Thanks for using our free plan!`,
      text2: `You will be downgraded to a free plan when your subscription plan ends, and will no longer be able to access ${oldPlan} plan features.`,
      footer: (
        <p>
          If you changed your mind, you can subscribe to our plans again when
          your current plan ends, or easily contact us.
        </p>
      ),
    },
  };

  const [content, setContent] = useState(null);

  useEffect(() => {
    if (cancelPlan) {
      setContent(contentOptions.cancel);
    } else if (
      oldPlan === 'free' ||
      (oldPlan === 'plus' && newPlan === 'growth')
    ) {
      setContent(contentOptions.upgrade);
    } else if (
      newPlan === 'free' ||
      (newPlan === 'plus' && oldPlan === 'growth')
    ) {
      setContent(contentOptions.downgrade);
    }
  }, []);

  if (!content) return null;

  return (
    <Dialog.Root open={open} onOpenChange={close}>
      <Dialog.Portal>
        <Dialog.Overlay />

        <div css={portalCSS}>
          <Dialog.Content css={contentCSS}>
            <Dialog.Close css={closeButtonCSS}>
              <CloseSVG />
            </Dialog.Close>
            <div
              css={(theme) => css`
                display: flex;
                align-items: center;
                justify-content: center;
                padding-bottom: 0.5em;
                border-bottom: solid 1px ${theme.mono30};
              `}
            >
              <h1
                css={css`
                  font-size: 1.5em;
                `}
              >
                {content.title}
              </h1>
            </div>
            <div
              css={css`
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                padding: 1em;
                padding-bottom: 0;
                text-align: center;
                p {
                  margin-top: 1em;
                }
              `}
            >
              <p>{content.svg}</p>
              <p>{content.text1}</p>
              <p>{content.text2}</p>
              <p>{content.footer}</p>
            </div>
          </Dialog.Content>
        </div>
      </Dialog.Portal>
    </Dialog.Root>
  );
};
