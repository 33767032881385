import React from 'react';
import { css, Theme } from '@emotion/react';
import {
  planOptions,
  planTitle,
  planSubTitle,
  currentPlanTitle,
  planSection1,
  planSection2,
  planSection3,
  planOptionsColumn,
  planTitleHolder,
  planSection4,
} from '@birdi/theme/blocks';
import GrowthPlanSVG from '@birdi/icons/growth-plan-2.svg';
import GreenCheckCircleSVG from '@birdi/icons/green-check-circle.svg';
import { useMetaSelector } from '../meta/reducer';
import { Checkout } from './checkout-button';
import { PriceData } from './types';
import {
  sanitizedPrice,
  storagePricing,
  sanitizedPAYGProcessingPrice,
} from './utils';

interface GrowthPlanOptionProps {
  paymentInterval: 'monthly' | 'annual';
  prices: PriceData;
  planIsCanceled: boolean;
  isTrial: boolean;
}

export const GrowthPlanOption = (props: GrowthPlanOptionProps): JSX.Element => {
  const meta = useMetaSelector();
  const { paymentInterval, prices, planIsCanceled, isTrial } = props;
  const title = 'growth';
  const { currency, ...currentGrowthPrices } =
    prices[`${title}-${paymentInterval}`];

  const features = [
    'Access to the Birdi advanced platform',
    '3 paid seats included',
    'Manage your team with role types and permissions',
    'Real-time collaboration tools',
    'Share editable map access with your team',
  ];

  // `Additional 1Tb storage ${sanitizedPrice(storagePricing.paid[currency], currency)}/month`,

  const payableMonths = paymentInterval === 'monthly' ? 1 : 12;

  const totalPlanPriceIncludeGSD = sanitizedPrice(
    currentGrowthPrices.unit_amount,
    currency,
  );
  const monthlyPlanPriceIncludeGSD = sanitizedPrice(
    currentGrowthPrices.unit_amount / payableMonths,
    currency,
  );
  const paygPriceIncludeGSD = sanitizedPAYGProcessingPrice(
    currentGrowthPrices.gpCost,
    currency,
  );

  const label = isTrial
    ? 'Start a 14-day free trial'
    : !planIsCanceled &&
        meta.organisation.plan === 'growth' &&
        meta.organisation.planInterval === 'annual' &&
        paymentInterval === 'monthly'
      ? 'Downgrade'
      : 'Upgrade';

  isTrial;

  return (
    <div css={planOptionsColumn}>
      <span className="option-header">Small teams</span>
      <div css={planOptions}>
        <div css={planSection1}>
          <GrowthPlanSVG width="7em" height="7em" />
          <div css={(theme) => planTitleHolder(theme.lightCanary)}>
            <span css={planTitle}>{title}</span>
          </div>
          <span>
            For small teams looking to visualize geospatial data, gain insights
            and collaborate.
          </span>
          <span
            css={css`
              font-weight: bold;
            `}
          >
            3+ user.
          </span>
        </div>
        <div css={planSection2}>
          <span css={planSubTitle}>{monthlyPlanPriceIncludeGSD}</span>
          <span>per month</span>
          <span>plus PAYG processing</span>
        </div>
        <div css={planSection3}>
          {title === meta.organisation.plan &&
            paymentInterval === meta.organisation.planInterval &&
            !planIsCanceled && <div css={currentPlanTitle}>Current plan</div>}
          {(title !== meta.organisation.plan ||
            paymentInterval !== meta.organisation.planInterval ||
            planIsCanceled) && (
            <Checkout
              priceId={currentGrowthPrices.id}
              label={label}
              selectedPlan={'growth'}
              differentInterval={
                !!meta.organisation.planInterval &&
                meta.organisation.planInterval !== paymentInterval
              }
              planIsCanceled={planIsCanceled}
            />
          )}
        </div>
        <div css={planSection4}>
          <span
            css={css`
              font-weight: bold;
              margin-bottom: 1em;
              text-align: center;
              min-height: 2.5em;
            `}
          >
            All the Plus plan features plus:
          </span>
          {features.map((feature) => (
            <div
              key={feature}
              css={css`
                display: flex;
                flex-direction: row;
                align-items: flex-start;
                margin-top: 0.25em;
                span {
                  display: block;
                  text-align: left;
                }
              `}
            >
              <GreenCheckCircleSVG
                css={(theme: Theme) => css`
                  color: ${theme.flora};
                  min-width: 1.25em;
                  width: 1.25em;
                  height: 1.25em;
                  margin-right: 0.5em;
                  margin-top: 0.25em;
                `}
              />
              <span>{feature}</span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
