import React, { useRef, useState } from 'react';
import AvatarEditor from 'react-avatar-editor';
import * as Dialog from '@radix-ui/react-dialog';
import { css } from '@emotion/react';
import { BirdiTheme, buttonBlue } from '@birdi/theme';
import CloseSVG from '@birdi/icons/close.svg';
import { closeButtonCSS, contentCSS, portalCSS } from '@birdi/modal-kit';
import { uploadOne } from '@birdi/uppy-s3';
import { stroot, user as userAPI } from '@birdi/js-sdk';
import { LoadingSpinner } from '@birdi/loading';

// for other users (not mySelf) =>
// await userAPI.update(stroot('ohp4om'), scopeId, { profilePic: upload.uploadURL });

const ProfilePictureUploader = ({
  scopeId,
  mySelf,
  details,
  updateAvatarAndPatch,
}) => {
  const [open, setOpen] = useState(false);
  const [scale, setScale] = useState(1.5); // Default zoom level
  const [file, setFile] = useState<File | null>(null);
  const [profilePic, setProfilePic] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const editorRef = useRef<AvatarEditor>(null);

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      setFile(e.target.files[0]);
      setOpen(true);
      e.target.value = '';
    }
  };

  const getBlobFromCanvas = (canvas) =>
    new Promise((resolve) => canvas.toBlob((blob) => resolve(blob)));

  const saveEditedImage = async () => {
    if (editorRef.current) {
      await updateAvatarAndPatch({ profilePic: null });
      const currentTime = new Date().getTime();
      setLoading(true);
      try {
        const canvas = editorRef.current.getImage();
        // upload and save to db
        const blob = await getBlobFromCanvas(canvas);
        const upload = await uploadOne({
          data: blob,
          type: 'image/jpeg',
          name: 'profile1.jpg',
          public: true,
        });
        await updateAvatarAndPatch({
          profilePic: `${upload.uploadURL}?t=${currentTime}`,
        });
        // Convert canvas to a base64 image
        setProfilePic(`${upload.uploadURL}?t=${currentTime}`);
      } catch (error) {
        console.error('Error uploading image:', error);
      } finally {
        setLoading(false);
        setScale(1.5);
        setOpen(false);
      }
    }
  };

  return (
    <div
      css={(theme: BirdiTheme) => css`
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 0.5em;
      `}
    >
      {/* Profile Picture Preview */}
      <div
        css={(theme) => css`
          display: flex;
          justify-content: center;
          align-items: center;
          // background-image: url('${profilePic
            ? profilePic
            : details.profilePic}');
          background-image: ${loading
            ? 'none'
            : `url('${profilePic ? profilePic : details.profilePic}')`};
          background-position: center;
          background-size: cover;
          background-color: ${theme.flora};
          color: white;
          min-width: 5em;
          width: 5em;
          height: 5em;
          border-radius: 2.5em;
        `}
      >
        {loading ? (
          <LoadingSpinner size="1.5em" />
        ) : (
          !profilePic &&
          !details.profilePic &&
          details.fname &&
          details.lname && (
            <span
              css={css`
                font-size: 1.2em;
                font-weight: bold;
              `}
            >
              {`${details.fname[0]}${details.lname[0]}`}
            </span>
          )
        )}
      </div>

      {/* Upload Button */}
      {mySelf && (
        <>
          <label
            htmlFor="file-upload"
            css={(theme) => css`
              color: ${theme.deepOcean};
              text-decoration: underline;
              text-align: center;
              cursor: pointer;
            `}
          >
            Upload new
          </label>
          <input
            id="file-upload"
            type="file"
            accept="image/*"
            onChange={handleFileChange}
            css={css`
              display: none;
            `}
          />
        </>
      )}

      {/* Avatar Editor Dialog */}
      {file && (
        <Dialog.Root open={open} onOpenChange={setOpen}>
          <Dialog.Portal>
            <Dialog.Overlay
              css={css`
                position: fixed;
                inset: 0;
                // background-color: rgba(0, 0, 0, 0.5);
              `}
            />
            <div css={portalCSS}>
              <Dialog.Content
                css={(theme) => css`
                  ${contentCSS(theme)};
                  width: 370px;
                `}
              >
                <Dialog.Close css={closeButtonCSS}>
                  <CloseSVG />
                </Dialog.Close>
                <Dialog.Title>Resize Photo</Dialog.Title>

                <div
                  css={css`
                    display: flex;
                    flex-direction: column;
                    gap: 1em;
                    align-items: flex-end;
                  `}
                >
                  <AvatarEditor
                    ref={editorRef}
                    image={file}
                    width={250}
                    height={250}
                    border={25}
                    borderRadius={250}
                    color={[255, 255, 255, 0.6]} // Semi-transparent white
                    scale={scale} // Use scale state for resizing
                  />
                  <label>
                    Zoom: &nbsp;
                    <input
                      type="range"
                      min="1"
                      max="3"
                      step="0.1"
                      value={scale}
                      onChange={(e) => setScale(parseFloat(e.target.value))}
                      css={(theme) => css`
                        -webkit-appearance: none;
                        appearance: none;
                        height: 10px;
                        background: linear-gradient(
                          to right,
                          ${theme.deepOcean} ${((scale - 1) / (3 - 1)) * 100}%,
                          ${theme.mono30} ${((scale - 1) / (3 - 1)) * 100}%
                        );
                        border-radius: 5px;
                        outline: none;
                        &::-webkit-slider-thumb {
                          -webkit-appearance: none;
                          appearance: none;
                          width: 20px;
                          height: 20px;
                          background: ${theme.deepOcean};
                          border-radius: 50%;
                          cursor: pointer;
                        }
                      `}
                    />
                  </label>
                  <button
                    type="button"
                    onClick={saveEditedImage}
                    css={buttonBlue}
                  >
                    Save
                  </button>
                </div>
              </Dialog.Content>
            </div>
          </Dialog.Portal>
        </Dialog.Root>
      )}
    </div>
  );
};

export default ProfilePictureUploader;
