import React, { useCallback, useEffect, useRef, useState } from 'react';
import MoreHorizontalSVG from '@birdi/icons/more-horizontal.svg';
import MoreVerticalSVG from '@birdi/icons/more-vertical.svg';
import { css, Theme } from '@emotion/react';
import { useClickAway, useKey } from 'react-use';

interface MenuProps<S> {
  hide: () => void;
  wrapperRef: React.RefObject<S>;
}

export interface ButtonProps<T> {
  toggle: () => void;
  toggleRef: React.RefObject<T>;
  open: boolean;
}

interface ContextMenuProps<T, S> {
  Button?: React.FC<ButtonProps<T>>;
  Menu?: React.FC<MenuProps<S>>;
  renderContainer?: boolean;
}

export const DefaultMenu: React.FC<MenuProps<HTMLDivElement>> = (props) => {
  return (
    <div>
      <button onClick={props.hide}>Item 1</button>
    </div>
  );
};

export const DefaultButton: React.FC<ButtonProps<HTMLButtonElement>> = ({
  toggle,
  toggleRef,
}) => (
  <button
    onClick={(e) => {
      e.stopPropagation();
      toggle();
    }}
    css={(theme) => css`
      &:hover {
        background: ${theme.mono20};
      }
    `}
  >
    <MoreHorizontalSVG color="#777" />
  </button>
);

export const MoreVerticalButton: React.FC<ButtonProps<HTMLButtonElement>> = ({
  toggle,
  toggleRef,
}) => (
  <button
    onClick={toggle}
    css={(theme) => css`
      &:hover {
        background: ${theme.mono20};
      }
    `}
  >
    <MoreVerticalSVG color="#777" />
  </button>
);

export const ContextMenu2 = <T extends HTMLElement, S extends HTMLElement>({
  Button,
  Menu,
  renderContainer,
}: React.PropsWithChildren<ContextMenuProps<T, S>>) => {
  const [open, setOpen] = useState<boolean>(false);
  const toggle = () => setOpen(!open);
  const hide = () => setOpen(false);
  const wrapperRef = useRef<S>(null);
  const toggleRef = useRef<T>(null);

  // This seems to be broken when the map view is up, for some reason
  useKey('Escape', () => {
    if (open) hide();
  });

  useClickAway(wrapperRef, (event) => {
    if (!open || event.target === toggleRef.current) return;
    hide();
  });

  const Container = renderContainer
    ? ({ children }) => (
        <div
          css={css`
            position: relative;
          `}
        >
          {children}
        </div>
      )
    : ({ children }) => <>{children}</>;
  return (
    <Container>
      <Button toggle={toggle} toggleRef={toggleRef} open={open} />
      {open && <Menu wrapperRef={wrapperRef} hide={hide} />}
    </Container>
  );
};

ContextMenu2.defaultProps = {
  Button: DefaultButton,
  Menu: DefaultMenu,
};

export const baseMenuCSS = (theme: Theme) => css`
  z-index: 20;
  max-width: 16em;
  max-height: calc(100vh - 5em);
  overflow-y: auto;
  border-radius: 3px;
  padding: 0.5em;
  background: ${theme.mono0};
  box-shadow:
    rgba(9, 30, 66, 0.1) 0px 1px 1px,
    rgba(9, 30, 66, 0.16) 0px 0px 1px 0px;
  & button,
  a {
    padding: 0.25em;
    color: ${theme.mono98};
    width: 16em;
    text-align: left;
    display: inline-block;
    box-sizing: border-box;
  }
  & button:disabled,
  a:disabled {
    color: ${theme.mono20};
  }
  & button:hover,
  a:hover {
    background: ${theme.mono20};
  }
`;

export const defaultMenuCSS = (theme: Theme) => css`
  ${baseMenuCSS(theme)};
  position: absolute;
  top: calc(100% + 0.25em);
  right: 0;
`;
