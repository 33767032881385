import { next } from '@birdi/theme';
import React from 'react';

export const Input = React.forwardRef<
  HTMLInputElement,
  JSX.IntrinsicElements['input'] & {
    type?: 'text' | 'number' | 'range';
    symbol?: string;
  }
>((props, ref) => {
  const { type = 'text', symbol, ...rest } = props;
  const inputStyle =
    type === 'range' ? next.inputs.input.slider : next.inputs.input.input;

  const paddingRight = symbol ? `${0.5 + symbol.length * 0.6}rem` : '0.5rem';

  return (
    <div css={next.inputs.input.container}>
      <input
        {...rest}
        ref={ref}
        css={(theme: any) => [
          inputStyle(theme),
          {
            paddingRight: paddingRight,
          },
        ]}
        type={type}
      />
      {symbol && <span css={next.inputs.input.symbol}>{symbol}</span>}
    </div>
  );
});
