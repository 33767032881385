import { fonts, borderRadius, breakpoints } from './variables';
import ProximaNovaMediumWoff from './font/proxima-nova-medium.woff';
import ProximaNovaMediumWoff2 from './font/proxima-nova-medium.woff2';
import ProximaNovaBoldWoff from './font/proxima-nova-bold.woff';
import ProximaNovaBoldWoff2 from './font/proxima-nova-bold.woff2';

export interface BirdiTheme {
  mode: 'dark' | 'light';
  // Base monochromatic set
  mono0: string; // global background
  mono20: string; // borders
  mono30: string; // borders
  mono40: string; // light body font
  mono50: string; // light body font
  mono60: string; // hover
  mono70: string; // body font
  mono98: string; // black
  // functional colours
  displayFont: string;
  displayFontInverse: string;
  globalBackground: string;
  // floating shadow
  floatingShadow: string;
  // brand monochromatic set
  white: string;
  sand: string;
  dove: string;
  hailstone: string;
  midGrey: string;
  darkGrey: string;
  slate: string;
  nightSky: string;
  blackbird: string;
  // brand colour set
  crimsonSunbird: string;
  lightCrimsonSunbird: string;
  canary: string;
  lightCanary: string;
  sunConure: string;
  lightSunConure: string;
  deepOcean: string;
  ocean: string;
  lightOcean: string;
  flora: string;
  lightFlora: string;
  lightMidnight: string;
  midnight: string;
}

// Makes it work in `css={theme => ...}` props
declare module '@emotion/react' {
  interface Theme extends BirdiTheme {}
}

const brandMonochrome = {
  white: 'hsl(0, 0%, 100%)', // FFFFFF
  sand: 'hsl(30, 11%, 96%)', // #F7F6F5
  dove: 'hsl(0, 0%, 93%, 1)', //#EEEEEE
  hailstone: 'hsl(0, 0%, 88%)', // #E0E0E0
  midGrey: 'hsl(0, 0%, 73%)', // #B9B9B9
  darkGrey: 'hsl(0, 0%, 46%)', // #757575
  slate: 'hsl(218, 12%, 35%)', // #4F5765
  nightSky: 'hsl(220, 12%, 20%)', // #2D3139
  blackbird: 'hsl(220, 15%, 16%)', // #22262E
};

export const brandColours = {
  crimsonSunbird: 'hsl(0, 60%, 56%)', // #D24A4A
  lightCrimsonSunbird: 'hsl(0, 65%, 89%)', // #F5D3D3
  canary: 'hsl(43, 100%, 64%)', // #FFCA46
  lightCanary: 'hsl(42, 100%, 96%)', // #FFF9EB
  sunConure: 'hsl(16, 97%, 63%)', // #FC7543
  lightSunConure: 'hsl(16, 90%, 96%)', // #FEF0EB
  deepOcean: 'hsl(203, 100%, 38%)', // #0078C0
  ocean: 'hsl(212, 96%, 56%)', // #2287FA
  lightOcean: 'hsl(213, 100%, 96%)', // #EBF4FF
  flora: 'hsl(153, 60%, 56%)', // #4AD294
  lightFlora: 'hsl(150, 60%, 96%)', // #EFFBF5
  lightMidnight: 'hsl(240, 22%, 92%)', // #E6E6EF
  midnight: 'hsl(240, 24%, 29%)', // #38385C
};

export const themeLight: BirdiTheme = {
  mode: 'light',
  // Base monochromatic set
  mono0: brandMonochrome.white, // global background
  mono20: brandMonochrome.sand, // borders
  mono30: brandMonochrome.hailstone, // borders
  mono40: brandMonochrome.midGrey, // input borders
  mono50: brandMonochrome.darkGrey, // light body font
  mono60: brandMonochrome.darkGrey,
  mono70: brandMonochrome.nightSky, // body font
  mono98: brandMonochrome.blackbird, // black
  // Functional colours
  displayFont: brandColours.midnight,
  displayFontInverse: brandMonochrome.white,
  globalBackground: brandMonochrome.white,
  floatingShadow:
    'rgba(9, 30, 66, 0.1) 0px 1px 1px, rgba(9, 30, 66, 0.16) 0px 0px 1px 0px',
  ...brandMonochrome,
  ...brandColours,
};

export const themeDark: BirdiTheme = {
  mode: 'dark',
  // Base monochromatic set
  mono0: brandMonochrome.blackbird, // global background
  mono20: brandMonochrome.darkGrey, // borders,
  mono30: brandMonochrome.darkGrey, // borders
  mono40: brandMonochrome.midGrey, // input borders
  mono50: brandMonochrome.hailstone, // light body font
  mono60: brandMonochrome.slate, // hover
  mono70: brandMonochrome.sand, // body font
  mono98: brandMonochrome.white, // white
  // Functional colours
  displayFont: brandMonochrome.white,
  displayFontInverse: brandColours.midnight,
  globalBackground: brandMonochrome.nightSky,
  floatingShadow:
    'rgba(9, 30, 66, 0.1) 0px 1px 1px, rgba(9, 30, 66, 0.16) 0px 0px 1px 0px',
  ...brandMonochrome,
  ...brandColours,
};

export const getTheme = (mode: string) =>
  mode === 'dark' ? themeDark : themeLight;

/**
 * TODO: Deprecate the global input styles
 */
export const globals = (theme: BirdiTheme) => `
  @font-face {
    font-family: 'ProximaNova';
    font-weight: 500;
    font-style: normal;
    font-display: swap;
    unicode-range: U+000-5FF;
    src: url('${ProximaNovaMediumWoff2}') format('woff2'), url('${ProximaNovaMediumWoff}') format('woff');
  }
  @font-face {
    font-family: 'ProximaNova';
    font-weight: 600;
    font-style: normal;
    font-display: swap;
    unicode-range: U+000-5FF;
    src: url('${ProximaNovaBoldWoff2}') format('woff2'), url('${ProximaNovaBoldWoff}') format('woff');
  }

  @font-face {
    font-family: 'ProximaNova';
    font-weight: 700;
    font-style: normal;
    font-display: swap;
    unicode-range: U+000-5FF;
    src: url('${ProximaNovaBoldWoff2}') format('woff2'), url('${ProximaNovaBoldWoff}') format('woff');
  }

  html {
    font-size: 16px;
    @media (max-width: ${breakpoints.xs}) {
      font-size: 14px;
    }
  }
  body {
    margin: 0;
    padding: 0;
    line-height: 1.5em;
    background: ${theme.globalBackground};
    font-family: ${fonts.body};
    color: ${theme.displayFont};
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
  }
  button, input, textarea, select {
    font-family: ${fonts.body};
  }
  svg {
    stroke-width: 1.5px;
  }
  :where(
    input[type="text"],
    textarea,
    select,
  ) {
    appearance: none;
  }
  :where(
    input[type="text"],
    input[type="number"],
    input[type="password"],
    input[type="email"],
    textarea,
    select,
    textarea
  ) {
    background: inherit;
    border: 1px solid ${theme.mono30};
    border-radius: ${borderRadius};
    box-sizing: border-box;
    line-height: 1.5rem;
    font-size: 1rem;
    color: inherit;
    &:disabled {
      background: ${theme.mono0};
      color: ${theme.mono50};
    }
  }
  :where(input[type="number"]) {
    /* Chrome, Safari, Edge, Opera */
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    /* Firefox */
    -moz-appearance: textfield;
  }
  :where(input[type="checkbox"]) {
    accent-color: ${theme.deepOcean};
  }
  table {
    border-collapse: collapse;
  }
  a, a:visited {
    text-decoration: none;
  }
  :where(button) {
    font-size: 1em;
    background: none;
    font-weight: inherit;
    cursor: pointer;
    border: 0;
    &:focus {
      outline: 0;
    }
  }
  h1, h2, h3, h4 {
    color: ${theme.displayFont};
  }
  h1 {
    font-feature-settings: "dlig", "liga";
    font-size: 1.5rem;
    line-height: 1.5em;
  }
  h2 {
    font-feature-settings: "dlig", "liga";
    font-size: 1.25rem;
    font-weight: 600;
    line-height: 1.25em;
  }
  h3 {
    font-feature-settings: "dlig", "liga";
    font-size: 1rem;
  }
  h4 {
    font-weight: 600;
  }
  hr {
    border: none;
    border-top: 1px solid ${theme.mono30};
    margin: 1rem 0;
  }
  dt {
    font-weight: 700;
  }
  dd {
    margin: 0;
  }
`;
