import React from 'react';
import { Controller } from 'react-hook-form';
import { errorMessage, blockLabel } from '@birdi/theme/form';
import Select from 'react-select';
import { css } from '@emotion/react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import * as accountActions from '../account/actions';
import * as onboardingActions from './reducer';

const industriesList = [
  { value: 'Agriculture', label: 'Agriculture' },
  { value: 'Civil & Infrastructure', label: 'Civil & Infrastructure' },
  { value: 'Construction', label: 'Construction' },
  { value: 'Consulting', label: 'Consulting' },
  { value: 'Consumer/Business Services', label: 'Consumer/Business Services' },
  { value: 'Drone Services', label: 'Drone Services' },
  { value: 'Education', label: 'Education' },
  { value: 'Energy', label: 'Energy' },
  { value: 'Facilities Services', label: 'Facilities Services' },
  { value: 'Finance', label: 'Finance' },
  { value: 'Forestry', label: 'Forestry' },
  { value: 'Government', label: 'Government' },
  { value: 'Insurance', label: 'Insurance' },
  { value: 'Marketing', label: 'Marketing' },
  { value: 'Media Production', label: 'Media Production' },
  { value: 'Mining & Aggregates', label: 'Mining & Aggregates' },
  { value: 'Non Profit', label: 'Non Profit' },
  { value: 'Real Estate', label: 'Real Estate' },
  { value: 'Research', label: 'Research' },
  { value: 'Technology', label: 'Technology' },
  { value: 'Telecommunications', label: 'Telecommunications' },
  {
    value: 'Transportation/Trucking/Railroad',
    label: 'Transportation/Trucking/Railroad',
  },
  { value: 'Trades', label: 'Trades' },
  { value: 'Surveying', label: 'Surveying' },
  { value: 'Locating Services', label: 'Locating Services' },
  { value: 'Power', label: 'Power' },
  { value: 'Other', label: 'Other' },
];

const teamsList = [
  {
    value: 'Drone pilot or fleet management',
    label: 'Drone pilot or fleet management',
  },
  { value: 'C-Suite', label: 'C-Suite' },
  { value: 'Owner / Sole Trader', label: 'Owner / Sole Trader' },
  {
    value: 'Contractor / Sub-Contractor',
    label: 'Contractor / Sub-Contractor',
  },
  { value: 'Construction', label: 'Construction' },
  { value: 'Operations', label: 'Operations' },
  { value: 'Marketing', label: 'Marketing' },
  { value: 'Finance', label: 'Finance' },
  { value: 'Environment', label: 'Environment' },
  { value: 'Surveying', label: 'Surveying' },
  { value: 'Asset Management', label: 'Asset Management' },
];

const IndustryAndTeamSelect = (props): React.ReactElement => {
  const { onboarding, updateAnswers, updateStateAndPatch, control, formState } =
    props;
  // Returning false immediately when onboarding has not loaded
  // allows our ReactSelect defaults to populate
  if (!onboarding.loaded) return false;
  return (
    <div>
      <label css={blockLabel} htmlFor="industry">
        What industry do you work in?
        <Controller
          render={({ field: { onChange } }) => (
            <Select
              inputId="industry"
              instanceId="industry"
              options={industriesList}
              placeholder="Select"
              onChange={(option) => {
                onChange(option.value);
                updateAnswers({ industry: option.value });
              }}
              defaultValue={{
                value: onboarding.industry,
                label: onboarding.industry,
              }}
            />
          )}
          control={control}
          name="industry"
          rules={{ required: true }}
          defaultValue={
            onboarding.industry
              ? { value: onboarding.industry, label: onboarding.industry }
              : null
          }
        />
        {formState.errors.industry && (
          <span
            css={css`
              ${errorMessage};
              margin-top: 0.5em;
            `}
          >
            Please select your related industry.
          </span>
        )}
      </label>
      <label css={blockLabel} htmlFor="team">
        What team do you belong to?
        <Controller
          render={({ field: { onChange } }) => (
            <Select
              inputId="team"
              instanceId="team"
              options={teamsList}
              placeholder="Select"
              onChange={(option) => {
                onChange(option.value);
                updateAnswers({ team: option.value });
              }}
              defaultValue={{ value: onboarding.team, label: onboarding.team }}
            />
          )}
          control={control}
          name="team"
          rules={{ required: true }}
          defaultValue={
            onboarding.team
              ? { value: onboarding.team, label: onboarding.team }
              : null
          }
        />
        {formState.errors.team && (
          <span
            css={css`
              ${errorMessage};
              margin-top: 0.5em;
            `}
          >
            Please select your team.
          </span>
        )}
      </label>
    </div>
  );
};

export default connect(
  (state) => ({
    onboarding: state.onboarding,
  }),
  (dispatch) => ({
    updateAnswers: (field) => dispatch(onboardingActions.updateAnswers(field)),
    updateStateAndPatch: (field) =>
      dispatch(accountActions.updateStateAndPatch(field)),
  }),
)(withRouter(IndustryAndTeamSelect));
