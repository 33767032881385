import React from 'react';
import { css } from '@emotion/react';
import { BirdiTheme } from '@birdi/theme';

const steps = [1, 2, 3];

export const StepsDots = ({ step }) => (
  <div
    css={css`
      display: flex;
      flex-direction: row;
    `}
  >
    {steps.map((item) => (
      <div
        key={item}
        css={(theme: BirdiTheme) => css`
          width: 0.5em;
          height: 0.5em;
          margin-left: 0.25em;
          border-radius: 50%;
          background-color: ${step === item ? theme.flora : theme.mono30};
        `}
      />
    ))}
  </div>
);
