import { css } from '@emotion/react';
import { Tooltip } from './tooltip';
import { TooltipProvider } from '@radix-ui/react-tooltip';

export const NewMapBadge = () => (
  <TooltipProvider>
    <Tooltip
      trigger={
        <span
          css={(theme) => css`
            display: inline-block;
            background: ${theme.lightFlora};
            color: ${theme.flora};
            border-radius: 8px;
            padding: 0 4px;
            font-size: 14px;
            font-weight: 400;

            &:hover {
            }
          `}
          aria-label="(new map)"
        >
          🌟 New
        </span>
      }
    >
      <span>Birdi's new Map View is in beta trial phase.</span>
    </Tooltip>
  </TooltipProvider>
);
