import {
  stroot,
  jobRequests as jobRequestsAPI,
  user as userAPI,
} from '@birdi/js-sdk';
import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { css } from '@emotion/react';
import { useForm, Controller } from 'react-hook-form';
import { button } from '@birdi/theme/blocks';
import {
  blockLabel,
  textInput,
  textArea,
  errorMessage,
  reactSelect,
} from '@birdi/theme/form';
import {
  LocationSelect,
  blankLocationWithName,
  blacklistedAreas,
} from '@birdi/google-places-autocomplete';
import { SoftwareSelect } from '@birdi/select/SoftwareSelect';
import { ExperienceSelect } from '@birdi/select/ExperienceSelect';
import * as userActions from './actions';
import { BirdiTheme } from '@birdi/theme';
import {
  pageTitleHolder,
  pageTitle,
  pageGraySection,
  pageDescription,
} from '@birdi/theme/blocks';
import BookOpenSVG from '@birdi/icons/book-open.svg';

function arraysEqual(a: Array<string>, b: Array<string>) {
  if (a.length !== b.length) return false;
  for (let i = 0; i < a.length; i++) if (!b.includes(a[i])) return false;
  return true;
}

export const PilotSettingsInternal = ({
  account,
  updateStateAndPatch,
  getProfile,
}): React.ReactElement => {
  const [locationHasChanged, setLocationHasChanged] = useState(false);
  const [locationError, setLocationError] = useState('');
  const [location, setLocation] = useState({
    ...blankLocationWithName,
    loaded: false,
  });

  // Set up ReactHookForm
  const {
    register,
    control,
    getValues,
    setValue,
    reset,
    formState,
    handleSubmit,
    clearErrors,
    setError,
  } = useForm();

  // Reload profile on page load
  useEffect(() => {
    getProfile();
    userAPI
      .getLocation(stroot('foog4u'), account.id)
      .then((data) => setLocation(Object.assign(data.body, { loaded: true })));
  }, []);

  // Reset form when data has been gathered
  useEffect(() => {
    reset({
      jobNotifications: account.jobNotifications,
      company: account.company,
      position: account.position,
      experience: account.experience,
      software: account.software,
      platform: account.platform,
      bio: account.bio,
    });
  }, [account.loaded]);

  const checkSuburbValidation = (location) => {
    let suburbErrorText = '';
    if (getValues('jobNotifications')) {
      if (location.country && location.country !== 'Australia') {
        suburbErrorText = 'We currently facilitate jobs within Australia only.';
      } else {
        if (!location.suburb) suburbErrorText = 'Please enter a suburb.';
        if (blacklistedAreas.includes(location.suburb)) {
          suburbErrorText =
            'Please enter a specific suburb instead of a capital city, state or country.';
        }
      }
    }
    setLocationError(suburbErrorText);

    if (!suburbErrorText) {
      clearErrors('location');
    } else {
      setError('location', {
        message: suburbErrorText,
      });
    }

    return !suburbErrorText;
  };

  /**
   * This function checks whether the user has pending job requests
   * TODO: This should be moved to server side and prevented
   */
  const turnJobNotificationsOff = () => {
    jobRequestsAPI.getPilotJobRequests(stroot('at7cha')).then((res) => {
      const jobRequestsCount = res.body.items.length;
      if (jobRequestsCount) {
        window.alert('You already have pending job requests!');
        setValue('jobNotifications', true);
      } else {
        setValue('jobNotifications', false);
        clearErrors();
        setLocationError('');
      }
    });
  };

  const turnJobNotificationsOn = () => {
    setValue('jobNotifications', true);
    checkSuburbValidation(location);
  };

  const getUpdatedValues = () => {
    const formValues = getValues();
    // Check array changes
    const softwareHasChanged = !arraysEqual(
      formValues.software.map((opt) => opt.value),
      account.software.map((opt) => opt.value),
    );
    const experienceHasChanged = !arraysEqual(
      formValues.experience.map((opt) => opt.value),
      account.experience.map((opt) => opt.value),
    );
    // check for other fields updates
    let otherUpdatedFields = {};
    otherUpdatedFields = Object.keys(formValues).reduce((acc, key) => {
      if (formValues[key] !== account[key]) acc[key] = formValues[key];
      return acc;
    }, {});
    delete otherUpdatedFields.software;
    delete otherUpdatedFields.experience;
    // create total updates object
    const patch = { ...otherUpdatedFields };
    if (softwareHasChanged) patch.software = formValues.software;
    if (experienceHasChanged) patch.experience = formValues.experience;
    return patch;
  };

  const save = () => {
    if (locationError) return false;

    const valuesToUpdate = getUpdatedValues();

    if (!Object.keys(valuesToUpdate).length && !locationHasChanged)
      return false;

    if (locationHasChanged) {
      const formValues = getValues();
      userAPI.updateLocation(stroot('gu2ohm'), formValues.location);
    }

    return updateStateAndPatch(valuesToUpdate);
  };

  if (!account.loaded) return <div />;
  if (!location.loaded) return <div />;

  return (
    <div>
      <div css={pageTitleHolder}>
        <h2 css={pageTitle}>Pilot Profile</h2>
      </div>
      <div css={pageGraySection}>
        <p>Manage you're pilot settings and preferences.</p>
      </div>

      {/* <div css={pageDescription}>
        <div>
          <a
            href="https://help.birdi.io/en/articles/6648063-getting-started-as-a-pilot"
            target="_blank"
            rel="noopener noreferrer"
          >
            <BookOpenSVG />
            <span>Learn more about getting started as a pilot</span>
          </a>
        </div>
      </div> */}

      <div
        css={(theme: BirdiTheme) => css`
          padding: 1.5em;
        `}
      >
        <div
          css={css`
            max-width: 35em;
          `}
        >
          <form onSubmit={handleSubmit(save)}>
            <fieldset
              css={css`
                padding: 0;
                border: none;
                margin-bottom: 1em;
              `}
            >
              Are you available for hire as a drone pilot?
              <Controller
                control={control}
                name="jobNotifications"
                render={({ field }) => (
                  <div
                    css={css`
                      margin-top: 0.5em;
                    `}
                  >
                    <label htmlFor="jobNotificationsOn">
                      <input
                        id="jobNotificationsOn"
                        type="radio"
                        checked={field.value}
                        onChange={turnJobNotificationsOn}
                      />
                      Yes
                    </label>
                    <label
                      htmlFor="jobNotificationsOff"
                      css={css`
                        margin-left: 1em;
                      `}
                    >
                      <input
                        id="jobNotificationsOff"
                        type="radio"
                        value="off"
                        checked={!field.value}
                        onChange={turnJobNotificationsOff}
                      />
                      No
                    </label>
                  </div>
                )}
              />
            </fieldset>
            <label css={blockLabel} htmlFor="location">
              Where are your operations based?
              <Controller
                control={control}
                name="location"
                {...register('location')}
                defaultValue={location.name}
                rules={{
                  validate: () => checkSuburbValidation(location),
                }}
                render={({ field: { onChange } }) => (
                  <LocationSelect
                    inputId="location"
                    defaultValue={location.name}
                    isClearable
                    onClear={() => {
                      onChange(blankLocationWithName);
                      setLocation({ ...blankLocationWithName, loaded: true });
                      setLocationHasChanged(true);
                      checkSuburbValidation(blankLocationWithName);
                    }}
                    onLocationSelect={(geocode) => {
                      onChange(geocode.location);
                      const locationObject = geocode.location;
                      setLocation({ ...locationObject, loaded: true });
                      setLocationHasChanged(true);
                      checkSuburbValidation(geocode.location);
                    }}
                    placeholder="Look up suburbs"
                    styles={reactSelect}
                    apiOpts={{
                      types: '(regions)',
                      location: '-33.865, 151.209',
                      radius: 2050,
                    }}
                  />
                )}
              />
              {locationError && (
                <span
                  css={css`
                    ${errorMessage};
                    margin-top: 0.5em;
                  `}
                >
                  {locationError}
                </span>
              )}
            </label>
            <label css={blockLabel} htmlFor="company">
              Company
              <input
                css={textInput}
                type="text"
                id="company"
                {...register('company', {
                  required: true,
                })}
              />
              {formState.errors.company && (
                <span css={errorMessage}>
                  Please provide your company name.
                </span>
              )}
            </label>
            <label css={blockLabel} htmlFor="position">
              Position Title
              <input
                css={textInput}
                type="text"
                id="position"
                {...register('position', {
                  required: true,
                })}
              />
              {formState.errors.position && (
                <span css={errorMessage}>
                  Please provide your position title.
                </span>
              )}
            </label>
            <label css={blockLabel} htmlFor="experience">
              Experience
              <Controller
                render={({ field }) => (
                  <ExperienceSelect
                    value={getValues('experience')}
                    isMulti
                    onChange={(options) => {
                      field.onChange(options || []);
                    }}
                    inputId="experience"
                  />
                )}
                control={control}
                defaultValue={account.experience}
                name="experience"
              />
              {formState.errors.experience && (
                <span
                  css={css`
                    ${errorMessage};
                    margin-top: 0.3em;
                  `}
                >
                  Please provide your experiences.
                </span>
              )}
            </label>
            <label css={blockLabel} htmlFor="software">
              Software
              <Controller
                render={({ field }) => (
                  <SoftwareSelect
                    value={getValues('software')}
                    isMulti
                    onChange={(options) => {
                      field.onChange(options || []);
                    }}
                    inputId="software"
                  />
                )}
                control={control}
                defaultValue={account.software}
                name="software"
              />
              {formState.errors.software && (
                <span
                  css={css`
                    ${errorMessage};
                    margin-top: 0.3em;
                  `}
                >
                  Please provide your software.
                </span>
              )}
            </label>
            <label css={blockLabel} htmlFor="platform">
              Which mobile platform do you use?
              <div
                css={css`
                  display: flex;
                  flex-direction: column;
                  margin-top: 0.5em;
                `}
              >
                <label htmlFor="mobile-android">
                  <input
                    {...register('platform')}
                    id="mobile-android"
                    type="radio"
                    value="Android"
                    defaultChecked={account.platform === 'Android'}
                  />
                  Android
                </label>
                <label htmlFor="mobile-ios">
                  <input
                    id="mobile-ios"
                    type="radio"
                    value="IOS"
                    defaultChecked={account.platform === 'IOS'}
                    {...register('platform')}
                  />
                  IOS
                </label>
                <label htmlFor="mobile-both">
                  <input
                    id="mobile-both"
                    type="radio"
                    value="Android & IOS"
                    {...register('platform')}
                    defaultChecked={account.platform === 'Android & IOS'}
                  />
                  Both Android &amp; IOS
                </label>
              </div>
              {formState.errors.platform && (
                <span
                  css={css`
                    ${errorMessage};
                    margin-top: 0.3em;
                  `}
                >
                  Please provide your mobile platform.
                </span>
              )}
            </label>
            <label css={blockLabel} htmlFor="bio">
              Bio
              <textarea
                css={textArea}
                id="bio"
                name="bio"
                {...register('bio')}
                placeholder="Tell us a little about yourself ..."
              />
            </label>
            <div
              css={css`
                display: flex;
                justify-content: flex-end;
              `}
            >
              <button type="submit" css={(theme: BirdiTheme) => button(theme)}>
                Save
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export const PilotSettings = connect(
  (state) => ({
    account: state.account,
  }),
  (dispatch) => ({
    getProfile: () => dispatch(userActions.getProfile()),
    updateField: (field) => dispatch(userActions.updateField(field)),
    updateStateAndPatch: (field) =>
      dispatch(userActions.updateStateAndPatch(field)),
  }),
)(withRouter(PilotSettingsInternal));
