/* global fetch */

import { config } from '../config';
import { headers, APIResponse, stleaf, fetchAPI, SpanTag } from '../helpers';

export const getTags = (st: SpanTag): Promise<APIResponse> => {
  const url = `${config.API_URL}/v1/software-tags`;
  return fetchAPI(stleaf(st, 'eejo6k'), url, {
    method: 'GET',
    credentials: 'include',
    headers,
  });
};

export const add = (st: SpanTag, name: string): Promise<APIResponse> => {
  const url = `${config.API_URL}/v1/software/${name}`;
  return fetchAPI(stleaf(st, 'sheaj9'), url, {
    method: 'POST',
    credentials: 'include',
    headers,
  });
};

export const findByName = (st: SpanTag, name: string): Promise<APIResponse> => {
  const url = `${config.API_URL}/v1/software-tags/${name}`;
  return fetchAPI(stleaf(st, 'aihoo8'), url, {
    method: 'GET',
    credentials: 'include',
    headers,
  });
};

export const getAll = (st: SpanTag): Promise<APIResponse> => {
  const url = `${config.API_URL}/v1/software`;
  return fetchAPI(stleaf(st, 'moh2sh'), url, {
    method: 'GET',
    credentials: 'include',
    headers,
  });
};

export const remove = (st: SpanTag, name: string): Promise<APIResponse> => {
  const url = `${config.API_URL}/v1/software/${name}`;
  return fetchAPI(stleaf(st, 'nuiqu3'), url, {
    method: 'DELETE',
    credentials: 'include',
    headers,
  });
};
