import React from 'react';
import Creatable from 'react-select';
import { reactSelect } from '@birdi/theme/form';

const typesList = [
  { value: 'Drone', label: 'Drone' },
  { value: 'Battery', label: 'Battery' },
  { value: 'Camera', label: 'Camera' },
  { value: 'Surveying', label: 'Surveying' },
];

const customStyles = {
  valueContainer: () => ({ height: '30px', paddingLeft: '7px' }),
};

export default ({ onChange, defaultValue, edit }) => (
  <Creatable
    // isSearchable={false}
    isClearable
    placeholder="Select or start to type to add a new type ..."
    onChange={(action) => onChange(action)}
    options={typesList}
    defaultValue={defaultValue}
    isDisabled={edit || false}
    styles={reactSelect}
  />
);
