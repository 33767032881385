import React from 'react';
import { useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
// import { GoogleOAuthProvider } from '@react-oauth/google';
import { css } from '@emotion/react';
import { BirdiTheme } from '@birdi/theme';
import * as authStyles from './styles';
import { useMetaSelector } from '../meta/reducer';

// class App extends React.Component {
const App = ({ Section }: { Section: React.FC }) => {
  const match = useRouteMatch();
  const meta = useMetaSelector();
  const auth = useSelector((state) => state.auth);

  onclose = (event) => {
    event.preventDefault();
    // this.props._updateField({ message: false });
  };
  return (
    <div css={css(authStyles.auth)}>
      <div css={css(authStyles.background)} />
      <div css={css(authStyles.authContainer)}>
        <div
          css={(theme: BirdiTheme) =>
            css(authStyles.whiteBackgroundContainer(theme))
          }
        >
          <Section match={match} />
        </div>
      </div>
    </div>
  );
};

export default App;
