import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import BirdiLogoSVG from '@birdi/icons/birdi-logo-navy.svg';
import { css } from '@emotion/react';

const VerifyYourEmail = (props) => (
  <div
    css={css`
      text-align: center;
    `}
  >
    <BirdiLogoSVG
      css={css`
        width: 7em;
        height: 5em;
      `}
    />
    <div
      css={css`
        span {
          display: block;
          margin: 1.5em 0;
        }
      `}
    >
      <span>{`Welcome ${props.auth.fname}`}</span>
      <span
        css={css`
          font-weight: bold;
          font-size: 1.2em;
        `}
      >
        Verify your email
      </span>
      <span>
        Before you can login to Birdi, you’ll need to verify your account. We’ve
        sent a verification link to
      </span>
      <span
        css={css`
          font-weight: bold;
        `}
      >
        {props.auth.email}
      </span>
    </div>
  </div>
);

export default connect((state) => ({ auth: state.auth }))(
  withRouter(VerifyYourEmail),
);
